import { types } from 'mobx-state-tree'
import { Mapping } from 'prosemirror-transform'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { BaseModel } from './BaseModel'

type CursorUpdateData = ZInfer<typeof schemas.sockets.CURSOR_UPDATED>

export const RemoteCollaborator = BaseModel.named('RemoteCollaborator')
  .props({
    clientId: types.identifier,
    color: '',
    user: types.model({
      name: '',
      avatar: types.maybeNull(types.string),
    }),
    unscaledOffsetTop: 0,
    positionInfo: types.maybe(
      types.model({
        version: types.number,
        headPosition: types.number,
        anchorPosition: types.number,
      }),
    ),
  })
  .views((self) => ({
    get startPosition(): number | undefined {
      if (self.positionInfo) {
        return Math.min(
          self.positionInfo.headPosition,
          self.positionInfo.anchorPosition,
        )
      }
    },
    get endPosition(): number | undefined {
      if (self.positionInfo) {
        return Math.max(
          self.positionInfo.headPosition,
          self.positionInfo.anchorPosition,
        )
      }
    },
    get gutterTopPosition(): number {
      const { editorZoom } = self.rootStore.view
      return self.unscaledOffsetTop ? self.unscaledOffsetTop / editorZoom : 0
    },
  }))
  .actions((self) => ({
    setUnscaledOffsetTop(value: number) {
      self.unscaledOffsetTop = value
    },
    update({ version, headPosition, anchorPosition }: CursorUpdateData) {
      self.positionInfo = { version, headPosition, anchorPosition }
    },
    applyMapping(mapping: Mapping) {
      if (self.positionInfo) {
        self.positionInfo.anchorPosition = mapping.map(
          self.positionInfo.anchorPosition,
        )
        self.positionInfo.headPosition = mapping.map(
          self.positionInfo.headPosition,
        )
      }
    },
  }))
