import isUUID from 'is-uuid'

import { NodeTypeMap, util } from '@showrunner/codex'

import { ElementNumberState } from './types'

const SCREENPLAY_NODE_TYPES = [
  NodeTypeMap.SCENE_HEADING,
  NodeTypeMap.DIALOGUE,
  NodeTypeMap.DUAL_DIALOGUE,
]
const STUDIO_NODE_TYPES = [
  NodeTypeMap.SLUG,
  NodeTypeMap.DIALOGUE,
  NodeTypeMap.BRACKET,
]

const isDummySlug = (summary: util.BlockSummary): boolean => {
  return util.isSlugSummary(summary) && !!summary.id && isUUID.nil(summary.id)
}

export const extractNumbering = (
  breakdown: util.ScriptBreakdown,
  isScreenplay: boolean,
): ElementNumberState => {
  const relevantTypes = isScreenplay ? SCREENPLAY_NODE_TYPES : STUDIO_NODE_TYPES
  const result: ElementNumberState = {}

  breakdown.getBlockSummaries(relevantTypes).forEach((summary) => {
    const { elementNumber, elementType, id } = summary
    if (isDummySlug(summary)) {
      return
    }
    const found = result[elementType]
    if (found) {
      found.isNumbered = found.isNumbered || !!elementNumber
    } else {
      result[elementType] = {
        nodeExists: true,
        isNumbered: !!elementNumber,
        blocks: [],
      }
    }
    result[elementType]?.blocks?.push({ id, elementNumber })
  })
  return result
}
