import React from 'react'

import classNames from 'classnames'

import { launchExplorerToast } from '@components/Toast'
import { capitalize } from '@util'

import styles from './NameListingInput.module.scss'

export const NameListingInput = ({
  initialValue = '',
  mode,
  itemType,
  onSubmit,
  onCancel,
  icon,
}: {
  mode: 'create' | 'rename'
  itemType: ListingType
  initialValue?: string
  onSubmit: (opts: { name: string }) => Promise<void>
  onCancel: () => void
  icon: string
}) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const [name, setName] = React.useState(initialValue)
  const [loading, setLoading] = React.useState(false)

  const placeholder =
    mode === 'create'
      ? `New ${capitalize(itemType)}`
      : `${capitalize(itemType)} Name`

  const handleSubmit = async () => {
    const trimmedName = name.trim()
    if (trimmedName.length === 0 || trimmedName === initialValue) {
      onCancel()
      return
    }
    try {
      setLoading(true)
      await onSubmit({ name: trimmedName })
      setLoading(false)
      if (mode === 'create') {
        launchExplorerToast({
          message: `Created "${trimmedName}".`,
          type: 'success',
          autoCloseDelayMs: 3000,
        })
      }
    } catch (e) {
      const message =
        mode === 'create'
          ? `Failed to create "${trimmedName}".`
          : `Failed to rename "${initialValue}".`
      launchExplorerToast({
        message,
        type: 'error',
      })
      onCancel()
    }
  }

  React.useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        // we trigger the onBlur handler instead of submitting
        // to avoid a duplicate call in Safari
        ref.current?.blur()
      } else if (e.key === 'Escape') {
        onCancel()
      }
    }
    window.addEventListener('keydown', keydownHandler)
    return () => {
      window.removeEventListener('keydown', keydownHandler)
    }
  })

  return (
    <div
      className={classNames(styles.nameListing, {
        [styles.nameListing_doc]: itemType !== 'folder',
      })}
    >
      <div
        className={classNames(styles.nameListing_icon, {
          [styles.nameListing_icon___disabled]: loading,
        })}
      >
        <i className={icon} />
      </div>
      <input
        ref={ref}
        className={styles.nameListing_input}
        placeholder={placeholder}
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoFocus
        disabled={loading}
        onBlur={handleSubmit}
      />
    </div>
  )
}
