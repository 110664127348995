import { SnapshotSummary } from '@util/ScriptoApiClient/types'

export const snapshotDescription = (snapshot: SnapshotSummary) => {
  // traps for the edge case here
  // https://github.com/showrunner/models/blob/d5fbad213952f0fbb8c35d406586f8cd2308f7ba/lib/dashboard-template-script.js#L76
  switch (snapshot.autoSave) {
    case 'session':
      return 'Writing session ended '
    case 'backup':
      return 'Backup autosaved '
    case 'export':
    case 'prompter':
    case 'rundown':
      return 'Exported '
    case 'copied':
      return 'Copied '
    case 'pushToPrompter':
      return 'Pushed to prompter '
    default:
      return 'Saved '
  }
}
export const snapshotAuthor = (snapshot: SnapshotSummary) => {
  const known = `by ${snapshot.creatorName} `
  switch (snapshot.autoSave) {
    // backups and sessions arent attributed to a user
    case 'session':
    case 'backup':
      return ''
    default:
      return known
  }
}
