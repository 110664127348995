import { useEffect } from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { observer } from 'mobx-react-lite'

import { ModalShell, useModalControls } from '@components/Modals'
import { useMst } from '@state'
import { ILoadedScript, IRundown } from '@state/types'

import { PrompterInnards } from './PrompterInnards'
import { FETCH_SEGMENTS_ERR, PUSH_ERR, PushStatus } from './util'

export const PushToPrompter = observer(function PushToPrompter({
  parent,
}: {
  parent: ILoadedScript | IRundown
}) {
  const { doDebug } = useMst()
  const controls = useModalControls()
  const isLoading = controls.loading
  const candidate = parent.prompterPushCandidate

  useEffect(() => {
    const hydrate = async () => {
      controls.setLoading(true)
      const candidate = parent.createPushCandidate()
      try {
        await doDebug()
        await candidate.loadSegments()
      } catch (e) {
        controls.setErrorMessage(FETCH_SEGMENTS_ERR)
      } finally {
        controls.setLoading(false)
      }
    }

    if (controls.opened && !candidate) hydrate()
  }, [controls, parent, doDebug, candidate])

  const scriptName = parent.name
  const visibleSegments = candidate?.visibleSegments

  const canPush =
    !isLoading &&
    candidate &&
    candidate.visibleSegments.find((vs) => vs.hasText)

  let pushStatus: PushStatus = 'unknown'
  if (!isLoading && !canPush) pushStatus = 'no-content'
  if (canPush && visibleSegments?.length === 1) pushStatus = 'simple'
  if (canPush && (visibleSegments?.length ?? 0) > 1) pushStatus = 'complex'

  const pushToPrompter = async () => {
    controls.setLoading(true)
    try {
      await doDebug()
      await candidate?.pushToPrompter()
      closeHandler()
    } catch (e) {
      controls.setErrorMessage(PUSH_ERR)
    } finally {
      controls.setLoading(false)
    }
  }

  const closeHandler = () => {
    parent.clearPushCandidate()
    controls.onClose()
  }

  return (
    <ModalShell
      cancelLabel="Cancel"
      confirmLabel="Push To Prompter"
      disabled={pushStatus === 'no-content'}
      errorMessage={controls.errorMessage}
      onClose={closeHandler}
      onConfirm={pushToPrompter}
      opened={controls.opened}
      loading={isLoading}
      size={500}
      title="Push To Prompter"
    >
      {visibleSegments && (
        <PrompterInnards
          candidate={candidate}
          pushStatus={pushStatus}
          scriptName={scriptName}
          segments={visibleSegments}
        />
      )}
    </ModalShell>
  )
})

const PushToPrompterModal = NiceModal.create(PushToPrompter)

export const showPushScriptToPrompterModal = (script: ILoadedScript) =>
  NiceModal.show(PushToPrompterModal, { parent: script })

export const showPushRundownToPrompterModal = (rundown: IRundown) =>
  NiceModal.show(PushToPrompterModal, { parent: rundown })
