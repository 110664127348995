import { Space, Text } from '@mantine/core'

export const OnboardingInfo = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Space h={40} />
      <Text ta="center" color="white" size="lg">
        {children}
      </Text>
      <Space h={20} />
    </>
  )
}
