import NiceModal from '@ebay/nice-modal-react'
import { Group, NumberInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { ModalShell } from '@components/Modals/ModalShell'
import { useModalControls } from '@components/Modals/useModalControls'

type CustomZoomModalProps = {
  currentValue: number
  onConfirm: (level: number) => void
}

const VALIDATION_MESSAGE = 'Enter a number between 50 and 300'
const formSchema = z.object({
  level: z.coerce
    .number()
    .int()
    .min(50, { message: VALIDATION_MESSAGE })
    .max(300, { message: VALIDATION_MESSAGE }),
})
type FormSchema = z.infer<typeof formSchema>

const CustomZoom = (props: CustomZoomModalProps) => {
  const controls = useModalControls()
  const { currentValue, onConfirm, ...passThroughProps } = props

  const handleConfirm = () => {
    onConfirm(Number(form.values.level) / 100)
    controls.onClose()
  }

  const form = useForm<FormSchema>({
    validate: zodResolver(formSchema),
  })

  return (
    <ModalShell
      {...passThroughProps}
      title="Zoom"
      confirmLabel="OK"
      size={225}
      onConfirm={form.onSubmit(handleConfirm)}
      opened={controls.opened}
      onClose={controls.onClose}
    >
      <Group justify="center">
        <NumberInput
          clampBehavior="none"
          w={100}
          allowDecimal={false}
          allowNegative={false}
          suffix="%"
          defaultValue={currentValue}
          min={50}
          max={300}
          step={5}
          data-autofocus
          {...form.getInputProps('level')}
        />
      </Group>
    </ModalShell>
  )
}

const CustomZoomModal = NiceModal.create<CustomZoomModalProps>(CustomZoom)

export const showCustomZoomModal = (props: CustomZoomModalProps) => {
  NiceModal.show(CustomZoomModal, props)
}
