export const extractTsRestSuccess = async <
  SuccessResponse extends number,
  T extends { status: number },
>(
  promise: Promise<T>,
  desiredStatus: SuccessResponse,
) => {
  const result = await promise
  if (result.status !== desiredStatus) {
    throw new Error('Unknown server error')
  }
  return result as Extract<T, { status: SuccessResponse }>
}
