import * as codex from '@showrunner/codex'

import { formatSeconds } from '@util'

export type FormattedTiming = {
  total: string
  bracket: string
  dialogue: string
}

export type FormattedSlugTiming = FormattedTiming & {
  title: string
  isBlank: boolean
  isZeroth: boolean
}

export const formatTimeData = ({
  bracketSeconds,
  dialogueSeconds,
}: {
  bracketSeconds: number
  dialogueSeconds: number
}): FormattedTiming => {
  return {
    total: formatSeconds(bracketSeconds + dialogueSeconds),
    dialogue: formatSeconds(dialogueSeconds),
    bracket: formatSeconds(bracketSeconds),
  }
}

type FormattedTimingWithSlugs = {
  timing: FormattedTiming
  slugTiming: FormattedSlugTiming[]
}

export const formatTimeDataWithSlugs = (
  timingData: codex.util.TimingSummary,
): FormattedTimingWithSlugs => {
  // overall timing
  const timing: FormattedTiming = formatTimeData({
    bracketSeconds: timingData.bracketSeconds,
    dialogueSeconds: timingData.dialogueSeconds,
  })

  const result: FormattedTimingWithSlugs = {
    timing,
    slugTiming: [],
  }

  // iterate through the timing data for each slug
  timingData.slugs.forEach((slugTiming) => {
    // we only want to include the zeroth slug if it has
    // timing > 0 (this is existing logic but maybe it would be more
    // correct to include it any time there are brackets or dialogue in it?)
    if (slugTiming.isZerothSlug && slugTiming.totalSeconds === 0) {
      return
    }
    const formattedTiming = formatTimeData(slugTiming)
    const rawTitle = slugTiming.isZerothSlug
      ? 'before first slug'
      : slugTiming.title.trim()
    const isBlank = rawTitle.length === 0
    const title = isBlank ? 'slug' : rawTitle

    result.slugTiming.push({
      ...formattedTiming,
      title,
      isBlank,
      isZeroth: slugTiming.isZerothSlug,
    })
  })
  return result
}
