import { DirectEditorProps, EditorView } from 'prosemirror-view'

import {
  getTextClipboardSerializer,
  ScriptFormatClipboardSerializer,
} from './serializers'

export const createEditorView = ({
  element,
  editorProps,
}: {
  element: HTMLElement
  editorProps: Omit<
    DirectEditorProps,
    'clipboardSerializer' | 'clipboardTextSerializer'
  >
}) => {
  const ev = new EditorView(element, editorProps)

  // pass the editor view into the serializers so that when copy-to-clipboard
  // happens, they can find the latest script format
  ev.props.clipboardTextSerializer = getTextClipboardSerializer(ev)
  ev.props.clipboardSerializer = new ScriptFormatClipboardSerializer(ev)

  return ev
}
