import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import {
  ActionIcon,
  Button,
  Checkbox,
  Code,
  CopyButton,
  Divider,
  Group,
  ScrollArea,
  Textarea,
  Tooltip,
} from '@mantine/core'
import jsonata from 'jsonata'

import { FaIcon } from '@components/FaIcon'
import { ModalShell, useModalControls } from '@components/Modals'
import { OutsideAnchor } from '@components/OutsideAnchor'
import { rundownToPojoArray } from '@components/RundownGrid/helpers/export'
import { Toast } from '@components/Toast'
import { IRundown } from '@state'
import { saveTextToFile } from '@util'

import styles from './ExportAsJsonModal.module.scss'

type Props = {
  rundown: IRundown
}

const CopyToClipboard = ({ value }: { value: string }) => (
  <CopyButton value={value} timeout={2000}>
    {({ copied, copy }) => (
      <Tooltip label={copied ? 'Copied' : 'Copy'} position="left">
        <ActionIcon
          color="gray"
          variant="outline"
          onClick={copy}
          className={styles.copyToClipboardButton}
        >
          {copied ? (
            <FaIcon icon="fa-check" c="green" />
          ) : (
            <FaIcon icon="fa-copy" faIconSet="fa-regular" />
          )}
        </ActionIcon>
      </Tooltip>
    )}
  </CopyButton>
)

const ExportAsJsonModal = NiceModal.create<Props>(({ rundown }) => {
  const controls = useModalControls()
  const [rowData] = React.useState(rundownToPojoArray(rundown))
  const [showJsonata, setShowJsonata] = React.useState(false)

  const [output, setOutput] = React.useState(JSON.stringify(rowData, null, 2))

  const updateOutputJson = (value: unknown) =>
    setOutput(JSON.stringify(value, null, 2))

  const [transformStr, setTransformStr] = React.useState('')
  const [errorMessage, setErrorMessge] = React.useState('')

  const transformAndUpdateOutput = async () => {
    if (transformStr.trim().length > 0) {
      try {
        const expression = jsonata(transformStr)
        const result = await expression.evaluate(rowData)
        updateOutputJson(result)
      } catch (e) {
        const { message } = e as { message?: string }
        setErrorMessge(message ?? 'Error transforming json')
      }
    } else {
      updateOutputJson(rowData) // reset to displaying everything
    }
  }

  const handleShowJsonata = (checked: boolean) => {
    setShowJsonata(checked)
    if (checked) {
      transformAndUpdateOutput()
    } else {
      updateOutputJson(rowData)
    }
  }

  const handleConfirm = () => {
    saveTextToFile({ text: output, fileName: `${rundown.name}.json` })
  }

  const scrollAreaHeight = showJsonata ? '30vh' : '50vh'

  return (
    <ModalShell
      size="xl"
      title="Export rundown as JSON"
      opened={controls.opened}
      onClose={controls.onClose}
      onConfirm={handleConfirm}
      confirmLabel="Download"
      cancelLabel="Close"
    >
      <ScrollArea h={scrollAreaHeight}>
        <Code block h={scrollAreaHeight} className={styles.code}>
          <CopyToClipboard value={output} />
          {output}
        </Code>
      </ScrollArea>
      <Divider />
      <Group>
        <Checkbox
          checked={showJsonata}
          onChange={(e) => handleShowJsonata(e.target.checked)}
          label={
            <span>
              Transform output with&nbsp;
              <OutsideAnchor href="http://help.scripto.live/en/articles/9397134-json-export-from-rundowns">
                JSONata
              </OutsideAnchor>
            </span>
          }
        />
      </Group>
      {showJsonata && (
        <Group justify="stretch" align="flex-start">
          <Textarea
            ff="Courier Prime"
            placeholder="Enter JSONata expression"
            value={transformStr}
            onChange={(e) => {
              setTransformStr(e.target.value)
              setErrorMessge('')
            }}
            classNames={{
              root: styles.textArea,
              input: styles.textAreaInput,
            }}
          />
          <Button
            variant="subtle"
            size="compact-sm"
            onClick={transformAndUpdateOutput}
          >
            Apply
          </Button>
        </Group>
      )}
      {errorMessage.length > 0 && (
        <Toast message={errorMessage} type="error" dismissable={false} />
      )}
    </ModalShell>
  )
})

export const showExportAsJson = (rundown: IRundown) => {
  NiceModal.show(ExportAsJsonModal, { rundown })
}
