import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Checkbox, TextInput } from '@mantine/core'

import { ModalShell, useModalControls } from '@components/Modals'
import { fireAndForget } from '@util'

type PrintOptionsProps = {
  onPrint: (customHeader: string) => void
}

export const PrintOptions = ({ onPrint }: PrintOptionsProps) => {
  const controls = useModalControls()
  const [showCustomHeader, setShowCustomHeader] = React.useState(false)
  const [customHeader, setCustomHeader] = React.useState('')

  const toggleShowCustomHeader = () => {
    if (showCustomHeader) {
      setShowCustomHeader(false)
      setCustomHeader('')
    } else {
      setShowCustomHeader(true)
    }
  }

  const handlePrint = () => {
    controls.onClose()
    // set a timeout to let the options modal go away
    //  before the system modal appears
    setTimeout(() => onPrint(customHeader))
  }

  return (
    <ModalShell
      title="Print Options"
      onConfirm={handlePrint}
      confirmLabel="Print"
      cancelLabel="Cancel"
      size="xs"
      opened={controls.opened}
      onClose={controls.onClose}
    >
      <Checkbox
        size="md"
        checked={showCustomHeader}
        onChange={toggleShowCustomHeader}
        label="Include custom header text"
      />
      {showCustomHeader && (
        <TextInput
          autoFocus
          value={customHeader}
          onChange={(e) => setCustomHeader(e.target.value)}
        />
      )}
    </ModalShell>
  )
}

export const PrintOptionsModal = NiceModal.create(PrintOptions)

export const showPrintOptions = (props: PrintOptionsProps) =>
  fireAndForget(NiceModal.show(PrintOptionsModal, props))
