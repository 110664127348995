import { observer } from 'mobx-react-lite'
import { Redirect } from 'wouter'

import { ServerDownPage } from '@components/ErrorPage'
import { NoShow } from '@components/NoShow'
import { useDocumentTitle } from '@hooks/useDocumentTitle'
import { PopupPlayer } from '@ink/components'
import { findConfigForPathname, ROUTE_PATTERNS } from '@util/pathConfigs'

import * as Layouts from '../layouts'
import { useMst } from '../state'

import { AppRouter } from './AppRouter'
import { LoadingRoute } from './components'
import { OnboardingRouter } from './OnboardingRouter'

// This manages the biggest "sectors" of our app (see docs/app-states.md for an overview)
// Right now it's a bit janky because of the split between choo & react for
// app bootstrapping
export const MainRouter = observer(function MainRouter() {
  const root = useMst()
  useDocumentTitle(root)

  const { loading, hydratingOrg, location, user, loggedIn, serverDown } = root

  // The inkPlayer route is only in popup windows launched by us. (i.e.
  // If you navigate directly to /inkplayer this won't render)
  if (location.pathname === ROUTE_PATTERNS.inkPlayer && window.opener) {
    return <PopupPlayer />
  }

  const routeConfig = findConfigForPathname(location.pathname)

  if (serverDown) {
    return (
      <Layouts.ScrollingBody>
        <ServerDownPage />
      </Layouts.ScrollingBody>
    )
  }

  if (loading || hydratingOrg) {
    return <LoadingRoute />
  }

  // A valid URL that does not require an org context (whether or not authenticated
  // goes to the OnboardingRouter)
  const onboardingRouteSpecified = routeConfig && !routeConfig.requiresOrg
  if (onboardingRouteSpecified) {
    return <OnboardingRouter />
  }

  // At this point if you're not logged in we must redirect you to /login
  // If your URL is valid, we'll preserve it with ?returnTo=
  if (!loggedIn) {
    const to = routeConfig ? location.loginRedirect : ROUTE_PATTERNS.login
    return <Redirect to={to} />
  }

  if (user.orgMemberships.length === 0) {
    return (
      <Layouts.ScrollingBody>
        <NoShow />
      </Layouts.ScrollingBody>
    )
  }

  return <AppRouter />
})
