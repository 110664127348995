import { useState } from 'react'

import { Checkbox } from '@mantine/core'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { useMst } from '@state'

const staffable = (email: string) => /.@scripto.(cc|computer|live)/.test(email)

export function StaffCheckbox({
  user,
}: {
  user: ZInfer<typeof schemas.GetUserByStaffResponse>
}) {
  const mst = useMst()
  const { email, id: userId, staff } = user
  const isSelf = mst.user.id === userId
  const [isStaff, setIsStaff] = useState(staff)

  const onChange = async () => {
    await mst.apiClient.updateUserByStaff({ userId, staff: !isStaff })
    setIsStaff(!isStaff)
  }

  return (
    <Checkbox
      label="Staff"
      onChange={onChange}
      checked={isStaff}
      disabled={isSelf || !staffable(email)}
    />
  )
}
