import { Group, Text } from '@mantine/core'

export const TimingBreakdown = (props: {
  dialogue: string
  bracket: string
}) => (
  <>
    <Group justify="space-between">
      <Text c="dark.3">Dialogue</Text>
      <Text c="dark.3">{props.dialogue}</Text>
    </Group>
    <Group justify="space-between">
      <Text c="dark.3">Brackets</Text>
      <Text c="dark.3">{props.bracket}</Text>
    </Group>
  </>
)
