import React from 'react'

import { useElementSize, useTimeout } from '@mantine/hooks'

export const useDebouncedElementSize = (
  debounceIntervalMillis: number = 250,
) => {
  const [height, setHeight] = React.useState(0)
  const [width, setWidth] = React.useState(0)
  const { ref, ...unbounced } = useElementSize()

  const lastUpdateMillis = React.useRef(0)

  const updateState = () => {
    setHeight(unbounced.height)
    setWidth(unbounced.width)
    lastUpdateMillis.current = new Date().valueOf()
  }

  const valueHasChanged =
    height !== unbounced.height || width !== unbounced.width
  const isInitalState = height === 0 && width === 0

  const timeout = useTimeout(updateState, debounceIntervalMillis)

  if (valueHasChanged) {
    const timeSinceLastUpdate = new Date().valueOf() - lastUpdateMillis.current
    const isLongEnough = timeSinceLastUpdate > debounceIntervalMillis
    if (isInitalState || isLongEnough) {
      updateState()
    } else {
      timeout.start()
    }
  }

  return {
    ref,
    height,
    width,
  }
}
