import { Button, CopyButton, Table, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { showAlert, showError } from '@components/Modals'
import { useInviteData } from '@components/Settings/useInviteData'
import { IInvite, IOrg } from '@state/types'

import styles from './MemberTableRow.module.scss'

export const InviteTableRow = observer(function InviteTableRow({
  invite,
  workspace,
}: {
  invite: IInvite
  workspace: IOrg
}) {
  const { invalidateInviteQuery } = useInviteData(workspace)
  const revokeInvite = async () => {
    try {
      await invite.revoke()
      invalidateInviteQuery()
      showAlert({
        title: 'Success',
        children: 'Invite revoked',
      })
    } catch (e) {
      showError({ message: 'Failed to revoke invite' })
    }
  }

  return (
    <Table.Tr className="membertable_row___pending">
      <Table.Td>
        <Text fz={14} c="dimmed">
          {invite.email}
        </Text>
      </Table.Td>
      <Table.Td>
        <CopyButton
          value={`${window.location.origin}/signup?invite=${invite.id}`}
          timeout={3000}
        >
          {({ copied, copy }) => (
            <Button
              variant="subtle"
              size="xs"
              onClick={copy}
              leftSection={
                copied ? (
                  <FaIcon icon="fa-check" c="green" />
                ) : (
                  <FaIcon icon="fa-link" faIconSet="fa-regular" />
                )
              }
            >
              <Text fz={14} fs="italic" c="dimmed">
                {copied
                  ? 'Pending invitation link copied to clipboard'
                  : 'Pending invitation'}
              </Text>
            </Button>
          )}
        </CopyButton>
      </Table.Td>

      <Table.Td className={styles.membertable_action}>
        <Button
          variant="subtle"
          onClick={revokeInvite}
          loading={invite.isRevoking}
        >
          <Text fz={15}>Revoke Invite</Text>
        </Button>
      </Table.Td>
    </Table.Tr>
  )
})
