import { MutableRefObject, useCallback, useRef } from 'react'
// This hook bridges the old callback style ref with the useRef hook.
// We need this for any 3rd party components that use callback refs
// (e.g. to blur the script title input when the user hits "Enter")
// Might not be needed if this gets fixed:  https://github.com/JedWatson/react-input-autosize/issues/188
export function useCallbackRef<T>() {
  const ref = useRef<T | null>(null)
  const setRef = useCallback((node: T) => {
    ref.current = node
  }, [])

  return [ref, setRef] as [MutableRefObject<T>, (node: T) => void]
}
