import React from 'react'

import { Overlay } from '@mantine/core'
import { useClickOutside } from '@mantine/hooks'

import { ConfirmModalProps, showConfirmModal } from '@components/Modals'

// we need the overlay z-index lower than the normal 200
// because we're going to put a modal up and the modal needs to
// be ablvoe both the overlay and the editor
const overlayZIndex = 190
// editor needs to be above the modal
const editorZIndex = overlayZIndex + 1

// If you click outside, this component will do one of three things
// depending on the current value of the behavior prop:
//
// 1. 'block' stops the click event and does nothing
//
// 2. 'confirm' triggers a modal to ask the user for confirmation. If the
//    user confirms, the onClickOutside callback is trigered
//
// 3. 'passthrough' No overlay is created, the click outside callback is
//     always triggered (this just plain old useClickOutside)
//
export type ClickOutsideBehavior = 'block' | 'confirm' | 'passthrough'
export const ClickOutsideConfirmation = ({
  confirmationProps,
  onClickOutside,
  children,
  behavior,
}: {
  behavior: ClickOutsideBehavior
  confirmationProps: Omit<ConfirmModalProps, 'onConfirm'>
  promptForConfirmation?: Omit<ConfirmModalProps, 'onConfirm'>
  onClickOutside: () => void
  children: React.ReactNode
}) => {
  // when we're not blocking, we'll use this to capture
  // the click outside, otherwise the overlay will capture
  // it. The difference is that this will not block the click
  const clickOutsideRef = useClickOutside(() => {
    onClickOutside()
  })

  const handleClick = async () => {
    if (behavior === 'confirm') {
      const confirmed = await showConfirmModal(confirmationProps)
      if (confirmed) {
        onClickOutside()
      }
    }
  }

  return (
    <div>
      {behavior !== 'passthrough' && (
        <Overlay
          zIndex={overlayZIndex}
          fixed
          opacity={0}
          onClick={handleClick}
        />
      )}
      <div
        ref={behavior === 'passthrough' ? clickOutsideRef : undefined}
        style={{
          position: 'relative',
          zIndex: editorZIndex,
        }}
      >
        {children}
      </div>
    </div>
  )
}
