import { ButtonMode } from '@state/types'

const MAX_VISIBLE_COLLAB = 3

export function jumpToCollaborator(user: { clientId: string }) {
  if (!user || user.clientId === '') return
  const collaborator = document.getElementById(`remote-user-${user.clientId}`)

  // sidestep stale clientIds (ie: the remote user refreshed the page)
  if (collaborator) {
    collaborator.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export const getMaxCollaborators = (mode: ButtonMode) =>
  mode === 'normal' ? MAX_VISIBLE_COLLAB : 1
