import cn from 'classnames'
import type { Node as PmNode } from 'prosemirror-model'
import { Plugin, TextSelection } from 'prosemirror-state'
import { Decoration, DecorationSet, DirectEditorProps } from 'prosemirror-view'

import { NodeTypeKey, NodeTypeMap, ScriptDocType } from '@showrunner/codex'

import { PAGE_WIDGET_ORDER } from '@choo-app/lib/editor/plugins/inlinePageBreaks'
import { cursorInDualDialogueBlock, nextNodeOnEnter } from '@util'

import { PluginFactory } from './types'

const getLastTextBlock = (doc: PmNode) => {
  const { lastChild } = doc
  const hasFormatInfo = lastChild?.type.name === NodeTypeMap.FORMAT_INFO

  const lastBlock = hasFormatInfo
    ? doc.child(doc.childCount - 2).lastChild
    : lastChild?.lastChild
  const hasContent = lastBlock && lastBlock.content.size > 0
  return { lastBlock, hasContent }
}

const endOfLastTextBlock = (doc: PmNode): number => {
  const { lastChild } = doc
  const hasFormatInfo = lastChild?.type.name === NodeTypeMap.FORMAT_INFO

  return hasFormatInfo
    ? doc.content.size - lastChild.nodeSize - 1
    : doc.content.size - 1
}

const handleClick: DirectEditorProps['handleClick'] = (editorView, _, evt) => {
  if (
    evt.target instanceof HTMLElement &&
    evt.target.classList.contains('ensure-newline-target')
  ) {
    // exit early if not editable
    if (!editorView.editable) {
      return false
    }

    const { tr, doc } = editorView.state
    const { lastBlock, hasContent } = getLastTextBlock(doc)

    if (lastBlock && hasContent) {
      const finalBlockPos = endOfLastTextBlock(doc)

      const nextBlock = nextNodeOnEnter(
        lastBlock.type.name as NodeTypeKey,
        doc.attrs.docType as ScriptDocType,
        cursorInDualDialogueBlock(editorView.state),
      )

      tr.insert(finalBlockPos, editorView.state.schema.node(nextBlock))
        .setSelection(TextSelection.create(tr.doc, finalBlockPos))
        .scrollIntoView()
      editorView.dispatch(tr)
    }
    return true
  }
  return false
}

const buildDecoration = ({
  finalBlockPos,
  forceVisible,
}: {
  finalBlockPos: number
  forceVisible: boolean
}): Decoration =>
  Decoration.widget(
    finalBlockPos,
    () => {
      const elt = document.createElement('div')
      elt.className = cn('ensure-newline-target', { visible: forceVisible })
      return elt
    },
    {
      side: PAGE_WIDGET_ORDER.ensureNewline,
      key: 'ensure-newline',
    },
  )

// Plugin adds a widget decoration at the bottom of the script. Clicking
// on it will create a new blank block at the end if the last block
// is not empty. This is designed to help collaborators append to
// a doc without colliding on other folks' work/breaking blocks.
export const ensureNewlinePlugin: PluginFactory = ({ mst }) =>
  new Plugin({
    props: {
      decorations(editorState) {
        const decoration = buildDecoration({
          finalBlockPos: endOfLastTextBlock(editorState.doc),
          forceVisible: mst.view.isDebugEnabled('show-newline'),
        })

        return DecorationSet.create(editorState.doc, [decoration])
      },
      handleClick,
    },
  })
