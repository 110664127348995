import { observer } from 'mobx-react-lite'
import { redo, undo } from 'prosemirror-history'

import { Toolbar } from '@components/Toolbar'
import { detectedPlatform, Keys, useShortcuts } from '@hooks/useShortcuts'
import { useMst } from '@state'

const { CMD, SHIFT } = Keys

export const UndoRedo = observer(function UndoRedo() {
  const { currentScript } = useMst()
  const editorState = currentScript?.observableEditor?.editorState
  const editorView = currentScript?.observableEditor?.editorView

  const canUndo = editorState && undo(editorState)
  const canRedo = editorState && redo(editorState)
  const redoKeys = detectedPlatform === 'mac' ? [CMD, SHIFT, 'Z'] : [CMD, 'Y']

  const { getItemProps } = useShortcuts({
    undo: {
      keys: [CMD, 'Z'],
      action: () => {
        if (editorState) undo(editorState, editorView?.dispatch)
      },
      disabled: !canUndo,
    },
    redo: {
      keys: redoKeys,
      action: () => {
        if (editorState) redo(editorState, editorView?.dispatch)
      },
      disabled: !canRedo,
    },
  })

  const undoProps = getItemProps('undo')
  const redoProps = getItemProps('redo')

  return (
    <>
      <Toolbar.Button
        icon="fa-mail-reply"
        tooltip={`Undo (${undoProps.shortcut})`}
        focusEditor
        {...undoProps}
      />
      <Toolbar.Button
        icon="fa-mail-forward"
        tooltip={`Redo (${redoProps.shortcut})`}
        focusEditor
        {...redoProps}
      />
    </>
  )
})
