/*
  Duration values are stored in the database as an integer value of seconds
  and they're displayed in the grid in one of two ways:

  For duration and cumulativeDuration column types, they're shown like you'd
  see on a digital clock-- e.g. 2:34 is 2 minutes and 34 seconds (in the database
  its 154)

  For front and back timing, the accrued duration is applied to a known start/end
  time which is displayed as a date in the grid. The rundown-wide value for start-time
  is stored in the database as a Date but the front/back timing are not stored as they
  are computed.

  When copying/pasting these types of values, we want to capture a string representation
  that makes sense if you're pasting into a spreadsheet but one that also works if
  you paste into our grid.

  This is achieved with a combination of these AgGrid helpers:
  * valueGetters: look at the stored values in the database and figure out a new raw
    value to show in the grid. In cumulative duration this gives us the sum
    of all the durations for this row and above. In front/back, this puts together a
    Date
  * valueFormatters: These are used to transform the integer and Date values when displaying
    the value in ag-grid. This is what makes 91 into 1:31 or turns a raw Date object into
    a formatted start time.
  * valueParser: if you paste 1:23 into a duration field, this decodes that to mean 83 seconds
  * exporters: When we copy to the clipboard or (in the future) export values,
    we'd normally just get a string-- so a duration of 83 would turn into '83'. The
    exporters are where we change the result of a valueGetter into one useful for OTHER
    software (similar to valueFormatters but for external consumption)
*/

import * as AgGrid from '@ag-grid-community/core'
import { addSeconds } from 'date-fns'

import { util } from '@showrunner/codex'

import { showError } from '@components/Modals'
import { IRundown } from '@state/types'
import { formatSeconds } from '@util'
import {
  EnrichedRowData,
  isGridBlobKey,
  stripBlobKeyPrefix,
} from '@util/rundowns'
import { RundownRowData } from '@util/ScriptoApiClient/types'

export const durationFormatter: AgGrid.ValueFormatterFunc = ({ value }) => {
  return typeof value !== 'number' ? '' : formatSeconds(value)
}

export const durationExporter = (value: unknown) => {
  if (typeof value === 'number') {
    return formatSeconds(value)
  }
  return value
}

const durationValuesForRow = (
  params: AgGrid.ValueGetterParams<EnrichedRowData>,
):
  | {
      colId: string
      rowDuration: number
      cumulative: number
    }
  | undefined => {
  const { data: rowData, colDef } = params
  if (rowData && isGridBlobKey(colDef.field)) {
    const colId = stripBlobKeyPrefix(colDef.field)
    const rowDuration = rowData.blobData[colId]
    if (typeof rowDuration === 'number') {
      return {
        colId,
        rowDuration,
        cumulative: rowData.durations[colId],
      }
    }
  }
}

// if the duration column for this row/col cumulative column is populated
// with a number, return the sum of all durations including this one
export const cumulativeGetter: AgGrid.ValueGetterFunc<EnrichedRowData> = (
  params,
): number | undefined => {
  const durations = durationValuesForRow(params)
  if (durations) {
    return durations.cumulative
  }
}

// Factory to build getter that adds elapsed time to the rundown startTime
// This is used for frontTiming and endTiming
const buildStartTimeGetter =
  (includeCurrentRow: boolean) =>
  (params: AgGrid.ValueGetterParams<EnrichedRowData>): Date | undefined => {
    const { context } = params
    const rundown = context as IRundown
    const { startTime } = rundown.blobData
    const durations = durationValuesForRow(params)
    if (startTime && durations) {
      // subtract out the current row
      const elapsedTime = includeCurrentRow
        ? durations.cumulative
        : durations.cumulative - durations.rowDuration
      return addSeconds(startTime, elapsedTime)
    }
  }

// Front timing is shown if this row has a number in the duration column
// but does not include the value of the current row. It only works if there's
// a start time specified and returns a date of the start time + cumulative NOT
// including the current row
export const frontTimingGetter = buildStartTimeGetter(false)

// End timing is exactly like front timing EXCEPT it includes the current row
export const endTimingGetter = buildStartTimeGetter(true)

// back timing is essentially "what time does this segment need to start
// so that we end at the specfied time". We get it by seeing how much
// remaining duration there is NOT including this row and subtracting that
// from the end time
export const backTimingGetter = (
  params: AgGrid.ValueGetterParams<EnrichedRowData>,
): Date | undefined => {
  const { context } = params
  const rundown = context as IRundown
  const { endTime } = rundown.blobData
  const durations = durationValuesForRow(params)
  if (endTime && durations) {
    const totalDuration = rundown.getTotalDurationForColumn(durations.colId)
    if (totalDuration) {
      const remainingTime =
        totalDuration - (durations.cumulative - durations.rowDuration)
      return addSeconds(endTime, -1 * remainingTime)
    }
  }
}

export const timeFormatter: AgGrid.ValueFormatterFunc<RundownRowData> = (
  params,
) => {
  if (params.value instanceof Date) {
    return params.value.toLocaleTimeString()
  }
  return ''
}

// When parsing duration inputs, invalid values are rejected
// and an error message is shown. Valid inputs are converted to
// non-negative integers or undefined (when clearing out the cell)
export const durationParser: AgGrid.ValueParserFunc = ({
  newValue,
  oldValue,
}) => {
  const cleanedInput = typeof newValue === 'string' ? newValue.trim() : ''
  if (cleanedInput.length === 0) {
    // We use null to convey that an existing value needs to be reset to
    // null (all blob data values must be JSON serializable).
    return null
  }

  const parsed = util.parseTimingString(cleanedInput)
  if (isNaN(parsed)) {
    showError({
      title: 'Invalid Duration',
      message: `Sorry! Scripto doesn’t recognize “${cleanedInput}” as a duration.`,
    })
    return oldValue
  }
  return parsed
}
