// We start the app in the loading state, but we also re-enter it if we switch orgs.
// It's important that we clear out all the org-specific model instances while they're
// not bound to any rendered components... here's where we do that.
import React from 'react'

import { Loader } from '@components/Loader'
import { useMst } from '@state'
export const LoadingRoute = () => {
  const mst = useMst()

  React.useEffect(() => {
    mst.resetOrgState()
  }, [mst])

  return <Loader fullScreen visible />
}
