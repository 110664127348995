import React from 'react'

import NiceModal from '@ebay/nice-modal-react'

import { Toast } from '@components/Toast'

import { ModalShell } from './ModalShell'
import { useModalControls } from './useModalControls'

type ErrorModalProps = {
  opened: boolean
  onClose: () => void
  title?: string
  message: React.ReactNode
  buttonLabel?: string
}

const ErrorModalBase = ({
  opened,
  onClose,
  title = 'Error',
  message,
  buttonLabel = 'OK',
}: ErrorModalProps) => {
  return (
    <ModalShell
      size="xs"
      title={title}
      opened={opened}
      onClose={onClose}
      onConfirm={onClose}
      confirmLabel={buttonLabel}
    >
      <Toast
        message={message}
        dismissable={false}
        type="error"
        icon="fas fa-circle-exclamation"
      />
    </ModalShell>
  )
}

type NiceErrorProps = Omit<ErrorModalProps, 'opened' | 'onClose'>
const NiceErrorModal = NiceModal.create((props: NiceErrorProps) => {
  const { opened, onClose } = useModalControls()
  return <ErrorModalBase {...props} opened={opened} onClose={onClose} />
})

export const showError = (props: NiceErrorProps | React.ReactNode) => {
  if (typeof props === 'string' || React.isValidElement(props)) {
    NiceModal.show(NiceErrorModal, { message: props })
  } else {
    NiceModal.show(NiceErrorModal, props as NiceErrorProps)
  }
}
