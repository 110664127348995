import React from 'react'

import { EditorState, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { blockInfoPluginKey } from '@choo-app/lib/editor/plugins/block-info'
import { docInfoPluginKey } from '@choo-app/lib/editor/plugins/doc-info'
import { ILoadedScript } from '@state'
import { BlockInfo } from '@util/constants'

export type ElementNumberInventory = BlockInfo[]

export const elementNumbersPluginKey = new PluginKey<ElementNumberInventory>(
  'elementNumbers',
)

export const getElementNumberInventory = (
  state: EditorState,
): ElementNumberInventory => {
  const blockInfo = blockInfoPluginKey.getState(state)
  const docInfo = docInfoPluginKey.getState(state)

  // besides displaying existing element numbers, we also need to display a placeholder
  // when we know that *other* elements are present in the document
  return (
    blockInfo?.filter((b) => docInfo?.elementNumbers[b.type]?.isNumbered) ?? []
  )
}

const gutterDecorations = ({
  inventory,
  script,
}: {
  inventory: ElementNumberInventory
  script: ILoadedScript
}): Decoration[] => {
  const onChange = (e: Event, id: string) => {
    const evt = e as unknown as React.ChangeEvent<HTMLInputElement>
    const { value } = evt.currentTarget
    script.updateElementNumber({ id, value })
  }

  return inventory.map(({ id, pos, elementNumber }) => {
    return Decoration.widget(
      pos + 1,
      () => {
        const elt = document.createElement('input')
        elt.type = 'text'
        elt.innerHTML = ''
        elt.value = elementNumber ?? ''
        elt.placeholder = '#'
        elt.maxLength = 5
        elt.className = 'element-number-input'
        elt.onchange = (e) => onChange(e, id)
        return elt
      },
      {
        key: id + elementNumber,
        stopEvent: () => true,
        ignoreSelection: true,
        side: -1,
      },
    )
  })
}

export const getDecorations = ({
  state,
  script,
}: {
  script: ILoadedScript
  state: EditorState
}) => {
  const inventory = elementNumbersPluginKey.getState(state)
  if (!inventory) return DecorationSet.empty

  const decos = gutterDecorations({ inventory, script })
  return DecorationSet.create(state.doc, decos)
}
