import styles from './StaffBadges.module.scss'

const betaFlagClasses = `o-badge o-badge--gray ${styles.spaced}`
const debugFlagClasses = `o-badge o-badge--green ${styles.spaced}`

export const StaffBadges = ({
  flags,
  debugFlags = [],
}: {
  flags: string[]
  debugFlags?: string[]
}) => {
  if (debugFlags.some((f) => f === 'hideFlags')) return null

  return (
    <>
      {flags.map((f, idx) => (
        <div key={idx} className={betaFlagClasses}>
          {f}
        </div>
      ))}
      {debugFlags.map((f, idx) => (
        <div key={idx} className={debugFlagClasses}>
          {f}
        </div>
      ))}
    </>
  )
}
