import React from 'react'

import { Divider, Group, Stack } from '@mantine/core'

import {
  ColorTextInputWithPreview,
  ResetButton,
  Swatches,
} from '@components/ColorPicker'

export const ColorPalette = ({
  color,
  onChange,
  inputLabel = 'Text color',
}: {
  color: string
  onChange: (val: string) => void
  inputLabel?: string
}) => {
  const [activeColor, setActiveColor] = React.useState(color)

  const changeHandler = (val: string) => {
    setActiveColor(val)
    onChange(val)
  }

  return (
    <Stack pt={10} px={10} gap={15}>
      <Swatches onClick={changeHandler} activeColor={activeColor} />
      <Divider />
      <Group gap={10} justify="space-between" align="end">
        <ColorTextInputWithPreview
          label={inputLabel}
          color={activeColor}
          onChange={setActiveColor}
          onSubmit={changeHandler}
        />
        <ResetButton
          onClick={() => changeHandler('')}
          disabled={activeColor === ''}
        />
      </Group>
    </Stack>
  )
}
