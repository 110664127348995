import NiceModal from '@ebay/nice-modal-react'
import { Container, Group, Space, Stack } from '@mantine/core'

import { ScriptDocType } from '@showrunner/codex'

import { ModalShell, useModalControls } from '@components/Modals'
import { useMst } from '@state'

import { modalShortcuts } from './helpers'
import { ShortcutTable } from './Table'

import styles from './Shortcuts.module.scss'

const NAV_COL_WIDTH = 450
const EL_COL_WIDTH = 260

type ShortcutsModalProps = {
  isSafari?: boolean
  scriptType?: ScriptDocType
}

const Shortcuts = ({
  isSafari = false,
  scriptType = 'screenplay',
}: ShortcutsModalProps) => {
  const controls = useModalControls()
  const mst = useMst()
  const platform = mst.view.isDebugEnabled('windows') ? 'win' : undefined
  const shortcuts = modalShortcuts(platform)
  const navRows = Object.entries(shortcuts.navigation)
  const formattingRows = Object.entries(shortcuts.formatting)
  const elementRows = Object.entries(shortcuts.elements[scriptType])

  const modalWidth = isSafari ? NAV_COL_WIDTH : NAV_COL_WIDTH + EL_COL_WIDTH

  return (
    <ModalShell
      title="Keyboard Shortcuts"
      size={modalWidth}
      opened={controls.opened}
      onClose={controls.onClose}
    >
      <Group gap="xl" align="flex-start" grow>
        <Container className={styles.shortcuts_container} size={NAV_COL_WIDTH}>
          <Stack gap={5}>
            <ShortcutTable title="Navigation" rows={navRows} />
            <Space h="md" />
            <ShortcutTable title="Formatting" rows={formattingRows} />
          </Stack>
        </Container>
        {!isSafari && (
          <Container className={styles.shortcuts_container} size={EL_COL_WIDTH}>
            <Stack gap={5}>
              <ShortcutTable title="Elements" rows={elementRows} />
            </Stack>
          </Container>
        )}
      </Group>
    </ModalShell>
  )
}

export const ShortcutsModal = NiceModal.create(Shortcuts)

export const showShortcutsModal = (props: ShortcutsModalProps) =>
  NiceModal.show(ShortcutsModal, props)
