import { observer } from 'mobx-react-lite'

import { OrgPermissionMap } from '@showrunner/codex'

import { StaffBadges } from '@components/Staff/Badges'
import { Toolbar } from '@components/Toolbar'
import { useNavigation } from '@hooks'
import { IRundown, useMst } from '@state'

import { RundownTitle } from './DocumentTitle'
import { HeaderTimestamp } from './HeaderTimestamp'
import { MiniHeader } from './MiniHeader'
import { RundownSyncStatus } from './RundownSyncStatus'

export const RundownHeader = observer(function RundownHeader({
  rundown,
}: {
  rundown: IRundown
}) {
  const { currentOrg, location, user, view } = useMst()
  const { navigateToFolder, navigateToScript } = useNavigation()

  const {
    prefersSplitLayout,
    prefersRowLayout,
    prefersColumnLayout,
    exitSplitLayout,
    updateLayoutPreference,
  } = user

  const scriptId = location.getPathParam('scriptId')

  const handleCloseRundown = () => {
    exitSplitLayout()
    if (scriptId) {
      navigateToScript(scriptId)
    } else {
      navigateToFolder(rundown.folderId)
    }
  }

  return (
    <Toolbar>
      <MiniHeader.LeftSection>
        <RundownTitle
          rundown={rundown}
          readOnly={
            !user.currentOrgPermissions?.includes(
              OrgPermissionMap.UPDATE_RUNDOWNS,
            )
          }
        />
        <HeaderTimestamp
          at={rundown.contentsModifiedAt}
          userName={rundown.lastModifier?.name}
          createdAt={rundown.createdAt}
        />
        {user.staff && currentOrg && (
          <StaffBadges
            flags={currentOrg.betaFlags}
            debugFlags={view.debugFlags}
          />
        )}
      </MiniHeader.LeftSection>
      <MiniHeader.RightSection>
        <RundownSyncStatus rundown={rundown} />
        <Toolbar.Button
          icon="fa-table-rows"
          active={prefersSplitLayout && prefersRowLayout}
          onClick={() => updateLayoutPreference('rows')}
        />
        <Toolbar.Button
          icon="fa-table-columns"
          active={prefersSplitLayout && prefersColumnLayout}
          onClick={() => updateLayoutPreference('columns')}
        />
        <Toolbar.Button icon="fa-xmark" onClick={handleCloseRundown} />
      </MiniHeader.RightSection>
    </Toolbar>
  )
})
