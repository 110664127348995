import { Anchor } from '@mantine/core'
import cn from 'classnames'

import styles from './Paginator.module.scss'

export type Direction = 'left' | 'right'
type DirectionClickHandler = (direction: Direction) => void

type PaginatorProps = {
  from: number
  to: number
  total: number
  canPageForward: boolean
  canPageBackward: boolean
  onClick: DirectionClickHandler
  mode?: 'mini' | 'normal'
}

function PaginatorButton({
  direction,
  disabled,
  onClick,
  mode = 'normal',
}: {
  direction: Direction
  disabled?: boolean
  onClick: DirectionClickHandler
  mode?: 'mini' | 'normal'
}) {
  const isNormal = mode === 'normal'
  const iconClasses = cn(
    'fa',
    direction === 'left' ? 'fa-chevron-left' : 'fa-chevron-right',
  )
  const buttonClasses = cn({
    [styles.pagination_link___first]: disabled,
  })

  return (
    <Anchor
      className={buttonClasses}
      size={isNormal ? 'sm' : 'xs'}
      onClick={() => {
        if (!disabled) onClick(direction)
      }}
    >
      {isNormal && direction === 'right' && (
        <span className={styles.pagination_label}>Next</span>
      )}
      <span className={styles.pagination_icon}>
        <i className={iconClasses}></i>
      </span>
      {isNormal && direction === 'left' && (
        <span className={styles.pagination_label}>Previous</span>
      )}
    </Anchor>
  )
}

export function Paginator({
  from,
  to,
  total,
  canPageBackward,
  canPageForward,
  onClick,
  mode = 'normal',
}: PaginatorProps) {
  const handleClick = (direction: Direction) => {
    if (onClick) {
      onClick(direction)
    }
  }

  const label = `${from}-${to} of ${total}`

  const paginatorClass = cn({
    [styles.pagination___box]: mode === 'normal',
    [styles.pagination___snapshots]: mode === 'mini',
  })

  return (
    <div className={paginatorClass}>
      <PaginatorButton
        direction="left"
        onClick={handleClick}
        disabled={!canPageBackward}
        mode={mode}
      />
      <div className="is-light">{label}</div>
      <PaginatorButton
        direction="right"
        onClick={handleClick}
        disabled={!canPageForward}
        mode={mode}
      />
    </div>
  )
}
