import React from 'react'

import { ActionIcon, Overlay, Space, Stack, Text } from '@mantine/core'
import { useElementSize, useInterval } from '@mantine/hooks'
import { RouteComponentProps } from 'wouter'

import { FaIcon } from '@components/FaIcon'
import { Toolbar } from '@components/Toolbar'
import { useMst, useNavigation } from '@hooks'
import { useUnloadRouteEffect } from '@routes'

import { HistoryAndBody } from './HistoryAndBody'
import { SnapshotLandToolbar } from './toolbar'
import { useScriptData } from './useSnapshotLandData'

import styles from './SnapshotLand.module.scss'

// how often to poll for new snapshots when the query is mounted and
// we're in the foreground
const CHECK_FOR_LATEST_INTERVAL_MS = 60 * 1000

const Title = ({ name }: { name?: string }) => {
  if (name) {
    return (
      <Text span>
        Snapshot history for&nbsp;
        <Text span fw="bold">
          {name}
        </Text>
      </Text>
    )
  }
  return <Text span>Snapshot history</Text>
}

export const SnapshotLand = ({
  params,
}: RouteComponentProps<{ scriptId: string }>) => {
  const { view } = useMst()
  const { navigate } = useNavigation()
  const scriptQuery = useScriptData(params.scriptId)
  const { ref: sizeRef, width } = useElementSize()

  React.useEffect(() => {
    view.snapshotLand.initializeForScript(params.scriptId)
  }, [params.scriptId, view.snapshotLand])

  const interval = useInterval(() => {
    view.snapshotLand.checkForNewer(params.scriptId)
  }, CHECK_FOR_LATEST_INTERVAL_MS)

  React.useEffect(() => {
    interval.start()
    return interval.stop
  }, [interval])

  // when we leave snapshotLand, we remove all the snapshot
  // state from mst
  useUnloadRouteEffect(() => {
    view.snapshotLand.tearDownSnapshotLand()
  })

  const exitSnapshotLand = () => {
    navigate(view.explorerState.lastScriptPath ?? `/scripts/${params.scriptId}`)
  }

  return (
    <Overlay backgroundOpacity={1} fixed ref={sizeRef}>
      <Stack className={styles.snapshotLand} gap={0}>
        <Toolbar>
          <Toolbar.Section position="left">
            <Space h={20} />
            <FaIcon icon="fa-clock-rotate-left" />
            <Title name={scriptQuery.data?.script.name} />
          </Toolbar.Section>
          <Toolbar.Section position="right">
            <ActionIcon onClick={exitSnapshotLand}>
              <FaIcon c="dark.9" icon="fa-xmark" size="18" />
            </ActionIcon>
          </Toolbar.Section>
        </Toolbar>
        <SnapshotLandToolbar scriptId={params.scriptId} />
        <HistoryAndBody scriptId={params.scriptId} width={width} />
      </Stack>
    </Overlay>
  )
}
