import React from 'react'

import { Notification, Popover, UnstyledButton } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'
import { useScriptMultiClick } from '@hooks'

import { useRundownLinks } from './useRundownLinks'

export const ScriptLink = ({
  scriptId,
  blockId,
}: {
  scriptId: string
  blockId?: string | null
}) => {
  const { goToBlock, goToScript, showLinkError, dismissLinkError } =
    useRundownLinks({ scriptId })

  const ref = React.useRef<HTMLButtonElement>(null)

  const handleClick = () => {
    if (blockId) {
      goToBlock(blockId)
    } else {
      goToScript(ref.current)
    }
  }

  useScriptMultiClick<HTMLButtonElement>({
    ref,
    onClick: handleClick,
  })

  return (
    <Popover
      withinPortal
      opened={showLinkError}
      onClose={dismissLinkError}
      shadow="md"
      position="bottom-start"
      styles={{ dropdown: { padding: 0 } }}
    >
      <Popover.Target>
        <UnstyledButton ref={ref}>
          <FaIcon
            color="violet"
            fixedWidth
            icon={blockId ? 'fa-right-from-line' : 'fa-file'}
          />
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <Notification color="red" onClose={dismissLinkError}>
          This element is no longer in the script
        </Notification>
      </Popover.Dropdown>
    </Popover>
  )
}
