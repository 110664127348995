import { Button, Text } from '@mantine/core'

import { launchMoveDocModal } from '@components/FolderExplorer/DotDotDotMenu'
import { showAsyncConfirmModal } from '@components/Modals'
import { IListing } from '@state'

import styles from './TrashedActions.module.scss'

export const Restore = ({ listing }: { listing: IListing }) => (
  <Button
    variant="outline"
    color="dark"
    size="xs"
    onClick={() =>
      launchMoveDocModal({
        listing,
        operation: 'restore',
        isCurrentScript: true,
      })
    }
  >
    Restore
  </Button>
)

export const Destroy = ({
  name,
  onConfirm,
}: {
  name: string
  onConfirm: () => Promise<void>
}) => (
  <Button
    classNames={{ root: styles.destroy_root }}
    variant="outline"
    color="red"
    size="xs"
    onClick={() =>
      showAsyncConfirmModal({
        dangerous: true,
        title: 'Delete Permanently',
        confirmLabel: 'Delete',
        children: (
          <Text>
            Are you sure you want to delete&nbsp;
            <Text span fw="bold">
              &quot;{name}&quot;
            </Text>
            ? This cannot be undone.
          </Text>
        ),
        onConfirm,
        errorMessage: `Failed to delete “${name}”`,
      })
    }
  >
    Delete permanently
  </Button>
)
