import { types } from 'mobx-state-tree'

import {
  findCommentMark,
  scrollCommentPanel,
} from '@components/Comments/domHelpers'
import { notEmptyFilter } from '@util'
import { CommentMeta } from '@util/ScriptoApiClient/types'

import { BaseModel } from './BaseModel'
import { IsoDate } from './IsoDate'
import { CommentEventPayload } from './SocketManager/helpers'

// Note: our database/api model does some gross stuff with comments--
// cominging the concept of a thread and a comment. The CommentModel
// reflects this. If it's got a snippet and parentId is null, then it's
// a combination of a thread and the first comment in the thread.
export const CommentModel = BaseModel.named('CommentModel')
  .props({
    id: types.identifier,
    parentId: types.maybeNull(types.string),
    snippet: types.maybeNull(types.string),
    createdAt: IsoDate,
    updatedAt: IsoDate,
    creator: types.model({
      id: types.string,
      name: types.string,
      avatar: types.maybeNull(types.string),
    }),
    // such bullshit! since the first comment is ALSO the thread when
    // it's deleted, we need to keep it around as a placeholder. oy!
    // whoever created this datamodel is not coming to my birthday party
    text: types.maybeNull(types.string),
    resolvedAt: types.maybeNull(IsoDate),
    deletedAt: types.maybeNull(IsoDate),
    // special flag that only applies to the dummy comment we create
    // for an unsaved new thread
    isUnsavedThread: false,
  })
  .views((self) => ({
    get threadId() {
      return self.parentId ?? self.id
    },

    isInThread(threadId: string): boolean {
      return self.id === threadId || self.parentId === threadId
    },

    get isEditable(): boolean {
      return self.creator.id === self.rootStore.user.id
    },
  }))
  .actions((self) => ({
    findMarkElement(): HTMLElement | null {
      return findCommentMark(self.threadId)
    },
    setUnresolved(timestamp: string) {
      self.resolvedAt = null
      self.updatedAt = IsoDate.create(timestamp)
    },
    setResolved(timestamp: string) {
      self.resolvedAt = IsoDate.create(timestamp)
      self.updatedAt = IsoDate.create(timestamp)
    },
    setDeleted(timestamp: string) {
      self.deletedAt = IsoDate.create(timestamp)
      self.updatedAt = IsoDate.create(timestamp)
    },
    setUndeleted() {
      self.deletedAt = null
    },
    update({ text, timestamp }: { text: string; timestamp: string }) {
      self.text = text
      self.updatedAt = IsoDate.create(timestamp)
    },
  }))

type CreatedAt = { createdAt: Date }
const sortAsc = (a: CreatedAt, b: CreatedAt) => {
  return a.createdAt.valueOf() - b.createdAt.valueOf()
}

export const CommentData = BaseModel.named('CommentData')
  .props({
    // the keys are threadIds and the values are the number
    // of comments in that thread
    threadCountMap: types.map(types.number),
    commentMap: types.map(CommentModel),
    panelOpen: false,
    selectedThreadId: types.maybe(types.string),
    selectedCommentId: types.maybe(types.string),
  })
  .views((self) => ({
    get allComments() {
      return Array.from(self.commentMap.values())
    },
    get undeletedComments() {
      return this.allComments.filter((c) => !c.deletedAt)
    },
    // this does the work of pretending we'd modelled comments and threads
    // separately.
    getThread(id: string) {
      const rootComment = self.commentMap.get(id)
      if (rootComment && rootComment.parentId === null) {
        const comments = this.allComments
          .filter((c) => c.threadId === rootComment.id && !c.deletedAt)
          .sort(sortAsc)

        return {
          id: rootComment.id,
          // in reality, all root comments have a snippet
          // in the database, but it's not guaranteed
          snippet: rootComment.snippet ?? '',
          resolvedAt: rootComment.resolvedAt,
          updatedAt: rootComment.updatedAt,
          comments,
          rootComment,
        }
      }
    },
    get threads() {
      return (
        this.allComments
          .map((c) => this.getThread(c.id))
          .filter(notEmptyFilter)
          // when a thread consists of all deleted comments, we treat the thread
          // as deleted
          .filter((thread) => thread.comments.some((c) => !c.deletedAt))
      )
    },
    get threadBeingAdded() {
      return this.threads.find((t) => t.rootComment.isUnsavedThread)
    },
    // comments aren't marked as resolved, threads are. So for a
    // particular comment we need to find its thread and ascertain if
    // it's resolved
    inResolvedThread(commentId: string): boolean {
      const threadId = self.commentMap.get(commentId)?.threadId
      if (threadId) {
        return !!this.getThread(threadId)?.resolvedAt
      }
      return false
    },
    getSnippetForComment(commentId: string): string | undefined {
      const threadId = self.commentMap.get(commentId)?.threadId
      if (threadId) {
        return this.getThread(threadId)?.snippet
      }
    },
  }))
  .actions((self) => ({
    openPanel() {
      self.panelOpen = true
    },
    closePanel() {
      self.panelOpen = false
      self.selectedCommentId = undefined
      self.selectedThreadId = undefined
    },
    selectThread(threadId: string, commentId?: string) {
      self.selectedThreadId = threadId
      self.selectedCommentId = commentId
    },
    deselectAll() {
      self.selectedCommentId = undefined
      self.selectedThreadId = undefined
    },
    setThreadCounts(threadCounts: { [key: string]: number }) {
      self.threadCountMap.replace(threadCounts)
    },
    updateCounts(
      { commentId, parentId }: CommentEventPayload,
      type: 'added' | 'deleted',
    ) {
      const rootId = parentId ?? commentId
      const existingCount = self.threadCountMap.get(rootId) ?? 0
      const newCount = type === 'added' ? existingCount + 1 : existingCount - 1
      if (newCount > 0) {
        self.threadCountMap.set(rootId, newCount)
      } else {
        self.threadCountMap.delete(rootId)
      }
    },
    ingestComment(payload: CommentMeta) {
      self.commentMap.put(payload)
    },
    ingestCommentList(payload: CommentMeta[]) {
      payload.forEach(this.ingestComment)
    },
    ensureUnsavedThread({ snippet, id }: { snippet: string; id: string }) {
      if (self.threadBeingAdded?.id === id) {
        return
      }
      const { user } = self.rootStore
      const now = new Date()
      self.commentMap.put({
        id,
        snippet,
        parentId: null,
        text: '',
        createdAt: now,
        updatedAt: now,
        creator: {
          id: user.id,
          name: user.name,
          avatar: user.avatar,
        },
        resolvedAt: null,
        deletedAt: null,
        isUnsavedThread: true,
      })
      self.selectedThreadId = id
      this.openPanel()
    },
    clearUnsavedThread() {
      const { threadBeingAdded } = self
      if (threadBeingAdded) {
        self.commentMap.delete(threadBeingAdded.id)
      }
    },
    handleClickCommentBubble(commentIds: string[]) {
      const threadId = commentIds.find((cid) => {
        const comment = self.commentMap.get(cid)
        return comment && !comment.resolvedAt
      })
      if (threadId) {
        this.openPanel()
        this.selectThread(threadId)
        scrollCommentPanel(threadId)
      }
    },
  }))
  .actions((self) => ({
    async refreshCounts(scriptId: string) {
      try {
        const counts = await self.apiClient.getCommentCounts({ scriptId })
        self.setThreadCounts(counts)
      } catch (err) {
        self.log.warn('error fetching comment counts', { scriptId, err })
      }
    },
    async loadComment(params: { commentId: string; scriptId: string }) {
      const payload = await self.apiClient.getComment(params)
      self.ingestComment(payload)
      self.ingestCommentList(payload.replies)
    },
    async loadThread(params: { commentIds: string[]; scriptId: string }) {
      const payload = await self.apiClient.getCommentThreads(params)
      payload.forEach((threadData) => {
        self.ingestComment(threadData)
        self.ingestCommentList(threadData.replies)
      })
    },
    async loadHistory(scriptId: string) {
      const result = await self.apiClient.fetchCommentHistory({ scriptId })
      self.ingestCommentList(result)
      // Now we have to do this terrible thing... some comments will have
      // parentIds for parents that weren't returned because they were deleted
      // (the COMMENT was deleted but the THREAD was not). So we need to find them
      // and fetch them
      const missingThreads = new Set<string>()
      self.undeletedComments.forEach((c) => {
        if (!self.getThread(c.threadId)) {
          missingThreads.add(c.threadId)
        }
      })
      await Promise.all(
        Array.from(missingThreads.values()).map((threadId) =>
          this.loadThread({
            scriptId,
            commentIds: [threadId],
          }),
        ),
      )
    },
    processSocketMessage(payload: CommentEventPayload) {
      switch (payload.eventType) {
        case 'COMMENT_ADDED':
          self.updateCounts(payload, 'added')
          this.loadComment(payload)
          break
        case 'COMMENT_DELETED':
          self.commentMap.get(payload.commentId)?.setDeleted(payload.timestamp)
          self.updateCounts(payload, 'deleted')
          break
        case 'COMMENT_RESOLVED':
          self.threadCountMap.delete(payload.commentId)
          self
            .getThread(payload.commentId)
            ?.rootComment.setResolved(payload.timestamp)
          break
        case 'COMMENT_UNRESOLVED':
          self
            .getThread(payload.commentId)
            ?.rootComment.setUnresolved(payload.timestamp)
          this.refreshCounts(payload.scriptId)
          break
        case 'COMMENT_UPDATED':
          self.commentMap.get(payload.commentId)?.update(payload)
          break
      }
    },
  }))
