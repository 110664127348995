import React from 'react'

import { Group, ScrollArea, SegmentedControl, Stack, Text } from '@mantine/core'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { ILoadedScript, useMst } from '@state'
import { buildDateSortFn } from '@util'

import * as Parts from './CommentParts'
import { scrollCommentPanel } from './domHelpers'
import { ThreadBase } from './Thread'
import { CommentPojo, ThreadPojo } from './types'

import styles from './CommentHistoryFeed.module.scss'

const FORMAT_STR = 'MMMM d, yyyy'
const getDisplayDate = (dt: Date) => {
  const todayStr = format(new Date(), FORMAT_STR)
  const result = format(dt, FORMAT_STR)
  return result === todayStr ? 'Today' : result
}

const updatedAtSortFn = buildDateSortFn('updatedAt', 'desc')
type Chunk<T extends { updatedAt: Date }> = {
  title: string
  items: T[]
}
const sortAndChunkByUpdatedAt = <T extends { updatedAt: Date }>(
  data: T[],
): Chunk<T>[] => {
  const result: Chunk<T>[] = []
  data.sort(updatedAtSortFn).forEach((item) => {
    const currentChunk: Chunk<T> | undefined = result[result.length - 1]
    const title = getDisplayDate(item.updatedAt)
    if (title === currentChunk?.title) {
      currentChunk.items.push(item)
    } else {
      result.push({
        title,
        items: [item],
      })
    }
  })
  return result
}

type CommentFilter = 'open' | 'resolved'
const FilterPicker = ({
  filter,
  setFilter,
}: {
  filter: CommentFilter
  setFilter: (value: CommentFilter) => void
}) => (
  <SegmentedControl
    value={filter}
    data={[
      {
        value: 'open',
        label: 'Open',
      },
      {
        value: 'resolved',
        label: 'Resolved',
      },
    ]}
    onChange={(value) => {
      setFilter(value === 'open' ? 'open' : 'resolved')
    }}
  />
)

const EMPTY_LABELS = {
  open: {
    title: 'No open comments',
  },
  resolved: {
    title: 'No resolved comment threads',
  },
}

const EmptyFeed = ({ filter }: { filter: CommentFilter }) => (
  <Text ta="center" pt={15} pb={15}>
    {EMPTY_LABELS[filter].title}
  </Text>
)

export const FeedWrapper = ({
  children,
  maxHeight,
  filter,
  setFilter,
}: {
  children: React.ReactNode
  maxHeight: number
  filter: CommentFilter
  setFilter: (value: CommentFilter) => void
}) => (
  <div className={styles.commentHistoryFeed_wrapper}>
    <Group className={styles.commentHistoryFeed_topBar} justify="space-between">
      <Text fw="bold">Comments</Text>
      <Group gap={5}>
        <FilterPicker filter={filter} setFilter={setFilter} />
      </Group>
    </Group>
    <ScrollArea.Autosize mah={maxHeight}>
      <Stack className={styles.commentHistoryFeed_scrollArea} gap={10}>
        {children}
      </Stack>
    </ScrollArea.Autosize>
  </div>
)

export const OpenFeed = ({
  comments,
  onJumpTo,
  getSnippet,
}: {
  getSnippet: (commentId: string) => string | undefined
  comments: CommentPojo[]
  onJumpTo: (commentId: string) => void
}) => {
  const chunks = sortAndChunkByUpdatedAt(comments)

  return (
    <>
      {chunks.map((ch) => (
        <React.Fragment key={ch.title}>
          <Text fw="bold" size="sm">
            {ch.title}
          </Text>
          {ch.items.map((c) => (
            <Parts.FeedComment
              key={c.id}
              creator={c.creator}
              text={c.text}
              snippet={getSnippet(c.id)}
              onClick={() => onJumpTo(c.id)}
            />
          ))}
        </React.Fragment>
      ))}
      {chunks.length === 0 && <EmptyFeed filter="open" />}
    </>
  )
}

export const ResolvedFeed = ({
  threads,
  onUnresolveThread,
}: {
  threads: Array<ThreadPojo & { orphaned: boolean }>
  onUnresolveThread: (threadId: string) => void
}) => {
  const chunks = sortAndChunkByUpdatedAt(threads)

  return (
    <>
      {chunks.map((ch) => (
        <React.Fragment key={ch.title}>
          <Text size="sm" fw="bold">
            {ch.title}
          </Text>
          {ch.items.map((thread) => (
            <ThreadBase
              key={thread.id}
              mode="feed"
              thread={thread}
              showSnippet
              resolveOption={thread.orphaned ? 'none' : 'unresolve'}
              operations={{
                changeThreadResolution: () => onUnresolveThread(thread.id),
              }}
            />
          ))}
        </React.Fragment>
      ))}
      {threads.length === 0 && <EmptyFeed filter="resolved" />}
    </>
  )
}

// mobx wiring, all the UI comes from the above component
export const CommentHistoryFeed = observer(function CommentHistoryFeed({
  script,
  onClose,
}: {
  script: ILoadedScript
  onClose: () => void
}) {
  const { view } = useMst()
  const maxHeight = view.dimensions.scriptScroller.height - 100
  const [filter, setFilter] = React.useState<'open' | 'resolved'>('open')
  const { commentMap, selectThread, panelOpen, openPanel } = script.commentData

  const scrollScriptAndPanel = (threadId: string, commentId?: string) => {
    selectThread(threadId, commentId)
    script.goToCommentMark(threadId)
    if (panelOpen) {
      scrollCommentPanel(threadId, commentId)
    } else {
      openPanel()
    }

    onClose()
  }

  const handleJumpToComment = (commentId: string) => {
    const comment = commentMap.get(commentId)
    if (comment) {
      scrollScriptAndPanel(comment.threadId, comment.id)
    }
    onClose()
  }

  const handleUnresolve = async (threadId: string) => {
    await script.unresolveCommentThread(threadId)
    scrollScriptAndPanel(threadId)
    onClose()
  }

  const getSnippetForComment = (commentId: string) => {
    return script.commentData.getSnippetForComment(commentId)
  }

  return (
    <FeedWrapper filter={filter} setFilter={setFilter} maxHeight={maxHeight}>
      {filter === 'open' && (
        <OpenFeed
          getSnippet={getSnippetForComment}
          comments={script.openComments}
          onJumpTo={handleJumpToComment}
        />
      )}
      {filter === 'resolved' && (
        <ResolvedFeed
          threads={script.resolvedAndOrphanThreads}
          onUnresolveThread={handleUnresolve}
        />
      )}
    </FeedWrapper>
  )
})
