import { FolderListPayload, OrgPayload } from '../types'

import { ApiConfig } from './types'

export const getOrg: ApiConfig<string, OrgPayload> = (orgId: string) => ({
  method: 'GET',
  url: `/orgs/${orgId}`,
})

export const getOrgFolders: ApiConfig<
  string,
  { folders: FolderListPayload }
> = (orgId) => ({
  method: 'GET',
  url: `/orgs/${orgId}/folders`,
})

export const getOrgIdForResource: ApiConfig<
  {
    resourceId: string | number
    resourceType: 'org' | 'rundown' | 'script' | 'folder'
  },
  { orgId: string }
> = (data) => ({
  method: 'POST',
  url: `/orgs/find`,
  data,
})
