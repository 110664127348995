import { ActionIcon, Code } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'

export const CopyableId = ({ id }: { id: string }) => (
  <div>
    <Code>{id.slice(0, 7)}</Code>
    <ActionIcon
      variant="subtle"
      onClick={() => navigator.clipboard.writeText(id)}
    >
      <FaIcon icon="fa-copy" />
    </ActionIcon>
  </div>
)
