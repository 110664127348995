import React from 'react'

import { ActionIcon, Divider, Group, Menu, Stack, Text } from '@mantine/core'

import {
  ColorTextInput,
  ColorTextInputWithPreview,
  isHex,
  ResetButton,
  Swatches,
} from '@components/ColorPicker'
import { FaIcon } from '@components/FaIcon'
import { BLACKISH_TEXT } from '@theme/colors'

import styles from './TextColorPicker.module.scss'

// display black for missing values and white for all invalid hexcodes
const toDisplayColor = (color: string) =>
  color === '' ? BLACKISH_TEXT : isHex(color) ? color : '#fff'

export const TextColorPicker = ({
  pendingColor,
  currentColor,
  originalColor,
  onChange,
  originalColors,
}: {
  pendingColor: string
  currentColor: string
  originalColor: string
  onChange: (val: string) => void
  originalColors: string[]
}) => {
  const [opened, setOpened] = React.useState(false)

  const displayColor = toDisplayColor(pendingColor)
  const canRevert =
    pendingColor !== currentColor && currentColor !== originalColor

  return (
    <Stack gap={2}>
      <Menu width={450} shadow="xl" opened={opened} onChange={setOpened}>
        <Text fw="bold">Text color</Text>
        <ColorTextInput
          defaultToBlack
          showSwatch
          color={pendingColor}
          onChange={onChange}
          onSubmit={onChange}
        />
        <Menu.Dropdown>
          <Stack pt={20} px={10} gap={15}>
            <ActionIcon
              className={styles.colorPicker_closeButton}
              onClick={() => setOpened(false)}
            >
              <FaIcon c="dark" icon="fa-xmark" />
            </ActionIcon>
            <Stack gap={5}>
              <Text fw="bold" c="gray.7" size="sm">
                Standard palette
              </Text>
              <Swatches activeColor={displayColor} onClick={onChange} />
            </Stack>
            {originalColors.length > 0 && (
              <Stack gap={5}>
                <Text fw="bold" c="gray.7" size="sm">
                  Script palette
                </Text>
                <Swatches
                  colors={[originalColors]}
                  activeColor={displayColor}
                  onClick={onChange}
                />
              </Stack>
            )}
            <Divider />
            <Group mb={8} justify="space-between" align="flex-start">
              <ColorTextInputWithPreview
                defaultToBlack
                label="Text color"
                onChange={onChange}
                onSubmit={onChange}
                color={pendingColor}
              />
              <Group gap={5} mt={20}>
                <ResetButton
                  onClick={() => onChange(currentColor)}
                  disabled={!canRevert}
                  label="Revert to previous"
                ></ResetButton>
                <ResetButton
                  onClick={() => onChange(originalColor)}
                  disabled={pendingColor === originalColor}
                  label="Reset to default"
                />
              </Group>
            </Group>
          </Stack>
        </Menu.Dropdown>
      </Menu>
    </Stack>
  )
}
