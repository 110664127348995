import { Plugin, PluginKey } from 'prosemirror-state'

import { NodeTypeMap } from '@showrunner/codex'

import { getConfigData } from './configData'
import { PluginFactory } from './types'

/**
 * Since the new pagination model shares the schema with the old one, we
 * can wind up with a schema-valid doc that has multiple structural pages
 * (which we don't want)
 *
 * This plugin removes structural pagination by joining all pages into
 * a single page (the first one)
 *
 * If the document's pagination type is inline (vs none) and if any of
 * the pages were manual page breaks, the first node within
 * that page is given the attribute pageBreak=manual
 *
 * This plugin does not compute the new dynamic pagination, it just scrubs
 * old pagination.
 */
const key = new PluginKey('removeStructuralPagination')
export const removeStructuralPaginationPlugin: PluginFactory = () => {
  return new Plugin({
    key,
    appendTransaction(transactions, oldState, editorState) {
      const { paginationType } = getConfigData(editorState)

      if (
        paginationType === 'structural' ||
        oldState.doc.eq(editorState.doc) ||
        // don't depaginate for other users' transactions
        transactions.some((t) => t.getMeta('collab$'))
      ) {
        return null
      }

      // At this point we know we have some extraneous structural pages so we
      // iterate through the pages of the doc and remove/convert as needed
      const { tr } = editorState
      editorState.doc.content.forEach((child, offset, index) => {
        // Only perform this on page nodes that are not the first
        if (child.type.name !== NodeTypeMap.PAGE || index === 0) {
          return
        }
        const page = child
        // map the pos for previous pages we joined
        const pagePos = tr.mapping.map(offset)
        // If this page was a manual break, we will save that information
        // if the paginationType is inline, by setting pageBreak="manual"
        // on the first block of the page
        const markInlineManualBreak =
          !page.attrs.dynamic && paginationType === 'inline'
        if (markInlineManualBreak) {
          const pageStartBlock = page.content.firstChild
          if (pageStartBlock) {
            tr.setNodeMarkup(pagePos + 1, undefined, {
              ...pageStartBlock.attrs,
              pageBreak: 'manual',
            })
          }
        }

        tr.join(pagePos)
      })

      return tr
    },
  })
}
