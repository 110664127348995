import { schemas, ZInfer } from '@showrunner/scrapi'

import { FormatBlockName, FormatOption, FormatOptionValue } from '@util/formats'

export type BulkChangeHandler = <Op extends FormatOption>(
  blockName: FormatBlockName,
  changes: Record<Op, FormatOptionValue<Op>>,
) => void

export type ConfigurableBlockType = ZInfer<
  typeof schemas.scriptFormats.StandardBlocks
>

export type BlockConfigurations = ZInfer<
  typeof schemas.scriptFormats.BlockConfigurations
>

// 96px / 9.6ch per inch
export const calcLeftMarginIn = (ch: number) => 1.5 + ch / 9.6

export const calcLeftMarginCh = (inch: number) =>
  Math.round((inch - 1.5) * 9.6 * 10) / 10

export const calcRightMarginIn = (leftMarginIn: number, widthCh: number) =>
  Math.round((8.5 - leftMarginIn - (widthCh * 9.6) / 96) * 10) / 10

export const calcWidthCh = (leftMarginIn: number, rightMarginIn: number) =>
  Math.round((((8.5 - leftMarginIn - rightMarginIn) * 96) / 9.6) * 100) / 100

export const isBlockTooNarrow = (width: number) => (width * 9.6) / 96 < 1
