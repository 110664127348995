import { Menu, Text, UnstyledButton } from '@mantine/core'
import cn from 'classnames'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { useNavigation } from '@hooks'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util/pathConfigs'

import styles from './OrgSwitcher.module.scss'

const OrgMenuItem = ({
  orgName,
  orgId,
  isCurrent,
  onClick,
}: {
  orgName: string
  orgId: string
  isCurrent: boolean
  onClick: (id: string) => void
}) => (
  <Menu.Item
    className={cn(styles.switcher_workspace, {
      [styles.selected]: isCurrent,
    })}
    onClick={() => onClick(orgId)}
  >
    {orgName}
  </Menu.Item>
)

export const OrgSwitcher = () => {
  const { user, switchOrgAndRelaunch: switchOrg } = useMst()
  const { selectedMembership } = user
  const { navigate } = useNavigation()
  const handleSelectOrg = (orgId: string) => {
    if (selectedMembership?.orgId !== orgId) {
      switchOrg(orgId)
    }
  }

  return (
    <Menu position="bottom-start">
      <Menu.Target>
        <UnstyledButton className={styles.switcher_current}>
          <Text fw="bold">
            {selectedMembership?.name ?? 'Select Show'}&nbsp;
          </Text>
          <span className={styles.switcher_icon}>
            <i className="fa fa-caret-down" />
          </span>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown className={styles.switcher_list}>
        {user.sortedOrgMemberships.map((m) => (
          <OrgMenuItem
            key={m.orgId}
            orgId={m.orgId}
            orgName={m.name}
            onClick={handleSelectOrg}
            isCurrent={selectedMembership?.orgId === m.orgId}
          />
        ))}
        <Menu.Divider />
        <FancyMenuItem
          key="CREATE_NEW"
          onClick={() => navigate(ROUTE_PATTERNS.createWorkspace)}
          iconClass="fa-solid fa-plus"
          title="New workspace"
        />
      </Menu.Dropdown>
    </Menu>
  )
}
