import { Node } from 'prosemirror-model'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { pageTopWidget } from '../inlinePageBreaks/decorations'

export const buildManualDecorations = (doc: Node): DecorationSet => {
  const decorations: Decoration[] = []
  doc.descendants((n, pos) => {
    const isManualBreak = n.attrs?.pageBreak === 'manual'
    if (isManualBreak) {
      decorations.push(pageTopWidget({ pos, isManualBreak }))
    }
  })

  return DecorationSet.create(doc, decorations)
}
