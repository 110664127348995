import React from 'react'

import { reaction } from 'mobx'

import { ILoadedScript } from '@state'
import { SyncStatusLevel } from '@state/models/SyncStatus'
import { DatadogClient } from '@util/datadog'

const ddLog = DatadogClient.getInstance()

export const useSyncStatusEffects = (script: ILoadedScript) => {
  const sendLog = (level: SyncStatusLevel) => {
    const ddInfo = {
      topic: 'sync',
      scriptId: script.id,
      hasUnsavedChanges: script.syncStatus.sendStepsRequired,
    }

    if (level === 'fatal') {
      ddLog.error('user alerted to persistent sync failure', ddInfo)
    } else if (level === 'error') {
      ddLog.warn('sync level error', ddInfo)
    }
  }

  const handleLevelChange = (level: SyncStatusLevel) => {
    if (level === 'fatal') {
      script.setPmEditability(false)
    } else {
      script.setPmEditability(true)
    }
    sendLog(level)
  }

  React.useEffect(() => {
    const reactionDisposer = reaction(
      () => script.syncStatus.status,
      handleLevelChange,
    )
    return reactionDisposer
    // deliberately ignore exhaustive deps for mobx reaction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
