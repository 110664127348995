import React from 'react'

import { Menu } from '@mantine/core'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { StatusBar } from '@components/StatusBar'
import { useMst } from '@state'

export const RowHeightMenu = () => {
  const { user } = useMst()
  const { wrapRundownText } = user.prefs
  const [open, setOpen] = React.useState(false)

  return (
    <Menu onChange={setOpen} opened={open}>
      <Menu.Target>
        <StatusBar.FaActionIcon
          tooltip={open ? undefined : 'Row height'}
          onClick={() => setOpen(true)}
          icon="fa-line-height"
        ></StatusBar.FaActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <FancyMenuItem
          title="Fixed height"
          selected={!wrapRundownText}
          onClick={() => user.updatePreferences({ wrapRundownText: false })}
        />
        <FancyMenuItem
          title="Wrap text"
          selected={wrapRundownText}
          onClick={() => user.updatePreferences({ wrapRundownText: true })}
        />
      </Menu.Dropdown>
    </Menu>
  )
}
