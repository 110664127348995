import { z } from 'zod'

export const slotContentType = z.enum([
  'full-title',
  'short-title',
  'timestamp',
  'page-number',
  'page-number-total',
  'duration',
])

export const staticSlotContent = z.enum([
  'full-title',
  'short-title',
  'timestamp',
  'page-number',
  'page-number-total',
  'duration',
])

// TODO: in the future we can let users style the decorations or
// could configure the presets like the uppercase
// we do for script titles, etc.
export const slotDecoration = z.object({
  // size? underline? bold? uppercase?
  circled: z.boolean().optional(),
  // we can/should loop back to make this more versatile, but i'm in hotfix mode
  large: z.boolean().optional(),
})

export const slotConfig = z.discriminatedUnion('slotType', [
  z.object({
    slotType: z.literal('static'),
    contentType: staticSlotContent,
    decoration: slotDecoration.optional(),
  }),
  // FUTURE: we'll use these other configs soon, not yet
  // use a value from the script row when printing from rundown
  z.object({
    slotType: z.literal('rundown-cell-value'),
    colId: z.string(),
    decoration: slotDecoration.optional(),
  }),
  z.object({
    slotType: z.literal('literal'),
    text: z.string(),
    decoration: slotDecoration.optional(),
  }),
])

const headerOrFooterSlots = z.object({
  left: slotConfig,
  center: slotConfig,
  right: slotConfig,
  // FUTURE: for SNL (and others)
  leftCorner: slotConfig,
  rightCorner: slotConfig,
})
const slotPosition = headerOrFooterSlots.keyof()

const CSS_NUMBER_RE = /[+-]?([0-9]*[.])?[0-9]+/
type CssSizeUnit = 'px' | 'in'
export const cssSize = z.custom<`${number}${CssSizeUnit}`>((val) => {
  return typeof val === 'string' ? CSS_NUMBER_RE.test(val) : false
})

export const pageMarginSizes = z.object({
  top: cssSize,
  left: cssSize,
  right: cssSize,
  bottom: cssSize,
})

export const pageSize = z.object({
  height: cssSize,
  width: cssSize,
})

const orientation = z.enum(['portrait', 'landscape'])
const pageLayout = z.object({
  margins: pageMarginSizes,
  orientation: orientation,
  size: pageSize,
})

const headerOrFooterPrefs = z.object({
  enabled: z.boolean().optional(),
  slots: headerOrFooterSlots.partial(),
  divider: z.boolean().optional(),
  showOnFirstPage: z.boolean().optional(),
})

const headerPrefs = headerOrFooterPrefs.extend({
  includeRundownItemNumber: z.boolean().optional(),
})

export const scriptPrintPreferences = z.object({
  columns: z.boolean().catch(false),
  monochrome: z.boolean().catch(false),
  twoColDialogueSpacing: z
    .union([z.literal(1), z.literal(1.5), z.literal(2)])
    .catch(1),
  headers: headerPrefs,
  footers: headerOrFooterPrefs,
})

export const rundownPrintPrefs = z.object({
  orientation: orientation.catch('portrait'),
  pageSize: z.enum(['letter', 'a4', 'legal', 'ledger']).catch('letter'),
  monochrome: z.boolean().catch(false),
  rowDensity: z.enum(['compact', 'standard', 'loose']).catch('standard'),
})

export type StaticSlotContent = z.infer<typeof slotContentType>
export type SlotPosition = z.infer<typeof slotPosition>
export type SlotConfig = z.infer<typeof slotConfig>
export type PageMarginSizes = z.infer<typeof pageMarginSizes>
export type PageSize = z.infer<typeof pageSize>
export type PageLayout = z.infer<typeof pageLayout>
export type HeaderOrFooterPrefs = z.infer<typeof headerOrFooterPrefs>
export type ScriptPrintPreferences = z.infer<typeof scriptPrintPreferences>
export type RundownPrintPrefs = z.infer<typeof rundownPrintPrefs>

export const DEFAULT_SCRIPT_PREFS: ScriptPrintPreferences = {
  monochrome: false,
  columns: false,
  twoColDialogueSpacing: 1,
  headers: {
    enabled: true,
    showOnFirstPage: false,
    slots: {
      left: {
        slotType: 'static',
        contentType: 'full-title',
      },
      right: {
        slotType: 'static',
        contentType: 'page-number',
      },
    },
  },
  footers: {
    enabled: true,
    slots: {},
  },
}
