import NiceModal from '@ebay/nice-modal-react'
import { NumberInput, NumberInputProps, Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'

import { ModalShell, useModalControls } from '@components/Modals'
import { ILoadedScript } from '@state'
import { settingsSchema, SyncSettings } from '@state/models/SyncStatus'

export const DebugSync = ({ script }: { script: ILoadedScript }) => {
  const controls = useModalControls()

  const form = useForm<SyncSettings>({
    initialValues: { ...script.syncStatus.settings },
    validate: zodResolver(settingsSchema),
  })

  const handleSubmit = () => {
    script.syncStatus.updateSettings({ ...form.values })
    controls.onClose()
  }

  const getProps = (field: keyof SyncSettings): NumberInputProps => ({
    ...form.getInputProps(field),
    size: 'sm',
  })

  return (
    <ModalShell
      title="Debug Sync Settings"
      onConfirm={form.onSubmit(handleSubmit)}
      onClose={controls.onClose}
      confirmLabel="Save"
      cancelLabel="Cancel"
      size="sm"
      opened={controls.opened}
    >
      <Stack>
        <NumberInput label="OK Threshold" {...getProps('okThreshold')} />
        <NumberInput label="Warn Threshold" {...getProps('warnThreshold')} />
        <NumberInput
          label="Modal Error Threshold"
          {...getProps('modalErrorThreshold')}
        />
        <NumberInput
          label="Get steps delay"
          {...getProps('pullDelay')}
          description="Delay in seconds when getting steps"
        />
        <NumberInput
          label="Send steps delay"
          {...getProps('pushDelay')}
          description="Delay in seconds when sending steps"
        />
      </Stack>
    </ModalShell>
  )
}

export const DebugSyncModal = NiceModal.create(DebugSync)
export const showDebugSyncModal = ({ script }: { script: ILoadedScript }) =>
  NiceModal.show(DebugSyncModal, { script })
