import { Plugin, Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { PluginFactory } from '@choo-app/lib/editor/plugins/types'
import { commentDomHelpers } from '@components/Comments'

import {
  COMMENT_MARK_ACTIONS,
  commentActionForTransaction,
  CommentInventory,
  commentsPluginKey,
  getDecorations,
  getOpenThreadIdAtCursor,
  getSavedCommentInventory,
  getSnippetAndPosFromSelection,
} from './helpers'

const mapUnsavedComment = (
  tr: Transaction,
  unsavedComment: CommentInventory['unsavedComment'],
): CommentInventory['unsavedComment'] => {
  if (unsavedComment) {
    return {
      ...unsavedComment,
      pos: tr.mapping.map(unsavedComment.pos),
    }
  }
}

export const commentsPlugin: PluginFactory = ({ script }) => {
  return new Plugin<CommentInventory>({
    state: {
      init(config, state) {
        return getSavedCommentInventory(state)
      },
      apply(tr, pluginState, oldState, newState) {
        const commentAction = commentActionForTransaction(tr, pluginState)
        // if this transaction explicitly moved the cursor selection, check
        // to see if it resulted in us being within a comment. If so AND if the
        // comment panel is open, go ahead and select the comment thread in the
        // side panel
        const activeMarkId =
          tr.selectionSet && script.commentData.panelOpen
            ? getOpenThreadIdAtCursor(newState.selection)
            : undefined

        if (
          oldState.doc.eq(newState.doc) &&
          pluginState.activeMarkId === activeMarkId &&
          !commentAction
        ) {
          return pluginState
        }

        // if the tr is a commentAction use that to create or destroy
        // the unsavedComment otherwise, map its position
        const unsavedComment =
          commentAction === COMMENT_MARK_ACTIONS.CREATE_UNSAVED
            ? getSnippetAndPosFromSelection(newState)
            : commentAction === COMMENT_MARK_ACTIONS.REMOVE_UNSAVED
              ? undefined
              : mapUnsavedComment(tr, pluginState.unsavedComment)

        const newInventory: CommentInventory = {
          ...getSavedCommentInventory(newState),
          unsavedComment,
          activeMarkId,
        }

        return newInventory
      },
    },
    key: commentsPluginKey,
    view: () => ({
      update: (editorView: EditorView) => {
        const pluginState = commentsPluginKey.getState(editorView.state)
        if (!pluginState) {
          return
        }

        const { activeMarkId, unsavedComment } = pluginState

        const { panelOpen, selectedThreadId, selectThread } = script.commentData

        if (unsavedComment) {
          script.commentData.ensureUnsavedThread(unsavedComment)
        } else if (panelOpen) {
          script.commentData.clearUnsavedThread()
          // select thread if a new mark is active
          if (activeMarkId && activeMarkId !== selectedThreadId) {
            selectThread(activeMarkId)
            commentDomHelpers.scrollCommentPanel(activeMarkId)
          }
        }
      },
    }),
    props: {
      decorations(state) {
        return getDecorations(state, script)
      },
    },
  })
}
