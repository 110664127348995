import { ActionIcon } from '@mantine/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { startNewCommentMark } from '@choo-app/lib/editor/plugins/comments'
import { FaIcon } from '@components/FaIcon'
import { ILoadedScript, useMst } from '@state'

import styles from './CreateThreadButton.module.scss'

export const CreateThreadButton = observer(function GutterComments({
  script,
}: {
  script: ILoadedScript
}) {
  const { user } = useMst()
  const pageless = user.prefs.pageless
  const { selectionVerticalCenter } = script.pmDomInfo
  const buttonSize = pageless ? '30' : '40'
  const iconSize = pageless ? '14' : '18'

  const handleClick = () => {
    if (script.observableEditor) {
      const { editorView } = script.observableEditor
      startNewCommentMark(editorView)
    }
  }

  return (
    <ActionIcon
      variant="default"
      className={cn(styles.createThreadButton, {
        [styles.paged]: !pageless,
        [styles.pageless]: pageless,
      })}
      radius="xl"
      style={{
        top: selectionVerticalCenter,
      }}
      size={buttonSize}
      onClick={handleClick}
    >
      <FaIcon size={iconSize} icon="fa-comment-plus" />
    </ActionIcon>
  )
})
