import { Stack, Text } from '@mantine/core'

import { OutsideAnchor } from '@components/OutsideAnchor'

export const Disclaimer = ({ isScreenplay }: { isScreenplay: boolean }) => {
  const blockName = isScreenplay ? 'scene' : 'slug'
  return (
    <Stack mb={10}>
      <Text fw="bold">There are no {blockName}s available to print.</Text>
      <Text>
        When a script contains multiple {blockName}s, each section can be
        printed individually.
      </Text>
      <Text>
        <OutsideAnchor href="https://help.scripto.live/en/articles/3734333-script-text-blocks">
          {`Learn more about ${blockName}s and other block types.`}
        </OutsideAnchor>
      </Text>
    </Stack>
  )
}
