import React from 'react'

import { ActionIcon, Text, Tooltip } from '@mantine/core'
import { compile } from 'path-to-regexp'
import { useLocation } from 'wouter'

import { AnnouncementPopover } from '@components/AnnouncementPopover'
import { FaIcon } from '@components/FaIcon'
import { OutsideAnchor } from '@components/OutsideAnchor'
import { useMst } from '@hooks'
import { SnapshotViewType } from '@state/models/View/SnapshotLandState'
import { ROUTE_PATTERNS } from '@util'

import styles from './SnapshotLandIcon.module.scss'

const toSnapshotLand = compile<{ scriptId: string }>(
  ROUTE_PATTERNS.scriptHistory,
)

const snapshotLandPath = (scriptId: string, view?: SnapshotViewType) => {
  const path = toSnapshotLand({ scriptId })
  const search = view ? `?view=${view}` : ''
  return path + search
}

export const SnapshotLandIcon = ({ scriptId }: { scriptId: string }) => {
  const { user, location, view } = useMst()
  const [, setLocation] = useLocation()

  const handleClick = () => {
    view.snapshotLand.trackSnapshotEvent('ENTER_SNAPSHOT_LAND')
    view.explorerState.setLastScriptPath(location.pathname)
    setLocation(snapshotLandPath(scriptId, user.prefs.snapshotTab))
  }

  if (!view.snapshotLandEnabled) {
    return null
  }

  return (
    <AnnouncementPopover>
      <AnnouncementPopover.Target>
        <Tooltip label="Snapshot history">
          <ActionIcon onClick={handleClick} color="dark.9">
            <FaIcon icon="fa-clock-rotate-left" />
          </ActionIcon>
        </Tooltip>
      </AnnouncementPopover.Target>
      <AnnouncementPopover.Dropdown title="New and improved snapshot history 🎉">
        <Text>
          Now you can compare any two snapshots to each other! You can also
          quickly navigate between snapshots without needing to exit the view.
        </Text>
        <OutsideAnchor
          className={styles.snapshotLandIcon_link}
          href="https://help.scripto.live/en/articles/3625055-snapshots-history-and-comparison-views"
        >
          Learn more about snapshots.
        </OutsideAnchor>
      </AnnouncementPopover.Dropdown>
    </AnnouncementPopover>
  )
}
