import { ResolvedPos } from 'prosemirror-model'
import { TextSelection, Transaction } from 'prosemirror-state'

import { NodeTypeKey, NodeTypeMap } from '@showrunner/codex'

const { BRACKET, PARENTHETICAL } = NodeTypeMap
export const WRAPPED_BLOCKS = [BRACKET, PARENTHETICAL] as const
const PAREN_WRAP_CHARS = ['(', ')']
const BRACKET_WRAP_CHARS = ['[', ']']

type WrappedBlockTuple = typeof WRAPPED_BLOCKS
type WrappedBlock = WrappedBlockTuple[number]

export const isWrappedBlock = (value: string): value is WrappedBlock =>
  WRAPPED_BLOCKS.includes(value as WrappedBlock)

const getWrapChars = (blockType: NodeTypeKey) => {
  if (blockType === PARENTHETICAL) return PAREN_WRAP_CHARS
  if (blockType === BRACKET) return BRACKET_WRAP_CHARS
  return []
}

function parseSingleBlockSelection(tr: Transaction) {
  const { $from } = tr.selection
  const start = $from.start()
  const end = $from.end()
  const blockType = $from.parent.type.name as NodeTypeKey

  return { $from, start, end, blockType }
}

function calcWhitespace($from: ResolvedPos) {
  const { textContent } = $from.parent
  return textContent.length - textContent.trimEnd().length
}

export function checkWrap(tr: Transaction) {
  const { start, end, blockType } = parseSingleBlockSelection(tr)

  if (blockType !== BRACKET && blockType !== PARENTHETICAL) {
    return false
  }

  const [preWrapChar, postWrapChar] = getWrapChars(blockType)
  const startChar = tr.doc.textBetween(start, start + 1)
  const endChar = tr.doc.textBetween(end - 1, end)

  return startChar === preWrapChar && endChar === postWrapChar
}

export function maybeTrimWhitespace(tr: Transaction) {
  const { $from, end } = parseSingleBlockSelection(tr)
  const ws = calcWhitespace($from)
  if (ws > 0) tr.deleteRange(end - ws, end)
  return tr
}

/*
utility function to help figure out if/when/how much to massage
the existing selection after injecting new wrapping characters

i feel like this is overly convoluted, but i wasted more time than
i should have trying to simplify with no joy.
*/
function wrapSelectionAdjustment(tr: Transaction, wasWrapped: boolean) {
  const { start, end } = parseSingleBlockSelection(tr)
  const from = tr.selection.$from.pos
  const to = tr.selection.$to.pos
  const fromAtStart = from === start
  const toAtEnd = to === end
  let fromAdj = 0
  let toAdj = 0

  // previously wrapped blocks require a fair amount of adjustment
  if (wasWrapped) {
    // dont modify plain cursors positioned at the beginning of a block
    const shouldAdjustTo = !(fromAtStart && tr.selection.empty)

    // if the from pos is interior, shift the righthand side of the selection
    // one char to the right
    if (!fromAtStart) fromAdj++
    if (shouldAdjustTo) toAdj++
    // empty blocks are another special case
    if (tr.selection.$from.parent.nodeSize === 2) {
      toAdj--
    }
  } else if (toAtEnd) {
    // if the block wasnt wrapped shift only the righthand
    // side of selections that touch the end of the block
    toAdj--
  }

  // if we started with a plain cursor and the modifications above
  // resulted in drift between the to and from, the 'to' is canonical
  if (tr.selection.empty) fromAdj = toAdj

  return { from, to, fromAdj, toAdj }
}

export function maybeStripWrapChars(tr: Transaction) {
  const { start, end } = parseSingleBlockSelection(tr)
  const wasWrapped = checkWrap(tr)

  // only delete wrapping characters when they are present
  if (wasWrapped) {
    tr.deleteRange(tr.mapping.map(end - 1), tr.mapping.map(end))
    tr.deleteRange(tr.mapping.map(start), tr.mapping.map(start + 1))
  }

  return tr
}

export function maybeAddWrapChars(tr: Transaction, wasWrapped: boolean) {
  const { start, end, blockType } = parseSingleBlockSelection(tr)
  const [preWrapChar, postWrapChar] = getWrapChars(blockType)
  const isWrapped = checkWrap(tr)
  const shouldWrap = !isWrapped && preWrapChar !== undefined

  if (shouldWrap) {
    // calculate adjustments *before* the transaction is mutated
    const { from, to, fromAdj, toAdj } = wrapSelectionAdjustment(tr, wasWrapped)

    // we avoid mapping positions here purposefully
    // we want to add characters where the start
    // and end are *now*, not where they *used to* be
    // because of this, its important to insert the last wrapping
    // character before the first one.
    tr.insertText(postWrapChar, end)
    tr.insertText(preWrapChar, start)

    // preserve the original selection
    tr.setSelection(
      TextSelection.create(
        tr.doc,
        tr.mapping.map(from) + fromAdj,
        tr.mapping.map(to) + toAdj,
      ),
    )
  }

  return tr
}
