import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import {
  Button,
  Checkbox,
  Code,
  Divider,
  Group,
  NumberInput,
  Select,
  Space,
  Stack,
  Text,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { z } from 'zod'

import { ModalShell, useModalControls } from '@components/Modals'
import { ILoadedScript } from '@state'
import { setBlockOverrides } from '@util'
import {
  BlockOverrides,
  blockOverridesParser,
  FormatBlockName,
  FormatOption,
  FormatOptionValue,
  minifyOverrides,
  SingleBlockOverride,
} from '@util/formats'

const BlockType = blockOverridesParser.keyof()

type ValueSetter = <Op extends FormatOption>(
  option: Op,
  value: FormatOptionValue<Op>,
) => void

type Resetter = <Op extends FormatOption>(option: Op) => void

const numberParser = z.coerce.number().int().min(0).max(3)

const BlockTypeCustomizer = observer(
  ({
    overrides,
    setValue,
    resetValue,
  }: {
    overrides: SingleBlockOverride
    setValue: ValueSetter
    resetValue: Resetter
  }) => {
    return (
      <Stack>
        <Checkbox
          label="bold"
          checked={overrides?.bold}
          onChange={(e) => setValue('bold', e.target.checked)}
        />
        <Checkbox
          label="italic"
          checked={overrides?.italic}
          onChange={(e) => setValue('bold', e.target.checked)}
        />
        <NumberInput
          size="sm"
          allowDecimal={false}
          min={0}
          max={3}
          label="Space above"
          description="Between 0 and 3"
          value={overrides?.blockTopMargin}
          onChange={(value) => {
            if (!value) {
              resetValue('blockTopMargin')
            } else {
              const parsed = numberParser.safeParse(value)
              if (parsed.success) {
                setValue('blockTopMargin', parsed.data)
              }
            }
          }}
        />
        <Select
          label="color"
          data={[
            {
              label: 'red',
              value: '#990000',
            },
            {
              label: 'green',
              value: '#009900',
            },
            {
              label: 'blue',
              value: '#000099',
            },
          ]}
          onChange={(value) => {
            if (value) {
              setValue('color', value)
            }
          }}
        />
        <Button variant="subtle" onClick={() => resetValue('color')}>
          Reset color to base
        </Button>
      </Stack>
    )
  },
)

const CustomizeScriptFormat = observer(function CustomizeScriptFormat({
  script,
}: {
  script: ILoadedScript
}) {
  const controls = useModalControls()
  const [originalFormat] = React.useState(script.blockFormats)

  const savedOverrides: BlockOverrides = script.blockOverrides ?? {}
  const [unsavedOverrides, setUnsavedOverrides] =
    React.useState<BlockOverrides>({})

  const setValue = <Op extends FormatOption>({
    blockName,
    option,
    value,
  }: {
    blockName: FormatBlockName
    option: Op
    value: FormatOptionValue<Op>
  }) => {
    const mergedValue: BlockOverrides = {
      ...unsavedOverrides,
      [blockName]: {
        ...unsavedOverrides[blockName],
        [option]: value,
      },
    }

    // this will always be true
    if (script.blockFormats) {
      setUnsavedOverrides(minifyOverrides(script.blockFormats, mergedValue))
    }
  }

  const resetValue = <Op extends FormatOption>({
    blockName,
    option,
  }: {
    blockName: FormatBlockName
    option: Op
  }) => {
    const mergedValue: BlockOverrides = {
      ...unsavedOverrides,
      [blockName]: {
        ...unsavedOverrides[blockName],
        [option]: undefined,
      },
    }

    // this will always be true
    if (script.blockFormats) {
      setUnsavedOverrides(minifyOverrides(script.blockFormats, mergedValue))
    }
  }

  const [selectedBlock, setSelectedBlock] =
    React.useState<FormatBlockName>('action')

  const saveChanges = () => {
    const { editorView } = script.pmEditor
    if (editorView) {
      setBlockOverrides(editorView, unsavedOverrides)
      controls.onClose()
    }
  }

  return (
    <ModalShell
      size={900}
      title="Customize ScriptFormat"
      opened={controls.opened}
      onClose={controls.onClose}
      errorMessage={controls.errorMessage}
      loading={controls.loading}
    >
      <Group wrap="nowrap" align="flex-start">
        <Stack>
          <Select
            label="Pick block to customize"
            data={BlockType.options.sort()}
            value={selectedBlock}
            onChange={(value) => {
              const parsed = BlockType.safeParse(value)
              if (parsed.success) {
                setSelectedBlock(parsed.data)
              }
            }}
          />
          <Divider dir="h" />
          <Space h={10} />
          <BlockTypeCustomizer
            overrides={unsavedOverrides[selectedBlock]}
            setValue={(option, value) =>
              setValue({ option, value, blockName: selectedBlock })
            }
            resetValue={(option) =>
              resetValue({ option, blockName: selectedBlock })
            }
          />
        </Stack>
        <Stack gap={5}>
          <Text>Unsaved overrides</Text>
          <Code
            block
            style={{
              width: 300,
              height: 200,
              overflow: 'auto',
            }}
          >
            {JSON.stringify(unsavedOverrides, null, 2)}
          </Code>
          <Text>Saved Overrides</Text>
          <Code
            block
            style={{
              width: 300,
              height: 200,
              overflow: 'auto',
            }}
          >
            {JSON.stringify(savedOverrides, null, 2)}
          </Code>
        </Stack>
        <Stack gap={5}>
          <Text>Original Format</Text>
          <Code
            block
            style={{
              width: 300,
              height: 400,
              overflow: 'auto',
            }}
          >
            {originalFormat
              ? JSON.stringify(originalFormat[selectedBlock], null, 2)
              : 'error'}
          </Code>
        </Stack>
      </Group>
      <Group gap="xs" justify="center">
        <Button type="submit" onClick={saveChanges}>
          Save
        </Button>
        <Button variant="outline" onClick={controls.onClose}>
          Cancel
        </Button>
      </Group>
    </ModalShell>
  )
})

const CustomizeScriptFormatModal = NiceModal.create(CustomizeScriptFormat)
export const showCustomizeFormatModal = (script: ILoadedScript) => {
  NiceModal.show(CustomizeScriptFormatModal, { script })
}
