import { useState } from 'react'

import { Spinner } from '@components/Spinner'

import { AvatarUpdater } from './AvatarUpdater'
import { ProfileUpdater } from './ProfileUpdater'

import styles from './UserSettings.module.scss'

export const UserSettings = () => {
  const [loading, setLoading] = useState(false)
  return (
    <>
      {loading && <Spinner delayMs={300} />}
      <div className={styles.userSettings_page}>
        <h1>Profile</h1>
        <div className={styles.userSettings_group}>
          <ProfileUpdater setLoading={setLoading} />
          <AvatarUpdater setLoading={setLoading} />
        </div>
      </div>
    </>
  )
}
