import { CSSObject, Global } from '@emotion/react'
import { observer } from 'mobx-react-lite'

import { commentDomHelpers } from '@components/Comments'
import { ILoadedScript } from '@state'

const OPEN_COMMENT_MARK_SELECTOR = commentDomHelpers.openMarkSelector()

const MARK_BACKGROUND_CSS_VAR = '--comment-mark-background'

/*

  COMMENT_MARK_STYLE is a style equivalent to the following
  css (we're using a style so we can DRY up all the selector
  names that need to match across components)

    :root {
      --comment-mark-background: #fefb94;
    }

    [data-thread-id][data-thread-status="open"] {
      background-color: var(--comment-mark-background);
    }

  This sets a default value for the background color of
  comment marks.  At runtime, we create a style to set the
  value of the css var to orange for a selector that targets
  the currently active thread.

*/
const COMMENT_MARK_STYLE: { [key: string]: CSSObject } = {
  ':root': {
    [MARK_BACKGROUND_CSS_VAR]: commentDomHelpers.COMMENT_BACKGROUND.UNSELECTED,
  },
  [OPEN_COMMENT_MARK_SELECTOR]: {
    backgroundColor: `var(${MARK_BACKGROUND_CSS_VAR})`,
    scrollMargin: 50,
  },
}

/*
  this creates a style that looks like:

  [data-thread-id="3EA8D384-ADD3-4878-8374-5CC43021621A"] {
    '--comment-mark-background: #fab007;
  }
*/
const buildSelectedMarkStyle = (
  threadId?: string,
): { [key: string]: CSSObject } | undefined => {
  if (threadId) {
    const selector = commentDomHelpers.idSelector('data-thread-id', threadId)
    return {
      [selector]: {
        [MARK_BACKGROUND_CSS_VAR]:
          commentDomHelpers.COMMENT_BACKGROUND.SELECTED,
      },
    }
  }
}

export const InlineStyles = observer(function InlineStyles({
  script,
}: {
  script: ILoadedScript
}) {
  const { cursorCoords } = script.pmDomInfo

  const styles: { [key: string]: CSSObject } = {
    // if the user cannot interact with comments, we dont advertise them
    ...(script.canAddComments ? COMMENT_MARK_STYLE : {}),
    ...buildSelectedMarkStyle(script.commentData.selectedThreadId),
    '[data-blurred-cursor]': {
      left: cursorCoords.x,
      top: cursorCoords.y,
    },
  }

  return <Global styles={styles} />
})
