import { useQuery, useQueryClient } from '@tanstack/react-query'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { useMst } from '@state'

export const PAGE_SIZE = 20
const HEADER_HEIGHT = '38px'
const INPUT_HEIGHT = '44px'
const SUMMARY_HEIGHT = '26px'
const PAGINATOR_HEIGHT = '52px'

export const stackHeight = `calc(100vh - ${HEADER_HEIGHT})`
export const FILTERTAG_CLASSNAME = 'advanced-search-filter-tag'

export const calcMaxScrollerHeight = ({
  isPaginated,
  filterOffset = 0,
}: {
  isPaginated: boolean
  filterOffset?: number
}) =>
  `calc(100vh
    - ${filterOffset}px
    - ${HEADER_HEIGHT}
    - ${INPUT_HEIGHT}
    - ${SUMMARY_HEIGHT}
    - ${isPaginated ? PAGINATOR_HEIGHT : 0}
  )`

export const hasPhraseOrActiveFilter = (criteria?: SearchCriteria) => {
  if (hasActiveFilter(criteria)) {
    return true
  }

  if (criteria?.phrase) {
    return criteria.phrase.trim().length > 0
  }

  return false
}

export const hideUnpairedQuotes = (phrase: string): string => {
  const quoteCount = phrase.match(/["]/g)?.length ?? 0
  const hasUnpairedQuote = quoteCount % 2 !== 0
  return hasUnpairedQuote ? phrase.replace(/"(?=[^"]*$)/, '') : phrase
}

export const quotify = (phrase: string | undefined) => {
  if (!phrase) return

  // if found, remove any trailing unpaired quotes
  phrase = hideUnpairedQuotes(phrase)

  // isolate quoted substrings
  const alreadyQuoted = phrase.match(/"[^"]+"/g)

  if (alreadyQuoted && alreadyQuoted.length > 0) {
    alreadyQuoted?.forEach((w) => {
      // yank the quoted substrings to avoid duplicates
      // remember to ignore quotes that only wrap whitespace
      if (!/"\s"/.test(w)) phrase = phrase?.replace(w, '')
    })
  }

  const massagedQuotes =
    alreadyQuoted
      // remove leading/trailing whitespace
      ?.map((m) => m.replace(/"\s+/, '"').replace(/\s+"/, '"'))
      // filter empty phrases
      .filter((m) => m !== '""')
      .join(' ') ?? ''

  return phrase
    .split(/\s/) // use whitespace to split unquoted words
    .filter((x) => x !== '') // yank empty strings
    .map((w) => `"${w}"`) // wrap the words in quotes
    .concat(massagedQuotes) // append quoted phrases
    .join(' ')
    .trim()
}

export const hasActiveFilter = (criteria?: SearchCriteria) =>
  criteria &&
  !!(
    criteria.docType ||
    criteria.folderId ||
    criteria.includeTitleOnly ||
    criteria.includeTrash ||
    criteria.modifiedAfter ||
    criteria.modifiedBefore
  )

export const useSearchQuery = ({
  workspaceId,
  from,
  filters,
}: {
  workspaceId: string
  from: number
  filters: SearchCriteria
}) => {
  const { doDebug, scrapi } = useMst()

  const {
    docType,
    folderId,
    includeTitleOnly,
    includeTrash,
    modifiedAfter,
    modifiedBefore,
    phrase,
  } = filters

  const queryFn = async () => {
    await doDebug()

    const apiResult = await scrapi.workspaces.searchDocuments({
      params: { id: workspaceId },
      body: {
        from,
        size: PAGE_SIZE,
        phrase: phrase === '' ? undefined : quotify(phrase),
        docType,
        folderId,
        includeTitleOnly,
        includeTrash,
        modifiedAfter,
        modifiedBefore,
      },
    })
    if (apiResult.status !== 200) {
      throw new Error('Error getting search results')
    }
    return apiResult
  }

  const queryResult = useQuery({
    queryFn,
    queryKey: [
      'searchDocuments',
      {
        phrase,
        from,
        docType,
        folderId,
        includeTitleOnly,
        includeTrash,
        modifiedAfter,
        modifiedBefore,
      },
    ],
    // staleTime only applies to the window being foregrounded
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    gcTime: 0,
    enabled: hasPhraseOrActiveFilter(filters),
  })
  return queryResult
}

export const useInvalidateCache = () => {
  const queryClient = useQueryClient()

  return (phrase?: string) =>
    queryClient.invalidateQueries({
      queryKey: ['searchDocuments', { phrase }],
    })
}

export type ApiSearchResult = Awaited<ReturnType<typeof useSearchQuery>>
export type SearchCriteria = ZInfer<typeof schemas.SearchCriteria>
