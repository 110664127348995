import React from 'react'

import { TextProps } from '@mantine/core'
import cn from 'classnames'

import { ScriptStatus, ScriptStatusMap } from '@showrunner/codex'

import { FaIcon } from './FaIcon'

import styles from './ScriptStatusIcon.module.scss'

const OPEN_ICON = 'fa-door-open'
const LIMITED_ICON = 'fa-user-group'
const PRIVATE_ICON = 'fa-key-skeleton'

export type ScriptStatusIconProps = Omit<TextProps, 'children'> & {
  status: ScriptStatus
}

export const ScriptStatusIcon = React.forwardRef<
  HTMLDivElement,
  ScriptStatusIconProps
>(({ status, ...textProps }, ref) => {
  const isPrivate = status === ScriptStatusMap.PRIVATE
  const isLimited = status === ScriptStatusMap.LIMITED

  const icon = isPrivate ? PRIVATE_ICON : isLimited ? LIMITED_ICON : OPEN_ICON

  return (
    <FaIcon
      {...textProps}
      ref={ref}
      icon={icon}
      className={cn({
        [styles.open]: !isLimited && !isPrivate,
        [styles.limited]: isLimited,
        [styles.private]: isPrivate,
      })}
    />
  )
})

ScriptStatusIcon.displayName = 'ScriptStatusIcon'
