import { Center, Text } from '@mantine/core'
import cn from 'classnames'

import styles from './BodyPlaceholder.module.scss'

const MESSAGES = {
  pickOne: 'Choose a snapshot',
  pickTwo: 'Choose snapshots to compare',
  pickAnother: 'Choose a second snapshot to compare',
  error: 'Error loading snapshot',
  loading: 'Loading...',
} as const

export const BodyPlaceholder = ({
  variant,
  fill,
}: {
  fill?: boolean
  variant: keyof typeof MESSAGES
}) => (
  <Center
    className={cn(styles.bodyPlaceholder, {
      [styles.fillParent]: fill,
      [styles.error]: variant === 'error',
    })}
  >
    <Text c={variant === 'error' ? 'red.7' : 'gray.7'} size="18">
      {MESSAGES[variant]}
    </Text>
  </Center>
)
