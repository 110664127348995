import { chainCommands, splitBlockAs } from 'prosemirror-commands'
import { type Command } from 'prosemirror-state'

import { exitDualColumn } from './exitDualColumn'
import { maybeInsertPrecedingBlock } from './maybeInsertPrecedingBlock'
import { maybeSidestepLastChar } from './maybeSidestepLastChar'
import { nextBlockTypeAndAttrsForSplit } from './nextBlockTypeAndAttrsForSplit'

export const handleEnter: Command = (state, dispatch) => {
  const tr = maybeSidestepLastChar(state)
  const newState = state.apply(tr)

  return chainCommands(
    exitDualColumn,
    maybeInsertPrecedingBlock,
    splitBlockAs(() => nextBlockTypeAndAttrsForSplit(newState)),
  )(newState, dispatch)
}
