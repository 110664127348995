import React from 'react'

import { Avatar, AvatarProps, MantineStyleProp } from '@mantine/core'

import grayface from '@assets/images/grayface.svg'
import { getAvatarRoot } from '@config'

const resolveSrc = (avatarSrc: string | null | undefined) => {
  if (!avatarSrc) {
    return grayface
  }

  const isFullyQualifiedUrl = avatarSrc
    .toLowerCase()
    .trim()
    .match(/^https?:\/\//)

  return isFullyQualifiedUrl ? avatarSrc : getAvatarRoot() + avatarSrc
}

// We're making a non-polymorphic customization of a mantine component. See:
// https://mantine.dev/guides/polymorphic/#wrapping-polymorphic-components
interface UserAvatarProps
  extends Omit<AvatarProps, 'src' | 'size'>,
    Omit<React.ComponentPropsWithoutRef<'div'>, keyof AvatarProps> {
  ringColor?: string
  avatarSrc?: string | null
  diameter: number
}

// make the ring width proportional to the diameter of the
// image
const ringWidthFor = (diameter: number) => {
  const computed = Math.round(diameter / 10) - 1
  return Math.max(computed, 2)
}

const combineStyles = ({
  diameter,
  ringColor,
  style,
}: {
  diameter: number
  ringColor?: string
  style: MantineStyleProp
}): MantineStyleProp => {
  if (!ringColor) {
    return style
  }
  const borderStyle = {
    borderWidth: ringWidthFor(diameter),
    borderStyle: 'solid',
    borderColor: ringColor,
  }

  // The MantineStyleProp can be an array of styles or an object
  if (Array.isArray(style)) {
    return [...style, borderStyle]
  }
  return {
    ...style,
    ...borderStyle,
  }
}

export const UserAvatar = React.forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ ringColor, avatarSrc, style, diameter, ...passthrough }, ref) => {
    const src = resolveSrc(avatarSrc)

    return (
      <Avatar<'div'>
        ref={ref}
        src={src}
        style={combineStyles({ style, diameter, ringColor })}
        size={diameter}
        {...passthrough}
      />
    )
  },
)

UserAvatar.displayName = 'UserAvatar'
