import { observer } from 'mobx-react-lite'
import { useRoute } from 'wouter'

import { ScriptTitle } from '@components/MiniHeader/DocumentTitle'
import { StaffBadges } from '@components/Staff/Badges'
import { Toolbar } from '@components/Toolbar'
import { useNavigation } from '@hooks'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util/pathConfigs'

import { HeaderTimestamp } from './HeaderTimestamp'
import { MiniHeader } from './MiniHeader'
import { ScriptSyncStatus } from './ScriptSyncStatus'

export const ScriptMiniHeader = observer(function ScriptMiniHeader() {
  const { user, location, currentOrg, currentScript, view } = useMst()
  const { navigateToFolder, navigateToRundown } = useNavigation()

  const [isSplitEditor] = useRoute(ROUTE_PATTERNS.splitEditor)

  const handleCloseScript = () => {
    user.exitSplitLayout()
    const rundownId = location.getPathParam('rundownId')
    if (rundownId) {
      navigateToRundown(rundownId)
    } else {
      // or go back to the containing folder
      navigateToFolder(currentScript?.folderId)
    }
  }

  return (
    <Toolbar>
      <MiniHeader.LeftSection>
        {currentScript && <ScriptTitle script={currentScript} />}
        {currentScript && (
          <HeaderTimestamp
            userName={currentScript.lastModifier?.name}
            at={currentScript.contentsModifiedAt}
            createdAt={currentScript.createdAt}
          />
        )}
        {!isSplitEditor && user.staff && currentOrg && (
          <StaffBadges
            flags={currentOrg.betaFlags}
            debugFlags={view.debugFlags}
          />
        )}
      </MiniHeader.LeftSection>
      <MiniHeader.RightSection>
        {currentScript && <ScriptSyncStatus script={currentScript} />}
        <Toolbar.Button icon="fa-xmark" onClick={handleCloseScript} />
      </MiniHeader.RightSection>
    </Toolbar>
  )
})
