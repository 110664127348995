// constants shared across editors
// List of Editor dispatch actions
export const actionTypes = {
  START: 'start',
  META: 'meta',
  TRANSACTION: 'transaction',
}
// shared standard for scroll threshold and margin
// docs: https://prosemirror.net/docs/ref/#view.EditorProps.scrollThreshold
export const SCROLL_MARGIN = { top: 100, right: 0, bottom: 32, left: 0 }
// prefix for steps stored offline
export const UNSAVED_STEPS_PREFIX = 'scripto-unsaved-steps'
// prefix for pdf settings
export const PDF_PREFERENCES_PREFIX = 'scripto-pdf-preferences'
// prefix for other settings
export const PREFERENCES_PREFIX = 'scripto-preferences'

export default {
  actionTypes,
  SCROLL_MARGIN,
  UNSAVED_STEPS_PREFIX,
  PREFERENCES_PREFIX,
}
