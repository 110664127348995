import React from 'react'

import { Anchor, Badge, ScrollArea, Stack, Text } from '@mantine/core'
import type { Story as InkStory } from 'inkjs'

import { showError } from '@components/Modals'
import { Toolbar } from '@components/Toolbar'
import { CompileStatus } from '@ink'

import styles from './InkPlayer.module.scss'

const makeAStory = async (json: string) => {
  const ink = await import('inkjs')
  return new ink.Story(json)
}

const StoryLine = ({ text }: { text: string }) => (
  <div className={styles.storyLine}>{text}</div>
)

const Choice = ({ text, onSelect }: { text: string; onSelect: () => void }) => (
  <div className={styles.choice}>
    <Anchor onClick={onSelect}>
      <Text>{text}</Text>
    </Anchor>
  </div>
)

// for some really stupid reason, InkJS.Story isn't correctly typed
type Story = InstanceType<typeof InkStory>
// and choice isn't exported
type Choice = Story['currentChoices'][number]

const extractPlayedLines = (story?: Story): string[] => {
  const lines: string[] = []
  while (story?.canContinue) {
    const next = story.Continue()
    if (typeof next === 'string') {
      lines.push(next)
    }
  }
  return lines
}

type InkPlayerProps = {
  inkJson: string
  status: CompileStatus
}

export const InkPlayer = ({ inkJson, status }: InkPlayerProps) => {
  const [lastJson, setLastJson] = React.useState<string | undefined>()
  const storyRef = React.useRef<Story | undefined>()
  const choiceHistoryRef = React.useRef<number[]>([])
  const viewportRef = React.useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    viewportRef.current?.scrollTo({
      top: viewportRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  const [storyLines, setStoryLines] = React.useState<string[]>(
    extractPlayedLines(storyRef.current),
  )
  const [choices, setChoices] = React.useState<Choice[]>(
    storyRef.current?.currentChoices ?? [],
  )

  const handlePickChoice = (choiceIndex: number) => {
    if (storyRef.current) {
      storyRef.current.ChooseChoiceIndex(choiceIndex)
      choiceHistoryRef.current.push(choiceIndex)
      setStoryLines([...storyLines, ...extractPlayedLines(storyRef.current)])
      setChoices(storyRef.current.currentChoices)
      setTimeout(scrollToBottom)
    }
  }

  const resetStory = async (inkJson: string, choicesToPlay: number[]) => {
    setLastJson(inkJson)
    const newStory = await makeAStory(inkJson)
    newStory.onError = showError
    const newLines = extractPlayedLines(newStory)
    const newChoiceHistory: number[] = []
    for (let i = 0; i < choicesToPlay.length; i++) {
      const previousChoice = choicesToPlay[i]
      const { currentChoices } = newStory
      if (previousChoice >= currentChoices.length) {
        break
      }
      newStory.ChooseChoiceIndex(previousChoice)
      newChoiceHistory.push(previousChoice)
      newLines.push(...extractPlayedLines(newStory))
    }
    storyRef.current = newStory
    setStoryLines(newLines)
    setChoices(newStory.currentChoices)
    choiceHistoryRef.current = newChoiceHistory
  }

  const handleStartOver = () => {
    if (inkJson) {
      resetStory(inkJson, [])
    }
  }

  const handleGoBack = () => {
    if (inkJson) {
      resetStory(inkJson, choiceHistoryRef.current.slice(0, -1))
    }
  }

  React.useEffect(() => {
    if (inkJson && lastJson !== inkJson) {
      resetStory(inkJson, choiceHistoryRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inkJson, lastJson])

  const compileBadgeColor =
    status === 'ok' ? 'green' : status === 'working' ? 'yellow.9' : 'red'

  return (
    <Stack gap={0} className={styles.inkPlayer}>
      <Toolbar>
        <Toolbar.Section position="left">
          <Toolbar.Button
            buttonClasses={styles.inkPlayer_button}
            label="Back"
            icon="fa-backward-step"
            onClick={handleGoBack}
          />
          <Toolbar.Button
            className={styles.inkPlayer_button}
            label="Restart"
            icon="fa-arrow-rotate-left"
            onClick={handleStartOver}
          />
        </Toolbar.Section>
        <Toolbar.Section position="right">
          <Badge color={compileBadgeColor}>{status}</Badge>
        </Toolbar.Section>
      </Toolbar>
      <ScrollArea viewportRef={viewportRef}>
        {storyLines.map((line, idx) => (
          <StoryLine key={idx} text={line} />
        ))}
        <div className={styles.choices}>
          {choices.map((choice, idx) => (
            <Choice
              key={idx}
              text={choice.text}
              onSelect={() => handlePickChoice(choice.index)}
            />
          ))}
        </div>
      </ScrollArea>
    </Stack>
  )
}
