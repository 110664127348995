import { FormEvent, useState } from 'react'

import {
  Button,
  Card,
  Group,
  Space,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'

import {
  showAlert,
  showError,
  showStytchChangeEmailModal,
} from '@components/Modals'
import { useMst } from '@state'

export const ProfileUpdater = observer(function ProfileUpdater({
  setLoading,
}: {
  setLoading: (val: boolean) => void
}) {
  const { doDebug, user } = useMst()
  const [name, setName] = useState(user.name)

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    try {
      await doDebug()
      await user.update({ name })
      showAlert({
        title: 'Success',
        children: 'Your name has been updated',
      })
    } catch (e) {
      showError({ message: 'Failed to update your name' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={submit}>
      <Stack>
        <Card>
          <label htmlFor="email">
            <Text fw="bold">Email address</Text>
          </label>
          <Group justify="space-between">
            <Text>{user.email}</Text>
            <Button variant="subtle" onClick={showStytchChangeEmailModal}>
              Edit
            </Button>
          </Group>
        </Card>
        <Card>
          <Stack gap="xs">
            <label htmlFor="name">
              <Text fw="bold">Name</Text>
            </label>
            <TextInput
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </Stack>
          <Space h="xl" />
          <Group>
            <Button
              type="submit"
              disabled={name === user.name}
              value="Save Changes"
            >
              Save changes
            </Button>
          </Group>
        </Card>
      </Stack>
    </form>
  )
})
