import { useState } from 'react'

import { Table } from '@mantine/core'

import { showAlert } from '@components/Modals'
import { NavAnchor } from '@components/NavAnchor'
import { useMst } from '@state'
import { OrgPayload } from '@util/ScriptoApiClient/types'

import { displayRole } from '../helpers'

export function MembersForm({ org }: { org: OrgPayload }) {
  const { apiClient } = useMst()
  const [removedUserIds, setRemovedUserIds] = useState<string[]>([])
  const removeMember = async (userId: string) => {
    try {
      await apiClient.removeOrgMemberByStaff({
        orgId: org.id,
        userId,
      })
      setRemovedUserIds([userId, ...removedUserIds])
      showAlert({
        title: 'Success',
        children: 'User removed from workspace',
      })
    } catch (e) {
      showAlert({
        title: 'Error',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: (e as any).message,
      })
    }
  }

  return (
    <form className="s-form s-form--inline">
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Role</Table.Th>
            <Table.Th>Email</Table.Th>
            <Table.Th>Permissions</Table.Th>
            <Table.Th>Remove?</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {org.members
            .filter((m) => !removedUserIds.includes(m.id))
            .map((member) => {
              const isOwner = member.id === org.owner.id
              return (
                <Table.Tr key={member.id}>
                  <Table.Td>
                    <NavAnchor href={`/staff/users/${member.id}`}>
                      {member.name}
                    </NavAnchor>
                  </Table.Td>
                  <Table.Td>
                    {displayRole(member.id === org.owner.id, member.isAdmin)}
                  </Table.Td>
                  <Table.Td>{member.email}</Table.Td>
                  <Table.Td>{member.permissions.join(' ')}</Table.Td>
                  <Table.Td>
                    {isOwner ? null : (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeMember(member.id)}
                      >
                        🗑
                      </div>
                    )}
                  </Table.Td>
                </Table.Tr>
              )
            })}
        </Table.Tbody>
      </Table>
    </form>
  )
}
