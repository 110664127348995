import React from 'react'

import { Button, ButtonProps, Tooltip } from '@mantine/core'
import cn from 'classnames'

import styles from './StatusBarButton.module.scss'

type StatusBarButtonProps = {
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  tooltip?: string
  active?: boolean
  variant?: ButtonProps['variant']
}

export const StatusBarButton = React.forwardRef<
  HTMLButtonElement,
  StatusBarButtonProps
>(
  (
    { children, onClick, tooltip = false, active = false, variant = 'subtle' },
    ref,
  ) => (
    <Tooltip label={tooltip} disabled={!tooltip}>
      <Button
        ref={ref}
        onMouseDown={(e) => {
          e.preventDefault()
        }}
        onClick={onClick}
        variant={variant}
        className={cn(styles.statusBar_button, {
          [styles.statusBar_button___active]: active,
        })}
      >
        {children}
      </Button>
    </Tooltip>
  ),
)

StatusBarButton.displayName = 'StatusBarButton'
