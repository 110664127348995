export * from './formatDuration'

const OPENING_BRACKET_RE = /^[\s]*?\[/
const CLOSING_BRACKET_RE = /][\s]*?$/

export const trimAndRemoveBrackets = (input: string): string => {
  const trimmed = input.trim()
  if (trimmed.startsWith('[') && trimmed.endsWith(']')) {
    return trimmed.substring(1, trimmed.length - 1).trim()
  }
  return trimmed
}

export const removeBracketsFromNode = (domNode: HTMLElement) => {
  let f = domNode.firstChild
  let l = domNode.lastChild

  // keep digging through nested marks until you find the precious content
  while (f && !f.nodeValue) {
    f = f.firstChild
  }

  while (l && !l.nodeValue) {
    l = l.lastChild
  }

  if (f) f.nodeValue = f.nodeValue?.replace(OPENING_BRACKET_RE, '') ?? null
  if (l) l.nodeValue = l.nodeValue?.replace(CLOSING_BRACKET_RE, '') ?? null
}
