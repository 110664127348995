import LinkifyIt from 'linkify-it'
import throttle from 'lodash.throttle'

import { MarkTypeMap, NodeTypeMap } from '@showrunner/codex'

import { DatadogClient } from '@util/datadog'

const ddLog = DatadogClient.getInstance()

const linkify = new LinkifyIt()
const {
  BRACKET,
  CHARACTER,
  DIALOGUE,
  DOC,
  END_OF_ACT,
  NEW_ACT,
  PAGE,
  PARENTHETICAL,
  SCENE_HEADING,
  SLUG,
  TEXT,
  TRANSITION,
} = NodeTypeMap

const PM_ERROR_THROTTLE_MS = 5000
const logInvalidPosition = throttle((data) => {
  ddLog.warn('bug: PM asked to find DOM at an invalid position', data)
}, PM_ERROR_THROTTLE_MS)
const logInvalidOffset = throttle((data) => {
  ddLog.warn('pm anomaly: could not get offset', data)
}, PM_ERROR_THROTTLE_MS)
/**
 * Get current non-text DOM Node based on cursor position.
 * @param {EditorView} view - current editor view
 * @return {Node} DOM node
 */
function getCurrentDOMNode(view) {
  const { pos } = view.state.selection.$anchor
  return getDOMNodeAtPos(view, pos)
}
function getDOMNodeAtPos(view, position) {
  try {
    const { node } = view.domAtPos(position)
    if (node.nodeType === window.Node.TEXT_NODE) {
      return node.parentNode
    }
    return node
  } catch (e) {
    if (e.message.includes('Invalid position')) {
      logInvalidPosition({ position })
    }
    return null
  }
}
/*
  given a possibly-null node, do the best job at figuring out its
  offset top (with up to two ancestors). If any of the desired values
  are null, use 0 and log an error
*/
function safelyGetOffset({ node, caller, includeParent, includeGrandparent }) {
  const parent = node ? node.offsetParent : null
  const grandparent = parent ? parent.offsetParent : null
  const nodeOk = !!node
  const parentOk = !!(parent || !includeParent)
  const grandparentOk = !!(grandparent || !includeGrandparent)
  const nodeOffset = nodeOk ? node.offsetTop : 0
  const parentOffset = includeParent && parentOk ? parent.offsetTop : 0
  const grandparentOffset =
    grandparentOk && includeGrandparent ? grandparent.offsetTop : 0
  const nodeInfo = nodeOk ? node.className : 'missing'
  if (!(nodeOk && parentOk && grandparentOk)) {
    logInvalidOffset({
      caller,
      nodeInfo,
    })
  }
  return nodeOffset + parentOffset + grandparentOffset
}

// TODO: handle dual dialogue (depth issues)
function getLastBlockType(state) {
  const { $anchor } = state.selection
  const blockIndex = $anchor.index(1)
  let lastBlock
  if (blockIndex < 1) {
    // check for previous page and get last node there
    const pageIndex = $anchor.index(0)
    // if there's no previous page, return null
    if (pageIndex < 1) {
      return null
    }
    // get previous page node
    const prevPage = $anchor.node(0).child(pageIndex - 1)
    // get last child of previous page
    lastBlock = prevPage.child(prevPage.childCount - 1)
  } else {
    // get block node at previous index
    lastBlock = $anchor.node(1).child(blockIndex - 1)
  }
  return lastBlock.type.name
}
/**
 * Get doc type from editor state
 * @param {object} state - editor state
 * @return {string} doc type (screenplay or variety)
 */
function getDocType(state) {
  return state.doc.attrs.docType
}
/**
 * Determines if the given position is at the end of its parent node
 * @param {ResolvedPos} $pos - resolved position to check
 */
function isEndOfParentNode($pos) {
  return $pos.parentOffset === $pos.parent.content.size
}
/**
 * Determines if the given selection is a cursor positioned inside an empty block
 * @param {Selection} selection - PM Selection
 */
function isEmptyBlockSelection(selection) {
  return selection.empty && selection.$anchor.node().nodeSize === 2
}
/**
 * detect if selection spans locked pages
 * SIDE EFFECT: alerts user of bad operation
 * @param {EditorState} viewState - current editor state
 * @return {boolean}
 */
function lockedPagesInSelection() {
  // no locked pages detected
  return false
}
// general text utils
// get size for monospace autogrow input (with minimum)
// NOTE: size only works for autosizing input if font is monospace
function getInputSize(value, min = 1) {
  return value.length > min ? value.length : min
}
/**
 * build up a single page JSON document by testing individual lines to see if they are
 * a recognizable Studio block type. assume dialogue otherwise
 *
 * @param {Array.<string>} lines - from a plaintext paste
 * @return {object} doc - PM JSON script
 */
function plainStudioToProse(lines) {
  const doc = { type: DOC, content: [{ type: PAGE }] }

  doc.content[0].content = lines.map((text) => {
    let content
    if (linkify.test(text)) {
      const intermediateDS = findLinksAndSplit(text)

      content = intermediateDS.map((row) => {
        // schema.nodeFromJSON() throws on blocks with a single empty string
        const result = row.text === '' ? null : { text: row.text, type: TEXT }

        if (result && row.url) {
          result.marks = [
            {
              type: MarkTypeMap.LINK,
              attrs: { href: row.url, autolink: true },
            },
          ]
        }
        return result
      })
    } else {
      // schema.nodeFromJSON() throws on blocks with a single empty string
      content = text === '' ? [] : [{ text, type: TEXT }]
    }

    // default to dialogue
    const block = { type: DIALOGUE, content }
    for (let i = 0; i < STUDIO_BLOCKS.length; i++) {
      if (text.match(STUDIO_REGEX[STUDIO_BLOCKS[i]])) {
        block.type = STUDIO_BLOCKS[i]
        break
      }
    }
    return block
  })
  return doc
}

/**
 * distinguish links within a paste payload and generate a data structure
 * that splits the original string at each and every valid link.
 *
 * findLinksAndSplit('something youtu.be/fe8 else')
 *
 * > [
 *   { text: 'something '},
 *   { text: 'youtu.be/fe8', url: 'http://youtu.be/fe8'},
 *   { text: ' else' }
 * ]
 *
 * @param {string} - a plaintext paste
 * @return {Array<{ text: string, url?: string}>} - Array of POJOs
 */
const findLinksAndSplit = (text) => {
  // linkify provides us with an array of links and their start/end positions
  // [{
  //   index: 10,
  //   lastIndex: 22,
  //   text: ''youtu.be/fe8',
  //   url: 'http://youtu.be/fe8'
  // }]
  const links = linkify.match(text)

  // be defensive
  if (!links || links.length < 1) return [{ text }]

  // [text, url?]
  let dataStore = []
  let start = 0
  let end = links[0].index

  const maybeAdd = (text, url) => {
    // dont inject empty text. (ie: a link began or terminated the entire string)
    if (start !== end && text !== '') {
      const row = { text }
      if (url) {
        row.url = url
      }
      dataStore.push(row)
    }
  }

  // reconstruct the entire original string by looping through each link
  // if intermediate plain text is present, make sure to include it
  links.forEach((l) => {
    end = l.index
    maybeAdd(text.slice(start, end))
    start = l.index
    end = l.lastIndex
    maybeAdd(l.text, l.url)
    start = l.lastIndex
  })
  // include the plain text that terminated the string (if present)
  end = text.length
  maybeAdd(text.slice(start, end))

  return dataStore
}

// constants
const INITIAL_NODE_SIZE = 6
// blocks whose content is always capitalized
const CAPITALIZED_BLOCK_TYPES = [
  SCENE_HEADING,
  TRANSITION,
  CHARACTER,
  SLUG,
  NEW_ACT,
  END_OF_ACT,
]
// a little sugar to convert 'new_act', 'sceneHeading'
const LEGIBLE_BLOCK_NAMES = {
  [NEW_ACT]: NEW_ACT.toUpperCase().replace('_', ' '),
  [SCENE_HEADING]: 'SCENE HEADING',
  [SLUG]: SLUG,
  [BRACKET]: BRACKET,
  [END_OF_ACT]: END_OF_ACT,
}
/*
  {***TREVOR***} is a Daily Show convention to get prompter specific formatting
  {SOT} and {VO} are an untimed Sam Bee stage direction convention
  note to john: when its time to add more, you'll either need to turn this
  into a plain old array or complicate the existing regexes for a block :)
*/
const STUDIO_REGEX = {
  [PARENTHETICAL]: /^(\(.+\)|\{(SOT|VO)})$/,
  [BRACKET]: /^(\[.+\])$/,
  [CHARACTER]: /^(\{\*\*\*.+\*\*\*\})$/,
}
const STUDIO_BLOCKS = Object.keys(STUDIO_REGEX)

export {
  getDocType,
  getLastBlockType,
  getCurrentDOMNode,
  getDOMNodeAtPos,
  findLinksAndSplit,
  safelyGetOffset,
  isEndOfParentNode,
  isEmptyBlockSelection,
  lockedPagesInSelection,
  getInputSize,
  plainStudioToProse,
  INITIAL_NODE_SIZE,
  CAPITALIZED_BLOCK_TYPES,
  LEGIBLE_BLOCK_NAMES,
  STUDIO_REGEX,
  STUDIO_BLOCKS,
}
