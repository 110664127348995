import { Toast } from '@components/Toast'
import { ToastComponentProps } from '@components/Toast/Toast'

export const IdenticalSnapshotsToast = (
  props: Partial<ToastComponentProps>,
) => (
  <Toast
    dismissable={false}
    message="Both snapshots are identical."
    {...props}
  />
)
