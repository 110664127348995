import { Space, Stack, Stepper } from '@mantine/core'

import styles from './ProgressIndicator.module.scss'

export const ProgressIndicator = ({
  currentStep,
}: {
  currentStep: 0 | 1 | 2 | 3
}) => {
  return (
    <Stack>
      <Stepper
        size="xs"
        classNames={{
          separator: styles.stepper_separator,
          stepBody: styles.stepper_stepBody,
          stepCompletedIcon: styles.stepper_stepCompletedIcon,
          stepIcon: styles.stepper_stepIcon,
          steps: styles.stepper_steps,
        }}
        active={currentStep}
      >
        <Stepper.Step />
        <Stepper.Step />
        <Stepper.Step />
        <Stepper.Step />
      </Stepper>
      <Space h={25} />
    </Stack>
  )
}
