import React from 'react'

import { Checkbox, Divider, Group, Space, Stack, Text } from '@mantine/core'

import { pluralize } from '@util'

import { PrintableScriptData } from './helpers'

import styles from './SelectRundownScripts.module.scss'

const RowItem = ({
  data,
  checked,
  setChecked,
}: {
  data: PrintableScriptData
  checked: boolean
  setChecked: Callback<[boolean]>
}) => (
  <Group mb={15} mt={15} wrap="nowrap">
    <Checkbox
      checked={checked}
      onChange={(evt) => {
        setChecked(evt.target.checked)
      }}
    />
    <Text>{data.title}</Text>
  </Group>
)

/*
  Count label is different based on whether there is more than 1 script
  to choose from:

  if only one script, "1 script to print"
  if several options "1 script selected" or "3 scripts selected"
*/
const CountLabel = ({
  count,
  soloMode,
}: {
  count: number
  soloMode?: boolean
}) => {
  const action = soloMode ? 'to print' : 'selected'
  const message = `${count} ${pluralize(count, 'script')} ${action}`
  return (
    <Text size="sm" c="dimmed">
      {message}
    </Text>
  )
}

// You can't launch the modal with no scripts but to satisfy the logic
const SingleOrNoScript = ({ item }: { item?: PrintableScriptData }) => (
  <Stack gap={4}>
    <Space h={10} />
    <Text fw="bold">{item?.title ?? 'No scripts to print'}</Text>
    <CountLabel count={item ? 1 : 0} soloMode />
    <Space h={10} />
    <Divider />
  </Stack>
)

const MultiScriptsSelector = ({
  data,
  selectedIds,
  setItemSelected,
  onBulkSelect,
}: {
  selectedIds: string[]
  setItemSelected: Callback<[string, boolean]>
  onBulkSelect: Callback<['all' | 'none']>
  data: PrintableScriptData[]
}) => {
  const allSelected = data.every((item) => selectedIds.includes(item.scriptId))
  const noneSelected = selectedIds.length === 0

  const indeterminate = !(allSelected || noneSelected)
  const handleBulkChange = () => {
    if (allSelected) {
      onBulkSelect('none')
    } else {
      onBulkSelect('all')
    }
  }

  return (
    <Stack gap={5}>
      <Stack gap={0}>
        <Group className={styles.scriptChecklist_selectAll}>
          <Checkbox
            checked={allSelected}
            indeterminate={indeterminate}
            onChange={handleBulkChange}
          />
        </Group>
        {/* Mantine ScrollArea breaks scrolling */}
        <div className={styles.scriptChecklist_scroll}>
          {data.map((item) => (
            <RowItem
              key={item.scriptId}
              data={item}
              checked={selectedIds.includes(item.scriptId)}
              setChecked={(checked: boolean) =>
                setItemSelected(item.scriptId, checked)
              }
            />
          ))}
        </div>
      </Stack>
      <CountLabel count={selectedIds.length} />
    </Stack>
  )
}

export const SelectRundownScripts = ({
  allPrintableItems,
  selectedIds,
  setSelectedIds,
}: {
  setSelectedIds: Callback<[string[]]>
  selectedIds: string[]
  allPrintableItems: PrintableScriptData[]
}) => {
  const setItemSelected = (scriptId: string, selected: boolean) => {
    const newIds = selected
      ? [...selectedIds, scriptId]
      : selectedIds.filter((id) => id !== scriptId)
    setSelectedIds(newIds)
  }

  const handleBulkSelect = (type: 'all' | 'none') => {
    if (type === 'all') {
      setSelectedIds(allPrintableItems.map((item) => item.scriptId))
    } else {
      setSelectedIds([])
    }
  }

  if (allPrintableItems.length < 2) {
    return <SingleOrNoScript item={allPrintableItems[0]} />
  }

  return (
    <MultiScriptsSelector
      data={allPrintableItems}
      selectedIds={selectedIds}
      setItemSelected={setItemSelected}
      onBulkSelect={handleBulkSelect}
    />
  )
}
