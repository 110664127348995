import { Group, Menu, Text, UnstyledButton } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { Avatar } from '@components/Avatar'
import { FaIcon } from '@components/FaIcon'
import { showError } from '@components/Modals'
import { useNavigation } from '@hooks'
import { useMst } from '@state'
import { pathTo, ROUTE_PATTERNS } from '@util'

export const UserMenuBase = ({
  isOnline,
  navigate,
  userName,
  avatarUrl,
  isStaff,
  onSignout,
}: {
  userName: string
  isStaff: boolean
  avatarUrl?: string
  isOnline: boolean
  navigate?: (path: string) => void
  onSignout?: () => void
}) => {
  return (
    <Menu shadow="xl" withinPortal>
      <Menu.Target>
        <UnstyledButton>
          <Group gap={5} wrap="nowrap">
            <Avatar
              statusIndicator={isOnline ? 'online' : 'offline'}
              avatarUrl={avatarUrl}
            />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <Text size="16" fw="normal" tt="none">
            Hi, {userName}
          </Text>
        </Menu.Label>
        <Menu.Divider />
        <Menu.Item
          leftSection={<FaIcon icon="fa-gear" />}
          onClick={() => navigate?.(pathTo(ROUTE_PATTERNS.settings))}
        >
          Settings
        </Menu.Item>
        {isStaff && (
          <Menu.Item
            leftSection={<FaIcon icon="fa-id-badge"></FaIcon>}
            onClick={() => navigate?.(pathTo(ROUTE_PATTERNS.staffLanding))}
          >
            Staff
          </Menu.Item>
        )}
        <Menu.Item
          leftSection={<FaIcon icon="fa-sign-out" />}
          onClick={onSignout}
        >
          Sign out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export const UserMenu = observer(function UserMenu() {
  const { user, socketManager } = useMst()
  const isOnline = socketManager.status === 'connected'
  const { navigate } = useNavigation()
  const handleSignout = async () => {
    try {
      await user.rootStore.logout()
    } catch (e) {
      showError('Unknown failure trying to log out')
    }
  }

  return (
    <UserMenuBase
      avatarUrl={user.avatarUrl}
      isOnline={isOnline}
      isStaff={user.staff}
      navigate={navigate}
      onSignout={handleSignout}
      userName={user.name}
    />
  )
})
