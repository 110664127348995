import { types } from 'mobx-state-tree'

import { ScriptFormatConfiguration } from '@util/formats'

export const ScriptFormatSummaryModel = types.model(
  'ScriptFormatSummaryModel',
  {
    id: types.string,
    name: types.string,
    description: types.string,
    icon: types.string,
  },
)

export const ScriptFormatModel = ScriptFormatSummaryModel.named(
  'ScriptFormatModel',
).props({
  definition: types.frozen<ScriptFormatConfiguration>(),
})
