import { type Command } from 'prosemirror-state'

import { NodeTypeKey, NodeTypeMap, ScriptDocType } from '@showrunner/codex'

import { getSelectionBlockType, nextNodeOnTab, setEditorBlockType } from '@util'

import { getLastBlockType } from '../../prose-utils.js'

function getTabHandler(docType: ScriptDocType) {
  return (reverse = false): Command =>
    (viewState, dispatch) => {
      const { $from } = viewState.selection
      const dual = $from.depth > 2
      // skip it if we're in the first node of a dual dialogue column
      // because it can only be character
      if (dual && $from.index(3) === 0) {
        return true
      }
      const blockType = getSelectionBlockType(viewState)
      // skip tabbing if this is a new act or end of act
      if (
        !blockType ||
        blockType === NodeTypeMap.NEW_ACT ||
        blockType === NodeTypeMap.END_OF_ACT
      ) {
        return true
      }
      const lastBlockType = getLastBlockType(viewState)
      const nextBlockType = nextNodeOnTab(
        blockType as NodeTypeKey,
        lastBlockType,
        docType,
        {
          reverse,
          dual,
        },
      )
      // dont cycle block type via tab when the selection spans several
      if (nextBlockType) {
        const { $from, $to } = viewState.selection
        if ($from.parent.eq($to.parent)) {
          setEditorBlockType(nextBlockType)(viewState, dispatch)
        }
      }
      return true
    }
}
export { getTabHandler }
