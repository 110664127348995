/*
  A pagination breakdown is a data-only summary of page break data in
  a script. It does the work of computing where dynamic page breaks
  need to go and exposes information to allow rendering code to do
  the appropriate thing. This class exposes:

  * Block IDs and prosemirror doc positions of manual and dynamic page breaks
  * The page number associated with those page breaks (today that's just a naive
    count but evenually we can implement page locking/overridable numbering)
  * Height information about pages (useful for simulating print previews)

  The inlinePaginationPlugin creates an instance of this class based on
  editor state and uses it to create decoration widgets used on screen.

  Printing code creates an instance of this class when converting a
  snapshot to static html to inject css classes where page breaks should
  occur.
*/

import { NodeTypeMap } from '@showrunner/codex'

import {
  addBlockHeights,
  isCharacterFollower,
  PositionedBlockInfo,
} from './helpers'

type PageInfo = {
  startPos: number
  endPos: number
  heightShortfall: number
}

type PageDecorationInfo = PageInfo & {
  pageNumber: string
  isManualBreak: boolean
}

type OneOrMore<T> = [T, ...T[]]

// a ChunkOfBlocks is a set of one or more blocks that should stay together on the same page.
class ChunkOfBlocks {
  readonly blocks: PositionedBlockInfo[]

  constructor(first: PositionedBlockInfo) {
    this.blocks = [first]
  }

  get firstBlock(): PositionedBlockInfo {
    return this.blocks[0]
  }

  get lastBlock(): PositionedBlockInfo {
    return this.blocks[this.blocks.length - 1]
  }

  private get leadBlockType(): string {
    return this.firstBlock.blockType
  }

  private get canAppendToCharacterChunk() {
    const characterBlockIndex = this.blocks.findIndex(
      (b) => b.blockType === NodeTypeMap.CHARACTER,
    )

    return (
      characterBlockIndex > -1 &&
      this.blocks.slice(characterBlockIndex).length < 3
    )
  }

  private get isSceneHeadingChunk() {
    return this.leadBlockType === NodeTypeMap.SCENE_HEADING
  }

  get isManualBreak(): boolean {
    return this.firstBlock.manualBreak
  }

  get heightInfo() {
    return {
      lineHeight: addBlockHeights(this.blocks),
      spaceAbove: this.firstBlock.spaceAbove ?? 0,
    }
  }

  private shouldAddBlock(block: PositionedBlockInfo): boolean {
    // never add a manual break to a chunk
    if (block.manualBreak) {
      return false
    }

    if (isCharacterFollower(block) && this.canAppendToCharacterChunk) {
      return true
    }

    if (
      this.isSceneHeadingChunk &&
      this.blocks.length === 1 &&
      block.blockType !== NodeTypeMap.SCENE_HEADING
    ) {
      return true
    }

    return false
  }

  // add block if appropriate and return true if added
  addBlock(block: PositionedBlockInfo): boolean {
    if (this.shouldAddBlock(block)) {
      this.blocks.push(block)
      return true
    }
    return false
  }
}

abstract class PageOfChunks {
  protected readonly chunks: OneOrMore<ChunkOfBlocks>
  protected readonly maxLines: number

  constructor({
    maxLines,
    firstChunk,
  }: {
    maxLines: number
    firstChunk: ChunkOfBlocks
  }) {
    this.maxLines = maxLines
    this.chunks = [firstChunk]
  }

  protected get height(): number {
    return addBlockHeights(this.chunks.map((ch) => ch.heightInfo))
  }

  get viewLayoutInfo(): PageInfo {
    const { firstBlock } = this.chunks[0]
    const { lastBlock } = this.chunks[this.chunks.length - 1]

    return {
      startPos: firstBlock.startPos,
      endPos: lastBlock.startPos + lastBlock.nodeSize,
      heightShortfall: this.maxLines - this.height,
    }
  }

  get breakInfo(): { blockId: string; isManualBreak: boolean } {
    const { firstBlock } = this.chunks[0]

    return {
      blockId: firstBlock.blockId,
      isManualBreak: firstBlock.manualBreak,
    }
  }

  protected abstract shouldAddChunk(chunk: ChunkOfBlocks): boolean

  addChunk(chunk: ChunkOfBlocks): boolean {
    if (this.shouldAddChunk(chunk)) {
      this.chunks.push(chunk)
      return true
    }
    return false
  }
}

class ComputedPage extends PageOfChunks {
  constructor(opts: { maxLines: number; firstChunk: ChunkOfBlocks }) {
    super(opts)
  }

  protected shouldAddChunk(chunk: ChunkOfBlocks): boolean {
    // never add a manual break
    if (chunk.isManualBreak) {
      return false
    }

    // check to see if there's room on page
    const projectedHeight =
      this.height + chunk.heightInfo.spaceAbove + chunk.heightInfo.lineHeight
    if (projectedHeight > this.maxLines) {
      return false
    }

    return true
  }
}

export class PaginationBreakdown {
  readonly chunks: ChunkOfBlocks[]
  private readonly pages: OneOrMore<ComputedPage>
  private readonly maxLines: number

  constructor({
    maxLines,
    blocks,
  }: {
    maxLines: number
    blocks: PositionedBlockInfo[]
  }) {
    this.maxLines = maxLines
    this.chunks = this.blocksToChunks(blocks)
    this.pages = this.computePages()
  }

  private get firstChunk() {
    return this.chunks[0]
  }

  private computePages(): OneOrMore<ComputedPage> {
    const { maxLines, firstChunk } = this
    const computedPages: OneOrMore<ComputedPage> = [
      new ComputedPage({ maxLines: this.maxLines, firstChunk }),
    ]
    this.chunks.slice(1).forEach((chunk) => {
      const lastComputedPage = computedPages[computedPages.length - 1]

      if (!lastComputedPage.addChunk(chunk)) {
        computedPages.push(new ComputedPage({ maxLines, firstChunk: chunk }))
      }
    })

    return computedPages
  }

  private blocksToChunks(blocks: PositionedBlockInfo[]): ChunkOfBlocks[] {
    if (blocks.length === 0) {
      return []
    }

    const chunks: ChunkOfBlocks[] = [new ChunkOfBlocks(blocks[0])]
    blocks.slice(1).forEach((block) => {
      const currentChunk = chunks[chunks.length - 1]
      if (!currentChunk.addBlock(block)) {
        chunks.push(new ChunkOfBlocks(block))
      }
    })

    return chunks
  }

  // this is the data needed to generate the on-screen decorations
  get decorationData(): Array<PageDecorationInfo> {
    const { maxLines, firstChunk } = this
    const computedPages: OneOrMore<ComputedPage> = [
      new ComputedPage({ maxLines: this.maxLines, firstChunk }),
    ]

    this.chunks.slice(1).forEach((chunk) => {
      const lastComputedPage = computedPages[computedPages.length - 1]

      if (!lastComputedPage.addChunk(chunk)) {
        computedPages.push(new ComputedPage({ maxLines, firstChunk: chunk }))
      }
    })

    const result: PageDecorationInfo[] = []
    computedPages.forEach((page, index) => {
      const isManualBreak = page.breakInfo.isManualBreak
      result.push({
        ...page.viewLayoutInfo,
        pageNumber: String(index + 1),
        isManualBreak,
      })
    })
    return result
  }

  // used in printing to get block IDs and page numbers
  get pageBreaks() {
    return this.pages.map((p, index) => ({
      ...p.breakInfo,
      pageNumber: String(index),
    }))
  }

  get pageCount(): number {
    return this.pages.length
  }
}
