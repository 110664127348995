import React from 'react'

import { useQuery } from '@tanstack/react-query'

import { IFolder, IListing, IOrg, useMst } from '@state'

export type LoadMoreItem = {
  id: 'last-item'
  onClick?: Callback
  loading?: boolean
}
export type RenderedListing = IListing | LoadMoreItem

export function isLoadMoreItem(item: RenderedListing): item is LoadMoreItem {
  return item.id === 'last-item'
}

export const useFolderListings = (folder: IFolder) => {
  const mst = useMst()

  const { documentCount, cursorFilteredDocuments } = folder

  const hasMore = cursorFilteredDocuments.length < documentCount
  const [loadingNextPage, setLoadingNextPage] = React.useState(false)

  // for the folder details, use an automatic query.
  // This one is triggered when the component mounts or when
  // react-query decides the cache is stale
  const detailsQuery = useQuery({
    queryFn: async () => {
      await mst.doDebug()
      const updatedFolder = await mst.loadFolderSummary(folder.id)
      if (updatedFolder) {
        // if we don't have any documents for the current page (and there are
        // documents to find) auto-load a page
        if (updatedFolder.documentCount > 0 && !updatedFolder.cursorRecord) {
          await updatedFolder.loadPageOfDocuments()
        }
      }
      return { success: true }
    },
    queryKey: ['folderDetails', folder.id],
    staleTime: Infinity,
  })

  const loadNextPage = async () => {
    mst.log.info('load more clicked', {
      folderId: folder.id,
    })
    setLoadingNextPage(true)
    try {
      await mst.doDebug()
      await folder.loadPageOfDocuments()
    } finally {
      setLoadingNextPage(false)
    }
  }

  const renderedDocuments: RenderedListing[] = [...cursorFilteredDocuments]
  if (loadingNextPage || detailsQuery.isFetching) {
    renderedDocuments.push({
      id: 'last-item',
      loading: true,
    })
  } else if (hasMore) {
    renderedDocuments.push({
      id: 'last-item',
      onClick: loadNextPage,
    })
  }

  return {
    hasMore,
    renderedDocuments,
  }
}

export const useRecentlyEdited = (org: IOrg) =>
  useQuery({
    queryFn: org.refreshRecentListings,
    queryKey: ['recent-items', org.id],
  })
