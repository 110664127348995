import React from 'react'

import { Box, Container, Group, ScrollArea, Stack, Title } from '@mantine/core'

import { Loader } from '@components/Loader'
import { NavAnchor } from '@components/NavAnchor'
import { ROUTE_PATTERNS } from '@util'

import { TemplateListItem } from './TemplatesListItem'
import { TemplateWorkspaceForm } from './TemplateWorkspaceForm'
import { TemplateWorkspaceData, useStaffTemplates } from './useTemplateApis'
import { useTemplateForm } from './useTemplateForm'

export const Templates = () => {
  const { templatesQuery, isLoading } = useStaffTemplates()

  const templates: TemplateWorkspaceData[] =
    templatesQuery.data && templatesQuery.data.status === 200
      ? templatesQuery.data.body.templates
      : []

  const form = useTemplateForm(templates)

  return (
    <div>
      <Title order={1}>
        <NavAnchor href={ROUTE_PATTERNS.staffLanding}>Staff Zone</NavAnchor>
      </Title>

      <Container size="xl" bg="white" p={20}>
        <Loader visible={isLoading} />
        <Group align="stretch" grow gap={30}>
          <ScrollArea.Autosize
            style={{ border: '1px black solid', width: 300 }}
          >
            <Stack gap={0}>
              {templates.map((t) => (
                <TemplateListItem
                  key={t.code}
                  sequence={t.sequence}
                  label={`${t.name} (${t.code})`}
                  selected={form.values.selectedCode === t.code}
                  itemType={t.active ? 'active' : undefined}
                  onClick={() => {
                    form.setValues({
                      ...t,
                      selectedCode: t.code,
                      mode: 'update',
                    })
                  }}
                />
              ))}

              <TemplateListItem
                label="CREATE NEW"
                itemType="new"
                selected={form.values.mode === 'create'}
                onClick={() => {
                  form.reset()
                }}
              />
            </Stack>
          </ScrollArea.Autosize>
          <Box miw={400}>
            <TemplateWorkspaceForm form={form} />
          </Box>
        </Group>
      </Container>
    </div>
  )
}
