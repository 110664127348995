/*
  When postgres sorts alphabetically, it uses a collation that we cannot
  fully reproduce client-side. This gets us close.
*/

const SORT_LOCALE: Intl.LocalesArgument = 'en-US'

export const toPostgresLower = (value: string) =>
  value.toLocaleLowerCase(SORT_LOCALE)

// this is how postgres sorts MOSTLY, but there are weird rules for
// special characters
export const sortAlphaLowerLikePostgres = (a: string, b: string) => {
  const aLower = toPostgresLower(a)
  const bLower = toPostgresLower(b)
  return aLower.localeCompare(bLower, SORT_LOCALE)
}

// This preserves the lowercasing of postgres but splices in the natural
// numbering so that hi14 comes before hi123
export const sortAlphaLowerNaturalNumbering = (a: string, b: string) => {
  const aLower = toPostgresLower(a)
  const bLower = toPostgresLower(b)
  return aLower.localeCompare(bLower, SORT_LOCALE, { numeric: true })
}

/*
  The following obscenity is used when we're filtering listings
  by the cursor. Things get icky when they're not alpha-numerical or when
  we are sorting with natural numbering and we wind up lopping off listings
  that should be included.

  Here we are trying a bunch of different things to make the definition
  of "this is after the cursor" more permissive, even though this means
  that some stuff might not quite sort "normally" (e.g. you load a second
  page and it populates stuff in the first page)

  TLDR; this is designed to be permissive and approximate. It will
  return false when there's any chance postgres thinks candidate comes
  before threshold. It means that pagination sorting is MOSTLY correct
  and that eventually all records get loaded (or at least that's the intention)
*/
const NON_ALPHA_NUMERIC_RE = /[^a-zA-Z0-9]/g
export const aIsDefinitelyAfterBInPostgres = (
  a: string,
  b: string,
): boolean => {
  // this is the closest approximation to how postgres sorts, if this says it's not after,
  // go with that
  const sortedDiff = sortAlphaLowerLikePostgres(a, b)
  if (sortedDiff <= 0) {
    return false
  }

  // now consider our natural numbering, don't exclude for that reason
  const naturalNumberDiff = sortAlphaLowerNaturalNumbering(a, b)
  if (naturalNumberDiff <= 0) {
    return false
  }

  // Before excluding a value from a page set, we're now going to replace
  // all non-alphanumeric characters with "-" and try again with the different cases
  const mungedThreshold = b.replaceAll(NON_ALPHA_NUMERIC_RE, '-')
  const mungedCandidate = a.replaceAll(NON_ALPHA_NUMERIC_RE, '-')
  const mungedDiff = sortAlphaLowerLikePostgres(
    mungedCandidate,
    mungedThreshold,
  )
  if (mungedDiff <= 0) {
    return false
  }

  const mungedNumericDiff = sortAlphaLowerNaturalNumbering(
    mungedCandidate,
    mungedThreshold,
  )
  return mungedNumericDiff > 0 ? true : false
}
