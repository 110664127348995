import React from 'react'

import { Anchor, Stack, Text } from '@mantine/core'

import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util'

const staffItems: Array<{
  title: string
  href: NavAnchorHref
  description: string
}> = [
  {
    title: 'Beta Flags',
    href: ROUTE_PATTERNS.staffFlags,
    description: 'Add and remove all shows from individual flags',
  },
  {
    title: 'Template Workspaces',
    href: ROUTE_PATTERNS.staffTemplateWorkspaces,
    description: 'Manage the templates used to create new workspaces',
  },
  {
    title: 'Invites',
    href: ROUTE_PATTERNS.staffInvites,
    description:
      'Send a new workspace creation invite, or search for previous invites.',
  },
  {
    title: 'Rundowns',
    href: ROUTE_PATTERNS.staffRundowns,
    description: 'Manage rundown schemas',
  },
  {
    title: 'Script Formats',
    href: ROUTE_PATTERNS.staffFormats,
    description: 'View and create script formats',
  },
  {
    title: 'Users',
    href: ROUTE_PATTERNS.staffUsers,
    description: 'List user info for support & debugging',
  },
  {
    title: 'Workspaces',
    href: ROUTE_PATTERNS.staffWorkspaces,
    description: 'Find a workspace by name, tier, status or flag',
  },
  {
    title: 'Clone workspace',
    href: ROUTE_PATTERNS.staffCloneWorkspace,
    description: 'Create a new workspace by cloning an existing one',
  },
]

export const LandingPage = () => {
  const { environment } = useMst()
  const { API_URL } = environment.config

  const isHostedServer = API_URL.startsWith('https')
  const isHostedClient = window.location.protocol === 'https:'

  const canShowBull = isHostedServer === isHostedClient

  const bullBoardOrigin = isHostedServer ? window.location.origin : API_URL
  const bullBoardUrl = canShowBull ? `${bullBoardOrigin}/staff/bull` : ''
  const bullMessage = canShowBull
    ? '🐂 🐂 🐂 🐂 🐂 🐂 🐂 🐂 🐂'
    : '😿 (no joy for local client with remote server)'

  return (
    <div>
      <h1>New and improved 👩🏻‍🍳 Staff Zone 👨🏾‍🍳</h1>
      <Stack>
        {staffItems.map((item) => (
          <React.Fragment key={item.href}>
            <Text>
              <Text span fz="xl" fw="bold">
                <NavAnchor href={item.href}>{item.title}</NavAnchor>
              </Text>
              <Text span> - {item.description}</Text>
            </Text>
          </React.Fragment>
        ))}
        <Text>
          <Text span fz="xl" fw="bold">
            <Anchor href={bullBoardUrl}>Bull MQ</Anchor>
          </Text>
          <Text span>- {bullMessage}</Text>
        </Text>
      </Stack>
    </div>
  )
}
