import throttle from 'lodash.throttle'
import { nanoid } from 'nanoid'
import {
  collab,
  getVersion,
  receiveTransaction,
  sendableSteps,
} from 'prosemirror-collab'
import { dropCursor } from 'prosemirror-dropcursor'
import { gapCursor } from 'prosemirror-gapcursor'
import { Transaction } from 'prosemirror-state'
import { Step } from 'prosemirror-transform'
import { EditorView } from 'prosemirror-view'

import { dismissToast, TOAST_ID } from '@components/Toast'
import { ILoadedScript, IRoot } from '@state'
import { createEditorView, ScriptoApiClient } from '@util'
import { DatadogClient } from '@util/datadog'

import { SCROLL_MARGIN } from './constants.js'
import { createLiveEditorState } from './createEditorState'
import {
  convertStepData,
  pullStepsFromScrapiAndHandleErrors,
  pushStepsAndHandleErrors,
  validateConfirmedSteps,
} from './editor-helpers'
import { handleClick } from './handleClick'
import { getLiveEditorPlugins } from './plugin-configs'

const ddLog = DatadogClient.getInstance()

const safeJson = (value: unknown) => {
  try {
    const parsed = JSON.parse(JSON.stringify(value))
    return parsed
  } catch (e) {
    return 'bad json'
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractErrorInfo = (e: any) => {
  const code = typeof e?.code === 'number' ? e.code : undefined
  const message = typeof e?.message === 'string' ? e.message : 'Unknown error'
  const stack = e?.stack
  return {
    code,
    message,
    stack,
  }
}

const RECEIVE_TR_OPTS = { mapSelectionBackward: true }
const RECEIVE_TR_PHASE = 'receiveTransaction'

export class EditorManager {
  private readonly mst: IRoot
  private readonly apiClient: ScriptoApiClient

  // guard against trying to push & pull steps at the same time
  private isProcessingSteps = false

  // Tracking values about the recursive sync cycle -- used for logging
  private cycleStartTime = 0
  // disconnected is a little weird. It means we BECAME disconnected
  // after having been connected once
  private disconnected = false
  private availableServerVersion = 0
  private readonly scriptId: string
  private readonly collabId = nanoid()

  // keep track of when the selection was explicitly changed by us
  // to know that we need to send an UPDATE_CURSOR socket message
  private hasPendingCursorUpdate = false

  view: EditorView | null = null

  constructor({ scriptId, mst }: { mst: IRoot; scriptId: string }) {
    this.mst = mst
    this.scriptId = scriptId
    this.apiClient = this.mst.apiClient

    // bound methods
    this.dispatch = this.dispatch.bind(this)

    // activity analytics
    this.trackScriptActivity = throttle(
      this.trackScriptActivity.bind(this),
      10000,
    )
  }

  get socketId() {
    return this.mst.socketManager.socketId
  }

  // isDestroyed and !this.view are effectively synonymous, just using
  // this syntactic sugar to make the code clearer.
  get isDestroyed() {
    return !this.view
  }

  get element() {
    return document.getElementById('editor')
  }

  get debugSync(): boolean {
    return this.mst.view.debugSync
  }

  get debugPullDelay(): number | undefined {
    return this.mst.currentScript?.syncStatus.settings.pullDelay
  }

  get debugPushDelay(): number | undefined {
    return this.mst.currentScript?.syncStatus.settings.pushDelay
  }

  get locallyConfirmedVersion() {
    try {
      return this.view ? getVersion(this.view.state) : 0
    } catch (e) {
      ddLog.warn('document version unavailable')
      return 0
    }
  }
  get hasLatestServerSteps() {
    return this.availableServerVersion === this.locallyConfirmedVersion
  }
  get hasLocalStepsToSend() {
    return this.view && !!sendableSteps(this.view.state)
  }
  get hasStepsToProcess() {
    return this.hasLocalStepsToSend || !this.hasLatestServerSteps
  }
  get shouldProcessSteps() {
    return (
      !this.disconnected &&
      this.view &&
      this.hasStepsToProcess &&
      !this.isProcessingSteps
    )
  }

  // this is awkward. Sometimes the editorManager needs to get the script that it
  // belongs to. In a perfect world, the editor Manager would be INSIDE the mst script
  get loadedScriptParent(): ILoadedScript | null {
    const { currentScript } = this.mst
    if (
      currentScript &&
      currentScript.id === this.scriptId &&
      !this.isDestroyed
    ) {
      return currentScript
    }
    return null
  }

  start(script: ILoadedScript, element: HTMLElement) {
    this.setView(null)
    const isEditable = !!script.isEditable
    // create new state with provided script
    const state = createLiveEditorState({
      // provided by starting payload
      clientId: this.socketId,
      collabId: this.collabId,
      scriptId: script.id,
      script,
      user: this.mst.user,
      mst: this.mst,
    })

    // create new editor view
    const view = createEditorView({
      element,
      editorProps: {
        state,
        attributes: {
          class: `is-${script.type}`,
        },
        editable: () => isEditable,
        scrollThreshold: SCROLL_MARGIN,
        scrollMargin: SCROLL_MARGIN,
        dispatchTransaction: this.dispatch,
        handleClick,
      },
    })
    this.setView(view)
  }

  dispatch(tr: Transaction) {
    if (!this.view) {
      return
    }
    const editorState = this.view.state.apply(tr)
    this.view.updateState(editorState)
    if (tr.selectionSet) {
      this.hasPendingCursorUpdate = true
    }

    // if we have steps on the tr, kick off the sync cycle. If not
    // deal with any pending cursor updates
    if (tr.docChanged) {
      this.trackScriptActivity()
      this.processOutstandingSteps('dispatch')
    } else {
      this.sendCursorUpdateIfAppropriate()
    }
  }
  /**
   * This is a wrapper around getSteps and createSteps and ensures we are only involved
   * in one attempt to get in sync with the server at a time. The field member: this.isProcessingSteps
   * acts as a guard (replacing throttle, debounce and asyncLimit in editor v1)
   *
   * Source and depth are passed in for logging purposes
   */
  async processOutstandingSteps(trigger: string, depth = 0) {
    this.reportSyncStatus(trigger, depth)

    if (!this.view) {
      return
    }
    // beginning and end of cycle are for logging/tracking how long it's taking us
    // to process outstanding steps
    const beginningOfCycle = depth === 0
    const endOfCycle = !this.shouldProcessSteps && !beginningOfCycle

    if (!this.shouldProcessSteps) {
      // if we just got in sync after pushing steps, we'll have
      // a pending cursor update.
      this.sendCursorUpdateIfAppropriate()

      if (endOfCycle) {
        // we hit the end of our recursion and have nothing left to process.
        // Update the syncLatency for log enhancement
        ddLog.setSyncCycleLatency(new Date().valueOf() - this.cycleStartTime)
      }
    } else {
      this.isProcessingSteps = true
      if (beginningOfCycle) {
        this.cycleStartTime = new Date().valueOf()
      }

      try {
        if (this.hasLatestServerSteps) {
          const sendable = sendableSteps(this.view.state)
          if (sendable) {
            await this.pushSteps({
              scriptId: this.scriptId,
              version: sendable.version,
              steps: sendable.steps,
            })
          }
        } else {
          await this.pullSteps(this.scriptId, this.locallyConfirmedVersion)
        }
      } catch (err) {
        // push and pull are meant to handle their own errors
        ddLog.error('Bug: error thrown in push or pull steps', { err })
      } finally {
        this.isProcessingSteps = false
      }

      // recurse to handle any new steps we created locally or
      // or learned about on the server while processing
      if (!this.isDestroyed) {
        this.processOutstandingSteps('recursion', depth + 1)
      }
    }
  }

  // When we discover there's a script version available, we call this. We ONLY want to
  // use this to increase this.availableServerVersion. There are normal cases where
  // we already know of a higher available version (from a socket change or if we are
  // receiving info about our already locally applied confirmed changes)
  onScriptVersionUpdated(
    {
      scriptId,
      version: advertisedVersion,
    }: { scriptId: string; version: number },
    trigger: string,
  ) {
    if (scriptId !== this.scriptId || this.isDestroyed) {
      return
    }

    if (advertisedVersion > this.availableServerVersion) {
      this.availableServerVersion = advertisedVersion
    }

    // we may or may not have steps to process
    // but processOutstandingSteps will determine that
    this.processOutstandingSteps(trigger)
  }

  async pullSteps(scriptId: string, version: number) {
    if (this.isDestroyed) {
      return
    }

    const apiResult = await pullStepsFromScrapiAndHandleErrors({
      debugDelay: this.debugPullDelay,
      scrapiClient: this.mst.scrapi,
      fromVersion: version,
      scriptId,
    })

    if (!apiResult.success && apiResult.noRetry) {
      // TODO: if not retryable, we should notify users and stop the sync loop
      ddLog.error('Non-retryable error on get steps', {
        apiResult,
        scriptId,
        version,
      })
    }

    // Only process the steps if we got a valid result and
    // we're not destroyed
    if (
      this.isDestroyed ||
      !this.view ||
      !apiResult.success ||
      !validateConfirmedSteps(
        apiResult.result.steps,
        this.locallyConfirmedVersion,
      )
    ) {
      return
    }

    const { steps, collabIds } = convertStepData(apiResult.result.steps)

    let phase = ''
    try {
      phase = RECEIVE_TR_PHASE
      const tr = receiveTransaction(
        this.view.state,
        steps,
        collabIds,
        RECEIVE_TR_OPTS,
      )
      phase = 'apply'
      const newState = this.view.state.apply(tr)
      phase = 'update'
      this.view.updateState(newState)
      const script = this.loadedScriptParent
      if (script) {
        script.syncStatus.reportGetSuccess()
      }
    } catch (err: unknown) {
      const { message, stack } = extractErrorInfo(err)
      const localSteps = steps.map((step) => step.toJSON())
      const remoteStepRange = safeJson([steps[0], steps[steps.length - 1]])
      ddLog.error(
        'apply steps failure summary',
        { phase, remoteStepRange },
        err,
      )
      ddLog.error('apply steps failure', {
        localSteps: safeJson(localSteps),
        message: safeJson(message),
        stack: safeJson(stack),
        remoteStepRange,
        phase,
      })
      /* intermittently in very busy scripts we see an unrecoverable error
          from within the ProseMirror collab plugin when it is unable to
          rebase local unconfirmed steps on top of remote confirmed steps.

          we are operating under the assumption that this happens when local
          structural repagination steps are valid but not invertable.

          this causes an endless error loop in an otherwise fault tolerant flow.
          displaying a normal modal doesn't interrupt attempts to synchronize
          so (for now) we signal a fatal error and force a reload.

          this has no hope of eradicating the error, but we expect it to
          minimize the repurcussions for editors because their own local steps
          should be discarded when they reload the script with new steps.

          if the same editor encounters this error several times in succession
          we can investigate a more nuclear option to avoid saving unconfirmed
          steps in local storage entirely.
        */
      if (phase === RECEIVE_TR_PHASE) {
        ddLog.error('fatal error rebasing remote steps', {
          remoteStepRange,
        })
        window.alert(
          "We encountered an error we can't recover from. Reload to continue working.",
        )
        window.location.reload()
      }
    }
  }

  async pushSteps({
    scriptId,
    version,
    steps,
  }: {
    scriptId: string
    version: number
    steps: readonly Step[]
  }) {
    const pushResult = await pushStepsAndHandleErrors({
      apiClient: this.apiClient,
      params: {
        scriptId,
        clientId: this.collabId,
        version,
        steps,
      },
      debugDelay: this.debugPushDelay,
    })

    if (this.isDestroyed || !this.view) {
      return
    }

    // If the server response is success, the steps were confirmed by the authority,
    // and we can mark them as confirmed in prosemirror-collab
    if (pushResult.type === 'success') {
      try {
        const tr = receiveTransaction(
          this.view.state,
          steps,
          new Array(steps.length).fill(this.collabId),
          RECEIVE_TR_OPTS,
        )
        const newState = this.view.state.apply(tr)
        this.view.updateState(newState)
        const { currentScript } = this.mst
        currentScript?.syncStatus.reportPushSuccess()

        // sanity check- the returned version from the server should be
        // exactly the same as the confirmed version in prosemirror collab
        if (pushResult.version === this.locallyConfirmedVersion) {
          this.onScriptVersionUpdated(
            {
              scriptId,
              version: pushResult.version,
            },
            'push success',
          )
        } else {
          ddLog.error('Push steps logic bug', {
            pushVersion: pushResult.version,
            locallyConfirmedVersion: this.locallyConfirmedVersion,
          })
        }
      } catch (err) {
        ddLog.error('Failure confirming own steps', {
          scriptId,
          socketId: this.mst.socketManager.socketId,
          collabId: this.collabId,
          version,
          stepCount: steps.length,
          err,
        })
      }
    } else if (pushResult.type === 'behind') {
      this.onScriptVersionUpdated(
        { scriptId, version: pushResult.version },
        'push behind',
      )
    }
  }

  private dispatchEmptyTransaction() {
    if (this.view) {
      this.dispatch(this.view.state.tr)
    }
  }

  onUserLeft() {
    this.dispatchEmptyTransaction()
  }

  onUserJoin() {
    this.sendCursorUpdateEvenIfAlreadySent()
  }
  /**
   *  only send cursor updates if we are in sync and have changed our
   *  selection since we last reported. The former is important to enable
   *  remote position mapping, the latter to prevent infinite feedback loops
   */
  sendCursorUpdateIfAppropriate() {
    if (this.view && this.hasPendingCursorUpdate && !this.hasStepsToProcess) {
      this.hasPendingCursorUpdate = false
      const { selection } = this.view.state
      const payload = {
        scriptId: this.scriptId,
        clientId: this.socketId,
        version: this.locallyConfirmedVersion,
        headPosition: selection.$head.pos,
        anchorPosition: selection.$anchor.pos,
      }
      this.mst.socketManager.updatePmCursor(payload)
    }
  }

  // there are two situations where we might want to send a cursor update even
  // though our selection hasn't changed since we last reported it. One is when
  // a new user joins the script (bad server design). The second is when we (re)join
  // the socket room.
  sendCursorUpdateEvenIfAlreadySent() {
    this.hasPendingCursorUpdate = true
    this.sendCursorUpdateIfAppropriate()
  }

  // we received a cursor update from another user. We need to dispatch
  // a transaction to get the remote cursors plugin to run
  onCursorUpdated() {
    this.dispatchEmptyTransaction()
  }

  onDisconnected(script: ILoadedScript) {
    if (!this.disconnected && this.view) {
      this.disconnected = true
      this.setEditable(false, script)
    }
  }

  onJoinedRoom({
    version,
    editable,
    script,
  }: {
    version: number
    editable: boolean
    script: ILoadedScript
  }) {
    if (this.view && !this.isDestroyed) {
      this.disconnected = false
      dismissToast({ id: TOAST_ID.SCRIPT_DISCONNECT })
      this.setEditable(editable, script)
      this.onScriptVersionUpdated({ scriptId: script.id, version }, 'join-room')
      this.view.focus()
      this.sendCursorUpdateEvenIfAlreadySent()
    }
  }

  // matching the editor-v1 signature here-- editor-v1 doesn't use the
  // scriptId. If we switch
  setServerVersion(version: number, scriptId: string) {
    this.onScriptVersionUpdated(
      {
        scriptId,
        version,
      },
      'join',
    )
  }
  getSendableSteps() {
    if (!this || !this.view || !this.view.state) {
      return null
    }
    return sendableSteps(this.view.state)
  }
  trackScriptActivity() {
    const script = this.loadedScriptParent
    const format = script?.scriptFormat.id ?? 'unset'
    const paginationType = script?.paginationType ?? 'unset'
    ddLog.info('script activity', { paginationType, format })
    this.mst.trackEvent('EDITOR_WRITING_ACTIVITY')
  }
  // sets current view and cleans up any previous view state
  setView(prosemirrorView: EditorView | null) {
    // tear down the current prosemirror view if it exists
    this.view?.destroy()
    // if we're not creating a new view, clean up any
    // errant html we've added
    if (!prosemirrorView && this.element) {
      this.element.innerHTML = ''
    }
    this.view = prosemirrorView
    this.mst.currentScript?.updateEditorViewObservables()
  }

  // when we navigte away from the script, we need to tear down
  // the editor view & state, but this class might still be processing
  // async requests. We set isDestroyed to true to handle this
  destroy() {
    this.setView(null)
  }

  setEditable(value: boolean, script: ILoadedScript) {
    if (this.view && this.view.editable !== value) {
      this.view.setProps({ editable: () => value })
      this.reloadPlugins(script)
    }
  }

  reloadPlugins(script: ILoadedScript) {
    const { collabId, socketId: clientId, mst } = this
    const { user } = mst
    const editorView = this.view

    if (editorView) {
      const { id: scriptId } = script
      const config = { mst, script, clientId, collabId, scriptId, user }

      const ourPlugins = getLiveEditorPlugins(config).map((fn) => fn(config))
      const pmPlugins = [
        dropCursor(),
        gapCursor(),
        collab({
          clientID: collabId,
          version: script.version,
        }),
      ]

      const newState = editorView.state.reconfigure({
        plugins: ourPlugins.concat(pmPlugins),
      })
      editorView.updateState(newState)
    }
  }

  reportSyncStatus(trigger: string, depth: number) {
    const { currentScript } = this.mst

    if (
      currentScript &&
      currentScript.id === this.scriptId &&
      !this.isDestroyed
    ) {
      const getStepsRequired = !this.hasLatestServerSteps
      const sendStepsRequired = !!this.hasLocalStepsToSend
      currentScript.syncStatus.update({
        getStepsRequired,
        sendStepsRequired,
      })
    } else {
      ddLog.errorOnce(
        'BUG: Editor manager trying to report sync status incorrectly',
        {
          trigger,
          mstScriptId: currentScript?.id ?? 'missing',
          scriptId: this.scriptId,
          destroyed: this.isDestroyed,
          depth,
        },
        'invalid-sync-status',
      )
    }
  }
}
