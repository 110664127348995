import { useWindowEvent } from '@mantine/hooks'

import { showConfirmModal } from '@components/Modals'
import {
  BeforeRouteChangeHandler,
  matchRoutePattern,
  RouteChangeArgs,
  useBlockRouteChangeEffect,
  useUnloadRouteEffect,
} from '@routes'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util'

const goingToRouteForScript = ({
  scriptId,
  to,
}: {
  scriptId: string
  to: string
}) => {
  const [isMatch, params] = matchRoutePattern(
    [ROUTE_PATTERNS.scriptEditor, ROUTE_PATTERNS.splitEditor],
    to,
  )
  return isMatch && params.scriptId === scriptId
}

const goingToRouteForRundown = ({
  rundownId,
  to,
}: {
  rundownId: number
  to: string
}) => {
  const [isMatch, params] = matchRoutePattern(
    [ROUTE_PATTERNS.rundown, ROUTE_PATTERNS.splitEditor],
    to,
  )
  return isMatch && params.rundownId === String(rundownId)
}

// when navigating to a new route, remove script and rundown models
// if they're not on the next route
export const useUnloadEditors = () => {
  const {
    currentScript,
    currentRundown,
    removeCurrentScript,
    removeCurrentRundown,
  } = useMst()

  const scriptId = currentScript?.id
  const rundownId = currentRundown?.id

  useUnloadRouteEffect(({ to }) => {
    if (rundownId && !goingToRouteForRundown({ to, rundownId })) {
      removeCurrentRundown()
    }

    if (scriptId && !goingToRouteForScript({ to, scriptId })) {
      removeCurrentScript()
    }
  })
}

// check for unsaved steps to block in-app navigation
export const useCheckUnsavedBeforeLeavingScript = () => {
  const { currentScript } = useMst()

  const handler: BeforeRouteChangeHandler = async ({ to }: RouteChangeArgs) => {
    const shouldPromptToConfirm = (): boolean => {
      return (
        !!currentScript &&
        !goingToRouteForScript({ to, scriptId: currentScript.id }) &&
        currentScript.syncStatus.sendStepsRequired
      )
    }

    if (!shouldPromptToConfirm()) {
      return {
        cancel: false,
      }
    }

    const userConfirmed = await showConfirmModal({
      children:
        "Scripto isn't finished syncing your most recent changes. Are you sure you want to leave this script?",
      title: 'Unsaved work',
      confirmLabel: 'Leave with unsaved work',
      cancelLabel: 'Wait until syncing completes',
      dangerous: true,
      size: 'lg',
    })

    return { cancel: !userConfirmed }
  }

  useBlockRouteChangeEffect(handler)
}

// If the user tries to leave the app with unsaved changes we block
// the window.beforeunload event but only if we haven't already put
// up a readonly banner.
export const useCheckUnsavedBeforeLeavingApp = () => {
  const { currentScript, socketManager } = useMst()

  const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    const shouldConfirmBeforeUnload =
      currentScript &&
      currentScript.syncStatus.sendStepsRequired &&
      !currentScript.syncStatus.isTooStaleForSafety &&
      socketManager.connected &&
      !currentScript.pmEditor.editabilityLost

    if (shouldConfirmBeforeUnload) {
      event.preventDefault()
    }
  }

  useWindowEvent('beforeunload', beforeUnloadHandler)
}
