import { IRoot } from '@state'
import * as pmHelpers from '@util/prosemirrorHelpers'

export class DebugTools {
  timeoutMs: number | undefined
  sendDebugLogs: boolean = false

  constructor(readonly mst: IRoot) {}

  get sc() {
    return this.mst.currentScript
  }

  get rd() {
    return this.mst.currentRundown
  }

  get em() {
    return this.sc?.pmEditor.editorManager
  }

  get es() {
    return this.mst.currentScript?.pmEditor.editorState
  }

  get ev() {
    return this.mst.currentScript?.pmEditor.editorView
  }

  get pm() {
    return pmHelpers
  }

  // set to a low number to test axios & fetch timeout errors
  setAPITimeout(ms: number) {
    this.mst.apiClient._axios.defaults.timeout = ms
    this.timeoutMs = ms
  }

  getAbortSignal(): AbortSignal | undefined {
    if (typeof this.timeoutMs === 'number') {
      return AbortSignal.timeout(this.timeoutMs)
    }
  }

  turnOnDebugLogs() {
    this.sendDebugLogs = true
  }
}
