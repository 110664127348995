import { Divider, Group, Space, Stack, Text } from '@mantine/core'
import cn from 'classnames'

import {
  HeaderOrFooterPrefs,
  SlotConfig,
  StaticSlotContent,
} from '@util/zodSchemas/printPreferences'

import styles from './Example.module.scss'

const sampleText: Record<StaticSlotContent | 'literal', string | string[]> = {
  'full-title': ['Episode #607', 'Act 1'],
  'short-title': 'Episode #607',
  'page-number': '2.',
  timestamp: '7/20/1969 at 10:56 PM',
  'page-number-total': 'Page 2/10',
  duration: '17:24',
  literal: 'YOUR TEXT HERE',
}

const isDuration = (config?: SlotConfig) =>
  config?.slotType === 'static' && config.contentType === 'duration'

const ExampleSlot = ({
  prefs,
  pos,
  isScreenplay,
}: {
  isScreenplay: boolean
  prefs: HeaderOrFooterPrefs['slots']
  pos: 'left' | 'center' | 'right'
}) => {
  const config = prefs[pos]
  if (
    !config ||
    (isDuration(config) && isScreenplay) ||
    config.slotType === 'rundown-cell-value'
  ) {
    return <Text size="12"></Text>
  }

  const text =
    config.slotType === 'literal'
      ? sampleText.literal
      : sampleText[config.contentType]

  if (Array.isArray(text)) {
    return (
      <Stack gap={0}>
        <Text size="12" ta={pos}>
          {text[0]}
        </Text>
        <Text size="12" ta={pos} tt="uppercase">
          {text[1]}
        </Text>
      </Stack>
    )
  }

  return (
    <Text size="12" ta={pos}>
      {text}
    </Text>
  )
}

export const Example = ({
  slots,
  hr,
  mode = 'header',
  hidden = false,
  isScreenplay = false,
}: {
  slots: HeaderOrFooterPrefs['slots']
  hr?: boolean
  mode?: 'header' | 'footer'
  hidden?: boolean
  isScreenplay?: boolean
}) => {
  const isFooter = mode === 'footer'

  return (
    <Stack gap={10} px={10}>
      <Text fw="bold">Example</Text>
      <div
        className={cn(styles.prefModal_exampleOuter, {
          [styles.prefModal_exampleOuter___footer]: mode === 'footer',
        })}
      >
        <div className={styles.prefModal_exampleInner}>
          {!hidden && (
            <>
              {mode === 'footer' && <Space h={10} />}
              {mode === 'footer' && hr && <Divider color="dark" />}
              <Group
                justify="space-between"
                align={isFooter ? 'start' : 'end'}
                className={styles.prefModal_exampleContent}
              >
                <Stack
                  gap={0}
                  align="flex-start"
                  className={styles.prefModal_exampleBookend}
                >
                  <ExampleSlot
                    prefs={slots}
                    isScreenplay={isScreenplay}
                    pos="left"
                  />
                </Stack>
                <Stack align="center">
                  <ExampleSlot
                    prefs={slots}
                    isScreenplay={isScreenplay}
                    pos="center"
                  />
                </Stack>
                <Stack
                  align="flex-end"
                  className={styles.prefModal_exampleBookend}
                >
                  <ExampleSlot
                    prefs={slots}
                    isScreenplay={isScreenplay}
                    pos="right"
                  />
                </Stack>
              </Group>
              {mode === 'header' && hr && <Divider color="dark" />}
            </>
          )}
        </div>
      </div>
    </Stack>
  )
}
