import { AxiosResponse } from 'axios'

import { templateSchema } from '../../rundowns'
import type { RundownPayload } from '../types'

import type { ApiConfig } from './types'

// This is a quick hack to prototype new rundown schemas without
// needing to create them in the database-- we shim them into the
// response payload. Set this to true and tweak the template schema
// to force all rundowns to use the template
const USE_TEMPLATE_SCHEMA = false

const templateTransformer = (response: AxiosResponse): RundownPayload => ({
  ...response.data,
  schema: {
    ...response.data.schema,
    name: 'TEMPLATE_SCHEMA',
    schema: templateSchema,
  },
})

export const getRundown: ApiConfig<number | string, RundownPayload> = {
  buildRequest: (id) => ({
    method: 'GET',
    url: `/rundowns/${id}`,
  }),
  transformResponse: USE_TEMPLATE_SCHEMA ? templateTransformer : undefined,
}
