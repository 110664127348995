import { buildShortcutTip, Keys } from '@hooks'
import { IRundownRow } from '@state/types'

export const SELECT_CHILD_ROWS_TIP = buildShortcutTip([Keys.ALT, 'Click'])
export const COPY_TIP = buildShortcutTip([Keys.CMD, 'C'])
export const PASTE_TIP = buildShortcutTip([Keys.CMD, 'V'])

/*
  1 row selected
    * Insert row above
    * Insert [header] row above
  0 rows selected
    * Insert row at top
    * Insert [header] row at top
*/
export const insertRowTitle = ({
  atTop,
  rowTypeName,
}: {
  atTop: boolean
  rowTypeName?: string
}) => {
  const parts: string[] = ['Insert']
  if (rowTypeName) {
    parts.push(rowTypeName)
  }
  parts.push('row')
  parts.push(atTop ? 'at top' : 'above')

  return parts.join(' ')
}

export const pasteRowsTitle = (rowCount: number, atTop: boolean) => {
  if (rowCount === 0) {
    return 'Paste rows'
  }
  const parts: string[] = ['Paste']
  if (rowCount > 1) {
    parts.push(String(rowCount))
  }
  parts.push(rowCount === 1 ? 'row' : 'rows')
  parts.push(atTop ? 'at top' : 'above')

  return parts.join(' ')
}

export const copyTitle = (selectedRowCount: number) => {
  const parts: string[] = ['Copy ']
  if (selectedRowCount === 0) {
    parts.push('cell')
  } else {
    parts.push(String(selectedRowCount), ' row')
  }

  if (selectedRowCount > 1) {
    parts.push('s')
  }
  return parts.join('')
}

export const selectChildRowsTitle = (row?: IRundownRow): string => {
  const levelName = row?.rowLevelName
  if (levelName) {
    return `Select all rows in this ${levelName}`
  }
  // this shouldn't get called because we don't render the menu
  // on a non-level row
  return 'Select all child rows'
}
