import { Anchor as AnchorComponent, AnchorProps } from '@mantine/core'

// this shouldnt be necessary. maybe we can contribute a patch upstream
type Kludge = Partial<AnchorProps> & { tabIndex: number }

export const Anchor = AnchorComponent.extend({
  defaultProps: {
    // this ensures our <NavAnchor> elements are always tab-navigable
    tabIndex: 0,
  } as Kludge,
  styles: {
    // This affects the focus outline
    root: {
      borderRadius: 2,
    },
  },
})
