import { Tooltip } from '@mantine/core'
import cn from 'classnames'

import { FaIcon, FaIconName, FaIconSet } from '@components/FaIcon'

import styles from './SyncStatusCloud.module.scss'

const FLASH_ANIMATION =
  'animate__animated animate__flash animate__infinite animate__slower'

type CloudIconType = 'xmark' | 'check' | 'syncing' | 'editing'
export type SyncStatusCloudProps = {
  status: 'ok' | 'warn' | 'error'
  iconType: CloudIconType
  tooltip: string
}

const BaseIcon: Record<
  CloudIconType,
  {
    iconName: FaIconName
    iconSet: FaIconSet
  }
> = {
  check: {
    iconName: 'fa-cloud-check',
    iconSet: 'fas',
  },
  xmark: {
    iconName: 'fa-cloud-xmark',
    iconSet: 'fas',
  },
  syncing: {
    iconName: 'fa-cloud',
    iconSet: 'fas',
  },
  editing: {
    iconName: 'fa-cloud-edit',
    iconSet: 'fa-kit',
  },
}

export const SyncStatusCloud = ({
  iconType,
  tooltip,
  status,
}: SyncStatusCloudProps) => {
  const color =
    status === 'ok' ? 'green.9' : status === 'warn' ? 'orange.6' : 'red.6'
  const flash = status === 'error'
  const { iconName, iconSet } = BaseIcon[iconType]
  return (
    <Tooltip label={tooltip} position="bottom">
      <div className={styles.iconWrapper}>
        <FaIcon
          className={cn({
            [FLASH_ANIMATION]: flash,
          })}
          c={color}
          size="18px"
          icon={iconName}
          faIconSet={iconSet}
        />
        {iconType === 'syncing' && (
          <FaIcon
            size="9px"
            c="white"
            icon="fa-rotate"
            className={cn(styles.syncSpinner, 'fa-spin')}
          />
        )}
      </div>
    </Tooltip>
  )
}

export const BadSocketCloud = ({
  type,
}: {
  type: 'disconnected' | 'error'
}) => {
  const tooltip =
    type === 'disconnected'
      ? 'Offline'
      : 'Error connecting to the server - try refreshing your browser'
  return <SyncStatusCloud status="error" iconType="xmark" tooltip={tooltip} />
}
