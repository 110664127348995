import * as AgGrid from '@ag-grid-community/core'

import { RundownRowData } from '@util/ScriptoApiClient/types'

export const blockIdGetter: AgGrid.ValueGetterFunc<RundownRowData> = (
  params,
) => {
  return params.data?.blockId ?? null
}

export const scriptIdGetter: AgGrid.ValueGetterFunc<RundownRowData> = (
  params,
) => {
  return params.data?.identityScriptId ?? params.data?.linkedScriptId ?? null
}
