import React from 'react'

import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  Paper,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useClickOutside } from '@mantine/hooks'

import { FaIcon } from '@components/FaIcon'

import styles from './HyperlinkEditor.module.scss'

export type HyperlinkFormValues = { href: string }

// common drop
const Wrapper = ({
  children,
  onClickOutside,
}: {
  children: React.ReactNode
  onClickOutside?: () => void
}) => {
  const clickOutsideRef = useClickOutside(() => onClickOutside?.())
  return (
    <Paper
      ref={clickOutsideRef}
      withBorder
      p={8}
      radius="sm"
      shadow="lg"
      className={styles.wrapper}
    >
      {children}
    </Paper>
  )
}

export const HyperlinkForm = ({
  href = '',
  onSubmit,
  onCancel,
}: {
  href?: string
  onSubmit: (values: HyperlinkFormValues) => void
  onCancel: () => void
}) => {
  const form = useForm<HyperlinkFormValues>({
    initialValues: { href },
  })
  const ref = React.useRef<HTMLInputElement>(null)

  // adding an autoFocus prop to the input itself
  // results in intermittent undesired scrolling
  React.useEffect(() => {
    ref.current?.focus({ preventScroll: true })
    ref.current?.select()
  }, [])

  return (
    <Wrapper onClickOutside={onCancel}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Group gap="sm">
          <TextInput
            ref={ref}
            size="sm"
            placeholder="Paste or type a link"
            onKeyDown={(e) => {
              if (e.key === 'Escape') onCancel()
            }}
            {...form.getInputProps('href')}
          />
          <Button type="submit" size="sm">
            Apply
          </Button>
        </Group>
      </form>
    </Wrapper>
  )
}

export const HyperlinkInfo = ({
  href,
  onEdit,
  onRemove,
}: {
  onEdit: () => void
  onRemove: () => void
  href?: string
}) => (
  <Wrapper>
    <Group>
      <Anchor href={href} target="_blank">
        <Text truncate maw={200}>
          {href}
        </Text>
      </Anchor>
      <Group gap={5}>
        <Tooltip label="Edit Hyperlink">
          <ActionIcon
            className={styles.hyperlinkEditor_action}
            onClick={onEdit}
          >
            <FaIcon icon="fa-pencil" />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Remove Hyperlink">
          <ActionIcon
            className={styles.hyperlinkEditor_action___danger}
            onClick={onRemove}
          >
            <FaIcon icon="fa-trash" />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Group>
  </Wrapper>
)
