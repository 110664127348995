import { z } from 'zod'

import { schemas as scrapiSchemas } from '@showrunner/scrapi'

import { printPreferences } from '@util/zodSchemas'

// prefs are always stored as objects
const SERIALIZED_PREF_RE = /^{.*}$/
const SERIALIZED_ARRAY_RE = /^\[.*\]$/

const localStorageValueToPojo = (value: unknown): object => {
  if (typeof value === 'string' && SERIALIZED_PREF_RE.test(value.trim())) {
    try {
      return JSON.parse(value)
    } catch {
      return {}
    }
  }
  return {}
}

const localStorageValueToArray = (value: unknown): Array<unknown> => {
  if (typeof value === 'string' && SERIALIZED_ARRAY_RE.test(value.trim())) {
    try {
      return JSON.parse(value)
    } catch {
      return []
    }
  }
  return []
}

const basePreference = z.unknown().transform(localStorageValueToPojo)
const baseArrayPreference = z.unknown().transform(localStorageValueToArray)

const storedRundownPrintPrefs = basePreference.pipe(
  printPreferences.rundownPrintPrefs,
)

const storedScriptPrintPrefs = basePreference
  .pipe(printPreferences.scriptPrintPreferences)
  .catch(printPreferences.DEFAULT_SCRIPT_PREFS)

// This user-generated typecheck is to convince typescript that successfully parsing a
// uuid via zod means it's a string.
type Uuid = string
function isUuid(value: unknown): value is Uuid {
  return z.string().uuid().safeParse(value).success
}

const parseFavoriteUuids = (raw: unknown): string[] => {
  const parsed = baseArrayPreference.safeParse(raw)
  if (parsed.success) {
    return parsed.data.filter(isUuid)
  }
  return []
}

const openedListing = z.tuple([
  scrapiSchemas.SerializableDate,
  scrapiSchemas.ListingId,
])
const openedListingsPref = basePreference.pipe(
  z.object({
    listings: z.array(openedListing),
  }),
)

type OpenedListing = z.infer<typeof openedListing>
type OpenedItem = OpenedListing & [Date, string]
function isOpenedItem(item: OpenedListing): item is OpenedItem {
  const [, id] = item
  return isUuid(id)
}

export type OpenedItemsPref = {
  listings: OpenedItem[]
}

const parseOpenedItems = (raw: unknown): OpenedItemsPref => {
  const parsed = openedListingsPref.safeParse(raw)
  if (parsed.success) {
    return { listings: parsed.data.listings.filter(isOpenedItem) }
  }

  return { listings: [] }
}

export const prefSchemas = {
  storedRundownPrintPrefs,
  storedScriptPrintPrefs,
  parseFavoriteUuids,
  parseOpenedItems,
}
