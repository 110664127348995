import { types } from 'mobx-state-tree'

// create a simple model to track height and width. We do this instead of
// types.frozen so when updating a value with the same numbers, we don't trigger
// object changes (and needless rerenders)
const Dimension = types
  .model('Dimension', {
    height: 0,
    width: 0,
  })
  .actions((self) => ({
    update({ height, width }: Dimensions) {
      self.height = height

      self.width = width
    },
    reset() {
      self.height = 0
      self.width = 0
    },
  }))

// This model tracks some key view dimensions for easy injection
// into child components. The updates are managed by hooks in the components.
// The dimenions are not accurate or relevant if the components are not currently
// renered.
export const DimensionsModel = types.model('Dimensions', {
  scriptScroller: types.optional(Dimension, {}),
})
