import { Checkbox as CheckboxComponent } from '@mantine/core'

import styles from './checkbox.module.scss'

export const Checkbox = CheckboxComponent.extend({
  classNames: {
    body: styles.body,
    description: styles.description,
    error: styles.error,
    input: styles.input,
    label: styles.label,
  },
})
