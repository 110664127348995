import React from 'react'

import { Button, Group, Popover, Space, Stack } from '@mantine/core'

import { useMst } from '@state'

import { FILTERTAG_CLASSNAME, hasPhraseOrActiveFilter } from '../helpers'
import { defaultCriteria, usePendingSearchContext } from '../useSearchContext'

import { DocTypePicker } from './DocTypePicker'
import { FolderPicker } from './FolderPicker'
import { IncludeRadio } from './IncludeRadio'
import { LastEditedFilter } from './LastEditedFilter'

export const FilterDropdown = ({
  dateFilterCategory,
  setDateFilterCategory,
  folderFilters,
  setFolderFilters,
  onClose,
  onSubmit,
  btnRef,
  setOpened,
}: {
  dateFilterCategory: string
  setDateFilterCategory: React.Dispatch<React.SetStateAction<string>>
  folderFilters: { category: string; folderName: string | undefined }
  setFolderFilters: React.Dispatch<
    React.SetStateAction<{ category: string; folderName: string | undefined }>
  >
  onClose?: () => void
  onSubmit?: (event: React.FormEvent) => void
  btnRef: React.RefObject<HTMLButtonElement>
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const mst = useMst()
  const context = usePendingSearchContext()

  const sharedRootFolder = mst.getRootFolder(false, false)

  return (
    <Popover.Dropdown
      ref={ref}
      onBlur={(e) => {
        /* TODO: advanced search - this whole function is pretty hacky.
        setting <Popover onChange={setIsOpen} should take care of all this for us internally.
        the reason i'm rewriting the logic by hand is because interacting with the <Select/>s
        inside is dismissing the popover prematurely.

        another fix would be swapping in a <Combobox/> and setting data-ignore-outside-clicks
        for each individual combobox item individually. see:
        https://github.com/showrunner/wallaby/commit/36f1f1e12b416c5c5eb943b4d925c0dcef9dbbed#diff-45d5dd8d0f9d71e811937cef4fe2f94b74fa8d14f26522c6f97722199f9ba965R26

        i did it this way instead because i realized the <DatePicker/> *also* dismisses the popover prematurely.
        someday we should either bring this up in the mantine discord, open an issue or attempt to PR a fix,
        but i figure it was worth conferring with boon before going on that goose chase
        */
        const isSelf =
          ref.current?.isEqualNode(e.relatedTarget) ||
          ref.current?.contains(e.relatedTarget)

        // the popover target button and filter tags have their
        // own logic for toggling viz
        const isPopoverTarget = e.relatedTarget?.contains(btnRef?.current)
        const isFilterTag =
          e.relatedTarget?.classList.contains(FILTERTAG_CLASSNAME)

        const isInside = isSelf || isPopoverTarget || isFilterTag
        if (!isInside) onClose?.()
      }}
    >
      <Stack>
        {mst.currentOrg?.hasRundownsEnabled && <DocTypePicker />}
        <IncludeRadio />
        <LastEditedFilter
          dateFilterCategory={dateFilterCategory}
          setDateFilterCategory={setDateFilterCategory}
        />
        {sharedRootFolder && (
          <FolderPicker
            folderFilters={folderFilters}
            setFolderFilters={setFolderFilters}
            setOpened={setOpened}
            sharedRootFolder={sharedRootFolder}
          />
        )}
      </Stack>
      <Space h={20} />
      <Group justify="center" gap="xs">
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            setDateFilterCategory('Any date')
            setFolderFilters({ category: 'Any folder', folderName: undefined })
            // we intentionally dont overwrite the phrase
            const { phrase, ...passThrough } = defaultCriteria
            context.mergePendingCriteria(passThrough)
          }}
        >
          Clear filters
        </Button>
        <Button
          size="xs"
          disabled={!hasPhraseOrActiveFilter(context.pendingCriteria)}
          onClick={(e) => {
            onSubmit?.(e)
            onClose?.()
          }}
        >
          Search
        </Button>
      </Group>
    </Popover.Dropdown>
  )
}
