/*
  Super lightweight model used specifically so prosemirror plugins
  can write information about the editorView DOM from plugins.

  This model should only get written to by things running in the
  prosemirror dispatch. It's basically a way to
  provide a one-stop clearinghouse for things that need positioning
  or view information relative to the editorView.

  NOTE: all view information here is unscaled by editor zoom. That allows
  us to recompute positions when zoom changes WITHOUT dispatching transactions

  TODO: DRY up other places that this information is computed
  TODO: Remove the need to dispatch transactions when zoom changes
*/

import deepEqual from 'fast-deep-equal'
import { types } from 'mobx-state-tree'

import { CursorCoords } from '../types'

import { BaseModel } from './BaseModel'

const DUMMY_COORDS: CursorCoords = {
  x: 0,
  y: 0,
  height: 0,
}

export const PmDomInfo = BaseModel.named('PmDomInfo')
  .props({
    // we compute anchor/head because the cursor pos isn't deterministically from/to
    selectionAnchorCoords: types.optional(
      types.frozen<CursorCoords>(),
      DUMMY_COORDS,
    ),
    selectionHeadCoords: types.optional(
      types.frozen<CursorCoords>(),
      DUMMY_COORDS,
    ),
  })
  .views((self) => ({
    scaleForZoom(value: number): number {
      return value / self.rootStore.view.editorZoom
    },
    get selectionIsInverted() {
      const { selectionAnchorCoords, selectionHeadCoords } = self
      const headHigher = selectionHeadCoords.y < selectionAnchorCoords.y
      const headLefter = selectionHeadCoords.x < selectionAnchorCoords.x
      return headHigher || headLefter
    },
    get selectionFromCoords() {
      return this.selectionIsInverted
        ? self.selectionHeadCoords
        : self.selectionAnchorCoords
    },
    get selectionToCoords() {
      return this.selectionIsInverted
        ? self.selectionAnchorCoords
        : self.selectionHeadCoords
    },
    get selectionVerticalCenter(): number {
      const bottom = this.selectionToCoords.y + this.selectionToCoords.height
      const top = this.selectionFromCoords.y
      const height = bottom - top

      return this.scaleForZoom(top + height / 2)
    },
    get cursorCoords(): CursorCoords {
      const { x, y, height } = self.selectionAnchorCoords
      return {
        x: this.scaleForZoom(x),
        y: this.scaleForZoom(y),
        height: this.scaleForZoom(height),
      }
    },
  }))
  .actions((self) => ({
    setSelectionCoords({
      anchor,
      head,
    }: {
      anchor: CursorCoords
      head: CursorCoords
    }) {
      if (!deepEqual(anchor, self.selectionAnchorCoords)) {
        self.selectionAnchorCoords = anchor
      }
      if (!deepEqual(head, self.selectionHeadCoords)) {
        self.selectionHeadCoords = head
      }
    },
  }))
