import React from 'react'

import { Card } from '@mantine/core'
import cn from 'classnames'

import { spreadableProps } from './domHelpers'

import styles from './CardWrapper.module.scss'

// Shared card for a thread or comment
export const CardWrapper = ({
  type,
  children,
  scrollToId,
  onClick,
  selected,
}: {
  type: 'panel-thread' | 'feed-thread' | 'feed-comment'
  scrollToId?: string
  children: React.ReactNode
  onClick?: Callback
  selected?: boolean
}) => {
  const scrollProps = scrollToId
    ? spreadableProps('data-panel-thread', scrollToId)
    : undefined

  const classes = cn(styles.cardWrapper, {
    [styles.cardWrapper___panelThread]: type === 'panel-thread',
    [styles.cardWrapper___selected]: type === 'panel-thread' && selected,
    [styles.cardWrapper___feedComment]: type === 'feed-comment',
    [styles.cardWrapper___feedThread]: type === 'feed-thread',
  })

  return (
    <Card
      className={classes}
      shadow="none"
      px={10}
      pt={10}
      pb={10}
      withBorder={type !== 'panel-thread'}
      onClick={onClick}
      {...scrollProps}
    >
      {children}
    </Card>
  )
}
