import { Switch as SwitchComponent } from '@mantine/core'

import styles from './switch.module.scss'

export const Switch = SwitchComponent.extend({
  defaultProps: {
    size: 'xs',
  },
  classNames: {
    body: styles.body,
    track: styles.track,
    labelWrapper: styles.labelWrapper,
    input: styles.input,
    root: styles.root,
  },
})
