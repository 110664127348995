import { Plugin, PluginKey } from 'prosemirror-state'
import { DecorationSet } from 'prosemirror-view'

import { getConfigData } from '../configData'

import { buildManualDecorations } from './decorations'

export type ManualPageBreakPluginState = {
  decorations: DecorationSet
} | null

export const inlineManualPagebreaksKey =
  new PluginKey<ManualPageBreakPluginState>('inlineManualPageBreaks')

export const inlineManualPageBreaksPlugin = () => {
  return new Plugin<ManualPageBreakPluginState>({
    key: inlineManualPagebreaksKey,
    state: {
      init(_, editorState) {
        const { paginationType } = getConfigData(editorState)
        if (paginationType !== 'inline') {
          return null
        }
        return { decorations: buildManualDecorations(editorState.doc) }
      },
      apply(_, pluginState, oldState, newState) {
        const { paginationType } = getConfigData(newState)
        if (paginationType !== 'inline') {
          return null
        }

        if (oldState.doc.eq(newState.doc) && pluginState !== null) {
          return pluginState
        }

        return { decorations: buildManualDecorations(newState.doc) }
      },
    },
    props: {
      decorations(editorState) {
        return inlineManualPagebreaksKey.getState(editorState)?.decorations
      },
    },
  })
}
