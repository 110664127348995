// couple of helpers to get around circular references.
// The gist is to give state tree nodes a way to get a typed reference to the rootnode
// and its environment which are globally available.
import { getEnv, getRoot, IAnyStateTreeNode } from 'mobx-state-tree'

import { SAME_TIME_DIFF_IN_SECONDS } from '@hooks'
import { SortOrder } from '@util/LocalPersistence'
import { sortAlphaLowerLikePostgres } from '@util/pgCollation'

import type { Environment, IListing, IRoot } from '../types'

export const getEnvironment = (node: IAnyStateTreeNode) =>
  getEnv<Environment>(node)

export const getRootInstance = (node: IAnyStateTreeNode) => getRoot<IRoot>(node)

// we don't want to sort by the ACTUAL time difference if two
// value are super close in time or when docs are being edited
// rapidly, they flap in the document list. So, if the difference is within
// SAME_TIME_DIFF_IN_SECONDS of each other, treat them the same
const areVeryCloseInTime = (a: Date, b: Date): boolean => {
  const diff = a.valueOf() - b.valueOf()
  return Math.abs(diff) < SAME_TIME_DIFF_IN_SECONDS * 1000
}

// if two items are close together, preserve the current sort order
// when sorting
export const sortRecentFn = (a: IListing, b: IListing): number => {
  if (areVeryCloseInTime(a.contentsModifiedAt, b.contentsModifiedAt)) {
    return 0
  }
  return b.contentsModifiedAt.valueOf() - a.contentsModifiedAt.valueOf()
}

export const sortAlphaByNameFn = (a: IListing, b: IListing) => {
  const diff = sortAlphaLowerLikePostgres(a.name, b.name)
  if (diff === 0) {
    return a.uuid < b.uuid ? -1 : 1
  }
  return diff
}

export const sortByCreationFn = (a: IListing, b: IListing) =>
  (b.createdAt?.valueOf() ?? 1) - (a.createdAt?.valueOf() ?? 1)

export const sortListings = (listings: IListing[], sortOrder: SortOrder) => {
  switch (sortOrder) {
    case 'alphabetical':
      return listings.sort(sortAlphaByNameFn)
    case 'newest':
      return listings.sort(sortByCreationFn)
    case 'oldest':
      return listings.sort(sortByCreationFn).reverse()
    case 'recent':
      return listings.sort(sortRecentFn)
  }
}

export const GET_LISTINGS_PAGE_SIZE = 50
