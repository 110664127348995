import React from 'react'

import { Button, Group } from '@mantine/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import {
  LISTING_MAX_HEIGHT,
  LISTING_MAX_HEIGHT_PX,
} from '@components/FolderExplorer/constants'
import { IFolder, IListing, IOrg } from '@state/types'

import { DocumentListing } from './DocumentListing'
import {
  isLoadMoreItem,
  RenderedListing,
  useFolderListings,
  useRecentlyEdited,
} from './hooks'
import { FavoritesPlaceholder, RecentPlaceholder } from './Placeholder'

import styles from './DocumentList.module.scss'

class DocListingRenderer extends React.PureComponent<{
  data: RenderedListing[]
  index: number
  style: React.CSSProperties
  loadMore?: Callback
}> {
  render() {
    const item = this.props.data[this.props.index]

    return (
      <div style={this.props.style}>
        {isLoadMoreItem(item) ? (
          <Group justify="center" pt={10}>
            <Button
              size="xs"
              variant="subtle"
              onClick={item.onClick}
              loading={item.loading}
            >
              Load More
            </Button>
          </Group>
        ) : (
          <DocumentListing listing={item} />
        )}
      </div>
    )
  }
}

export const ListWrapper = ({
  children,
  scrollOverflow = false,
}: {
  children: React.ReactNode
  scrollOverflow?: boolean
}) => (
  <div
    className={cn(styles.wrapper, {
      [styles.wrapper__overflow]: scrollOverflow,
    })}
    style={{
      '--doc-listing-max-height': LISTING_MAX_HEIGHT_PX,
    }}
  >
    {children}
  </div>
)

export const DocumentList = ({
  documents,
}: {
  documents: RenderedListing[]
}) => (
  <ListWrapper>
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          itemCount={documents.length}
          itemSize={LISTING_MAX_HEIGHT}
          width={width}
          itemData={documents}
          overscanCount={20}
          itemKey={(index) => documents[index].id}
        >
          {DocListingRenderer}
        </FixedSizeList>
      )}
    </AutoSizer>
  </ListWrapper>
)

export const FavoritesList = ({ documents }: { documents: IListing[] }) => {
  if (documents.length === 0) {
    return <FavoritesPlaceholder />
  }
  return <DocumentList documents={documents} />
}

export const RecentlyEditedList = observer(function RecentlyEditedList({
  org,
}: {
  org: IOrg
}) {
  useRecentlyEdited(org)
  const documents = org.rootStore.recentListings

  if (documents.length === 0) {
    return <RecentPlaceholder />
  }

  return <DocumentList documents={documents} />
})

export const FolderContentsList = observer(function FolderContentsList({
  folder,
}: {
  folder: IFolder
}) {
  const { renderedDocuments } = useFolderListings(folder)
  return <DocumentList documents={renderedDocuments} />
})
