import { ICellRendererParams } from '@ag-grid-community/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { IRundown } from '@state/types'
import { isGridBlobKey } from '@util/rundowns'
import { RundownRowData } from '@util/ScriptoApiClient/types'

import styles from './CheckboxCell.module.scss'

export const CheckboxCell = observer(function CheckboxCell({
  context,
  data,
  colDef,
}: ICellRendererParams<RundownRowData>) {
  const rundown = context as IRundown
  const columnKey = colDef?.field
  const row = data?.id ? rundown.getRow(data.id) : null
  const checked =
    row && columnKey && isGridBlobKey(columnKey)
      ? !!row.getBlobValue(columnKey)
      : false

  // for print preview
  const readOnly = colDef?.editable === false
  const editable = !readOnly && rundown.isEditable

  const handleCheckChanged = async () => {
    if (editable && row && columnKey && isGridBlobKey(columnKey)) {
      await rundown.updateRowBlobData({
        rowId: row.id,
        columnKey,
        value: !checked,
      })
    }
  }

  // don't use the mantine checkbox here without ascertaining if it
  // can print properly in princeXML
  return (
    <div
      className={cn(styles.checkbox, { [styles.checked]: checked })}
      onClick={handleCheckChanged}
    >
      <FaIcon size="sm" icon="fa-check" className={styles.checkmark} />
    </div>
  )
})
