import { useQuery } from '@tanstack/react-query'

import { useMst } from '@state'

import { staleTime } from '../helpers'

export const useFetchBetaFlags = () => {
  const mst = useMst()

  const betaFlagQuery = async () => {
    const res = await mst.apiClient.getBetaFlags()
    return res.features
  }

  const { isError, data } = useQuery({
    queryKey: ['staff_flag_query'],
    queryFn: betaFlagQuery,
    staleTime,
  })

  return { isError, data }
}
