import { colors } from '@theme'
const backgroundColor = colors.violet[0]

const FAV_COLLECTION_SELECTOR = '[data-folder-listing="favorites"]'

const highlightElement = (elt: HTMLElement) => {
  // use the Animate api directly on the element. This is a prosemirror
  // managed element and we don't want to futz with tweaking the attributes
  elt.animate(
    [
      { backgroundColor },
      // maintain full opacity outline for 66% of the time
      {
        backgroundColor,
        offset: 0.66,
      },
      // then fade out
      {
        backgroundColor: 'transparent',
      },
    ],
    1500,
  )
}

export const highlightSelector = (selector: string) => {
  const element = document.querySelector(selector)
  if (element instanceof HTMLElement) {
    highlightElement(element)
  }
}

export const highlightBlockInEditor = (elt: HTMLElement) => {
  highlightElement(elt)
}

export const highlightFavoritesCollection = () => {
  const elt = document.querySelector(FAV_COLLECTION_SELECTOR)
  if (elt instanceof HTMLElement) highlightElement(elt)
}

const JIGGLE_CLASSES = ['animate__animated', 'animate__headShake']

export const jiggle = (target: Element | string | null) => {
  const element =
    typeof target === 'string' ? document.querySelector(target) : target
  if (element instanceof Element) {
    // css animation only works if the classes weren't there to begin with so
    // we need to first remove them if present then introduce a minor delay before re-adding
    element.classList.remove(...JIGGLE_CLASSES)
    setTimeout(() => element.classList.add(...JIGGLE_CLASSES), 10)
  }
}
