import { email } from '@sideway/address'

const VERIFICATION_CODE_REGEX = /^\d{6}$/

/*
  NOTE: Using typescript function overloads
  so we can use these in useForm configs
  without wrappng them

  with function overloads we can do this:

  { validate: { email:  validateEmail } }

  without, the typescript compiler would need us
  to do this:

  { validate: email: (value) => validateEmail(value) }
*/

// we have to be careful when swapping in messages because for some undocumented
// reason, Mantine calls the validators with a non-string sometimes
const getMessage = (defaultMessage: string, customMessage?: string) => {
  if (typeof customMessage === 'string' && customMessage.length > 0) {
    return customMessage
  }
  return defaultMessage
}

export function validateEmail(value: string): string
export function validateEmail(value: string, customMessage: string): string
export function validateEmail(value: string, customMessage?: string) {
  if (!email.isValid(value)) {
    return getMessage('Not a valid email', customMessage)
  }
}

export function validateOtpCode(value: string): string
export function validateOtpCode(value: string, customMessage: string): string
export function validateOtpCode(value: string, customMessage?: string) {
  if (!VERIFICATION_CODE_REGEX.test(value)) {
    return getMessage('The code needs to be a 6-digit number', customMessage)
  }
}

export function validateNotEmpty(value: string): string
export function validateNotEmpty(value: string, customMessage: string): string
export function validateNotEmpty(value: string, customMessage?: string) {
  if (value.length === 0) {
    return getMessage('This field is required', customMessage)
  }
}
