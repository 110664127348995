import React from 'react'

import { Group, Menu, Text, Tooltip } from '@mantine/core'
import cn from 'classnames'

import { Toolbar } from '@components/Toolbar'
import { UserAvatar } from '@components/UserAvatar'
import { ButtonMode, IRemoteCollaborator } from '@state/types'
import { pluralize } from '@util'

import { getMaxCollaborators, jumpToCollaborator } from './helpers'

import styles from './Collaborators.module.scss'

export const OverflowCollaborators = ({
  containerHeight,
  collaborators,
  mode,
}: {
  containerHeight: number
  collaborators: IRemoteCollaborator[]
  mode: ButtonMode
}) => {
  const [opened, setOpened] = React.useState(false)
  const totalCollaborators = collaborators.length
  const overflow = totalCollaborators - getMaxCollaborators(mode)
  const tooltip = `${totalCollaborators} ${pluralize(
    totalCollaborators,
    'collaborator',
  )}`
  // dont show half an avatar (38 for each row, 4 for padding)
  const maxHeight =
    containerHeight > 0 ? Math.floor(containerHeight / 38) * 38 + 4 : '70vh'

  return (
    <Menu
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      onChange={setOpened}
      width={200}
    >
      <Menu.Target>
        <Tooltip disabled={opened} label={tooltip}>
          <Toolbar.Button label={`+${overflow}`} fixedWidth={50} dropdown />
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown style={{ maxHeight }}>
        {/* clone collaborators before sorting */}
        {[...collaborators]
          .sort((a, b) => a.user.name.localeCompare(b.user.name))
          .map((u) => (
            <Menu.Item key={u.clientId} onClick={() => jumpToCollaborator(u)}>
              <Group align="center" wrap="nowrap" gap={3}>
                <UserAvatar
                  avatarSrc={u.user.avatar}
                  ringColor={u.color}
                  className={cn(
                    styles.collaborators_avatar,
                    styles.collaborators_menuAvatar,
                  )}
                  radius="xl"
                  diameter={26}
                />
                <Text size="sm">{u.user.name}</Text>
              </Group>
            </Menu.Item>
          ))}
      </Menu.Dropdown>
    </Menu>
  )
}
