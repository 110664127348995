import { ActionIcon, Group, Stack } from '@mantine/core'
import cn from 'classnames'

import { BLACKISH_TEXT } from '@theme/colors'

import styles from './Swatches.module.scss'

export const lightSwatches = [
  BLACKISH_TEXT, // black 1
  '#d6d6d6', // white 1
  '#d90000', // red 1
  '#d6336c', // pink 1
  '#ae3ec9', // grape 1
  '#61369f', // violet 1
  '#4263eb', // indigo 1
  '#1c7ed6', // blue 1
  '#1098ad', // cyan 1
  '#0ca678', // teal 1
  '#37b24d', // green 1
  '#74b816', // lime 1
  '#fcc419', // yellow 1
  '#f76707', // orange 1
]

export const medSwatches = [
  '#5c5f66', // black 2
  '#f8f8f8', // white 2
  '#ff8787', // red 2
  '#f783ac', // pink 2
  '#da77f2', // grape 2
  '#8e66c8', // violet 2
  '#748ffc', // indigo 2
  '#4dabf7', // blue 2
  '#3bc9db', // cyan 2
  '#63e6be', // teal 2
  '#69db7c', // green 2
  '#a9e34b', // lime 2
  '#ffe066', // yellow 2
  '#ffa94d', // orange 2
]

export const darkSwatches = [
  '#c1c2c5', // black 3
  '#fff', // white 3
  '#ffc9c9', // red 3
  '#fcc2d7', // pink 3
  '#eebefa', // grape 3
  '#d3beee', // violet 3
  '#bac8ff', // indigo 3
  '#a5d8ff', // blue 3
  '#99e9f2', // cyan 3
  '#96f2d7', // teal 3
  '#b2f2bb', // green 3
  '#d8f5a2', // lime 3
  '#fff3bf', // yellow 3
  '#ffd8a8', // orange 3
]

const allSwatches = [lightSwatches, medSwatches, darkSwatches]

const Swatch = ({
  color,
  onClick,
  isActive,
}: {
  color: string
  onClick: (color: string) => void
  isActive: boolean
}) => (
  <ActionIcon
    className={cn(styles.swatch, {
      [styles.swatch___active]: isActive,
    })}
    bg={color}
    size={25}
    onClick={() => onClick(color)}
  ></ActionIcon>
)

export const Swatches = ({
  activeColor,
  onClick,
  colors = allSwatches,
}: {
  activeColor: string
  onClick: (color: string) => void
  colors?: string[][]
}) => (
  <Stack gap={5}>
    {colors.map((row, idx) => (
      <Group gap={5} key={idx}>
        {row.map((s) => (
          <Swatch
            isActive={s === activeColor.toLowerCase()}
            key={s}
            color={s}
            onClick={onClick}
          />
        ))}
      </Group>
    ))}
  </Stack>
)
