import { useLocation } from 'wouter'

import { useMst } from '@state'
import { pathTo } from '@util'
import { ROUTE_PATTERNS } from '@util/pathConfigs'

export const useNavigation = () => {
  const [, setLocationPathname] = useLocation()
  const { location, user, view } = useMst()

  const navigate = (path: string) => {
    setLocationPathname(path)
  }

  const navigateToFolder = (folderId?: string) => {
    if (!folderId) {
      navigate(ROUTE_PATTERNS.root)
      return
    }
    view.setSelectedFolderId(folderId)
    navigate(`/folders/${folderId}`)
  }

  const pathToRundown = (rundownId: string) => {
    const scriptId = location.getPathParam('scriptId')

    return user.prefersSplitLayout && scriptId
      ? pathTo(ROUTE_PATTERNS.splitEditor, {
          rundownId,
          scriptId,
        })
      : pathTo(ROUTE_PATTERNS.rundown, { rundownId })
  }

  const navigateToRundown = (rundownId: string) => {
    navigate(pathToRundown(rundownId))
  }

  const pathToScript = (
    scriptId: string,
    queryParams?: { [key: string]: string | number | boolean },
  ) => {
    const rundownId = location.getPathParam('rundownId')

    return user.prefersSplitLayout && rundownId
      ? pathTo(
          ROUTE_PATTERNS.splitEditor,
          {
            rundownId,
            scriptId,
          },
          { queryParams },
        )
      : pathTo(ROUTE_PATTERNS.scriptEditor, { scriptId }, { queryParams })
  }

  const navigateToScript = (
    scriptId: string,
    queryParams?: { [key: string]: string | number | boolean },
  ) => {
    navigate(pathToScript(scriptId, queryParams))
  }

  // We use a temporary query string when navigating to a block in a script,
  // but once the script loads, it uses that param to navigate to the block
  // and then removes it with this function
  const removeBlockParam = () => {
    const blockId = location.getQueryParam('block')
    if (blockId) {
      // build a new URL without the block search params
      const remainingParams: string[] = []
      Object.keys(location.queryParams).forEach((key) => {
        if (key !== 'block') {
          remainingParams.push(`${key}=${location.queryParams[key]}`)
        }
      })
      const search =
        remainingParams.length > 0 ? `?${remainingParams.join('&')}` : ''
      setLocationPathname(`${location.pathname}${search}`, { replace: true })
    }
  }

  const changeSearch = (search: string) => {
    const target = search ? `${location.pathname}?${search}` : location.pathname
    navigate(target)
  }

  return {
    changeSearch,
    navigate,
    navigateToFolder,
    navigateToRundown,
    navigateToScript,
    pathToRundown,
    pathToScript,
    removeBlockParam,
  }
}
