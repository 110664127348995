import { ActionIcon } from '@mantine/core'
import cn from 'classnames'

import { FaIcon } from '@components/FaIcon'

import styles from './ExpandIcon.module.scss'

export const ExpandIcon = ({
  expanded,
  width,
  variant = 'folder',
}: {
  expanded: boolean
  width: number
  variant?: 'folder' | 'header'
}) => {
  const isFolder = variant === 'folder'

  return (
    <div
      className={styles.expandIcon_wrapper}
      style={{ width, minWidth: width }}
    >
      <ActionIcon
        className={cn(styles.expandIcon_btn, {
          [styles.expandIcon_folder]: isFolder,
        })}
      >
        <FaIcon
          icon="fa-chevron-right"
          size={isFolder ? '10' : '14'}
          className={cn(styles.expandIcon, {
            [styles.expandIcon___rotate]: expanded,
          })}
        />
      </ActionIcon>
    </div>
  )
}
