import { CSSObject } from '@emotion/react'

const divertArrow = '.ink-divert-arrow'
const done = '.ink-done'
const end = '.ink-end'
const divertTarget = '.ink-divert-target'
const comment = '.ink-comment'
const knotPunctuation = '.ink-knot-punctuation'
const inkFunction = '.ink-function'
const knotName = '.ink-knot-name'
const functionParam = '.ink-function-param'
const globalDeclaration = '.ink-global-declaration'
const stitchPunctuation = '.ink-stitch-punctuation'
const stitchName = '.ink-stitch-name'
const stitchParam = '.ink-stitch-param'
const logicLine = '.ink-logic-line'
const hashTag = '.ink-hashtag'
const choice = '.ink-choice'
const choiceLabel = '.ink-choice-label'
const gather = '.ink-gather'
const gatherLabel = '.ink-gather-label'
const inlineLogic = '.ink-inline-logic'

const inkClassNames = {
  divertArrow,
  done,
  end,
  divertTarget,
  comment,
  knotPunctuation,
  knotName,
  inkFunction,
  functionParam,
  globalDeclaration,
  stitchPunctuation,
  stitchName,
  stitchParam,
  logicLine,
  hashTag,
  choice,
  choiceLabel,
  gather,
  gatherLabel,
  inlineLogic,
} as const
export type InkClassNameMap = typeof inkClassNames

// Knots, stitches, diverts, "Choice markers" like * and - and +
const BLUE = '#0000FF'
// comments
const GRAY = '#84756C'
// code
const GREEN = '#008000'

// this is a hard-coded version of something we could allow to be
// user-configurable or script-format configurable
export const inkSyntaxStyles: Record<ValueOf<InkClassNameMap>, CSSObject> = {
  [divertArrow]: {
    color: BLUE,
  },
  [done]: {
    color: BLUE,
  },
  [end]: {
    color: BLUE,
  },
  [divertTarget]: {
    color: BLUE,
  },
  [knotPunctuation]: {
    color: BLUE,
    fontWeight: 'bold',
  },
  [knotName]: {
    color: BLUE,
    fontWeight: 'bold',
  },
  [inkFunction]: {
    color: BLUE,
  },
  [functionParam]: {
    color: BLUE,
  },
  [comment]: {
    color: GRAY,
  },
  [globalDeclaration]: {
    color: GREEN,
  },
  [stitchPunctuation]: {
    color: BLUE,
  },
  [stitchName]: {
    color: BLUE,
  },
  [stitchParam]: {
    color: BLUE,
  },
  [logicLine]: {
    color: GREEN,
  },
  [hashTag]: {
    color: GRAY,
  },
  [choice]: {
    color: BLUE,
  },
  [choiceLabel]: {
    color: BLUE,
  },
  [gather]: {
    color: BLUE,
  },
  [gatherLabel]: {
    color: BLUE,
  },
  [inlineLogic]: {
    color: GREEN,
  },
}
