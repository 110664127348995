import { ChangeEvent, useRef } from 'react'

import { Button, Card, Stack, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { Avatar } from '@components/Avatar'
import { showError } from '@components/Modals'
import { useMst } from '@state'

const AVATAR_SIZE_LIMIT = 2 * 1024 * 1024

export const AvatarUpdater = observer(function AvatarUpdater({
  setLoading,
}: {
  setLoading: (val: boolean) => void
}) {
  const { user } = useMst()

  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    inputRef?.current?.click()
  }

  const uploadAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const avatar = e.target.files[0]
      const tooBig = avatar.size > AVATAR_SIZE_LIMIT
      const validFile = /.*\.(png|jpe?g|gif)$/.test(avatar.name.toLowerCase())
      setLoading(true)
      try {
        if (!tooBig && validFile) {
          await user.uploadAvatar({ avatar })
        }
        if (tooBig) {
          throw new Error('Your profile image must be 2MB or less')
        }
        if (!validFile) {
          throw new Error('Only JPG, PNG and GIF profile images are allowed')
        }
      } catch (e) {
        showError({
          message: (e as Error).message,
        })
      }
      setLoading(false)
    }
  }

  return (
    <Card>
      <Stack gap={0} align="center">
        <Text fw="bold">Profile picture</Text>
        <Avatar
          statusIndicator={undefined}
          avatarUrl={user.avatarUrl}
          mode="profile"
        ></Avatar>
        <Button variant="subtle" color="violet" onClick={handleClick}>
          Upload new picture
        </Button>
        <input
          ref={inputRef}
          onChange={(e) => uploadAvatar(e)}
          style={{ display: 'none' }}
          type="file"
          accept="image/png, image/jpeg, image/gif"
        />
      </Stack>
    </Card>
  )
})
