import { type Command, TextSelection } from 'prosemirror-state'

import { NodeTypeMap } from '@showrunner/codex'
import { schema } from '@showrunner/prose-schemas'

const { ACTION } = NodeTypeMap

export const exitDualColumn: Command = (viewState, viewDispatch) => {
  if (!(viewState.selection instanceof TextSelection)) return false
  const { $cursor } = viewState.selection

  // skip if no cursor or not empty
  if (!$cursor || $cursor.parent.content.size) {
    return false
  }
  // skip if dialogue depth is normal
  if ($cursor.depth === 2) {
    return false
  }
  const firstChild = $cursor.index(3) === 0
  const lastChild = $cursor.index(3) === $cursor.node(3).childCount - 1
  // skip if this node is first child or not last child in column
  if (firstChild || !lastChild) {
    return false
  }
  const { tr } = viewState
  if ($cursor.index(2) === 0) {
    // first column
    // we must delete the empty element of the current selection
    tr.delete($cursor.before(), $cursor.after())
    // then set selection to position of 2nd column character element
    // if all these things are true:
    // - empty element
    // - last child
    // - first column
    // then the position of the first element of the next column is
    //   cursor position
    //   end of empty element (+1)
    //   end of first column (+1)
    //   beginning of second column (+1)
    //   beginning of first element (+1)
    // so cursor position + 4???? I hate traversing in PM!!! ack
    const nextPos = tr.doc.resolve(tr.mapping.map($cursor.pos + 4))
    const sel = TextSelection.findFrom(nextPos, 1, true)
    if (sel) tr.setSelection(sel)
  } else {
    // second column
    const range = $cursor.blockRange()
    if (range) {
      tr.lift(range, 1)
    }
    const pos = tr.mapping.map($cursor.pos)
    const nextNode = ACTION
    tr.setBlockType(pos, pos, schema.nodes[nextNode])
  }
  viewDispatch?.(tr.scrollIntoView())
  return true
}
