import React from 'react'

import { Group, Menu, UnstyledButton } from '@mantine/core'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { SortOrder } from '@util/LocalPersistence'

import { FaIcon } from '../FaIcon'

import styles from './SortMenu.module.scss'

const SORT_OPTIONS: { id: SortOrder; title: string }[] = [
  { id: 'recent', title: 'Recently edited' },
  { id: 'alphabetical', title: 'Title' },
  { id: 'oldest', title: 'Oldest' },
  { id: 'newest', title: 'Newest' },
]

export const SortMenu = ({
  sortOrder,
  setSortOrder,
  label,
}: {
  sortOrder: SortOrder
  setSortOrder: Callback<[SortOrder]>
  label: React.ReactNode
}) => {
  return (
    <Menu width={180} shadow="md">
      <Menu.Target>
        <UnstyledButton className={styles.sortMenuButton}>
          <Group className={styles.sortMenuButtonContent} wrap="nowrap" gap={5}>
            <div className={styles.labelWrapper}>{label}</div>
            <FaIcon className={styles.chevron} size="13" icon="fa-caret-down" />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Sort by</Menu.Label>
        {SORT_OPTIONS.map((o) => (
          <FancyMenuItem
            key={o.id}
            selected={sortOrder === o.id}
            title={o.title}
            onClick={() => setSortOrder(o.id)}
          ></FancyMenuItem>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
