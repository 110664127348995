import { OrgPermissionMap } from '@showrunner/codex'

export type PermissionConfig = {
  code: string
  title: string
  description: string
  helpLink?: string
  enabled?: boolean
}

export const CHANGE_SCRIPT_STATUS_CONFIG: PermissionConfig = {
  code: OrgPermissionMap.CHANGE_SCRIPT_STATUS,
  title: 'Script Access',
  description: 'Manage who is allowed to mark scripts as Limited or Open',
  helpLink:
    'https://help.scripto.live/en/articles/5430603-permissions#h_4f027493e1',
}

export const MODIFY_LIMITED_SCRIPT_CONFIG: PermissionConfig = {
  code: OrgPermissionMap.MODIFY_LIMITED_SCRIPT,
  title: 'Limited Access Editing',
  description: 'Manage who is allowed to edit Limited Access scripts',
  helpLink:
    'https://help.scripto.live/en/articles/5430603-permissions#h_4f027493e1',
}
export const PUSH_TO_PROMPTER_CONFIG: PermissionConfig = {
  code: OrgPermissionMap.PUSH_TO_PROMPTER,
  title: 'Push to Prompter',
  description: 'Manage who is allowed to push scripts to the teleprompter',
  helpLink:
    'https://help.scripto.live/en/articles/5430603-permissions#h_1e004bea6d',
}

export const UPDATE_RUNDOWNS_CONFIG: PermissionConfig = {
  code: OrgPermissionMap.UPDATE_RUNDOWNS,
  title: 'Rundowns',
  description: 'Manage who is allowed to edit rundowns',
  helpLink:
    'https://help.scripto.live/en/articles/5430603-permissions#h_af13f249e0',
}

export const DESTROY_CONTENT_CONFIG: PermissionConfig = {
  code: OrgPermissionMap.DESTROY_CONTENT,
  title: 'Delete content',
  description:
    'Manage who is allowed to permanently delete shared scripts from the trash',
}
