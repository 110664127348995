import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import {
  Button,
  Checkbox,
  Divider,
  Group,
  Image,
  Radio,
  Space,
  Stack,
  Tabs,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { ScriptDocType } from '@showrunner/codex'

import footercenter from '@assets/images/footer-center.png'
import footerleft from '@assets/images/footer-left.png'
import footerright from '@assets/images/footer-right.png'
import headercenter from '@assets/images/header-center.png'
import headerleft from '@assets/images/header-left.png'
import headerright from '@assets/images/header-right.png'
import onecol from '@assets/images/onecol.png'
import twocol from '@assets/images/twocol.png'
import { ModalShell, useModalControls } from '@components/Modals'
import { Toast } from '@components/Toast'
import { useMst } from '@state'
import {
  ScriptPrintPreferences,
  SlotConfig,
  SlotPosition,
} from '@util/zodSchemas/printPreferences'

import { Example } from './Example'
import {
  CORNER_ITEM_NUMBER,
  getFilename,
  getPrintableScriptData,
  initialSelectedIds,
  isInactiveConfig,
  PrintableScriptData,
  PrintScriptModalProps,
} from './helpers'
import { SelectRundownScripts } from './SelectRundownScripts'
import { SlotSelect } from './SlotSelect'
import { usePrintScripts } from './usePrintScripts'

import styles from './PrintScriptModal.module.scss'

type Props = {
  allPrintableItems: PrintableScriptData[]
  docType: 'rundown' | ScriptDocType
  fileName: string
  enableDuration: boolean
}

const ScrollingContent = ({ children }: { children: React.ReactNode }) => (
  <Stack className={styles.prefModal_scrollingContent}>{children}</Stack>
)

const PrintPrefs = observer(function PrintPrefsParams({
  allPrintableItems,
  fileName,
  docType,
  enableDuration,
}: Props) {
  const { user, currentOrg } = useMst()
  const { loading, errorMessage, print } = usePrintScripts()
  const prefs = user.scriptPrintPrefs
  const updatePrefs = user.updateScriptPrintPreferences
  const controls = useModalControls()

  const [idsToPrint, setIdsToPrint] = React.useState(
    initialSelectedIds(allPrintableItems),
  )
  const itemsToPrint = allPrintableItems.filter((item) =>
    idsToPrint.includes(item.scriptId),
  )

  const updateHeaders = (value: Partial<ScriptPrintPreferences['headers']>) => {
    updatePrefs({ headers: { ...prefs.headers, ...value } })
  }
  const updateFooters = (value: Partial<ScriptPrintPreferences['footers']>) => {
    updatePrefs({ footers: { ...prefs.footers, ...value } })
  }

  const updateHeaderOrFooterSlot = ({
    type,
    position,
    config,
  }: {
    type: 'header' | 'footer'
    position: SlotPosition
    config?: SlotConfig
  }) => {
    const currentSlots =
      type === 'header' ? prefs.headers.slots : prefs.footers.slots

    const newValue = {
      ...currentSlots,
      [position]: config,
    }
    if (type === 'header') {
      updateHeaders({ slots: newValue })
    } else {
      updateFooters({ slots: newValue })
    }
  }

  const updateHeaderSlot = (position: SlotPosition, config?: SlotConfig) =>
    updateHeaderOrFooterSlot({
      type: 'header',
      position,
      config,
    })

  const updateFooterSlot = (position: SlotPosition, config?: SlotConfig) =>
    updateHeaderOrFooterSlot({
      type: 'footer',
      position,
      config,
    })

  const handlePrint = async (type: 'print' | 'download') => {
    if (itemsToPrint.length > 0) {
      const success = await print({
        fileName,
        printableItems: itemsToPrint,
        type,
      })
      if (success) {
        controls.onClose()
      }
    }
  }

  const PrefDivider = () => <Divider mt={2} mb={2} />

  const PrefStack = ({ children }: { children: React.ReactNode }) => (
    <Stack gap={5} px={10} align="center">
      {children}
    </Stack>
  )

  const disableStudioPrefs = docType === 'screenplay' // only enable from rundowns and studio scripts
  const disableColumnPrefs = disableStudioPrefs || !prefs.columns // disable when two-col isnt selected
  const toastMsg = `${currentOrg?.studioFormatName ?? 'Studio'} scripts only`

  return (
    <ModalShell
      title={docType === 'rundown' ? 'Print scripts' : 'Print preferences'}
      size={710}
      opened={controls.opened}
      onClose={controls.onClose}
      centered={false}
      loading={loading}
      errorMessage={errorMessage}
    >
      <Tabs
        classNames={{
          tab: styles.prefModal_tab,
        }}
        defaultValue={docType === 'rundown' ? 'rundown-scripts' : 'page'}
      >
        <Tabs.List>
          {docType === 'rundown' && (
            <Tabs.Tab value="rundown-scripts">Select Scripts</Tabs.Tab>
          )}
          <Tabs.Tab value="page">Page</Tabs.Tab>
          <Tabs.Tab value="header">Header</Tabs.Tab>
          <Tabs.Tab value="footer">Footer</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="rundown-scripts" pt="xs">
          <SelectRundownScripts
            selectedIds={idsToPrint}
            setSelectedIds={setIdsToPrint}
            allPrintableItems={allPrintableItems}
          />
          <Space h={20} />
          <Checkbox
            label="Include rundown item number in top-right corner"
            checked={!!prefs.headers.slots.rightCorner}
            onChange={(evt) => {
              updateHeaderSlot(
                'rightCorner',
                evt.target.checked ? CORNER_ITEM_NUMBER : undefined,
              )
            }}
          />
        </Tabs.Panel>
        <Tabs.Panel value="page" pt="xs">
          <ScrollingContent>
            <Space h={5} />
            <Group
              gap={0}
              justify="center"
              align="center"
              className={styles.prefModal_group}
            >
              <Radio.Group
                label="Text color"
                value={prefs.monochrome ? 'mono' : 'color'}
                onChange={(value) =>
                  updatePrefs({
                    monochrome: value === 'mono',
                  })
                }
              >
                <Space h={10} />
                <Stack gap="md">
                  <Radio label="Black and white" value="mono" />
                  <Radio label="Color" value="color" />
                </Stack>
              </Radio.Group>
              {/* on narrow screens we swap in a horizontal divider */}
              <Divider visibleFrom="sm" orientation="vertical" mx={30} />
              <Divider hiddenFrom="sm" w="100%" />
              <Stack>
                <Toast
                  type="neutral"
                  dismissable={false}
                  message={toastMsg}
                  icon="fas fa-info-circle"
                />
                <Group
                  justify="center"
                  align="flex-start"
                  wrap="nowrap"
                  gap={60}
                >
                  <Radio.Group
                    label="Layout"
                    value={prefs.columns ? 'two' : 'one'}
                    onChange={(value) =>
                      updatePrefs({
                        columns: value === 'two',
                      })
                    }
                  >
                    <Space h={10} />
                    <Stack gap="md">
                      <Group>
                        <Radio
                          label="One column"
                          value="one"
                          disabled={disableStudioPrefs}
                        />
                        <Image src={onecol} w={40} />
                      </Group>
                      <Group>
                        <Radio
                          label="Two column"
                          value="two"
                          disabled={disableStudioPrefs}
                        />
                        <Image src={twocol} w={40} />
                      </Group>
                    </Stack>
                  </Radio.Group>

                  <Radio.Group
                    label="Right column line spacing"
                    value={String(prefs.twoColDialogueSpacing)}
                    onChange={(value) =>
                      updatePrefs({
                        twoColDialogueSpacing: Number(value) as 1 | 2 | 1.5,
                      })
                    }
                  >
                    <Space h={10} />
                    <Stack gap="md">
                      <Radio
                        label="Single spaced"
                        value="1"
                        disabled={disableColumnPrefs}
                      />
                      <Radio
                        label="1.5 spaced"
                        value="1.5"
                        disabled={disableColumnPrefs}
                      />
                      <Radio
                        label="Double spaced"
                        value="2"
                        disabled={disableColumnPrefs}
                      />
                    </Stack>
                  </Radio.Group>
                </Group>
              </Stack>
            </Group>
            <Space h={20} />
          </ScrollingContent>
        </Tabs.Panel>
        <Tabs.Panel value="header" pt="xs">
          <ScrollingContent>
            <Stack gap={15}>
              <Stack gap={10} px={15} className={styles.prefModal_checkStack}>
                <Space h={2} />
                <Checkbox
                  label="Include header"
                  checked={prefs.headers.enabled}
                  onChange={(evt) => {
                    updateHeaders({
                      enabled: evt.target.checked,
                    })
                  }}
                />
                <Checkbox
                  px={30}
                  label="Show header on first page"
                  disabled={!prefs.headers.enabled}
                  checked={prefs.headers.showOnFirstPage}
                  onChange={(evt) =>
                    updateHeaders({
                      showOnFirstPage: evt.target.checked,
                    })
                  }
                />
                <Checkbox
                  px={30}
                  label="Horizontal rule appears below text"
                  disabled={!prefs.headers.enabled}
                  checked={prefs.headers.divider}
                  onChange={(evt) =>
                    updateHeaders({
                      divider: evt.target.checked,
                    })
                  }
                />
              </Stack>
              <PrefDivider />
              <Group
                align="center"
                justify="space-between"
                className={styles.prefModal_group___selects}
                gap={10}
                px={10}
              >
                <PrefStack>
                  <img src={headerleft} />
                  <SlotSelect
                    disabled={!prefs.headers.enabled}
                    label="Left"
                    config={prefs.headers.slots.left}
                    setContent={(value) => {
                      updateHeaderSlot('left', value)
                    }}
                    inactive={isInactiveConfig(
                      prefs.headers.slots.left,
                      !enableDuration,
                    )}
                  />
                </PrefStack>
                <PrefStack>
                  <img src={headercenter} />
                  <SlotSelect
                    disabled={!prefs.headers.enabled}
                    label="Center"
                    config={prefs.headers.slots.center}
                    setContent={(value) => updateHeaderSlot('center', value)}
                    inactive={isInactiveConfig(
                      prefs.headers.slots.center,
                      !enableDuration,
                    )}
                  />
                </PrefStack>
                <PrefStack>
                  <img src={headerright} />
                  <SlotSelect
                    disabled={!prefs.headers.enabled}
                    label="Right"
                    config={prefs.headers.slots.right}
                    setContent={(value) => updateHeaderSlot('right', value)}
                    inactive={isInactiveConfig(
                      prefs.headers.slots.right,
                      !enableDuration,
                    )}
                  />
                </PrefStack>
              </Group>
              <PrefDivider />
              <Example
                slots={prefs.headers.slots}
                hr={prefs.headers.divider}
                hidden={!prefs.headers.enabled}
                isScreenplay={!enableDuration}
              />
            </Stack>
          </ScrollingContent>
        </Tabs.Panel>
        <Tabs.Panel value="footer" pt="xs">
          <ScrollingContent>
            <Stack gap={15}>
              <Stack gap={10} px={15} className={styles.prefModal_checkStack}>
                <Space h={2} />
                <Checkbox
                  label="Include footer"
                  checked={prefs.footers.enabled}
                  onChange={(evt) => {
                    updateFooters({
                      enabled: evt.target.checked,
                    })
                  }}
                />
                <Checkbox
                  px={30}
                  label="Show footer on first page"
                  disabled={!prefs.footers.enabled}
                  checked={prefs.footers.showOnFirstPage}
                  onChange={(evt) => {
                    updateFooters({
                      showOnFirstPage: evt.target.checked,
                    })
                  }}
                />
                <Checkbox
                  px={30}
                  disabled={!prefs.footers.enabled}
                  label="Horizontal rule appears above text"
                  checked={prefs.footers.divider}
                  onChange={(evt) => {
                    updateFooters({
                      divider: evt.target.checked,
                    })
                  }}
                />
              </Stack>
              <PrefDivider />
              <Group
                className={styles.prefModal_group___selects}
                align="center"
                justify="space-between"
                gap={10}
                px={10}
              >
                <PrefStack>
                  <img src={footerleft} />

                  <SlotSelect
                    disabled={!prefs.footers.enabled}
                    label="Left"
                    config={prefs.footers.slots.left}
                    setContent={(value) => updateFooterSlot('left', value)}
                    inactive={isInactiveConfig(
                      prefs.footers.slots.left,
                      !enableDuration,
                    )}
                  />
                </PrefStack>
                <PrefStack>
                  <img src={footercenter} />
                  <SlotSelect
                    disabled={!prefs.footers.enabled}
                    label="Center"
                    config={prefs.footers.slots.center}
                    setContent={(value) => updateFooterSlot('center', value)}
                    inactive={isInactiveConfig(
                      prefs.footers.slots.center,
                      !enableDuration,
                    )}
                  />
                </PrefStack>
                <Stack gap={5} px={10} align="center">
                  <img src={footerright} />
                  <SlotSelect
                    disabled={!prefs.footers.enabled}
                    label="Right"
                    config={prefs.footers.slots.right}
                    setContent={(value) => updateFooterSlot('right', value)}
                    inactive={isInactiveConfig(
                      prefs.footers.slots.right,
                      !enableDuration,
                    )}
                  />
                </Stack>
              </Group>
              <PrefDivider />
              <Example
                slots={prefs.footers.slots}
                hr={prefs.footers.divider}
                mode="footer"
                hidden={!prefs.footers.enabled}
                isScreenplay={!enableDuration}
              />
            </Stack>
          </ScrollingContent>
          <Space h={2} />
        </Tabs.Panel>
      </Tabs>
      <Group gap="xs" justify="center">
        <Button
          disabled={itemsToPrint.length === 0}
          type="submit"
          onClick={() => handlePrint('print')}
        >
          Print
        </Button>
        <Button
          disabled={itemsToPrint.length === 0}
          type="submit"
          onClick={() => handlePrint('download')}
        >
          Export PDF
        </Button>
        <Button variant="outline" onClick={controls.onClose}>
          Close
        </Button>
      </Group>
    </ModalShell>
  )
})

export const PrintScriptModal = NiceModal.create((props: Props) => (
  <PrintPrefs {...props} />
))

export const showPrintScriptModal = (params: PrintScriptModalProps) => {
  const enableDuration =
    params.docType === 'script' && params.script.type !== 'screenplay'

  const docType =
    params.docType === 'rundown' ? params.docType : params.script.type
  const props: Props = {
    docType,
    allPrintableItems: getPrintableScriptData(params),
    fileName: getFilename(params),
    enableDuration,
  }

  NiceModal.show(PrintScriptModal, props)
}
