import { Redirect, Route, Switch } from 'wouter'

import * as Views from '@components/Settings'

import { GuardedRoute } from './util/GuardedRoute'

export const SettingsRouter = () => (
  <Views.SettingsPage>
    <Switch>
      {/* Redirects for old routes that use "orgs" with orgId optional */}
      <Route path="/settings/orgs/members/:orgId?">
        <Redirect to="/settings/org/members" />
      </Route>
      <Route path="/settings/orgs/permissions/:orgId?">
        <Redirect to="/settings/org/permissions" />
      </Route>
      <Route path="/settings/orgs/:orgId?">
        <Redirect to="/settings/org" />
      </Route>

      <Route path="/settings/developer" component={Views.DevSettings} />

      <Route path="/settings/org/members" component={Views.MemberSettings} />

      {/* If the user isn't an admin and hits this path, render a NotFound */}
      <GuardedRoute
        path="/settings/org/permissions"
        guard={(root) => root.user.isCurrentOrgAdmin}
        fallback={<Redirect to="/settings" />}
        component={Views.PermissionSettings}
      />

      <Route path="/settings/org" component={Views.OrgSettings} />

      <Route path="/settings/security" component={Views.ChangePassword} />
      <Route path="/settings" component={Views.UserSettings} />
      {/* Last route is a fallback if nothing above matches */}
      <Route>
        <Redirect to="/settings" />
      </Route>
    </Switch>
  </Views.SettingsPage>
)
