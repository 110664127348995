import { schemas } from '@showrunner/scrapi'

import { showError } from '@components/Modals'
import { ILoadedScript } from '@state'
import { getMergedFormatDefinition, saveTextToFile } from '@util'

export const downloadScriptFormat = (script: ILoadedScript) => {
  const doc = script.pmEditor.editorState?.doc
  if (!doc) {
    showError('Editor state is missing')
    return
  }

  const mergedFormat = schemas.scriptFormats.ScriptFormatDefinition.parse(
    getMergedFormatDefinition(doc, script.scriptFormat.definition),
  )

  if (!mergedFormat.paginationType) {
    showError('No pagination type in format')
    return
  }

  if (mergedFormat.paginationType === 'structural') {
    showError('Pagination type is structural')
    return
  }

  saveTextToFile({
    text: JSON.stringify(mergedFormat, null, 2),
    fileName: `script-format-from-${script.id}.json`,
  })
}
