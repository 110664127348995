import React from 'react'

import { Divider, Popover } from '@mantine/core'

import { CommentHistoryFeed } from '@components/Comments'
import { Toolbar } from '@components/Toolbar'
import { ButtonMode, ILoadedScript } from '@state'
import { fireAndForget, pluralize } from '@util'

import { POPOVER_PROPS } from './domHelpers'
import { SlackToggle } from './SlackToggle'

export const CommentHistoryButton = ({
  script,
  mode = 'normal',
  count,
  disableSlack,
}: {
  script: ILoadedScript
  mode?: ButtonMode
  count: number
  disableSlack: boolean
}) => {
  const [opened, setOpened] = React.useState(false)

  const label =
    mode === 'normal'
      ? `${count} ${pluralize(count, ' Comment')}`
      : String(count)

  return (
    <Popover
      shadow="md"
      width={350}
      position="bottom-end"
      opened={opened}
      onOpen={() => fireAndForget(script.commentData.loadHistory(script.id))}
      onChange={setOpened}
    >
      <Popover.Target>
        <Toolbar.Button
          dropdown
          icon="fa-comment"
          label={label}
          mode={mode}
          onClick={() => {
            setOpened(!opened)
          }}
        />
      </Popover.Target>
      <Popover.Dropdown {...POPOVER_PROPS}>
        <CommentHistoryFeed onClose={() => setOpened(false)} script={script} />
        <Divider />
        <SlackToggle readOnly={disableSlack} script={script} />
      </Popover.Dropdown>
    </Popover>
  )
}
