import { EditorState } from 'prosemirror-state'

import { NodeTypeKey, NodeTypeMap } from '@showrunner/codex'

import { isNavLinkElement } from '@util/constants'

import { LEGIBLE_BLOCK_NAMES } from '../../prose-utils'

import { BlockInfoState } from './types'

const DOC_OFFSET = 1

export const extractBlockInfo = (state: EditorState): BlockInfoState => {
  const blockInfo: BlockInfoState = []

  state.doc.forEach((page, pageOffset) => {
    if (page.type.name !== NodeTypeMap.PAGE) return

    page.forEach((block, blockOffset) => {
      const {
        attrs: { elementNumber, id },
        nodeSize,
        textContent,
        type: { name: type },
      } = block

      // if id-maker plugin hasn't run yet, short-circuit
      if (!id) return

      const trimmedText = textContent.trim()
      const isBlank = trimmedText === ''
      const text =
        isBlank && isNavLinkElement(type)
          ? LEGIBLE_BLOCK_NAMES[type]
          : trimmedText
      const pos = blockOffset + pageOffset + DOC_OFFSET

      blockInfo.push({
        type: type as NodeTypeKey,
        text,
        pos,
        elementNumber,
        id,
        isBlank,
        nodeSize,
      })
    })
  })

  return blockInfo
}
