import { TextInput as TextInputComponent } from '@mantine/core'

import styles from './input.module.scss'

export const TextInput = TextInputComponent.extend({
  defaultProps: {
    size: 'md',
  },
  classNames: {
    input: styles.textInput,
  },
})
