import { getParentOfType, getSnapshot, types } from 'mobx-state-tree'

import { schemas } from '@showrunner/scrapi'

import { IRundown } from '@state/types'
import {
  GridBlobColumnKey,
  isGridBlobKey,
  ROW_LEVEL_BLOB_FIELD,
  RowLevel,
} from '@util/rundowns'
import { BlobDataValue, RundownRowData } from '@util/ScriptoApiClient/types'

import { BaseModel } from '../BaseModel'

import { Rundown } from './Rundown'

const stripBlobKeyPrefix = (key: GridBlobColumnKey): string =>
  key.replace(/^blobData\./, '')

export const RundownRow = BaseModel.named('RundownRow')
  .props({
    id: types.identifierNumber,
    rowTypeId: types.string,
    sequence: types.number,
    rundownId: types.number,
    identityScriptId: types.maybeNull(types.string),
    linkedScriptId: types.maybeNull(types.string),
    blockId: types.maybeNull(types.string),
    blobData: types.map(types.frozen<BlobDataValue>()),
    scriptDragStatus: types.maybe(
      types.enumeration<'over' | 'under'>(['over', 'under']),
    ),
  })
  .views((self) => ({
    getBlobValue(key: GridBlobColumnKey) {
      return self.blobData.get(stripBlobKeyPrefix(key))
    },
    get parentRundown(): IRundown {
      return getParentOfType(self, Rundown)
    },
    get selectedInGrid(): boolean {
      return this.parentRundown.selectedRowIds.includes(self.id)
    },
    // This gives us a non-observable POJO that's we can use for ag-grid. We
    // want it to be non-observable because ag-grid modifies the blobData values
    // directly and then we handle the event to try to save the edits then refresh
    // the grid rows. (The changes are temporary and we don't want them to trigger
    // the full row refresh, hence the non-observable POJO)
    get pojo(): RundownRowData {
      const {
        id,
        linkedScriptId,
        identityScriptId,
        rowTypeId,
        rundownId,
        blockId,
        sequence,
        blobData,
      } = self

      return {
        id,
        linkedScriptId,
        identityScriptId,
        rowTypeId,
        rundownId,
        blockId,
        sequence,
        // we need a mutable clone since ag-grid is
        // going to mutate it
        blobData: { ...getSnapshot(blobData) },
      }
    },
    get rowLevel(): RowLevel | undefined {
      const raw = self.blobData.get(ROW_LEVEL_BLOB_FIELD)
      const parsed = schemas.rundownFormats.RowLevel.safeParse(raw)
      if (parsed.success) {
        return parsed.data
      }
    },
    get rowLevelName(): string | undefined {
      const { rowLevel, parentRundown } = this
      if (rowLevel && parentRundown) {
        return parentRundown.schema.schema.levels[rowLevel]?.displayName
      }
    },
    get scriptId(): string | null {
      return self.linkedScriptId ?? self.identityScriptId
    },
    get identityScriptColId(): string {
      return this.parentRundown.schema.schema.importRules.script.columnId
    },
    get identityScriptTitle(): string | undefined {
      if (self.identityScriptId) {
        const value = self.blobData.get(this.identityScriptColId)
        if (typeof value === 'string') {
          return value
        }
      }
    },
  }))
  .actions((self) => ({
    setSequence(value: number) {
      self.sequence = value
    },
    setDragStatus(value: 'over' | 'under') {
      self.scriptDragStatus = value
    },
    clearDragStatus() {
      self.scriptDragStatus = undefined
    },
    updateBlobValue(key: string, value: BlobDataValue | undefined) {
      if (value === null || value === undefined) {
        self.blobData.delete(key)
      } else {
        self.blobData.set(key, value)
      }
    },
    updateValue(key: string, value: BlobDataValue | undefined) {
      // TODO: once all clients are using the bulk endpoint for blob updates, this
      // endpoint will just be for other fields and we can stop checking for the gridBlobKeys
      if (isGridBlobKey(key)) {
        const blobPath = stripBlobKeyPrefix(key)
        this.updateBlobValue(blobPath, value)
      } else {
        // eslint-disable-next-line no-console
        console.error('BUG: Invalid rundown edit', { key, value })
      }
    },
  }))
