import React from 'react'

import { useTimeout } from '@mantine/hooks'
import cn from 'classnames'

import { FaIcon, GreenCheckIcon } from '@components/FaIcon'
import { showError } from '@components/Modals'
import { Toolbar } from '@components/Toolbar'
import { useMst } from '@state'
import { ButtonMode, ILoadedScript } from '@state/types'

import styles from './CreateSnapshot.module.scss'

const btnLabel = 'Snapshot'
const btnIcon = <FaIcon icon="fa-camera" />

export const CreateSnapshotButton = ({
  script,
  mode,
}: {
  script?: ILoadedScript
  mode?: ButtonMode
}) => {
  const { doDebug } = useMst()
  const [label, setLabel] = React.useState(btnLabel)
  const [icon, setIcon] = React.useState(btnIcon)

  // we set a timeout to trigger reverting the buttons default props
  const timeout = useTimeout(() => {
    setLabel(btnLabel)
    setIcon(btnIcon)
  }, 1000)

  const handleSnapshot = async () => {
    try {
      await doDebug()
      await script?.createSnapshot()
      setLabel('Saved')
      setIcon(<GreenCheckIcon />)
      timeout.start()
    } catch {
      showError('An unexpected error was encountered saving a snapshot')
    }
  }

  return (
    <Toolbar.Button
      buttonClasses={cn({
        [styles.btn]: mode === 'normal',
      })}
      customIcon={icon}
      label={label}
      onClick={handleSnapshot}
      mode={mode}
      focusEditor
    />
  )
}
