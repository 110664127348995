import { Anchor, Text } from '@mantine/core'

import { findLinksAndSplit } from '@choo-app/lib/editor/prose-utils'

export const AutolinkedText = ({ text }: { text: string }) => {
  const parsedText = findLinksAndSplit(text)

  if (!parsedText.some((m) => m.url)) return <>{text}</>

  const linkedText = parsedText.map((part, idx) =>
    part.url ? (
      <Anchor
        key={idx}
        href={part.url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        {part.text}
      </Anchor>
    ) : (
      <Text key={idx} span>
        {part.text}
      </Text>
    ),
  )

  return <Text span>{linkedText}</Text>
}
