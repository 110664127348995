import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Group, NumberInput } from '@mantine/core'
import { isInRange, useForm } from '@mantine/form'

import { ModalShell } from '@components/Modals/ModalShell'
import { useModalControls } from '@components/Modals/useModalControls'
import { useMst } from '@state'

type MultipleRowsModalProps = {
  onConfirm: (rowCount: number) => Promise<void>
  atTop: boolean
}

type MultipleRowsFormValues = {
  rowCount: number
}

const min = 1
const max = 100

const MultipleRowsBase = ({ onConfirm, atTop }: MultipleRowsModalProps) => {
  const { doDebug } = useMst()
  const controls = useModalControls()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const handleConfirm = async () => {
    setErrorMessage('')
    setLoading(true)
    try {
      await doDebug()
      await onConfirm(Number(form.values.rowCount))
      controls.onClose()
    } catch {
      setErrorMessage('Failed to insert rows')
    } finally {
      setLoading(false)
    }
  }

  const form = useForm<MultipleRowsFormValues>({
    initialValues: {
      rowCount: 10,
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: {
      rowCount: (v) =>
        isInRange(
          { min, max },
          `Select a number between ${min} and ${max}`,
        )(Number(v)),
    },
  })

  const label = atTop ? '# to insert at top' : '# to insert above selection'

  return (
    <ModalShell
      title="Insert multiple rows"
      confirmLabel="Insert"
      cancelLabel="Cancel"
      size="sm"
      onConfirm={form.onSubmit(handleConfirm)}
      opened={controls.opened}
      onClose={controls.onClose}
      loading={loading}
      errorMessage={errorMessage}
      disabled={!form.isValid()}
    >
      <Group justify="center">
        <NumberInput
          label={label}
          hideControls
          size="sm"
          min={min}
          max={max}
          step={1}
          data-autofocus
          {...form.getInputProps('rowCount')}
        />
      </Group>
    </ModalShell>
  )
}

const MulitpleRowsModal =
  NiceModal.create<MultipleRowsModalProps>(MultipleRowsBase)

export const showInsertMultipleRowsModal = (props: MultipleRowsModalProps) => {
  NiceModal.show(MulitpleRowsModal, props)
}
