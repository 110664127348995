import React from 'react'

import {
  ActionIcon,
  Blockquote,
  Button,
  Flex,
  Group,
  Menu,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from '@mantine/core'
import { format } from 'date-fns'

import {
  ClickOutsideBehavior,
  ClickOutsideConfirmation,
} from '@components/ClickOutsideConfirmation'
import { FaIcon } from '@components/FaIcon'
import { UserAvatar } from '@components/UserAvatar'
import { decodeHtmlString, fireAndForget } from '@util'

import { AutolinkedText } from './AutolinkedText'
import { CardWrapper } from './CardWrapper'
import { useKeyDown, useThreadContext } from './hooks'
import { CommentPojo } from './types'

import styles from './CommentParts.module.scss'

export const EditCommentForm = ({
  text,
  setText,
  initialValue,
  onSubmit,
  onCancel,
}: {
  initialValue: string
  text: string
  setText: Callback<[string]>
  onSubmit: Callback<[string]>
  onCancel: Callback
}) => {
  const decoded = getDecodedBodyText(initialValue)
  const hasUnsavedChanges = text !== decoded

  const handleSubmit = () => {
    if (hasUnsavedChanges) {
      onSubmit(text)
    } else {
      onCancel()
    }
  }
  const { handleKeyDown } = useKeyDown({
    onSubmit: handleSubmit,
    onCancel,
  })

  return (
    <ClickOutsideConfirmation
      behavior={hasUnsavedChanges ? 'confirm' : 'passthrough'}
      confirmationProps={{
        title: 'Unsaved changes',
        confirmLabel: 'Discard',
        children:
          'Are you sure you want to discard your edits to this comment?',
        dangerous: true,
      }}
      onClickOutside={onCancel}
    >
      <Stack gap={5} justify="stretch">
        <Textarea
          autoFocus
          autosize
          minRows={2}
          maxRows={5}
          flex={1}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={(e) => e.target.focus()}
        />
        <Group justify="flex-end" gap={5} mr={5}>
          <Button size="compact-sm" type="submit" onClick={handleSubmit}>
            Save
          </Button>

          <Button size="compact-sm" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </ClickOutsideConfirmation>
  )
}

const getDecodedBodyText = (text: string | null): string => {
  if (text) {
    const decoded = decodeHtmlString(text)
    if (decoded) {
      return decoded
    }
  }
  return ''
}

// Note: for unknown reasons we save html encoded strings to the database
// (and we need to support existing data even if we stop doing that). Also,
// the database doesn't guarantee comment text to be not null, so this component
// handles that AND styles the whitespace
export const CommentText = ({ children }: { children: string | null }) => {
  return (
    <span className={styles.commentParts_commentText}>
      <AutolinkedText text={getDecodedBodyText(children)} />
    </span>
  )
}

export const Attribution = ({
  name,
  avatar,
  timestamp,
}: {
  name: string
  avatar?: string | null
  timestamp?: Date
}) => (
  <Group gap={5} wrap="nowrap">
    <UserAvatar avatarSrc={avatar} alt={name} diameter={24} />
    <Stack gap={0}>
      <Text
        className={styles.commentParts_attributionName}
        span
        fw="bold"
        size="sm"
        lineClamp={1}
      >
        {name}
      </Text>
      {timestamp && (
        <Text span c="dimmed" size="xs">
          {format(timestamp, 'LLLL d, yyyy')}
        </Text>
      )}
    </Stack>
  </Group>
)

type Props = {
  text: string
  setText: Callback<[string]>
  placeholder: string
  avatar?: string | null
  onSubmit?: AsyncCallback
  onCancel?: Callback
  autoFocus?: boolean
  mode: 'new-thread' | 'existing-thread'
}
export const NewCommentForm = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    { text, setText, placeholder, avatar, onSubmit, onCancel, mode },
    inputRef,
  ) => {
    const hasValidComment = text.trim().length > 0
    const [isSaving, setIsSaving] = React.useState(false)
    const [hasError, setHasError] = React.useState(false)

    const updateText = (value: string) => {
      setText(value)
      setHasError(false)
    }

    const handleCancel = () => {
      if (!isSaving) {
        onCancel?.()
        updateText('')
      }
    }

    const handleSubmit = async () => {
      if (hasValidComment && onSubmit && !isSaving) {
        setIsSaving(true)
        setHasError(false)
        try {
          await onSubmit()
        } catch (e) {
          setHasError(true)
        } finally {
          setIsSaving(false)
        }
      }
    }

    const { handleKeyDown } = useKeyDown({
      onSubmit: () => fireAndForget(handleSubmit()),
      onCancel: handleCancel,
    })

    const clickOutsideBehavior: ClickOutsideBehavior = isSaving
      ? 'block'
      : hasValidComment
        ? 'confirm'
        : 'passthrough'

    return (
      <ClickOutsideConfirmation
        behavior={clickOutsideBehavior}
        onClickOutside={handleCancel}
        confirmationProps={{
          title: 'Unsaved changes',
          confirmLabel: 'Discard',
          children: 'Are you sure you want to discard your new comment?',
          dangerous: true,
        }}
      >
        <Stack gap={4}>
          <Flex mt={10} gap={10} align="flex-end" justify="space-between">
            <UserAvatar diameter={26} avatarSrc={avatar} mb={5} />
            <Textarea
              ref={inputRef}
              // data-autofocus is for the FocusTrap in ThreadBase
              data-autofocus={mode === 'existing-thread'}
              // autofocus is more powerful and is only used for the
              // UnsavedThread (new thread) where we steal focus from the
              // script
              autoFocus={mode === 'new-thread'}
              variant={isSaving ? 'filled' : 'default'}
              classNames={{
                input: isSaving
                  ? styles.commentParts_input___saving
                  : undefined,
              }}
              readOnly={isSaving}
              value={text}
              onKeyDown={handleKeyDown}
              onChange={(e) => updateText(e.target.value)}
              autosize
              placeholder={placeholder}
              minRows={1}
              maxRows={5}
              flex={1}
            />
            <ActionIcon mb={5} onClick={handleSubmit}>
              <FaIcon
                c={isSaving ? 'violet.2' : 'violet.7'}
                icon="fa-paper-plane-top"
                size="18"
              />
            </ActionIcon>
          </Flex>
          {hasError && (
            <Text size="xs" c="red" ml="36">
              Error saving comment
            </Text>
          )}
        </Stack>
      </ClickOutsideConfirmation>
    )
  },
)
NewCommentForm.displayName = 'NewCommentForm'

export const Snippet = ({ text }: { text: string }) => {
  return (
    <Blockquote color="yellow" pt={5} pb={5} px={10}>
      <Text
        c="dark.5"
        span
        size="sm"
        className={styles.commentParts_snippet}
        lineClamp={3}
      >
        {text}
        {text.trim().length === 0 && <span>&nbsp;</span>}
      </Text>
    </Blockquote>
  )
}

type FeedCommentProps = Pick<CommentPojo, 'snippet' | 'creator' | 'text'> & {
  onClick: Callback
}
export const FeedComment = ({
  snippet,
  creator,
  text,
  onClick,
}: FeedCommentProps) => {
  return (
    <CardWrapper type="feed-comment" onClick={onClick}>
      <Stack gap={10}>
        {snippet && <Snippet text={snippet} />}
        <Stack gap={5}>
          <Group justify="space-between" wrap="nowrap" gap={5}>
            <Attribution name={creator.name} avatar={creator.avatar} />
          </Group>
          <Text lineClamp={5} pt={2}>
            <CommentText>{text}</CommentText>
          </Text>
        </Stack>
      </Stack>
    </CardWrapper>
  )
}

export const ResolveButton = ({
  type,
  onClick,
}: {
  type: 'resolve' | 'unresolve'
  onClick?: () => void
}) => (
  <Tooltip
    position="bottom"
    label="Resolve thread"
    disabled={type === 'unresolve'}
  >
    <Button
      className={styles.commentParts_action}
      pb={2}
      px={4}
      pt={2}
      size="xs"
      variant="subtle"
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
      }}
    >
      {type === 'unresolve' ? (
        'Unresolve'
      ) : (
        <FaIcon icon="fa-check" size="18" />
      )}
    </Button>
  </Tooltip>
)

export const EditMenu = ({
  onClickDelete,
  onClickEdit,
}: {
  onClickDelete?: () => void
  onClickEdit?: () => void
}) => {
  const { operations } = useThreadContext()

  const handleClickMenu: React.MouseEventHandler<HTMLButtonElement> = () => {
    operations.selectThread?.()
  }

  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <ActionIcon
          variant="subtle"
          className={styles.commentParts_action}
          onClick={handleClickMenu}
        >
          <FaIcon c="dark.9" icon="fa-ellipsis-vertical" size="18" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={onClickEdit}>Edit</Menu.Item>
        <Menu.Item c="red" onClick={onClickDelete}>
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
