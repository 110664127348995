import { differenceInSeconds } from 'date-fns'

import { RundownRowData } from '@util/ScriptoApiClient/types'

import { CLIPBOARD_LIFESPAN_SECONDS } from './constants'
import { GridBlobColumnKey, RundownColDef } from './types'

type DurationValues = { [colId: string]: number }
export type EnrichedRowData = RundownRowData & { durations: DurationValues }

export const stripBlobKeyPrefix = (key: GridBlobColumnKey): string =>
  key.replace(/^blobData\./, '')

export function isGridBlobKey(key: unknown): key is GridBlobColumnKey {
  return typeof key === 'string' && /^blobData\./.test(key)
}

export const getBlobValue = ({
  data,
  field,
}: {
  data?: RundownRowData
  field?: string
}) => {
  if (data && field && isGridBlobKey(field)) {
    return data.blobData[stripBlobKeyPrefix(field)]
  }
}

export const isClipboardCurrent = (copiedAt: Date): boolean => {
  const diff = differenceInSeconds(new Date(), copiedAt)
  return diff < CLIPBOARD_LIFESPAN_SECONDS
}

const extractDurationsFromRow = (
  row: RundownRowData,
  durationColumns: RundownColDef[],
): DurationValues => {
  const result: DurationValues = {}
  durationColumns.forEach(({ field }) => {
    if (field && isGridBlobKey(field)) {
      const colId = stripBlobKeyPrefix(field)
      const value = row.blobData[colId]
      if (typeof value === 'number' && isFinite(value)) {
        result[colId] = value
      }
    }
  })
  return result
}

const addDurations = (a: number | undefined, b: number | undefined): number =>
  (a ?? 0) + (b ?? 0)

export const mapWithDurations = (
  rows: RundownRowData[],
  colDefs: RundownColDef[],
): EnrichedRowData[] => {
  const durationColumns = colDefs.filter(
    (cd) => cd.rundownColumnType === 'duration',
  )
  const accumulated: DurationValues = {}
  return rows.map((row) => {
    Object.entries(extractDurationsFromRow(row, durationColumns)).forEach(
      ([colId, value]) => {
        accumulated[colId] = addDurations(accumulated[colId], value)
      },
    )
    return {
      ...row,
      durations: { ...accumulated },
    }
  })
}
