import { roundToTenths } from '@util'
import { StandardBlockConfig } from '@util/formats'
import { LEFT_MARGIN_ZERO_PX, PageLayout, PX_PER_CH } from '@util/PageLayout'

export type ConfiguredMarginValue = Pick<
  StandardBlockConfig,
  'marginLeft' | 'width'
>
export type SliderPositions = {
  leftPx: number
  rightPx: number
}

export type TickData = {
  label?: string
}

export type AllowedRange = {
  minLeftPx: number
  maxRightPx: number
  minWidthPx: number
}

export type RulerConfig = {
  displayedUnitSize: number
  ticksPerUnit: number
  unscaledWidthPx: number
  allowedRange: AllowedRange
}

const chToPx = (chars: number) => PX_PER_CH * chars
const pxToCh = (px: number) => px / PX_PER_CH

// when a left margin of 0 is specified, it is actually
// a hard-coded value that means 1.5 inches
const LEFT_MARGIN_ADJUST_PX = LEFT_MARGIN_ZERO_PX

// this is hard-coded to 10 but we might want to move it into
// PageLayout if, for example, we wanted 1mm tick width for cm and
// 1/8" tick width for inches
const STANDARD_TICKS_PER_UNIT = 10

export const rulerConfigFromLayout = (pageLayout: PageLayout): RulerConfig => {
  const pageWidth = pageLayout.data.width
  const { marginLeftMin, marginRightMin } = pageLayout.limitsInDisplayUnits
  const minLeftPx = pageLayout.displayValueToPx(marginLeftMin)
  const minWidthPx = pageLayout.displayValueToPx(pageLayout.data.minBlockWidth)
  const maxRightPx = pageLayout.displayValueToPx(pageWidth - marginRightMin)

  return {
    displayedUnitSize: pageWidth,
    ticksPerUnit: STANDARD_TICKS_PER_UNIT,
    unscaledWidthPx: pageLayout.displayValueToPx(pageWidth),
    allowedRange: {
      maxRightPx,
      minLeftPx,
      minWidthPx,
    },
  }
}

export const buildTicks = ({
  displayedUnitSize,
  ticksPerUnit,
}: RulerConfig) => {
  const ticks: TickData[] = []
  const totalTicks = displayedUnitSize * ticksPerUnit
  for (let i = 0; i < totalTicks; i++) {
    const data: TickData = {}
    if (i % ticksPerUnit === 0) {
      data.label = String(i / ticksPerUnit)
    }
    ticks.push(data)
  }
  return ticks
}

// a margin configuration in a script format looks like
// { marginLeft: number; width: number }
// and the units are in ch with 0 for margin left representing
// 1.5 inches (just because...)  This helper transforms that
// into an absolute left in pixels
export const marginConfigToSliderPositions = (
  margins: ConfiguredMarginValue,
): SliderPositions => {
  const leftOffsetPx = chToPx(margins.marginLeft)
  const leftPx = leftOffsetPx + LEFT_MARGIN_ADJUST_PX
  const widthPx = chToPx(margins.width)
  const rightPx = leftPx + widthPx
  return {
    leftPx,
    rightPx,
  }
}

export const sliderPositionToMarginConfig = ({
  leftPx,
  rightPx,
}: SliderPositions): ConfiguredMarginValue => {
  const adjustedLeft = leftPx - LEFT_MARGIN_ADJUST_PX
  const widthPx = rightPx - leftPx

  return {
    marginLeft: roundToTenths(pxToCh(adjustedLeft)),
    width: roundToTenths(pxToCh(widthPx)),
  }
}
