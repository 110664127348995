import React from 'react'

import { getHotkeyHandler } from '@mantine/hooks'

import { noop } from '@util'

import {
  buildShortcutCode,
  buildShortcutTip,
  detectedPlatform,
  Platform,
} from './helpers'

export type ShortcutConfig = {
  keys: string[]
  disabled?: boolean
  action?: Callback | AsyncCallback
}

type UseShortcutsOptions = {
  platform?: Platform
  skip?: boolean
}

type HookReturn<T extends string> = {
  menuProps: {
    opened: boolean
    onChange: (value: boolean) => void
  }
  getItemProps: (key: T) => {
    onClick?: () => void
    shortcut?: string
  }
}

export const useShortcuts = <T extends string>(
  config: Record<T, ShortcutConfig>,
  options?: UseShortcutsOptions,
): HookReturn<T> => {
  const [isOpen, setIsOpen] = React.useState(false)
  const platform = options?.platform ?? detectedPlatform

  React.useEffect(() => {
    const listener = options?.skip
      ? noop
      : getHotkeyHandler(
          Object.values<ShortcutConfig>(config).map(
            ({ keys, action, disabled }) => {
              const code = buildShortcutCode(keys, platform)
              return [
                code,
                () => {
                  if (!disabled) {
                    action?.()
                    if (isOpen) {
                      setIsOpen(false)
                    }
                  }
                },
              ]
            },
          ),
        )
    document.body.addEventListener('keydown', listener)
    return () => document.body.removeEventListener('keydown', listener)
  })

  const buildTip = React.useCallback(
    (item: T): string => buildShortcutTip(config[item].keys, platform),
    [config, platform],
  )

  return {
    menuProps: {
      opened: isOpen,
      onChange: setIsOpen,
    },
    getItemProps: (item: T) => ({
      onClick: config[item].action,
      shortcut: options?.skip ? undefined : buildTip(item),
      disabled: config[item].disabled,
    }),
  }
}
