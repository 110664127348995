import React from 'react'

import {
  ActionIcon,
  Anchor,
  Divider,
  Group,
  Paper,
  Portal,
  ScrollArea,
  Space,
  Tooltip,
} from '@mantine/core'
import { useWindowEvent } from '@mantine/hooks'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { Node as PmNode } from 'prosemirror-model'

import { util as codexUtil } from '@showrunner/codex'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@state'
import { ILoadedScript } from '@state/types'
import { defaultPrompterViewPrefs } from '@util/LocalPersistence'

import { LEARN_MORE_HREF } from './PrompterViewInfo'
import { showPrompterViewSettingsModal } from './PrompterViewSettingsModal'

import styles from './PrompterView.module.scss'

const getLines = (script?: ILoadedScript): string[] => {
  const pmDoc = script?.observableEditor?.editorState.doc
  if (pmDoc) {
    const breakdown = new codexUtil.ScriptBreakdown(pmDoc, 1)
    return breakdown.prompterSlugs.map((ps) => ps.text.split('\n')).flat()
  }
  return []
}

const getPromptableLines = (pmDoc?: PmNode): string[] => {
  if (pmDoc) {
    const breakdown = new codexUtil.ScriptBreakdown(pmDoc, 1)
    return breakdown.prompterSlugs.map((ps) => ps.text.split('\n')).flat()
  }
  return []
}

export const PrompterView = observer(function PrompterView() {
  const { user, currentScript } = useMst()
  const [lines, setLines] = React.useState<string[]>(getLines(currentScript))

  const { darkMode, reversed, fontSize, autoUpdate } =
    user.prefs.prompterView ?? defaultPrompterViewPrefs

  const handleClose = () => {
    currentScript?.setShowPrompterView(false)
  }

  useWindowEvent('keydown', (e) => {
    if (e.key === 'Escape') {
      handleClose()
    }
  })

  // triggers a refresh of the displayed lines of text
  const pmDoc = currentScript?.observableEditor?.editorState.doc
  React.useEffect(() => {
    if (autoUpdate) {
      setLines(getPromptableLines(pmDoc))
    }
  }, [autoUpdate, pmDoc])

  return (
    <Portal>
      <Paper
        className={cn(styles.prompterView, {
          [styles.prompterViewDark]: darkMode,
        })}
      >
        <Group
          className={styles.prompterView_controls}
          gap="xs"
          justify="flex-end"
        >
          <Tooltip label="About Prompter View">
            <Anchor href={LEARN_MORE_HREF} target="_blank">
              <ActionIcon
                className={styles.prompterView_button}
                variant="outline"
                size={34}
              >
                <FaIcon icon="fa-question-circle" size="20" />
              </ActionIcon>
            </Anchor>
          </Tooltip>

          <Tooltip label="Settings">
            <ActionIcon
              className={styles.prompterView_button}
              variant="outline"
              size={34}
              onClick={showPrompterViewSettingsModal}
            >
              <FaIcon icon="fa-gear" size="20" />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Exit Prompter View">
            <ActionIcon
              className={styles.prompterView_button}
              variant="outline"
              size={34}
              onClick={handleClose}
            >
              <FaIcon icon="fa-xmark" size="26" />
            </ActionIcon>
          </Tooltip>
        </Group>
        <ScrollArea
          type="never"
          className={styles.prompterView_scrollableText}
          style={{
            fontSize,
            transform: reversed ? 'rotateY(180deg)' : undefined,
          }}
        >
          <Space h={50} />
          {lines.map((line, index) => (
            <p key={index}>{line}</p>
          ))}
          <Space h={100} />
          <Divider
            orientation="horizontal"
            color="light"
            w="80%"
            style={{ margin: 'auto' }}
          />
          <Space h={150} />
        </ScrollArea>
      </Paper>
    </Portal>
  )
})
