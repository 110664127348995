import React from 'react'

import { Menu, Tooltip, UnstyledButton } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'
import { FancyMenuItem } from '@components/FancyMenuItem'
import {
  InvisibleFdxPicker,
  InvisibleFdxPickerHandle,
} from '@components/InvisibleFdxPicker'
import { IOrg, useMst } from '@state'

import { showCreateDocModal } from './CreateDocModal'
import { FOLDER_SECTION_ID } from './FolderExplorer'

import styles from './CreateMenu.module.scss'

export const CreateMenu = ({
  folderId,
  workspace,
}: {
  folderId: string
  workspace: IOrg
}) => {
  const mst = useMst()
  const fdxPickerRef = React.useRef<InvisibleFdxPickerHandle>(null)
  const folder = mst.folderMap.get(folderId)

  const startCreatingFolder = () => {
    mst.view.setSelectedFolderId(folderId)
    // Make sure the folder tree section of the explorer is open
    mst.view.explorerState.setSectionCollapsed(FOLDER_SECTION_ID, false)
    mst.view.setCreatingChild(true)
  }

  if (!folder || folder?.belongsToTrashTree) return null

  return (
    <>
      <Menu>
        <Menu.Target>
          <div className={styles.createMenu_target}>
            <Tooltip label="Create folder or document inside">
              <UnstyledButton>
                <FaIcon icon="fa-plus" c="dark" size="14" />
              </UnstyledButton>
            </Tooltip>
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <FancyMenuItem
            title="Create sub-folder"
            iconClass="fas fa-folder"
            onClick={startCreatingFolder}
          ></FancyMenuItem>
          <FancyMenuItem
            title="Create document"
            iconClass="fas fa-file-plus"
            onClick={() =>
              showCreateDocModal({ folder, workspace, from: 'explorer' })
            }
          ></FancyMenuItem>
        </Menu.Dropdown>
      </Menu>
      <InvisibleFdxPicker
        folder={folder}
        ref={fdxPickerRef}
        onConfirm={() => mst.view.setSelectedFolderId(folderId)}
      />
    </>
  )
}
