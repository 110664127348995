import { Divider, Group, Stack, Text } from '@mantine/core'

import { util as codexUtil } from '@showrunner/codex'

import { formatTimeData, formatTimeDataWithSlugs } from './helpers'
import { SelectionTiming } from './SelectionTiming'
import { SlugTiming } from './SlugTiming'

const BASE_OFFSET = 80
const SELECTION_OFFSET = 112

export const TimingInfo = ({
  containerHeight,
  docTiming,
  selectionTiming,
}: {
  containerHeight: number
  docTiming: codexUtil.TimingSummary
  selectionTiming?: codexUtil.TimingSummary
}) => {
  const hasSelection = !!(selectionTiming && selectionTiming.totalSeconds > 0)
  const formattedDocTiming = formatTimeDataWithSlugs(docTiming)
  const formattedSelectionTiming = selectionTiming
    ? formatTimeData(selectionTiming)
    : undefined

  // only the slug section of the dropdown is scrollable.
  // the footer, total timing and optionally the selection timing are sticky
  const offset = BASE_OFFSET + (hasSelection ? SELECTION_OFFSET : 0)
  // Use Math.max as a fallback for storybooks, etc.
  const maxHeight = Math.max(containerHeight - 55 - offset, 100)
  // we dont summarize preslug timing unless one or more genuine slugs are present too
  const displaySlugTiming = formattedDocTiming.slugTiming.some(
    (t) => !t.isZeroth,
  )

  return (
    <Stack px={0} gap={0} pt={10} pb={5}>
      <Stack px={10} gap={5} pb={5}>
        <Group justify="space-between" wrap="nowrap">
          <Text fw="bold">Total script</Text>
          <Text>{formattedDocTiming.timing.total}</Text>
        </Group>
        <Divider />
        <Group justify="space-between" wrap="nowrap">
          <Text c="dark.3">Dialogue</Text>
          <Text c="dark.3">{formattedDocTiming.timing.dialogue}</Text>
        </Group>
        <Group justify="space-between" wrap="nowrap">
          <Text c="dark.3">Brackets</Text>
          <Text c="dark.3">{formattedDocTiming.timing.bracket}</Text>
        </Group>
      </Stack>
      {formattedSelectionTiming && (
        <SelectionTiming timing={formattedSelectionTiming} />
      )}
      {displaySlugTiming && (
        <SlugTiming
          slugTimings={formattedDocTiming.slugTiming}
          maxHeight={maxHeight}
        />
      )}
      <Divider mt={0} mb={5} color="gray.3" />
      <a
        href="https://help.scripto.live/en/articles/3712912-script-timer"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Group justify="space-between" wrap="nowrap" px={10} pt={2}>
          <Text c="dark.3" fz={14} td="underline">
            Learn about timing
          </Text>
          <Text c="dark.3" fz={14}>
            <i className="fa fa-info-circle"></i>
          </Text>
        </Group>
      </a>
    </Stack>
  )
}
