import { observer } from 'mobx-react-lite'

import { RundownHeader } from '@components/MiniHeader/RundownHeader'
import { RundownGrid } from '@components/RundownGrid'
import { RundownStatusbar } from '@components/RundownStatusbar'
import { RundownToolbar } from '@components/RundownToolbar'
import { Spinner } from '@components/Spinner'
import { FloatingMenuProvider } from '@hooks'
import { IRundown } from '@state/types'

import styles from './RundownEditor.module.scss'

/*
  This component has the rundown grid inside it (RundownGrid)
*/
export const RundownEditor = observer(function RundownEditor({
  rundown,
}: {
  rundown: IRundown
}) {
  return (
    <FloatingMenuProvider>
      <div className={styles.rundownEditor}>
        {rundown.gridIsLoading && <Spinner delayMs={300} />}
        <RundownHeader rundown={rundown} />
        <RundownToolbar rundown={rundown} />
        <div className={styles.rundownEditor_gridWrapper}>
          {/* we pass a css class to the rundown grid to set its size, border & any positioning we want */}
          <RundownGrid
            rundown={rundown}
            className={styles.rundownEditor_grid}
            userCanEdit={rundown.isEditable}
          />
        </div>
        <RundownStatusbar rundown={rundown} />
      </div>
    </FloatingMenuProvider>
  )
})
