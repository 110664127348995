import { Text } from '@mantine/core'

import { IOrg } from '@state/types'

import { showAsyncConfirmModal } from './AsyncConfirmModal'

export function showUpgradeInquiryModal(org: IOrg) {
  showAsyncConfirmModal({
    title: 'Request an Upgrade',
    children: (
      <Text>Can we email you to start discussing our pricing plans?</Text>
    ),
    confirmLabel: 'Yes',
    cancelLabel: 'Not Yet',
    onConfirm: org.upgradeInquiry,
    errorMessage: 'Failed to request upgrade',
  })
}
