import { Menu } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { useRundownOperations } from '@components/RundownGrid/useRundownOperations'
import { Toolbar } from '@components/Toolbar'
import { ButtonMode, IRundown } from '@state/types'

export const NumberingMenu = observer(function NumberingMenu({
  rundown,
  buttonMode,
}: {
  rundown: IRundown
  buttonMode: ButtonMode
}) {
  const operations = useRundownOperations(rundown)

  const { hasNumberedRows, sortedSelectedRows, selectionHasNumberedRows } =
    rundown

  const renumberTitle = (hasNumberedRows ? 'Ren' : 'N') + 'umber all rows...'
  const renumberSelTitle = renumberTitle.replace('all', 'selected')

  return (
    <Menu withinPortal>
      <Menu.Target>
        <div>
          <Toolbar.Button
            label="Numbering"
            mode={buttonMode}
            icon="fa-hashtag"
            dropdown
          />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={() => operations.handleSetItemNumbers(true)}>
          {renumberTitle}
        </Menu.Item>
        <Menu.Item
          onClick={() => operations.handleSetItemNumbers(false)}
          disabled={sortedSelectedRows.length < 1}
        >
          {renumberSelTitle}
        </Menu.Item>
        <Menu.Divider />
        <FancyMenuItem
          iconClass="fas fa-wand-magic-sparkles"
          onClick={operations.handleSmartNumbering}
          title="Smart numbering"
        />
        <Menu.Divider />
        <Menu.Item
          onClick={() => operations.handleRemoveItemNumbers(true)}
          disabled={!hasNumberedRows}
        >
          Remove all row numbers...
        </Menu.Item>
        <Menu.Item
          onClick={() => operations.handleRemoveItemNumbers(false)}
          disabled={!selectionHasNumberedRows}
        >
          Remove selected row numbers...
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
})
