import NiceModal from '@ebay/nice-modal-react'
import { Anchor, Group, Image, Space, Stack, Text, Title } from '@mantine/core'

import SEARCH_PNG from '@assets/images/sidebar-search.jpg'
import {
  ModalShell,
  showAsyncConfirmModal,
  useModalControls,
} from '@components/Modals'
import { OutsideAnchor } from '@components/OutsideAnchor'
import { useMst } from '@state'
import { LAST_WHATS_NEW_UPDATE } from '@util/constants'

import styles from './WhatsNew.module.scss'

export const WHATS_NEW_URL = 'https://www.scripto.live/whats-new'

export const offerUpdate = (version?: string) =>
  showAsyncConfirmModal({
    title: 'Update available',
    children: (
      <Stack gap={10}>
        <Space />
        <Text>
          We&apos;ve updated Scripto! Please refresh to get the latest and
          greatest.
        </Text>
        <OutsideAnchor href={WHATS_NEW_URL}>
          {'Learn more' + (version ? ` about ${version}` : '')}
        </OutsideAnchor>
        <Space />
      </Stack>
    ),
    errorMessage: 'Error reloading application',
    confirmLabel: 'Refresh',
    cancelLabel: 'Not Now',
    size: 'sm',
    onConfirm: () => Promise.resolve(window.location.reload()),
  })

export const WhatsNew = () => {
  const controls = useModalControls()
  const { user } = useMst()
  const lastSeen = user.prefs.whatsNew ?? 0

  const handleClose = () => {
    user.updatePreferences({ whatsNew: new Date().getTime() })
    controls.onClose()
  }

  // dont show the new bling more than once
  if (lastSeen > LAST_WHATS_NEW_UPDATE) return null

  return (
    <ModalShell
      title="New in Scripto"
      onConfirm={handleClose}
      confirmLabel="OK"
      size={530}
      opened={controls.opened}
      onClose={handleClose}
    >
      <Group>
        <Image
          maw={300}
          className={styles.modal_img}
          src={SEARCH_PNG}
          alt="Searching for documents from the sidebar"
        ></Image>
        <Stack w={180}>
          <Title order={4}>Search has moved to the sidebar ✨</Title>
          <Text>
            Now you can search from any page.&nbsp;
            <Anchor
              target="_blank"
              rel="noopener noreferrer"
              href={WHATS_NEW_URL}
            >
              Learn more
            </Anchor>
            .
          </Text>
        </Stack>
      </Group>
    </ModalShell>
  )
}

export const WhatsNewModal = NiceModal.create(WhatsNew)
export const showWhatsNewModal = () => NiceModal.show(WhatsNewModal)
