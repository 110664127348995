import React from 'react'

import { Divider, FocusTrap, Group, Stack, Text } from '@mantine/core'
import { useFocusWithin } from '@mantine/hooks'
import { observer } from 'mobx-react-lite'

import { decodeHtmlString } from '@util'

import { CardWrapper } from './CardWrapper'
import * as Parts from './CommentParts'
import { spreadableProps } from './domHelpers'
import { useThreadContext } from './hooks'
import { CommentPojo, ThreadOperations, ThreadPojo } from './types'

import styles from './Thread.module.scss'

export type ThreadBaseProps = {
  mode: 'panel' | 'feed'
  user?: {
    id: string
    avatar?: string | null
  }
  thread: ThreadPojo
  resolveOption: 'resolve' | 'unresolve' | 'none'
  selected?: boolean
  canAddComment?: boolean
  showSnippet?: boolean
  operations: ThreadOperations
  enableScrollTo?: boolean
}

export const ThreadComment = observer(function ThreadComment({
  comment,
  resolveButton,
  enableScrollTo,
  isEditable,
  operations,
}: {
  selected?: boolean
  resolveButton?: React.ReactNode
  comment: CommentPojo
  enableScrollTo?: boolean
  isEditable?: boolean
  operations: {
    onSubmit?: Callback<[string]>
    onDelete?: Callback
  }
}) {
  const { editedComment, setEditedComment } = useThreadContext()
  const isEditing = editedComment?.id === comment.id

  const setEditedCommentText = (newText: string) => {
    if (editedComment) {
      setEditedComment({
        ...editedComment,
        newText,
      })
    }
  }

  const handleSubmitEdit = (value: string) => {
    operations.onSubmit?.(value)
  }

  const startEditing = () => {
    const decoded = decodeHtmlString(comment.text)
    setEditedComment({
      id: comment.id,
      originalText: decoded,
      newText: decoded,
    })
  }

  const commentScrollProps = enableScrollTo
    ? spreadableProps('data-panel-comment', comment.id)
    : undefined

  return (
    <Stack gap={5} {...commentScrollProps}>
      <Group justify="space-between" wrap="nowrap" gap={5}>
        <Parts.Attribution
          name={comment.creator.name}
          avatar={comment.creator.avatar}
          timestamp={comment.createdAt}
        />
        {resolveButton}
      </Group>
      {editedComment?.id === comment.id && (
        <Parts.EditCommentForm
          text={editedComment.newText}
          setText={setEditedCommentText}
          initialValue={editedComment.originalText}
          onSubmit={handleSubmitEdit}
          onCancel={() => setEditedComment(null)}
        />
      )}
      {!isEditing && (
        <Group align="flex-start" justify="space-between" wrap="nowrap" gap={5}>
          <Text px={5}>
            <Parts.CommentText>{comment.text}</Parts.CommentText>
          </Text>
          {isEditable && (
            <Parts.EditMenu
              onClickEdit={startEditing}
              onClickDelete={operations.onDelete}
            />
          )}
        </Group>
      )}
    </Stack>
  )
})

export const ThreadBase = observer(function ThreadBase({
  user,
  thread,
  selected,
  showSnippet,
  operations,
  resolveOption,
  enableScrollTo,
  mode,
}: ThreadBaseProps) {
  const { editedComment } = useThreadContext()
  const newCommentInputRef = React.useRef<HTMLTextAreaElement>(null)

  // When an open thread gets selected, we show the input for
  // adding a comment. If the selection was because the user
  // clicked, we want the input to get focus. However, we don't
  // want it to happen if we select the comment for other reasons
  // (e.g. clicking the mark in a the script).
  //
  // this is achieved by using a <FocusTrap> and controlling the
  // active prop. When the user clicks, we set the active prop to true
  // and once we detect focus within the trap, we set the active prop
  // to false again.
  const [focusTrapActive, setFocusTrapActive] = React.useState(false)

  // when we get the focus within, if the target was the input ref and
  // it was because the focus trap was triggered, we scroll to the ref.
  // Then we remove the focus trap because we don't want subsequent
  // interactions in the thread to trigger programmatic focus
  const { ref } = useFocusWithin({
    onFocus: (e) => {
      if (e.target === newCommentInputRef.current && focusTrapActive) {
        newCommentInputRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      setFocusTrapActive(false)
    },
  })

  const [newCommentText, setNewCommentText] = React.useState('')
  const handleSubmitNewComment = async () => {
    const reply = newCommentText.trim()
    if (operations.addComment && reply.length > 0) {
      await operations.addComment(reply)
    }
    setNewCommentText('')
  }

  const handleEditComment = ({
    commentId,
    value,
  }: {
    commentId: string
    value: string
  }) => {
    const text = value.trim()
    if (text.length > 0 && operations.editComment) {
      operations.editComment({ commentId, text })
    }
  }

  const handleClick = () => {
    operations.selectThread?.()
    if (!selected) {
      const selectedText = document.getSelection()?.toString()
      if (!selectedText) {
        setFocusTrapActive(true)
      }
    }
  }

  return (
    <CardWrapper
      type={mode === 'feed' ? 'feed-thread' : 'panel-thread'}
      selected={selected}
      scrollToId={enableScrollTo ? thread.id : undefined}
      onClick={handleClick}
    >
      <FocusTrap active={focusTrapActive}>
        <Stack gap={10} ref={ref}>
          {showSnippet && <Parts.Snippet text={thread.snippet} />}
          {thread.comments.map((comment, index) => {
            const isFirst = index === 0
            const onSubmit = (value: string) =>
              handleEditComment({ commentId: comment.id, value })

            const onDelete = () => operations.deleteComment?.(comment.id)

            const resolveButton =
              resolveOption !== 'none' && isFirst ? (
                <Parts.ResolveButton
                  type={resolveOption}
                  onClick={operations.changeThreadResolution}
                />
              ) : undefined

            return (
              <React.Fragment key={comment.id}>
                {!isFirst && (
                  <Divider
                    className={styles.thread_divider}
                    mt={5}
                    mb={5}
                    pr={5}
                  />
                )}

                <ThreadComment
                  comment={comment}
                  isEditable={
                    mode === 'panel' && user?.id === comment.creator.id
                  }
                  enableScrollTo={mode === 'panel'}
                  operations={{
                    onSubmit,
                    onDelete,
                  }}
                  resolveButton={resolveButton}
                  selected={selected}
                />
              </React.Fragment>
            )
          })}

          {selected && mode === 'panel' && !editedComment && (
            <Parts.NewCommentForm
              ref={newCommentInputRef}
              mode="existing-thread"
              text={newCommentText}
              setText={setNewCommentText}
              placeholder="Reply..."
              avatar={user?.avatar}
              onSubmit={handleSubmitNewComment}
              onCancel={() => setNewCommentText('')}
            />
          )}
        </Stack>
      </FocusTrap>
    </CardWrapper>
  )
})
