import React from 'react'

import { Text, TextInput } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'

import { showError } from '@components/Modals'
import { PasswordInput, useStrongPasswordForm } from '@components/PasswordInput'
import { Pinput } from '@components/Pinput'
import { useNavigation } from '@hooks'
import { useMst } from '@state'
import { pathTo, ROUTE_PATTERNS } from '@util'
import * as formUtil from '@util/formUtil'
import { parseServerError } from '@util/parseServerError'

import { FormError, knownCodes, serverErrorMessages } from './FormErrors'
import { OnboardingPage } from './OnboardingPage'
import { OtpBailouts } from './OnboardingPage/BailoutLink'
import {
  ForgotPasswordSubtitle,
  SentOtpSubtitle,
  UpdatePasswordSubtitle,
} from './PageSubtitle'

const EMAIL_MISSING = 'MISSING'

export type ForgotPasswordPagePhase =
  | 'submit-email'
  | 'submit-code'
  | 'create-password'

type FormValues = {
  email: string
  verificationCode: string
  password: string
}

type RouteVariant = 'forgot' | 'update'

type ForgotPasswordPageProps = {
  onSubmitEmail: (email: string) => Promise<void>
  onSubmitOtp: (otp: string) => Promise<void>
  onSubmitNewPassword: (values: FormValues) => Promise<void>
  onReset: () => void
  phase: ForgotPasswordPagePhase
  loading?: boolean
  serverError?: string
  variant?: RouteVariant
}

const TITLES: Record<ForgotPasswordPagePhase, string> = {
  'submit-email': 'Forgot Password?',
  'submit-code': 'Verify Your Email',
  'create-password': 'Create a New Password',
}

export const ForgotPasswordPage = ({
  onSubmitEmail,
  onSubmitNewPassword,
  onSubmitOtp,
  onReset,
  loading,
  phase,
  serverError,
  variant = 'forgot',
}: ForgotPasswordPageProps) => {
  const { form, passwordStrength } = useStrongPasswordForm<FormValues>({
    passwordField: 'password',
    skipPasswordCheck: phase !== 'create-password',
    initialValues: {
      email: '',
      verificationCode: '',
      password: '',
    },
    validate: {
      email: formUtil.validateEmail,
      verificationCode: (value) => {
        if (phase === 'submit-code') {
          return formUtil.validateOtpCode(value)
        }
      },
    },
  })

  const handleSubmit = async (values: FormValues) => {
    if (phase === 'submit-email') {
      onSubmitEmail(values.email)
    } else if (phase === 'submit-code') {
      await onSubmitOtp(values.verificationCode)
      form.setFieldValue('verificationCode', '')
    } else {
      onSubmitNewPassword(values)
    }
  }

  const handleStartOver = () => {
    form.reset()
    onReset()
  }

  const resendCode = () => {
    form.setFieldValue('verificationCode', '')
    form.clearFieldError('verificationCode')
    onSubmitEmail(form.values.email)
  }

  const renderError = () => {
    if (!serverError) {
      return null
    }
    if (serverError === EMAIL_MISSING) {
      return <Text>No account found with that email address</Text>
    }

    const handleClick = () => {
      switch (serverError) {
        case knownCodes.OTP_EXPIRED_OR_USED:
          resendCode()
          break
        case knownCodes.SESSION_NOT_FOUND:
          handleStartOver()
          break
      }
    }

    return <FormError code={serverError} onClick={handleClick} />
  }

  const renderSubtitle = () => {
    if (phase === 'submit-email') {
      return variant === 'update' ? (
        <UpdatePasswordSubtitle />
      ) : (
        <ForgotPasswordSubtitle />
      )
    }
    if (phase === 'submit-code') {
      return <SentOtpSubtitle email={form.values.email} />
    }
    return null
  }

  const title = variant === 'update' ? 'Update Password' : TITLES[phase]
  return (
    <OnboardingPage title={title} loading={loading}>
      {renderSubtitle()}
      <OnboardingPage.Form
        onSubmit={form.onSubmit(handleSubmit)}
        errorMessage={renderError()}
      >
        {phase === 'submit-email' && (
          <TextInput label="Email" {...form.getInputProps('email')} />
        )}
        {phase === 'submit-code' && (
          <Pinput {...form.getInputProps('verificationCode')} />
        )}
        {phase === 'create-password' && (
          <PasswordInput
            label="New Password"
            {...form.getInputProps('password')}
            strengthInfo={passwordStrength}
          />
        )}
        <OnboardingPage.SubmitButton label="Submit" />
        {phase === 'submit-code' && (
          <OtpBailouts
            onResendCode={() => {
              form.setFieldValue('verificationCode', '')
              form.clearFieldError('verificationCode')
              onSubmitEmail(form.values.email)
            }}
            onStartOver={() => {
              form.reset()
              onReset()
            }}
          />
        )}
      </OnboardingPage.Form>

      <OnboardingPage.Links types={['backtologin', 'divider', 'support']} />
    </OnboardingPage>
  )
}

export const ForgotPasswordRouteInternal = ({
  variant = 'forgot',
}: {
  variant?: RouteVariant
}) => {
  const mst = useMst()
  const { navigate } = useNavigation()
  const [serverError, setServerError] = React.useState<string | undefined>()
  const [loading, setLoading] = React.useState(false)
  const [oneTimePasscodeId, setOneTimePasscodeId] = React.useState('')
  const [token, setToken] = React.useState('')
  const [phase, setPhase] =
    React.useState<ForgotPasswordPagePhase>('submit-email')
  const [isMigrating, setIsMigrating] = React.useState(false)

  useQuery({
    queryFn: async () => {
      if (variant === 'update' && mst.loggedIn) {
        await mst.apiClient.logout()
        // no need to redirect, but we still want to reload anonymously
        window.location.reload()
      }
      return true
    },
    queryKey: ['updatePassword', loading],
    staleTime: Infinity,
    gcTime: 0,
  })

  const handleApiError = (e: unknown) => {
    const { code, message } = parseServerError(e)
    setServerError(code ?? message)
  }

  const startApiRequest = () => {
    setServerError(undefined)
    setLoading(true)
  }

  const submitStytchEmail = async (email: string, isLegacy: boolean) => {
    try {
      const { oneTimePasscodeId } = isLegacy
        ? await mst.apiClient.initiateAccountMigration(email)
        : await mst.apiClient.initiateStychPasswordReset(email)
      setOneTimePasscodeId(oneTimePasscodeId)
      setPhase('submit-code')
      setIsMigrating(isLegacy)
    } catch (e) {
      handleApiError(e)
    }
  }

  const handleEnterEmail = async (email: string) => {
    startApiRequest()
    try {
      const { status } =
        await mst.apiClient.getUserStatusForForgotPassword(email)
      if (status === EMAIL_MISSING) {
        setServerError(EMAIL_MISSING)
        setLoading(false)
      } else {
        await submitStytchEmail(email, status === 'LEGACY')
      }
    } catch (e) {
      handleApiError(e)
    } finally {
      setLoading(false)
    }
  }

  const isRateLimited = (e: unknown) => {
    const { code } = parseServerError(e)
    if (code === knownCodes.TOO_MANY_REQUESTS) {
      showError(serverErrorMessages.tooManyRequests)
      return true
    }
    return false
  }

  const handleSubmitVerificationCode = async (code: string) => {
    startApiRequest()
    try {
      const { token } = await mst.apiClient.validateOneTimePasscode({
        oneTimePasscodeId,
        code,
      })
      setToken(token)
      setPhase('create-password')
    } catch (e) {
      if (!isRateLimited(e)) handleApiError(e)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitNewPassword = async ({ email, password }: FormValues) => {
    startApiRequest()
    try {
      const opts = { email, password, token, isMigrating }
      await mst.apiClient.completePasswordReset(opts)
      await mst.initializeAuthenticatedUser()
      navigate(pathTo(ROUTE_PATTERNS.root))
    } catch (e) {
      if (!isRateLimited(e)) handleApiError(e)
    } finally {
      setLoading(false)
    }
  }
  return (
    <ForgotPasswordPage
      loading={loading}
      serverError={serverError}
      onSubmitEmail={handleEnterEmail}
      onSubmitOtp={handleSubmitVerificationCode}
      onSubmitNewPassword={handleSubmitNewPassword}
      onReset={() => {
        setPhase('submit-email')
        setServerError(undefined)
        setIsMigrating(false)
      }}
      phase={phase}
      variant={variant}
    />
  )
}

export const ForgotPasswordRoute = () => <ForgotPasswordRouteInternal />

export const UpdatePasswordRoute = () => (
  <ForgotPasswordRouteInternal variant="update" />
)
