import { EditorState, TextSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { util as codexUtil, MarkTypeMap } from '@showrunner/codex'
import { schema } from '@showrunner/prose-schemas'

import { EditorManager } from '@choo-app/lib/editor/EditorManager'
import { blockInfoPluginKey } from '@choo-app/lib/editor/plugins/block-info'
import { commentsPluginKey } from '@choo-app/lib/editor/plugins/comments'
import { getConfigData } from '@choo-app/lib/editor/plugins/configData'
import {
  docInfoPluginKey,
  DocInfoState,
} from '@choo-app/lib/editor/plugins/doc-info'
import {
  getSelectionBlock,
  getSelectionBlockType,
  isSelectionTooBig,
  isSingleBlockTextSelection,
  shouldDisableAlignment,
} from '@util'
import { isNavLinkElement, NavLinkData } from '@util/constants'

import { BaseModel } from './BaseModel'

export const PmEditor = BaseModel.named('PmEditor')
  .props({
    editingHyperlink: false,
    // we track when users lose permission to edit the script during a session
    // to avoid prompting about unsaved edits from useAppUnloadHandler
    editabilityLost: false,
  })
  .volatile<{
    editorManager: EditorManager | null
    editorState: EditorState | null
  }>(() => ({
    editorManager: null,
    // we could get the editorState via the editorManager, but instead we
    // store it separately and re-set it any time the editorState changes so
    // it becomes observable
    editorState: null,
  }))
  .views((self) => ({
    get editorView(): EditorView | null {
      return self.editorManager?.view ?? null
    },
    get configData() {
      return self.editorState ? getConfigData(self.editorState) : null
    },
  }))
  .views((self) => ({
    get docInfo(): DocInfoState | null {
      if (self.editorState) {
        return docInfoPluginKey.getState(self.editorState) ?? null
      }
      return null
    },
    get navLinks(): NavLinkData[] {
      return self.editorState
        ? (blockInfoPluginKey
            .getState(self.editorState)
            ?.filter((b) => isNavLinkElement(b.type)) as NavLinkData[]) ?? []
        : []
    },
    get commentInventory() {
      if (self.editorState) {
        return commentsPluginKey.getState(self.editorState)
      }
    },
    get selection() {
      return self.editorState?.selection
    },
    getSelectionTiming(readRate: number) {
      if (this.selection && this.selection.content().size > 0) {
        return new codexUtil.FragmentBreakdown({
          fragment: this.selection.content().content,
          readRate,
        }).timing
      }
    },
    get docTiming() {
      return this.docInfo?.timing
    },
    get textSelection(): TextSelection | undefined {
      return self.editorState?.selection instanceof TextSelection
        ? self.editorState.selection
        : undefined
    },
    get selectionTouchesEndOfBlock(): boolean {
      if (!this.selection) return false
      const { $from, $to } = this.selection
      if (!$from.parent.isBlock) return false
      if ($to.parentOffset === $to.parent.content.size) return true
      return false
    },
    get singleBlockTextSelection(): boolean {
      if (self.editorState === null) return false
      return isSingleBlockTextSelection(self.editorState)
    },
    get selectionBlockType() {
      if (self.editorState === null) return
      if (!this.singleBlockTextSelection) return
      return getSelectionBlockType(self.editorState)
    },
    /**
     * attempt to pluck the relevant attribute from
     * plain cursors and text selections spanning a single block
     * otherwise return null
     *
     * @returns {string|undefined} - 'right' | 'center' | 'left' | undefined
     */
    get selectionAlignment(): string | undefined {
      if (self.editorState === null) return
      if (!this.singleBlockTextSelection) return
      return getSelectionBlock(self.editorState)?.attrs?.alignment
    },
    get disableAlignment(): boolean {
      if (self.editorState === null) return true
      return shouldDisableAlignment(self.editorState)
    },
  }))
  .views((self) => ({
    selectionContainsMark(name: string): boolean {
      const markType = schema.marks[name]
      const { textSelection, editorState } = self
      if (editorState && markType && textSelection) {
        const { from, $from, to, empty } = textSelection
        if (empty) {
          return !!markType.isInSet(editorState.storedMarks || $from.marks())
        } else {
          return editorState.doc.rangeHasMark(from, to, markType)
        }
      }
      return false
    },
    get canAddComment(): boolean {
      return !!self.selection && !self.selection.empty
    },
    get hasUnsavedComment(): boolean {
      return !!self.commentInventory?.unsavedComment
    },
    get canFormatText(): boolean {
      return self.editorState && self.editorView
        ? self.editorView.editable &&
            !!self.selection &&
            !isSelectionTooBig(self.editorState)
        : false
    },
    get hasModifiableSelectedText(): boolean {
      if (self.editorState && self.editorView) {
        return this.canFormatText && !self.selection?.empty
      }
      return false
    },
    get hyperlinkingEnabled(): boolean {
      if (!this.hasModifiableSelectedText) {
        return false
      }
      if (!self.singleBlockTextSelection) {
        return false
      }
      // check there's no overlapping hyperlink
      return !this.selectionContainsMark(MarkTypeMap.LINK)
    },
    get selectionContainsFormattingMark(): boolean {
      return (
        this.selectionContainsMark(MarkTypeMap.STRONG) ||
        this.selectionContainsMark(MarkTypeMap.EM) ||
        this.selectionContainsMark(MarkTypeMap.UNDERLINE) ||
        this.selectionContainsMark(MarkTypeMap.STRIKE)
      )
    },
  }))
  .actions((self) => ({
    setEditingHyperlink(value: boolean) {
      self.editingHyperlink = value
    },
    setEditorManager(value: EditorManager | null) {
      self.editorManager = value
      self.editorState = value?.view?.state ?? null
    },
    syncEditorView() {
      const state = self.editorManager?.view?.state ?? null
      self.editorState = state
    },
    focusEditor() {
      if (self.editorView) {
        self.editorView.focus()
        this.syncEditorView()
      }
    },
    rerender() {
      if (self.editorView) {
        const { tr } = self.editorView.state
        tr.setMeta('choo', true)
        self.editorView.dispatch(tr)
      }
    },
    selectPosition(pos: number) {
      if (self.editorView) {
        try {
          // this throws if pos is out of range
          const position = self.editorView.state.doc.resolve(pos)
          const selection = TextSelection.findFrom(position, 1, true)
          if (selection) {
            self.editorView.dispatch(
              self.editorView.state.tr.setSelection(selection),
            )
            this.focusEditor()
          }
        } catch {
          // noop
        }
      }
    },
    setEditabilityLost(val: boolean) {
      self.editabilityLost = val
    },
  }))
