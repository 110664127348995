import cn from 'classnames'
import { Node as PmNode } from 'prosemirror-model'

import { BlockFormats } from '@util/formats'
import { ScriptJson } from '@util/ScriptoApiClient/types'
import { ScriptPrintPreferences } from '@util/zodSchemas/printPreferences'

import { buildTwoColumnStyles } from './buildTwoColumnStyles'
import { isTwoColumnRow, Page, pmNodeToHtml, Row } from './helpers'
import { TwoColumnBreakdown } from './TwoColumnBreakdown'

// little component to let prosemirror render the "inside" of
// a block (but not the o-element o-<blocktype> part)
const PmNodeComponent = ({ pmNode }: { pmNode?: PmNode }) => {
  if (pmNode) {
    const html = pmNodeToHtml(pmNode)
    if (typeof html === 'string') {
      return (
        <div
          className={pmNode.type.name}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )
    }
  }
  return null
}

const RowComponent = ({ row }: { row: Row }) => {
  if (isTwoColumnRow(row)) {
    return (
      <div className="two-column-row">
        <div className="left-cell">
          <PmNodeComponent pmNode={row.bracket} />
        </div>
        <div className="right-cell">
          {row.rightNodes.map((rightNode, index) => (
            <PmNodeComponent pmNode={rightNode} key={index} />
          ))}
        </div>
      </div>
    )
  }
  return (
    <div className="one-column-row">
      {row.fullNodes.map((node, index) => (
        <PmNodeComponent key={index} pmNode={node} />
      ))}
    </div>
  )
}

const TwoColumnPage = ({ page }: { page: Page }) => (
  <div
    className={cn('two-column-page', {
      'restart-numbering': page.number === 1,
    })}
  >
    {page.rows.map((row, index) => (
      <RowComponent row={row} key={index} />
    ))}
  </div>
)

export type TwoColumnScriptProps = {
  scriptJson: ScriptJson
  blockFormats: BlockFormats
  prefs: ScriptPrintPreferences
}

export const TwoColumnScript = ({
  scriptJson,
  blockFormats,
  prefs,
}: TwoColumnScriptProps) => {
  const { breakdown } = new TwoColumnBreakdown({ scriptJson })

  return (
    <div
      className="two-column-script"
      style={buildTwoColumnStyles({
        blocks: blockFormats,
        prefs,
      })}
    >
      {breakdown.map((page, index) => (
        <TwoColumnPage page={page} key={index} />
      ))}
    </div>
  )
}
