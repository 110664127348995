import { Node as PmNode } from 'prosemirror-model'

import { BlockFormats } from '@util/formats'

import { extractBlockInfo } from './helpers'
import { PaginationBreakdown as PaginationBreakdownImpl } from './PaginationBreakdown'

// This is hard-coded today but we're passing as a parameter
// so we can change page sizes/margins/line heights in the future
const MAX_LINES_PER_PAGE = 54

export const createPaginationBreakdown = (
  doc: PmNode,
  blockFormats: BlockFormats,
) => {
  const blocks = extractBlockInfo({ doc, blockFormats })
  return new PaginationBreakdownImpl({
    blocks,
    maxLines: MAX_LINES_PER_PAGE,
  })
}

export type PaginationBreakdown = ReturnType<typeof createPaginationBreakdown>
