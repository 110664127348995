import {
  CommentHistoryPayload,
  CommentMeta,
  CommentWithReplies,
} from '../types'

import { ApiConfig } from './types'

type CommentHistoryParams = { scriptId: string }
type GetCommentParams = { scriptId: string; commentId: string }
type EditCommentParams = { scriptId: string; commentId: string; text: string }
type CommentThreadParams = { scriptId: string; commentIds: string[] }
type CreateCommentParams = {
  scriptId: string
  text: string
  parentId?: string
  snippet?: string
}

type CreateThreadParams = {
  id: string
  scriptId: string
  text: string
  snippet: string
}

type AddCommentToThreadParams = {
  scriptId: string
  text: string
  parentId: string
}

type SuccessPayload = { status: 'success' }

export const getComment: ApiConfig<GetCommentParams, CommentWithReplies> = ({
  scriptId,
  commentId,
}) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/comments/${commentId}`,
})

export const fetchCommentHistory: ApiConfig<
  CommentHistoryParams,
  CommentHistoryPayload
> = ({ scriptId }) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/comments/history`,
})

export const getCommentCounts: ApiConfig<
  CommentHistoryParams,
  Record<string, number>
> = ({ scriptId }) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/comments/counts`,
})

export const getCommentThreads: ApiConfig<
  CommentThreadParams,
  CommentWithReplies[]
> = ({ scriptId, commentIds }) => ({
  method: 'GET',
  url:
    `/scripts/${scriptId}/comments/` +
    (commentIds && commentIds.length
      ? `?ids[]=${commentIds.join('&ids[]=')}`
      : ''),
})

// TODO: remove this this once we deprecate the old comments plugin.
// This is an ambiguous method that lets us create a new comment thread
// OR add a comment to an existing thread
export const createCommentOrThreadDEPRECATED: ApiConfig<
  CreateCommentParams,
  Omit<CommentMeta, 'creator'>
> = ({ scriptId, text, parentId, snippet }) => ({
  method: 'POST',
  url:
    `/scripts/${scriptId}/comments` + (parentId ? `/${parentId}/replies` : ''),
  data: snippet ? { text, snippet } : { text },
})

export const createCommentThread: ApiConfig<
  CreateThreadParams,
  Omit<CommentMeta, 'creator'>
> = ({ scriptId, id, text, snippet }) => ({
  method: 'POST',
  url: `/scripts/${scriptId}/comments`,
  data: { id, text, snippet },
})

export const addCommentToThread: ApiConfig<
  AddCommentToThreadParams,
  Omit<CommentMeta, 'creator'>
> = ({ scriptId, text, parentId }) => ({
  method: 'POST',
  url: `/scripts/${scriptId}/comments/${parentId}/replies`,
  data: { text },
})

export const editComment: ApiConfig<EditCommentParams, CommentMeta> = ({
  scriptId,
  commentId,
  text,
}) => ({
  method: 'PUT',
  url: `/scripts/${scriptId}/comments/${commentId}`,
  data: { text },
})

export const resolveComment: ApiConfig<GetCommentParams, SuccessPayload> = ({
  scriptId,
  commentId,
}) => ({
  method: 'PUT',
  url: `/scripts/${scriptId}/comments/${commentId}/resolve`,
})

export const unresolveComment: ApiConfig<GetCommentParams, SuccessPayload> = ({
  scriptId,
  commentId,
}) => ({
  method: 'PUT',
  url: `/scripts/${scriptId}/comments/${commentId}/unresolve`,
})

/*
  the response is kind of nuts...

  replyCount is the number of non-deleted non-root comments in a thread. If you create a thread+comment then
  replyCount is 0.  If you then ADD a reply, then reply count is 1 (two comments, root + 1 reply).

  replyCount is ONLY non-deleted replies and ONLY counts not-the-parent

  When deleting a comment, we need to figure out whether to delete the comment mark in the script. If the comment
  you deleted is a root comment, then if replyCount = 0 you want to remove the mark. But if you delete a non-root
  comment you need to know if the parent was deletedAt AND if the replyCount is 0

  If you call deleteComment on a root comment, the value of parentDeleted is false. If you call it on a non-root
  comment, then it's either null or the value of the deletedAtColumn in the database for the parent.
*/
export const deleteComment: ApiConfig<
  GetCommentParams,
  {
    status: 'success'
    replyCount: number
    parentDeleted: boolean | null | string
  }
> = ({ scriptId, commentId }) => ({
  method: 'DELETE',
  url: `/scripts/${scriptId}/comments/${commentId}`,
})
