import { SegmentedControl as SegmentedControlComponent } from '@mantine/core'

import styles from './segmented-control.module.scss'

export const SegmentedControl = SegmentedControlComponent.extend({
  defaultProps: {
    size: 'xs',
  },
  classNames: {
    control: styles.control,
    indicator: styles.indicator,
    label: styles.label,
    root: styles.root,
  },
})
