import { Button, Card, Group, Stack } from '@mantine/core'

import { showAlert } from '@components/Modals'
import { FormError } from '@components/Onboarding/FormErrors'
import { PasswordInput, useStrongPasswordForm } from '@components/PasswordInput'
import { Toast } from '@components/Toast'
import { useMst } from '@state'
import * as formUtil from '@util/formUtil'
import { parseServerError } from '@util/parseServerError'

import styles from './ChangePassword.module.scss'

type FormValues = {
  oldPassword: string
  newPassword: string
}

export const ChangePassword = () => {
  const { apiClient } = useMst()

  const { form, passwordStrength } = useStrongPasswordForm<FormValues>({
    passwordField: 'newPassword',
    validateInputOnBlur: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validate: {
      oldPassword: formUtil.validateNotEmpty,
      newPassword: formUtil.validateNotEmpty,
    },
  })

  const handleSubmit = async ({ oldPassword, newPassword }: FormValues) => {
    try {
      await apiClient.changeStytchPassword({
        oldPassword,
        newPassword,
      })
      form.reset()
      showAlert({
        title: 'Success',
        children: 'Your password has been changed',
      })
    } catch (e) {
      const { code, message } = parseServerError(e)
      showAlert({
        title: 'Error',
        children: (
          <Toast
            type="error"
            dismissable={false}
            message={<FormError code={code ?? message} />}
          />
        ),
      })
    }
  }

  return (
    <div className={styles.changePassword_page}>
      <h1>Change Password</h1>
      <Card>
        <form style={{ width: 300 }} onSubmit={form.onSubmit(handleSubmit)}>
          <Stack gap="md">
            <PasswordInput
              label="Current password"
              {...form.getInputProps('oldPassword')}
            />
            <PasswordInput
              label="New password"
              strengthInfo={passwordStrength}
              {...form.getInputProps('newPassword')}
            />
            <Group>
              <Button disabled={!form.isValid()} type="submit">
                Save changes
              </Button>
            </Group>
          </Stack>
        </form>
      </Card>
    </div>
  )
}
