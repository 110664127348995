import { useQuery } from '@tanstack/react-query'
import { v4 as isUUID } from 'is-uuid'
import { Redirect } from 'wouter'

import { Loader } from '@components/Loader'
import { InviteErrorPage, JoinWorkspacePage } from '@components/Onboarding'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util/pathConfigs'

const getInviteIdStatus = (
  inviteId: string | undefined,
): 'missing' | 'malformed' | 'valid' => {
  if (inviteId === undefined) {
    return 'missing'
  }
  if (!isUUID(inviteId)) {
    return 'malformed'
  }
  return 'valid'
}

/*
  /signup is a kind of way-station route that looks up a launch invitation and
  figures out what to do with it based on invitation status and other possibilities.
*/
export const SignupRoute = () => {
  const {
    location,
    apiClient,
    loggedIn,
    user,
    switchOrgAndRelaunch: switchOrg,
  } = useMst()
  const inviteId = location.getQueryParam('invite')
  const inviteIdStatus = getInviteIdStatus(inviteId)

  const inviteQuery = useQuery({
    queryFn: () => apiClient.getInvite(inviteId ?? ''),
    queryKey: ['getInvite', inviteId],
    // we only want the query to actually run if we've got a UUID
    enabled: inviteIdStatus === 'valid',
  })

  // if user uses /signup without a query param, redirect to dashboard or login
  if (inviteIdStatus === 'missing') {
    const to = loggedIn ? ROUTE_PATTERNS.root : ROUTE_PATTERNS.login
    return <Redirect to={to} />
  }

  if (inviteIdStatus === 'malformed' || inviteQuery.isError) {
    return (
      <InviteErrorPage
        inviteStatus="invalid"
        loggedInUserEmail={loggedIn ? user.email : undefined}
      />
    )
  }

  if (inviteQuery.isSuccess) {
    const { status } = inviteQuery.data

    // Some invites are valid but aren't invitations to join a workspace.
    if (!inviteQuery.data.group) {
      const to = loggedIn
        ? ROUTE_PATTERNS.createWorkspace
        : ROUTE_PATTERNS.createAccount
      return <Redirect to={to} />
    }

    // if the user already belongs to the workspace, we want to put them into
    // the dashboard
    const workspaceId = inviteQuery.data.group.key.replace('group:', '')

    const alreadyInCorrectOrg =
      loggedIn && user.selectedMembership?.orgId === workspaceId
    const userAlreadyBelongs =
      loggedIn && !!user.orgMemberships.find((m) => m.orgId === workspaceId)

    if (alreadyInCorrectOrg) {
      return <Redirect to={ROUTE_PATTERNS.root} replace />
    }
    if (userAlreadyBelongs) {
      // switch orgs if we're not set to that org. This will trigger an app reload
      if (user.selectedMembership?.orgId !== workspaceId) {
        switchOrg(workspaceId)
      }
      return <Loader fullScreen />
    }

    if (status === 'accepted') {
      return (
        <InviteErrorPage
          inviteStatus="accepted"
          loggedInUserEmail={loggedIn ? user.email : undefined}
        />
      )
    } else {
      // open invite
      if (loggedIn) {
        return <JoinWorkspacePage invite={inviteQuery.data} />
      } else {
        return (
          <Redirect
            to={`${ROUTE_PATTERNS.createAccount}?returnTo=${location.pathname}${location.search}`}
          />
        )
      }
    }
  }

  // The only case left is inviteQuery.isLoading
  return <Loader fullScreen />
}
