import { NavLinkData } from '@util/constants'
// Helper function that gets an array of nav links, a moving item and
// a target item and decides if the moving item can be placed above the
// target item. We use this to configure the valid drop target UI
export const canPlaceAbove = ({
  links,
  movingId,
  targetId,
}: {
  links: NavLinkData[]
  movingId: string
  targetId: string
}): boolean => {
  const movingIndex = links.findIndex((item) => item.id === movingId)
  const targetIndex = links.findIndex((item) => item.id === targetId)
  return (
    // don't allow a link to be moved to precede itself
    targetIndex !== movingIndex &&
    // don't allow a link to be moved to precede its current successor
    targetIndex !== movingIndex + 1
  )
}

type NavLinkCursorPosition = {
  link: NavLinkData
  placement: 'above' | 'on' | 'below'
}
export const findCurrentNavLink = ({
  cursorPos,
  visibleNavLinks,
}: {
  cursorPos: number
  visibleNavLinks: NavLinkData[]
}): NavLinkCursorPosition | null => {
  const firstLink = visibleNavLinks[0]
  if (firstLink) {
    if (firstLink.pos > cursorPos) {
      return {
        link: firstLink,
        placement: 'above',
      }
    }

    // find all nav links at or before the cursor position-- we want
    // to get the last one
    const precedingLinks = visibleNavLinks.filter((nl) => nl.pos <= cursorPos)
    const link = precedingLinks[precedingLinks.length - 1]
    const isWithinLink =
      link.pos <= cursorPos && cursorPos - link.pos < link.nodeSize
    const placement = isWithinLink ? 'on' : 'below'
    return {
      link,
      placement,
    }
  }
  return null
}

export const isDraggableType = (link: NavLinkData): boolean =>
  ['sceneHeading', 'slug'].includes(link.type)
