import React from 'react'

import { Group } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { useMst } from '@hooks'
import { SideBySide, SideBySideProps } from '@layouts/SideBySide'

import { AsteriskView, SideBySideView, StaticView } from './bodyViews'
import { SnapshotList } from './SnapshotList'

// We size side-by-side views based on the first child. by specifying min widths.
// For this view we want to ensure the two items below but we need to wait for render
// time to make that call (we need to know the total avaiable width)
const MIN_BODY_WIDTH = 400
const MAX_LIST_WIDTH = 600
const DEFAULT_LIST_WIDTH = 400

const getLimits = (availableWidth: number): SideBySideProps['limits'] => {
  // we set the body's min width to get the list's max width
  const bodyMin = Math.max(availableWidth - MAX_LIST_WIDTH, MIN_BODY_WIDTH)
  return {
    firstChild: {
      minWidth: bodyMin,
      minHeight: Infinity,
    },
    secondChild: {
      minWidth: 250,
      minHeight: Infinity,
    },
  }
}

export const HistoryAndBody = observer(function HistoryAndBody({
  scriptId,
  width,
}: {
  scriptId: string
  width: number
}) {
  const mst = useMst()
  const { currentView } = mst.view.snapshotLand
  const limits = getLimits(width)
  const historyWidth = mst.user.prefs.snapshotHistoryWidth ?? DEFAULT_LIST_WIDTH

  const bodyWidth = width - historyWidth
  const handleResize = (bodySize: { width?: number }) => {
    if (typeof bodySize.width === 'number') {
      mst.user.updatePreferences({
        snapshotHistoryWidth: width - bodySize.width,
      })
    }
  }

  return (
    <SideBySide
      layout="columns"
      limits={limits}
      preferredWidth={bodyWidth}
      onResizeEnd={handleResize}
    >
      <Group bg="gray.2" h="100%" align="flex-start" justify="center">
        {currentView === 'static' && <StaticView scriptId={scriptId} />}
        {currentView === 'diff' && <SideBySideView scriptId={scriptId} />}
        {currentView === 'asterisk' && <AsteriskView scriptId={scriptId} />}
      </Group>
      <SnapshotList scriptId={scriptId} />
    </SideBySide>
  )
})
