import { Plugin, PluginKey } from 'prosemirror-state'

import { IRoot } from '@state'

import { COMMENT_RE, stripComments } from './input-rules.js'

/**
 * Creates a new plugin to handle input rules specifically for ink scripts.
 * currently the custom logic is limited to ensuring that comments within paste payloads
 * are preserved when cut/pasted and stripped out otherwise.
 * @param {object} config - plugin config
 * @param {object} config.script - script object from backend
 * @param {string} config.script.type - script doc type
 * @return {Plugin} input rules plugin
 */
export function inkInputRulesPlugin({ mst }: { mst: IRoot }) {
  const cutComments = (): string[] | undefined => mst.currentScript?.cutComments

  // modified copy of inputRules that doesn't return true on handleTextInput
  // ref: https://github.com/ProseMirror/prosemirror-inputrules/blob/master/src/inputrules.js#L53-L83
  return new Plugin({
    key: new PluginKey('INK_INPUT_RULES'),
    props: {
      /**
       * Intercept paste, solely to reset cut comment ids
       * ref: https://prosemirror.net/docs/ref/#view.EditorProps.handlePaste
       * @param {EditorView} view - Prosemirror EditorView instance
       * @param {dom.Event} event - paste event
       * @param {Slice} _ - slice parsed by PM (ignored)
       */
      handlePaste() {
        // reset
        mst.currentScript?.clearCutComments()
        return false
      },
      /**
       * counterintuitively this function runs BEFORE handlePaste.
       *
       * ref: https://prosemirror.net/docs/ref/#view.EditorProps.transformPastedHTML
       * @param {string} html
       */
      transformPastedHTML(html) {
        const pmPaste = html && html.includes('data-pm-slice')
        /*
          if no comment marks were cut previously or one of the cut marks isnt present
          in the clipboard HTML now, strip the mark prior to paste (to avoid duplicates)
        */
        if (pmPaste) {
          const ids = cutComments()

          html =
            ids && ids.length === 0
              ? stripComments(html)
              : // first param is matched string, second param is first group in regex
                html.replace(
                  COMMENT_RE,
                  (match, id) =>
                    ids?.includes(id)
                      ? match // either return match unchanged
                      : '', // or strip relevant data attributes
                )
        }

        return html
      },
    },
  })
}
