import { useState } from 'react'

import { Avatar, Group, Select, Table, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { showAlert, showError } from '@components/Modals'
import { Spinner } from '@components/Spinner'
import { useMst } from '@state'
import { IOrgMember } from '@state/types'
import { getAvatarUrl } from '@util'

import { ROLES_DATA } from '../util'

import { MemberAction } from './MemberAction'

import styles from './MemberTableRow.module.scss'

export const MemberTableRow = observer(function MemberTableRow({
  member,
}: {
  member: IOrgMember
}) {
  const { user, environment } = useMst()
  const [value, setValue] = useState(member.role as string)
  const isContributor = user.isCurrentOrgContributor

  const updateUserRole = async (role: string | null) => {
    if (member.role === role || role === null) return
    try {
      setValue(role)
      role === 'Contributor' ? await member.demote() : await member.promote()
      showAlert({
        title: 'Success',
        children: 'Your changes have been saved',
      })
    } catch (e) {
      setValue(member.role)
      showError({ message: 'Failed to update user' })
    }
  }

  const displayRole =
    !isContributor && member.role !== 'Owner' ? (
      <Select
        data={ROLES_DATA}
        value={value}
        variant="default"
        onChange={(role) => updateUserRole(role)}
        withCheckIcon={false}
      />
    ) : (
      member.role
    )

  return (
    <Table.Tr>
      <Table.Td>
        {member.isChangingRole && <Spinner noOverlay={true} delayMs={300} />}
        <Group gap="sm">
          {member.name && (
            <Avatar
              size={40}
              src={getAvatarUrl(member.avatar, environment.config)}
              radius={40}
            />
          )}
          <div>
            {member.name && <Text fz={14}>{member.name}</Text>}
            {!isContributor && (
              <Text fz={14} c="dimmed">
                {member.email}
              </Text>
            )}
          </div>
        </Group>
      </Table.Td>
      <Table.Td>{displayRole}</Table.Td>
      <Table.Td className={styles.membertable_action}>
        <MemberAction member={member} />
      </Table.Td>
    </Table.Tr>
  )
})
