/*
  This is an inner component that can be used in different CellRenderers.

  It renders the drag handles, the checkbox for selecting a row and
  the link icon to the script

  NOTE: If you do something to change the width of this, we need to make corresponding
  updates in @components/RundownGrid/columns/columnTypes which controls the
  width of the column.
*/
import React from 'react'

import { Checkbox, Group } from '@mantine/core'
import cn from 'classnames'

import { FaIcon } from '@components/FaIcon'

import styles from './Controls.module.scss'

export const DRAG_HANDLE_ATTRIBUTE = 'data-drag-handle'

export type ControlsProps = {
  showEditControls: boolean
  onCheck: (e: React.MouseEvent<Element>) => void
  onMouseDownCapture: (e: React.MouseEvent<Element>) => void
  registerDragger: (elt: HTMLElement) => void
  checked: boolean | 'indeterminate'
  link?: React.ReactNode
}

export const Controls = ({
  showEditControls,
  onCheck,
  onMouseDownCapture,
  registerDragger,
  checked,
  link,
}: ControlsProps) => {
  const dragRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (dragRef.current) {
      dragRef.current.setAttribute(DRAG_HANDLE_ATTRIBUTE, 'true')
      registerDragger(dragRef.current)
    }
  })

  const ref = React.useRef<HTMLDivElement>(null)

  // if you click on the background of the controls cell, we don't want to select the cell
  // itself-- you probably just "missed" when trying to click the checkbox.
  const handleMouseDownOnContainer = (e: React.MouseEvent<HTMLDivElement>) => {
    // We only want to handle this if the click was on the background, otherwise
    // we'd be blocking the controls themselves
    if (e.nativeEvent.target === ref.current) {
      onMouseDownCapture(e)
      // if we're not trying to bring up the context menu, go ahead and toggle the
      // checkbox
      if (e.buttons === 1 && !e.ctrlKey && !e.metaKey) {
        onCheck(e)
      }
    }
  }

  // If the checkbox is checked we always want to display the checkbox
  // and drag handles. The hoverItem class toggles opacity on hover and should
  // only get applied if not checked
  const hoverItemClass = cn({
    [styles.controls_hoverItem]: !checked,
  })

  return (
    <Group
      onMouseDownCapture={handleMouseDownOnContainer}
      ref={ref}
      className={cn(styles.controls)}
      gap={5}
      style={{ height: '100%' }}
    >
      {showEditControls && (
        <FaIcon
          className={cn(hoverItemClass, styles.controls_dragHandle)}
          icon="fa-grip-dots-vertical"
          color="gray"
          ref={dragRef}
        />
      )}
      <Checkbox
        className={hoverItemClass}
        readOnly
        size="xs"
        checked={checked === true}
        indeterminate={checked === 'indeterminate'}
        onClick={onCheck}
        onMouseDownCapture={onMouseDownCapture}
      />
      {link}
    </Group>
  )
}
