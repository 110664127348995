import React from 'react'

import { Group } from '@mantine/core'

import { StatusBarActionIcon } from './StatusBarActionIcon'
import { StatusBarButton } from './StatusBarButton'
import { StatusBarFaActionIcon } from './StatusBarFaActionIcon'
import { StatusBarText } from './StatusBarText'

import styles from './StatusBar.module.scss'

export const StatusBar = ({ children }: { children: React.ReactNode }) => (
  <Group className={styles.statusBar} justify="space-between" wrap="nowrap">
    {children}
  </Group>
)

export const StatusBarSection = ({
  children,
}: {
  children?: React.ReactNode
}) => (
  <Group gap="sm" wrap="nowrap">
    {children}
  </Group>
)

StatusBar.ActionIcon = StatusBarActionIcon
StatusBar.Button = StatusBarButton
StatusBar.FaActionIcon = StatusBarFaActionIcon
StatusBar.Section = StatusBarSection
StatusBar.Text = StatusBarText
