import {
  EditorState,
  Plugin,
  PluginKey,
  TextSelection,
} from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { PluginFactory } from './types'

const key = new PluginKey('decorateSelection')

type PluginState = DecorationSet

export const decorateSelectionPlugin: PluginFactory = () => {
  const buildDecoSet = ({ selection, doc }: EditorState): DecorationSet => {
    if (selection.empty || !(selection instanceof TextSelection)) {
      return DecorationSet.empty
    }
    const selectionDecoration = Decoration.inline(
      selection.$from.pos,
      selection.$to.pos,
      {
        class: 'prose-selection',
      },
    )

    return DecorationSet.create(doc, [selectionDecoration])
  }

  return new Plugin<PluginState>({
    key,
    state: {
      init(_, editorState) {
        return buildDecoSet(editorState)
      },
      apply(tr, pluginState, _, newState) {
        // if the transaction changed the selection
        // we need to recreate the decoration
        if (tr.selectionSet) {
          return buildDecoSet(newState)
        }
        // otherwise just apply the mapping
        return pluginState.map(tr.mapping, tr.doc)
      },
    },
    props: {
      decorations(state) {
        return this.getState(state)
      },
    },
  })
}
