import {
  Divider,
  Group,
  MantineStyleProps,
  useMantineTheme,
} from '@mantine/core'
import cn from 'classnames'

import { CopyUrlButton, ToolbarButton } from './ToolbarButton'
import { ToolbarButtonGroup } from './ToolbarButtonGroup'
import { ToolbarTabs } from './ToolbarTabs'

import styles from './Toolbar.module.scss'

export const Toolbar = ({
  children,
  bottomBar,
  bg = '#FFF',
}: {
  bg?: MantineStyleProps['bg']
  children: React.ReactNode
  bottomBar?: boolean
}) => {
  return (
    <Group
      bg={bg}
      className={cn(styles.toolbar, {
        [styles.toolbar_bottom]: bottomBar,
      })}
    >
      {children}
    </Group>
  )
}

const ToolbarSection = ({
  position,
  children,
  className,
  bottomBar,
}: {
  position: 'left' | 'right'
  children: React.ReactNode
  className?: string
  bottomBar?: boolean
}) => (
  <Group
    justify={position}
    gap={6}
    className={cn(styles.toolbar___inner, className, {
      [styles.toolbar___innerRight]: position === 'right',
      [styles.toolbar___innerBottom]: bottomBar,
    })}
  >
    {children}
  </Group>
)

const ToolbarDivider = () => {
  const theme = useMantineTheme()
  return (
    <Divider
      color={theme.colors.gray[2]}
      orientation="vertical"
      className={styles.toolbar___divider}
    />
  )
}

Toolbar.Divider = ToolbarDivider
Toolbar.Button = ToolbarButton
Toolbar.Section = ToolbarSection
Toolbar.CopyUrlButton = CopyUrlButton
Toolbar.Tabs = ToolbarTabs
Toolbar.ButtonGroup = ToolbarButtonGroup
