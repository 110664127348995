import { keymap } from 'prosemirror-keymap'
import { EditorState } from 'prosemirror-state'

import { IRoot } from '@state'
import { retrieveCommentIds } from '@util'

import { selectAlmostAll } from '../editor-keymap/select-almost-all'

import { toggleCodeComment } from './codeComment'
import { indent, outdent } from './tabIndent'

export const inkKeymapPlugin = ({ mst }: { mst: IRoot }) => {
  const cutShortcut = (viewState: EditorState) => {
    mst.currentScript?.setCutComments(retrieveCommentIds(viewState))
    // carry on either way
    return false
  }

  return keymap({
    Tab: indent,
    'Shift-Tab': outdent,
    'Mod-/': toggleCodeComment,
    'Mod-a': selectAlmostAll,
    'Mod-x': cutShortcut,
  })
}
