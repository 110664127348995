import { observer } from 'mobx-react-lite'

import { Toolbar } from '@components/Toolbar'
import { Keys, useShortcuts } from '@hooks'
import { IPopulatedPmEditor } from '@state'
import {
  isSelectionUntimed,
  shouldEnableTimingExclusion,
  toggleTimingExclusion,
} from '@util'

export const ExcludeFromTimingButton = observer(
  function ExcludeFromTimingButton({
    observableEditor,
  }: {
    observableEditor: IPopulatedPmEditor
  }) {
    const { editorState, editorView } = observableEditor

    const { getItemProps } = useShortcuts({
      exclude: {
        keys: [Keys.CMD, Keys.SHIFT, 'X'],
        action: toggleExclude,
        disabled: !shouldEnableTimingExclusion(editorState),
      },
    })

    function toggleExclude() {
      const { state, dispatch } = editorView
      toggleTimingExclusion(state, dispatch)
    }

    const itemProps = getItemProps('exclude')
    const active = isSelectionUntimed(editorState)
    const tooltip = `Exclude from timing (${itemProps.shortcut})`

    return (
      <div className="o-icon o-icon--custom">
        <Toolbar.Button
          customIcon={<i className="icon-exclude-timing"></i>}
          tooltip={tooltip}
          onClick={toggleExclude}
          {...itemProps}
          active={!!active}
          focusEditor
        />
      </div>
    )
  },
)
