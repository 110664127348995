import React from 'react'

import { Flex, Space } from '@mantine/core'

import { showError } from '@components/Modals'
import { Paginator } from '@components/Paginator'
import { Spinner } from '@components/Spinner'
import { DatadogClient } from '@util/datadog'
import {
  GetPageParams,
  PageGetter,
  PageGetterFilter,
  PaginatedPayload,
} from '@util/ScriptoApiClient/types'

const ddLog = DatadogClient.getInstance()

export const PaginatedPayloadView = <P extends GetPageParams, R>(props: {
  getPage: PageGetter<P, R>
  pageSize: number
  initialFilter?: PageGetterFilter<P>
  // Signature for a component that takes an onChange prop to return filter values
  FilterView?: ({
    onChange,
    filter,
  }: {
    filter: PageGetterFilter<P>
    onChange: (value: PageGetterFilter<P>) => void
  }) => JSX.Element
  // Signature for a component that renders an array of results
  ResultView: ({ results }: { results: R[] }) => JSX.Element
}) => {
  const { pageSize, getPage, ResultView, FilterView, initialFilter } = props

  const [filter, setFilter] = React.useState<PageGetterFilter<P>>(
    initialFilter ?? {},
  )
  const [loading, setLoading] = React.useState(true)
  const [payload, setPayload] = React.useState<PaginatedPayload<R>>({
    total: 0,
    results: [],
    from: 0,
  })

  const getPayload = async (from: number, filterValue: PageGetterFilter<P>) => {
    setLoading(true)
    const args = { from, size: pageSize, ...filterValue } as P

    try {
      const result = await getPage(args)
      setPayload(result)
    } catch (e) {
      const errorMessage = 'An unexpected error occurred'
      ddLog.error(errorMessage, undefined, e)
      showError(errorMessage)
      setPayload({ results: [], total: 0, from: 0 })
    } finally {
      setLoading(false)
    }
  }

  const startIndex = payload.from
  const endIndex = payload.from + payload.results.length - 1

  const renderResults = () => {
    if (payload.results.length === 0) return 'no results'
    return <ResultView results={payload.results} />
  }

  const handlePageClick = (direction: 'left' | 'right') => {
    const newOffset =
      direction === 'left'
        ? Math.max(startIndex - pageSize, 0)
        : Math.min(startIndex + pageSize, payload.total - 1)
    getPayload(newOffset, filter)
  }

  const handleFilterChange = (value: PageGetterFilter<P>) => {
    setFilter(value)
    getPayload(0, value)
  }

  // passing handleFilterChange below results in an
  // endless loop of requests, even if handleFilterChange
  // and getPayload are memoized with useCallback
  React.useEffect(
    () => handleFilterChange({ ...initialFilter }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialFilter],
  )

  return (
    <Flex direction="column">
      {loading && <Spinner delayMs={300} />}
      {FilterView && (
        <FilterView filter={filter} onChange={handleFilterChange} />
      )}
      <Space h="md" />
      {!loading && renderResults()}
      {payload.results.length > 0 && (
        <Paginator
          from={startIndex + 1}
          to={endIndex + 1}
          total={payload.total}
          canPageBackward={startIndex > 0}
          canPageForward={endIndex < payload.total - 1}
          onClick={handlePageClick}
        />
      )}
    </Flex>
  )
}
