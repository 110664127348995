import { Toast } from '@components/Toast'

export const NoPermissions = () => (
  <div>
    <h1>Permissions</h1>
    <Toast
      type="neutral"
      dismissable={false}
      message={
        <>
          Upgrade your show plan for access to advanced permissions.&nbsp;
          <a
            href="http://help.scripto.live/en/articles/5430603-advanced-permissions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </>
      }
    ></Toast>
  </div>
)
