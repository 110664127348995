import { ColDef } from '@ag-grid-community/core'

import { IRundown } from '@state/types'
import { RundownLayoutType } from '@util/LocalPersistence'

import { buildControlsColumn, schemaColumnToColDef } from './columnTypes'

/*
  When a user loads a rundown onto the screen, several factors affect the ag-grid colDefs we
  feed to the grid. This function is responsible for merging these together to produce the
  prop for the grid:

  1. Whether or not the user can edit
  2. The available columns for that schema
  3. If set, any user preferences on width, column order etc. for that schema
*/

export const getConfigurableColDefs = ({
  rundown,
  layout,
}: {
  rundown: IRundown
  layout: RundownLayoutType
}): ColDef[] => {
  const savedColumnState =
    layout === 'screen' ? rundown.screenColumnPrefs : rundown.printColumnPrefs

  return rundown.schema.schema.columns.map((schemaColumn) => {
    const colDef = schemaColumnToColDef(schemaColumn)
    const { hide, width } = savedColumnState[colDef.colId] ?? {}
    if (hide) {
      colDef.hide = true
    }
    if (typeof width === 'number') {
      colDef.width = width
    }
    return colDef
  })
}

export const getPrintColumnDefs = ({
  rundown,
}: {
  rundown: IRundown
}): ColDef[] => {
  return getConfigurableColDefs({
    rundown,
    layout: 'print',
  }).map((column) => ({
    ...column,
    editable: false,
    wrapText: true,
  }))
}

export const getScreenColumnDefs = ({
  userCanEdit,
  rundown,
  wrapText,
}: {
  rundown: IRundown
  userCanEdit: boolean
  wrapText: boolean
}): ColDef[] => {
  const configurableColumns = getConfigurableColDefs({
    rundown,
    layout: 'screen',
  }).map((column) => ({
    ...column,
    editable: column.editable && userCanEdit,
    wrapText,
  }))

  return [buildControlsColumn(userCanEdit), ...configurableColumns]
}
