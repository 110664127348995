import React from 'react'

import { observer } from 'mobx-react-lite'

import {
  clearNewCommentMark,
  saveNewCommentMark,
} from '@choo-app/lib/editor/plugins/comments'
import { ICommentThread, ILoadedScript, useMst } from '@state'

import { CardWrapper } from './CardWrapper'
import * as Parts from './CommentParts'
import { useThreadContext } from './hooks'
import { ThreadBase } from './Thread'
import { OpenThreadProvider } from './ThreadContext'

const UnsavedPanelThread = observer(function NewThreadForm({
  thread,
  script,
}: {
  script: ILoadedScript
  thread: ICommentThread
}) {
  const { user, apiClient, doDebug } = useMst()
  const { editorView } = script.pmEditor
  const [text, setText] = React.useState('')

  const handleCancel = () => {
    if (editorView) {
      clearNewCommentMark(editorView)
    }
  }

  const handleSubmit = async () => {
    const trimmed = text.trim()
    if (trimmed.length === 0) {
      handleCancel()
    } else {
      await doDebug(5)
      await apiClient.createCommentThread({
        id: thread.id,
        scriptId: script.id,
        snippet: thread.snippet,
        text: trimmed,
      })
      script.commentData.deselectAll()
      const { editorView } = script.pmEditor
      if (editorView) {
        saveNewCommentMark(editorView)
      }
    }
  }

  return (
    <CardWrapper type="panel-thread" selected>
      <Parts.NewCommentForm
        mode="new-thread"
        placeholder="Add new comment..."
        avatar={user.avatar}
        text={text}
        setText={setText}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </CardWrapper>
  )
})

const SavedPanelThread = observer(function SavedPanelThread() {
  const { user } = useMst()
  const { threadSelected, thread, operations } = useThreadContext()

  return (
    <ThreadBase
      showSnippet
      selected={threadSelected}
      thread={thread}
      user={user}
      resolveOption="resolve"
      mode="panel"
      enableScrollTo
      operations={operations}
    />
  )
})

export const PanelThread = observer(function PanelThread(props: {
  script: ILoadedScript
  thread: ICommentThread
  selected?: boolean
}) {
  if (props.thread.rootComment.isUnsavedThread) {
    return <UnsavedPanelThread {...props} />
  }
  return (
    <OpenThreadProvider script={props.script} thread={props.thread}>
      <SavedPanelThread />
    </OpenThreadProvider>
  )
})
