import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Group, Radio, Space, Stack, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'

import { FaIcon } from '@components/FaIcon'
import { ReadonlyFolderList } from '@components/FolderExplorer/FolderList'
import { ModalShell } from '@components/Modals/ModalShell'
import { useModalControls } from '@components/Modals/useModalControls'
import {
  CreateScriptFromRundownParams,
  IOrg,
  IReadonlyFolderListState,
  useMst,
} from '@state'

import styles from './CreateNewScriptModal.module.scss'

type CreateScriptFormValues = {
  name: string
  formatId: string
}

type ModalProps = {
  workspace: IOrg
  folderListState: IReadonlyFolderListState
  onConfirm: (values: CreateScriptFromRundownParams) => Promise<void>
}

const ModalBase = ({ workspace, onConfirm, folderListState }: ModalProps) => {
  const mst = useMst()
  const controls = useModalControls()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const { studioFormat, screenplayFormat } = workspace

  const form = useForm<CreateScriptFormValues>({
    initialValues: {
      name: 'Untitled',
      formatId: studioFormat.id,
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  const handleConfirm = async () => {
    setErrorMessage('')
    setLoading(true)
    try {
      await mst.doDebug()
      await onConfirm({
        ...form.values,
        folderId: folderListState.selectedFolderId,
      })
      controls.onClose()
    } catch {
      setErrorMessage('Failed to create new script row')
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalShell
      title="Insert new script"
      confirmLabel="Create new script"
      cancelLabel="Cancel"
      size={450}
      onConfirm={handleConfirm}
      opened={controls.opened}
      onClose={controls.onClose}
      loading={loading}
      errorMessage={errorMessage}
    >
      <Stack gap={25}>
        <Stack gap={5}>
          <Text fw="bold">Location</Text>
        </Stack>
        <div className={styles.parentFolder___tree}>
          <ReadonlyFolderList folderListState={folderListState} />
        </div>
        <Stack gap={5}>
          <Radio.Group
            {...form.getInputProps('formatId')}
            label={<Text fw="bold">Script format</Text>}
            px={0}
          >
            <Space h={8} />
            <Stack gap="sm">
              <Group gap={50}>
                <Group>
                  <Radio value={studioFormat.id} />
                  <Group align="center" gap={8}>
                    <FaIcon
                      icon={studioFormat.icon as `fa-${string}`}
                      size="16"
                    />
                    <Text>{studioFormat.name}</Text>
                  </Group>
                </Group>
                <Group>
                  <Radio value={screenplayFormat.id} />
                  <Group align="center" gap={8}>
                    <FaIcon
                      icon={screenplayFormat.icon as `fa-${string}`}
                      size="16"
                    />
                    <Text>{screenplayFormat.name}</Text>
                  </Group>
                </Group>
              </Group>
            </Stack>
          </Radio.Group>
        </Stack>
        <TextInput
          data-autofocus
          {...form.getInputProps('name')}
          label="Title"
          mb={10}
          onFocus={(e) => e.target.select()}
        />
      </Stack>
    </ModalShell>
  )
}

const CreateNewScriptModal = NiceModal.create<ModalProps>(ModalBase)

export const showCreateNewScriptModal = (props: ModalProps) => {
  NiceModal.show(CreateNewScriptModal, props)
}
