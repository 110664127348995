import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useMst } from '@state'

const queryKey = ['staff.listFormats']
export const useStaffFormats = () => {
  const { scrapi } = useMst()
  const queryClient = useQueryClient()
  const formatsQuery = useQuery({
    queryFn: async () => {
      const result = await scrapi.staff.listFormats()
      if (result.status === 200) {
        return result.body.formats
      }
      throw new Error('Unexpected result', { cause: result })
    },
    queryKey,
  })

  const refresh = () => {
    queryClient.refetchQueries({ queryKey })
  }

  return {
    refresh,
    formatsQuery,
  }
}
