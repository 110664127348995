import { Slide, ToastContainer, ToastPosition } from 'react-toastify'

import { TOAST_HOST_ID } from './constants'
// we can use either of the two react-toastify style sets and modify, in ToastHost.scss
// there's a lot of different options styling this library:  https://fkhadra.github.io/react-toastify/how-to-style
import 'react-toastify/dist/ReactToastify.css'
// import 'react-toastify/dist/ReactToastify.minimal.css'

// here are our overrides
import './ToastHost.module.scss'

// docs on multi-container toast setup
// https://fkhadra.github.io/react-toastify/multi-containers
const ToastHost = ({
  id,
  position,
}: {
  id: string
  position: ToastPosition
}) => {
  return (
    <ToastContainer
      containerId={id}
      position={position}
      autoClose={false}
      closeOnClick={false}
      closeButton={false}
      transition={Slide}
      hideProgressBar
      limit={3}
    />
  )
}

export const ScriptToastHost = () => (
  <ToastHost id={TOAST_HOST_ID.SCRIPT} position="bottom-right" />
)

export const FolderExplorerToastHost = () => (
  <ToastHost id={TOAST_HOST_ID.FOLDER_EXPLORER} position="bottom-left" />
)
