import React from 'react'

import { Radio } from '@mantine/core'

import { showAlert } from '@components/Modals'
import { useMst } from '@state'
import { capitalize } from '@util'
import { extractTsRestSuccess } from '@util/extractTsRest'
import {
  OrgPayload,
  WorkspaceAccountStatus,
} from '@util/ScriptoApiClient/types'

const STATUS_DESCRIPTIONS: Record<WorkspaceAccountStatus, string> = {
  TRIAL: 'the default, this is a free-trial',
  PAID: 'a current paying client',
  LAPSED: 'a client that used to pay but is no longer in contract',
  INTERNAL: 'a workspace that is owned/managed by scripto (for demos, etc)',
}

export function AccountStatusForm({ org }: { org: OrgPayload }) {
  const mst = useMst()
  const [status, setStatus] = React.useState<WorkspaceAccountStatus>(
    org.accountStatus,
  )

  const onChange = async (accountStatus: WorkspaceAccountStatus) => {
    try {
      await extractTsRestSuccess(
        mst.scrapi.staff.updateWorkspace({
          params: { id: org.id },
          body: { accountStatus },
        }),
        200,
      )
      setStatus(accountStatus)
      showAlert({
        title: 'Success',
        children: 'Workspace account status updated successfully',
      })
    } catch (e) {
      showAlert({
        title: 'Error',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: (e as any).message,
      })
    }
  }

  return (
    <Radio.Group
      value={status}
      onChange={(val) => onChange(val as WorkspaceAccountStatus)}
    >
      {Object.entries(STATUS_DESCRIPTIONS).map(([key, val]) => (
        <Radio
          key={key}
          value={key}
          label={capitalize(key)}
          description={val}
        />
      ))}
    </Radio.Group>
  )
}
