import { ChangeEvent } from 'react'

import { Badge, Checkbox, Group, Stack, Text } from '@mantine/core'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { IPrompterSegment } from '@state/types'

import styles from './PushToPrompterModal.module.scss'

export const displayTitle = (segment: IPrompterSegment) => {
  const hasTitle = segment.slugTitle.length > 0
  return segment.isZerothSlug && !hasTitle
    ? 'BEFORE SLUG'
    : hasTitle
      ? segment.slugTitle.toUpperCase()
      : 'SLUG'
}

const PushBadge = ({
  hasChanged,
  hasBeenPushed,
}: {
  hasChanged: boolean
  hasBeenPushed: boolean
}) => {
  return hasBeenPushed && !hasChanged ? null : (
    <Badge className={styles.promptermodal_badge}>
      {hasBeenPushed ? 'Changed' : 'New'}
    </Badge>
  )
}

const subtitle = ({
  hasText,
  hasBeenPushed,
  hasChanged,
  formattedDate,
}: {
  hasText: boolean
  hasBeenPushed: boolean
  hasChanged: boolean
  formattedDate: string
}) => {
  if (!hasText) return 'No prompter content'
  if (!hasBeenPushed) return 'Never pushed'
  return hasChanged
    ? `Changed since last push at ${formattedDate}`
    : `Unchanged since last push at ${formattedDate}`
}

export const PrompterSegmentItem = observer(function PrompterSegmentItem({
  segment,
}: {
  segment: IPrompterSegment
}) {
  const {
    hasText,
    hasChanged,
    hasBeenPushed,
    isUnnamed,
    lastPushedAt,
    shouldPush,
  } = segment

  const formattedDate =
    hasBeenPushed && lastPushedAt !== null
      ? format(new Date(lastPushedAt), "M/d/yyyy 'at' h:mmaaa")
      : ''

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    segment.setShouldPush(event.target.checked)
  }

  return (
    <Group className={styles.promptermodal_checkgroup} wrap="nowrap">
      <Checkbox checked={shouldPush} onChange={handleChange} />
      <Stack gap={0} className={styles.promptermodal_checktitlestack}>
        <Group
          justify="space-between"
          className={styles.promptermodal_checktitlegroup}
        >
          <Text
            className={styles.promptermodal_text}
            fs={isUnnamed ? 'italic' : undefined}
            fw={hasChanged ? 600 : 400}
          >
            {displayTitle(segment)}
          </Text>
          <PushBadge hasChanged={hasChanged} hasBeenPushed={hasBeenPushed} />
        </Group>
        <Text className={styles.promptermodal_text} c="dimmed">
          {subtitle({ hasText, hasBeenPushed, hasChanged, formattedDate })}
        </Text>
      </Stack>
    </Group>
  )
})
