import { Tabs, Tooltip } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'
import { ButtonMode } from '@state/types'

import styles from './ToolbarTabs.module.scss'

export type TabItem = {
  id: string
  label: string
  icon: `fa-${string}`
}

type ToolbarTabsProps = {
  items: TabItem[]
  selectedTab: string | null
  onSelectTab?: (value: string | null) => void
  mode?: ButtonMode
}

export const ToolbarTabs = ({
  selectedTab,
  onSelectTab,
  items,
  mode,
}: ToolbarTabsProps) => {
  return (
    <Tabs
      value={selectedTab}
      onChange={onSelectTab}
      classNames={{
        tab: styles.toolbarTab,
        list: styles.toolbarTabsList,
      }}
    >
      <Tabs.List>
        {items.map((item) => (
          <Tooltip
            disabled={mode !== 'mini'}
            label={item.label}
            key={item.id}
            position="bottom"
            // this offset makes the tooltip match the position of toolbar buttons
            offset={-1}
          >
            <Tabs.Tab leftSection={<FaIcon icon={item.icon} />} value={item.id}>
              {mode !== 'mini' && item.label}
            </Tabs.Tab>
          </Tooltip>
        ))}
      </Tabs.List>
    </Tabs>
  )
}
