import { ReactNode, useRef } from 'react'

import { Stack, Title } from '@mantine/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { OrgPermissionMap } from '@showrunner/codex'

import {
  InvisibleFdxPicker,
  InvisibleFdxPickerHandle,
} from '@components/InvisibleFdxPicker/InvisibleFdxPicker'
import { Spinner } from '@components/Spinner'
import { useResourceCreation } from '@hooks/useResourceCreation'
import { useMst } from '@state'
import { IFolder, IOrg } from '@state/types'

import { CreateCard } from './CreateCard'

import styles from './Dashboard.module.scss'

const CreateHeader = ({ folder }: { folder?: IFolder | undefined }) => {
  const crumb: string | ReactNode = folder ? (
    <>
      in <em>{folder.displayName}</em>
    </>
  ) : (
    ''
  )
  return (
    <Title order={1} className={styles.dash_header}>
      Create New {crumb}
    </Title>
  )
}

export const CreateCards = observer(function CreateCards({
  breakpoint,
  workspace,
}: {
  breakpoint: string
  workspace: IOrg
}) {
  const creation = useResourceCreation('dashboard')

  const { user, view, folderMap } = useMst()
  const fdxPickerRef = useRef<InvisibleFdxPickerHandle>(null)

  const folder = folderMap.get(view.selectedFolderId)
  const { hasRundownsEnabled } = workspace

  const createDisabled = !folder || folder.belongsToTrashTree
  const createRundownDisabled =
    createDisabled ||
    !hasRundownsEnabled ||
    folder.isPrivate ||
    !user.currentOrgPermissions.includes(OrgPermissionMap.UPDATE_RUNDOWNS)

  const handleCreate = async (
    itemId: 'import' | 'screenplay' | 'variety' | 'rundown',
  ) => {
    if (!folder) {
      return
    }
    switch (itemId) {
      case 'import':
        return fdxPickerRef?.current?.launchPicker()
      case 'screenplay':
        return creation.createScript({
          folder,
          formatId: workspace.screenplayFormat.id,
        })
      case 'variety':
        return creation.createScript({
          folder,
          formatId: workspace.studioFormat.id,
        })
      case 'rundown':
        return creation.createRundown(folder)
    }
  }

  const cards = [
    {
      onClick: () => handleCreate('variety'),
      isDisabled: createDisabled,
      ...workspace.studioFormat,
    },
    {
      onClick: () => handleCreate('screenplay'),
      isDisabled: createDisabled,
      ...workspace.screenplayFormat,
    },
    {
      id: 'import',
      name: 'Import',
      description: 'Choose a .FDX file from your computer',
      icon: 'fa-upload',
      onClick: () => handleCreate('import'),
      isDisabled: createDisabled,
    },
  ]

  if (hasRundownsEnabled) {
    cards.push({
      id: 'rundown',
      name: 'Rundown',
      description:
        'For listing the running order of elements and technical cues',
      icon: 'fa-table',
      onClick: () => handleCreate('rundown'),
      isDisabled: createRundownDisabled,
    })
  }

  return (
    <>
      <Stack gap={0}>
        <CreateHeader folder={view.currentFolder} />
        <div
          className={cn(styles.dash_buttonrow, breakpoint, {
            [styles.dash_buttonrow___four]: hasRundownsEnabled,
          })}
        >
          {cards.map((c) => (
            <div key={c.id}>
              <CreateCard
                name={c.name}
                description={c.description}
                icon={c.icon}
                onClick={c.onClick}
                isDisabled={c.isDisabled}
              />
            </div>
          ))}
          {folder && <InvisibleFdxPicker folder={folder} ref={fdxPickerRef} />}
        </div>
      </Stack>
      {creation.isCreating && <Spinner delayMs={300} fullScreen />}
    </>
  )
})
