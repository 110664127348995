/*
  Serializers for prose docs/fragments.

  TIP: For working on the clipboard, this tool is super handy (you can
  see the different clipboards: plain text, html, etc)
    https://langui.net/clipboard-viewer/

*/

import { DOMSerializer, Fragment, Slice } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import { schema } from '@showrunner/prose-schemas'

import { getConfigData } from '@choo-app/lib/editor/plugins/configData'
import { buildClipboardStyleString } from '@layouts/Script/buildFormatStyles'
import { FormatBlockName, ScriptFormatConfiguration } from '@util/formats'

const standardSerializer = DOMSerializer.fromSchema(schema)

export const getTextClipboardSerializer = (editorView: EditorView) => {
  const shouldUppercase = (nodeType: string) => {
    const blocks = getConfigData(editorView.state).currentBlockFormats
    const blockFormat = blocks[nodeType as FormatBlockName]
    return blockFormat?.uppercase
  }

  return (slice: Slice) => {
    const parts: string[] = []
    slice.content.descendants((node) => {
      if (node.isTextblock) {
        const text = node.textContent
        if (shouldUppercase(node.type.name)) {
          parts.push(text.toUpperCase())
        } else {
          parts.push(text)
        }
      }
    })

    return parts.join('\n')
  }
}

// prevent the auto-margin padding that <p> tags typically get
// and stop empty blocks from collapsing (doesn't work for google Drive)
const BLOCK_STYLES = `
.o-block-element {
  margin: 0;
  min-height: 1em;
}
`

const createStyleElement = (
  blocks: ScriptFormatConfiguration['blocks'],
): Element => {
  // generate format specific styles
  const template = document.createElement('template')
  template.innerHTML = `
    <style>
      ${BLOCK_STYLES}
      ${buildClipboardStyleString(blocks)}
    </style>`
  return template.content.children[0]
}

export class ScriptFormatClipboardSerializer extends DOMSerializer {
  private readonly editorView: EditorView

  constructor(ev: EditorView) {
    super(standardSerializer.nodes, standardSerializer.marks)
    this.editorView = ev
  }

  serializeFragment(
    fragment: Fragment,

    options: { [key: string]: unknown } | undefined,
  ) {
    const { currentBlockFormats } = getConfigData(this.editorView.state)
    const styleElement = createStyleElement(currentBlockFormats)
    const domFragment = standardSerializer.serializeFragment(fragment, options)
    domFragment.prepend(styleElement)

    return domFragment
  }
}
