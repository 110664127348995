import { CELL_VALUE_EXPORTER } from '@components/RundownGrid/columns/columnTypes'
import { IRundown } from '@state'
import { saveTextToFile } from '@util'
import { getBlobValue, RundownColumnType, RundownSchema } from '@util/rundowns'
import { RundownRowData } from '@util/ScriptoApiClient/types'

type RowPojo = {
  scriptId: string | null
  blockId: string | null
  [key: string]: unknown
}

const getScriptLink = ({ scriptId, blockId }: RowPojo): string | null => {
  if (!scriptId) {
    return null
  }
  const url = `${window.location.origin}/scripts/${scriptId}`
  const search = blockId ? `?block=${blockId}` : ''
  return url + search
}

const formatValue = (value: unknown, columnType: RundownColumnType) => {
  const formatter = CELL_VALUE_EXPORTER[columnType]
  if (formatter) {
    return formatter(value) ?? null
  }
  return value ?? null
}

const rowToPojo = (rowData: RundownRowData, schema: RundownSchema): RowPojo => {
  const { identityScriptId, linkedScriptId, blockId, rowTypeId } = rowData
  const result: RowPojo = {
    scriptId: identityScriptId ?? linkedScriptId,
    blockId: blockId,
    rowTypeId,
  }
  result.link = getScriptLink(result)
  schema.columns.forEach((col) => {
    const { colId, rundownColumnType, field } = col
    const value = getBlobValue({ data: rowData, field })
    const formattedValue = formatValue(value, rundownColumnType)
    result[colId] = formattedValue
  })
  return result
}

export const rundownToPojoArray = (rundown: IRundown): RowPojo[] => {
  const { schema, immutableRowData } = rundown
  return immutableRowData.map((rowData) => rowToPojo(rowData, schema.schema))
}

export const exportRundownAsJson = (rundown: IRundown, fileName: string) => {
  const contents = rundownToPojoArray(rundown)
  saveTextToFile({ text: JSON.stringify(contents, null, 2), fileName })
}
