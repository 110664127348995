import { Group, Text, Tooltip, UnstyledButton } from '@mantine/core'

import { FaIcon, FaIconName, FaIconSet } from '@components/FaIcon'

import styles from './ExplorerHeaderButton.module.scss'

export const ExplorerHeaderButton = ({
  tooltip,
  label,
  icon,
  iconSet,
  onClick,
  dropdownCaret,
}: {
  label?: string
  tooltip?: string
  onClick?: () => void
  icon?: FaIconName
  iconSet?: FaIconSet
  dropdownCaret?: boolean
}) => (
  <Tooltip position="bottom" label={tooltip ?? ''} disabled={!tooltip}>
    <UnstyledButton onClick={onClick} className={styles.explorerHeaderButton}>
      <Group gap={2}>
        {icon && (
          <FaIcon size="15" faIconSet={iconSet} icon={icon} fixedWidth />
        )}
        {label && <Text size="16">{label}</Text>}
        {dropdownCaret && <FaIcon size="13" icon="fa-caret-down" />}
      </Group>
    </UnstyledButton>
  </Tooltip>
)
