import React from 'react'

import { Text } from '@mantine/core'
import axios from 'axios'

import { useMst } from '@state'
import { InvitePayload } from '@util/ScriptoApiClient/types'

import { OnboardingPage } from './OnboardingPage'

type ServerErrorCode = 'inviteAccepted' | 'unknown'
type JoinWorkspacePageProps = {
  onSubmit: () => Promise<void>
  workspaceName?: string
  userEmail: string
  serverError?: ServerErrorCode
  loading?: boolean
}

const serverErrorMessages: Record<ServerErrorCode, string> = {
  inviteAccepted: 'This invitation has already been accepted',
  unknown: 'An unknown server error occurred',
}

export const JoinWorkspace = ({
  onSubmit,
  workspaceName = 'Workspace',
  userEmail,
  serverError,
  loading,
}: JoinWorkspacePageProps) => {
  const errorMessage = serverError
    ? serverErrorMessages[serverError]
    : undefined

  return (
    <OnboardingPage title={`Join ${workspaceName}`} loading={loading}>
      <OnboardingPage.Buttons includedButtons={['logout']} />
      <OnboardingPage.Form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
        errorMessage={errorMessage}
      >
        <Text>
          You&apos;re logged in as <strong>{userEmail}</strong>
        </Text>
        <OnboardingPage.SubmitButton label="Join Workspace" />
      </OnboardingPage.Form>
      <OnboardingPage.Links types={['support']} />
    </OnboardingPage>
  )
}

export const JoinWorkspacePage = ({ invite }: { invite: InvitePayload }) => {
  const mst = useMst()
  const [error, setError] = React.useState<ServerErrorCode | undefined>()
  const [loading, setLoading] = React.useState(false)
  const inviteId = invite.key.replace('invite:', '')

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const result = await mst.apiClient.acceptWorkspaceInvite({
        inviteId,
      })
      // dig the orgId out of the invite payload to set it as a user
      // preference
      const orgId = result.group?.key.replace('group:', '')
      if (orgId) {
        mst.user.updatePreferences({ currentOrgId: orgId })
      }
      await mst.initializeAuthenticatedUser()
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 422) {
        setError('inviteAccepted')
      } else {
        setError('unknown')
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <JoinWorkspace
      userEmail={mst.user.email}
      workspaceName={invite.group?.name}
      loading={loading}
      serverError={error}
      onSubmit={handleSubmit}
    />
  )
}
