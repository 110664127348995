import { observer } from 'mobx-react-lite'
import { DefaultParams, Route, RouteProps } from 'wouter'

import { NotFoundPage } from '@components/ErrorPage'
import { useMst } from '@state'
import { IRoot } from '@state/types'

type GuardedRouteExtraProps = {
  guard: (root: IRoot) => boolean
  fallback?: JSX.Element
}

export const GuardedRoute = observer(function GuardedRoute<
  T extends DefaultParams | undefined,
  RoutePath extends string = string,
>(props: RouteProps<T, RoutePath> & GuardedRouteExtraProps) {
  const { guard, fallback, component, ...routeProps } = props
  const root = useMst()
  const renderChildren = guard(root)

  return (
    <Route<T, RoutePath>
      {...routeProps}
      component={renderChildren ? props.component : undefined}
    >
      {renderChildren ? props.children : fallback ?? <NotFoundPage />}
    </Route>
  )
})
