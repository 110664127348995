import React from 'react'

import cn from 'classnames'

import styles from './PagelessWrapper.module.scss'

export const PagelessWrapper = ({
  children,
  noWrap,
  isStructural,
}: {
  children: React.ReactNode
  noWrap?: boolean
  isStructural: boolean
}) => {
  return (
    <div className={cn(styles.pagelessScroller)} data-editor-scroller>
      <div
        className={cn(styles.pagelessContent, {
          [styles.noWrap]: noWrap,
          [styles.inlinePagination]: !isStructural,
          [styles.structuralPagination]: isStructural,
        })}
      >
        {children}
      </div>
    </div>
  )
}
