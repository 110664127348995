import React from 'react'

import { Group, ScrollArea, Stack, Text, UnstyledButton } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { commentDomHelpers, PanelThread } from '@components/Comments'
import { FaIcon } from '@components/FaIcon'
import { ILoadedScript } from '@state'

import styles from './CommentsPanel.module.scss'

// TBD if we make this adjustable
const PANEL_WIDTH = 350

// Kind of a FAB
const PanelCloseButton = ({ onClick }: { onClick: () => void }) => (
  <Group justify="space-between" className={styles.commentsPanel_header}>
    <Text fw="bold">Comments</Text>
    <UnstyledButton className={styles.commentsPanel_close} onClick={onClick}>
      <FaIcon size="16" c="dark" icon="fa-xmark" />
    </UnstyledButton>
  </Group>
)

export const CommentsPanel = observer(function CommentsPanel({
  script,
}: {
  script: ILoadedScript
}) {
  const ref = React.useRef<HTMLDivElement>(null)
  const hasScrolledRef = React.useRef(false)
  const { openCommentThreads, commentData } = script
  const showPlaceholder = openCommentThreads.length === 0
  const sortedComments = openCommentThreads.sort((a, b) => a.pos - b.pos)

  // when the panel is opened, if there's a selected thread
  // scroll to it
  React.useEffect(() => {
    if (ref.current && commentData.panelOpen && !hasScrolledRef.current) {
      hasScrolledRef.current = true
      const { selectedThreadId, selectedCommentId } = script.commentData
      if (selectedThreadId) {
        commentDomHelpers.scrollCommentPanel(
          selectedThreadId,
          selectedCommentId,
        )
      }
    }
  })

  React.useEffect(() => {
    if (!commentData.panelOpen) {
      hasScrolledRef.current = false
    }
  }, [commentData.panelOpen])

  if (!commentData.panelOpen) {
    return null
  }

  return (
    <Stack gap={0} className={styles.commentsPanel} pt={5} pb={5}>
      <PanelCloseButton onClick={commentData.closePanel} />
      <ScrollArea
        ref={ref}
        style={{
          width: PANEL_WIDTH,
        }}
        styles={{
          viewport: {
            scrollPadding: 80,
          },
        }}
      >
        <Stack gap={10} className={styles.commentsList}>
          {sortedComments.map((t) => (
            <PanelThread
              key={t.id}
              script={script}
              thread={t}
              selected={commentData.selectedThreadId === t.id}
            />
          ))}
          {showPlaceholder && (
            <Text px={10} mt={15} ta="center">
              No open comment threads
            </Text>
          )}
        </Stack>
      </ScrollArea>
    </Stack>
  )
})
