import React from 'react'

import { Menu, Text, UnstyledButton } from '@mantine/core'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import {
  showAsyncConfirmModal,
  showDuplicateRundownModal,
  showError,
} from '@components/Modals'
import { Spinner } from '@components/Spinner'
import { launchExplorerToast } from '@components/Toast'
import { TIME_DAY_YEAR_MASK } from '@hooks'
import { useMst } from '@state'
import { IRundownListing, IScriptListing } from '@state/types'
import { highlightFavoritesCollection } from '@util/animation'

import {
  getRundownListingMenuItems,
  getScriptListingMenuItems,
  launchMoveDocModal,
} from './helpers'

import styles from './DotDotDotMenu.module.scss'

export type DotDotDotItem = {
  id: string
  label: string
  subLabel?: string
  icon?: `fa-${string}` | 'unfavorite'
  type?: 'danger'
  dividerAbove?: boolean
  disabled?: boolean
  isHighlighted?: boolean
}

const DotDotDotIcon = ({ icon }: { icon: `fa-${string}` | 'unfavorite' }) => {
  if (icon === 'unfavorite') {
    return (
      <div style={{ position: 'relative' }}>
        <FaIcon icon="fa-star" faIconSet="fa-regular" />
        <div style={{ position: 'absolute', top: 1, left: 0 }}>
          <FaIcon icon="fa-slash" size="13" />
        </div>
      </div>
    )
  }
  return <FaIcon icon={icon} size="14" />
}

export const DotDotDotMenu = ({
  menuItems,
  onSelect,
  // so far we are displaying creation date info for scripts/rundowns but not folders
  bottomSection = null,
}: {
  menuItems: DotDotDotItem[]
  onSelect?: (item: DotDotDotItem) => void
  bottomSection?: React.ReactNode
}) => (
  // Note we need withinPortal so that the menu will cross the sidebar
  // otherwise it would take its position from the sidebar
  <Menu position="bottom-start" withinPortal width={200}>
    <Menu.Target>
      <UnstyledButton className={styles.wrapper}>
        <FaIcon icon="fa-ellipsis-v" />
      </UnstyledButton>
    </Menu.Target>
    <Menu.Dropdown>
      {menuItems.map((item) => (
        <Menu.Item
          disabled={item.disabled}
          color={item.type === 'danger' ? 'red' : undefined}
          key={item.id}
          leftSection={
            item.icon ? <DotDotDotIcon icon={item.icon} /> : undefined
          }
          onClick={() => onSelect?.(item)}
        >
          {item.label}
        </Menu.Item>
      ))}
      {bottomSection}
    </Menu.Dropdown>
  </Menu>
)

export const ScriptListingMenu = observer(function ScriptListingMenu({
  scriptListing,
}: {
  scriptListing: IScriptListing
}) {
  const { user, currentScript, currentOrg } = useMst()
  const [loading, setLoading] = React.useState(false)
  const menuItems = getScriptListingMenuItems({
    scriptListing,
    user,
  })

  const handleSelect = async (item: DotDotDotItem) => {
    switch (item.id) {
      case 'addFavorite':
        currentOrg?.addFavorite(scriptListing.id)
        highlightFavoritesCollection()
        break
      case 'removeFavorite':
        currentOrg?.removeFavorite(scriptListing.id)
        break
      case 'duplicate':
        try {
          setLoading(true)
          await scriptListing.duplicate()
        } catch (e) {
          showError({ message: 'Failed to duplicate script' })
        } finally {
          setLoading(false)
        }
        break
      case 'destroy':
        showAsyncConfirmModal({
          dangerous: true,
          title: 'Delete Permanently',
          confirmLabel: 'Delete',
          children: (
            <>
              <Text span>
                Are you sure you want to delete&nbsp;
                <strong>“{scriptListing.name}”</strong>?
              </Text>
              <Text>This can&apos;t be undone.</Text>
            </>
          ),
          onConfirm: scriptListing.destroy,
          errorMessage: `Failed to delete “${scriptListing.name}”`,
        })
        break
      case 'copylink':
        navigator.clipboard.writeText(
          `${window.location.origin}/scripts/${scriptListing?.id}`,
        )
        launchExplorerToast({
          message: 'Link copied to clipboard 🔗',
          type: 'success',
          autoCloseDelayMs: 3000,
        })
        break
      case 'trash':
        try {
          setLoading(true)
          await scriptListing.moveToTrash()
          launchExplorerToast({
            message: `Moved "${scriptListing.name}" to trash.`,
            type: 'success',
            autoCloseDelayMs: 3000,
          })
        } catch (e) {
          launchExplorerToast({
            message: `Couldn't move "${scriptListing.name}" to trash.`,
            type: 'error',
          })
        }
        setLoading(false)
        break
      case 'move':
      case 'restore':
      case 'share':
        launchMoveDocModal({
          listing: scriptListing,
          operation: item.id,
          isCurrentScript: scriptListing.id === currentScript?.id,
        })
        break
      case 'rename':
        scriptListing.setIsEditingName(true)
        break
      default:
        break
    }
  }

  return (
    <>
      <DotDotDotMenu
        menuItems={menuItems}
        onSelect={handleSelect}
        bottomSection={<CreationBlurb date={scriptListing.createdAt} />}
      />
      {loading && <Spinner delayMs={300} fullScreen />}
    </>
  )
})

const CreationBlurb = ({ date }: { date: Date }) => (
  <>
    <Menu.Divider />
    <Menu.Item disabled>
      <Text size="sm" c="dark">
        Created at&nbsp;{format(date, TIME_DAY_YEAR_MASK)}
      </Text>
    </Menu.Item>
  </>
)
export const RundownListingMenu = observer(function RundownListingMenu({
  rundownListing,
}: {
  rundownListing: IRundownListing
}) {
  const { user, currentOrg } = useMst()
  const [loading, setLoading] = React.useState(false)
  const menuItems = getRundownListingMenuItems({
    rundownListing,
    user,
  })

  const handleSelect = async (item: DotDotDotItem) => {
    switch (item.id) {
      case 'addFavorite':
        currentOrg?.addFavorite(rundownListing.uuid)
        highlightFavoritesCollection()
        break
      case 'removeFavorite':
        currentOrg?.removeFavorite(rundownListing.uuid)
        break
      case 'copylink':
        navigator.clipboard.writeText(
          `${window.location.origin}/rundowns/${rundownListing.id}`,
        )
        launchExplorerToast({
          message: 'Link copied to clipboard 🔗',
          type: 'success',
          autoCloseDelayMs: 3000,
        })
        break
      case 'trash':
        try {
          setLoading(true)
          await rundownListing.moveToTrash()
          launchExplorerToast({
            message: `Moved "${rundownListing.name}" to trash.`,
            type: 'success',
            autoCloseDelayMs: 3000,
          })
        } catch (e) {
          launchExplorerToast({
            message: `Couldn't move "${rundownListing.name}" to trash.`,
            type: 'error',
          })
        }
        setLoading(false)
        break
      case 'move':
      case 'restore':
        launchMoveDocModal({
          listing: rundownListing,
          operation: item.id,
        })
        break
      case 'rename':
        rundownListing.setIsEditingName(true)
        break
      case 'duplicate': {
        const scriptRowCount = await rundownListing.getAssociatedScriptCount()
        if (scriptRowCount > 0) {
          showDuplicateRundownModal({
            listing: rundownListing,
            scriptRowCount,
          })
          break
        }
        try {
          setLoading(true)
          await rundownListing.duplicate()
        } catch (e) {
          showError({ message: "Couldn't duplicate rundown." })
        } finally {
          setLoading(false)
        }
        break
      }
      default:
        break
    }
  }

  return (
    <>
      <DotDotDotMenu
        menuItems={menuItems}
        onSelect={handleSelect}
        bottomSection={<CreationBlurb date={rundownListing.createdAt} />}
      />
      {loading && <Spinner delayMs={300} fullScreen />}
    </>
  )
})
