import React from 'react'

import { Checkbox, Stack, Text } from '@mantine/core'

import { showMoveToFolder } from '@components/Modals'
import { IFolder, useMst } from '@state'

import { usePendingSearchContext } from '../useSearchContext'

import { FilterSelect } from './Select'

export const FolderPicker = ({
  folderFilters,
  setFolderFilters,
  setOpened,
  sharedRootFolder,
}: {
  folderFilters: { category: string; folderName: string | undefined }
  setFolderFilters: React.Dispatch<
    React.SetStateAction<{ category: string; folderName: string | undefined }>
  >
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  sharedRootFolder: IFolder
}) => {
  const mst = useMst()
  const context = usePendingSearchContext()
  const privateRootFolder = mst.getRootFolder(true, false)

  const { pendingCriteria, mergePendingCriteria } = context

  const categoryData = ['Any folder', 'Custom...']

  if (mst.currentOrg?.hasPrivateScriptsEnabled) {
    categoryData.splice(1, 0, 'Private folders only')
    categoryData.splice(1, 0, 'Shared folders only')
  }

  const handleFolderSelection = async (folderId: string) => {
    const category =
      folderId === sharedRootFolder.id
        ? 'Shared folders only'
        : folderId === privateRootFolder?.id
          ? 'Private folders only'
          : 'Custom...'

    setFolderFilters({
      category,
      folderName:
        category === 'Custom...'
          ? mst.view.getFolder(folderId)?.name
          : undefined,
    })

    mergePendingCriteria({ folderId })

    setOpened(true)
  }

  const isDefault = folderFilters.category === 'Any folder'

  return (
    <Stack gap={6}>
      <Text fw="bold" fz={15}>
        Location
      </Text>
      <FilterSelect
        data={categoryData}
        value={folderFilters.category}
        isSelected={!isDefault}
        onChange={(f) => {
          // regardless of the new val, reset trash inclusion pref
          mergePendingCriteria({ includeTrash: false })

          if (f === 'Any folder') {
            mergePendingCriteria({ folderId: undefined })
          }

          if (f === 'Shared folders only') {
            mergePendingCriteria({ folderId: sharedRootFolder.id })
          }

          if (f === 'Private folders only') {
            mergePendingCriteria({ folderId: privateRootFolder?.id })
          }

          if (f === 'Custom...') {
            showMoveToFolder({
              folderListState:
                mst.view.initializeReadonlyFolderState(sharedRootFolder),
              title: 'Choose a folder',
              description: '',
              onSubmit: handleFolderSelection,
              onCancel: () => {
                mergePendingCriteria({ folderId: undefined })
                setFolderFilters({
                  category: 'Any folder',
                  folderName: undefined,
                })
              },
            })
          }

          if (f) setFolderFilters({ category: f, folderName: undefined })
        }}
      />
      {folderFilters.folderName && (
        <Text fz={13}>
          selected folder:{' '}
          <Text inherit span fw="bold">
            {folderFilters.folderName}
          </Text>
        </Text>
      )}
      {isDefault && (
        <Checkbox
          m={5}
          label="Include documents in trash"
          size="sm"
          checked={pendingCriteria.includeTrash}
          onChange={() => {
            mergePendingCriteria({
              includeTrash: !pendingCriteria.includeTrash,
            })
          }}
        />
      )}
    </Stack>
  )
}
