/**
 *          IMPORTANT:  Do not use mantine components
 *
 *    This file is used at runtime to generate static html to send to
 *    docraptor. The mantine provider is not available
 *
 *    The components in this file renders html that the PrinceXML engine hoists into
 *    page regions. Each region we use when printing is associated with a <PageRegion>
 *    component. The regions get one or more <Slot> components (the slot has content
 *    like a Page Number or title, etc). and might get addition decoration like a
 *    line separating a set of slots from the page, etc.
 *
 *    see https://www.princexml.com/doc/paged/#page-regions
 *
 */
import cn from 'classnames'

import {
  RundownPrintPrefs,
  ScriptPrintPreferences,
  SlotConfig,
} from '@util/zodSchemas/printPreferences'

// given a SlotConfig, extract any css classes to be applied to the slot
const extractSlotClasses = (config?: SlotConfig): string[] => {
  const result: string[] = []
  if (config) {
    const { slotType, decoration } = config
    result.push(`slot-type-${slotType}`)
    if (slotType === 'static') {
      result.push(config.contentType)
    }
    if (decoration?.circled) {
      result.push('circled')
    }
  }
  return result
}

const extractSlotStyles = (config?: SlotConfig): React.CSSProperties => {
  const result: React.CSSProperties = {}
  if (config?.slotType === 'rundown-cell-value') {
    result['--slot-content'] = `var(--rundown-cell-${config.colId}`
  }
  if (config?.slotType === 'literal') {
    result['--slot-content'] = `'${CSS.escape(config.text)}'`
  }

  return result
}

// there are three slots in the top region, three in the
// bottom region and (coming soon) one in the top right corner.
const Slot = ({
  position,
  config,
}: {
  position?: 'left' | 'right' | 'center'
  config?: SlotConfig
}) => {
  const slotClasses = extractSlotClasses(config)
  const slotStyles = extractSlotStyles(config)

  return (
    <div
      style={slotStyles}
      className={cn('slot-wrapper', position, ...slotClasses, {
        large: config?.decoration?.large,
      })}
    >
      <span className="slot-content" />
    </div>
  )
}

// The three slots top and bottom affect each other positioning-wise,
// the three-slot wrapper manages that.
const ThreeSlotWrapper = ({
  position,
  left,
  right,
  center,
}: {
  position: 'header' | 'footer'
  left?: SlotConfig
  right?: SlotConfig
  center?: SlotConfig
}) => {
  return (
    <div
      className={cn('three-slot-grouping', position, {
        'has-center-content': center,
      })}
    >
      <Slot position="left" config={left} />
      <Slot position="center" config={center} />
      <Slot position="right" config={right} />
    </div>
  )
}

const CornerWrapper = ({ config }: { config?: SlotConfig }) => (
  <div className="corner-wrapper">
    <Slot config={config} />
  </div>
)

// used in the top and bottom regions to separate the three slot wrapper
// from the page content. Even when hidden, the divider is present for spacing
// (when not hidden, there's a line)
const Divider = ({
  position,
  hidden,
}: {
  position: 'header' | 'footer'
  hidden: boolean
}) => <hr className={cn('header-footer-divider', position, { hidden })} />

// The wrapper for an entire page region
const PageRegion = ({
  children,
  region,
  enabled,
}: {
  enabled?: boolean
  children: React.ReactNode
  region: 'top' | 'bottom' | 'top-right-corner'
}) => {
  if (!enabled) return null
  return <section className={cn('page-region', region)}>{children}</section>
}

export const ScriptHeaderAndFooter = ({
  prefs,
}: {
  prefs: ScriptPrintPreferences
}) => {
  const { headers, footers } = prefs

  return (
    <>
      <PageRegion region="top" enabled={headers.enabled}>
        <ThreeSlotWrapper position="header" {...headers.slots} />
        <Divider hidden={!headers.divider} position="header" />
      </PageRegion>

      <PageRegion
        region="top-right-corner"
        enabled={!!headers.slots.rightCorner}
      >
        <CornerWrapper config={headers.slots.rightCorner} />
      </PageRegion>

      <PageRegion region="bottom" enabled={footers.enabled}>
        <Divider hidden={!footers.divider} position="footer" />
        <ThreeSlotWrapper position="footer" {...footers.slots} />
      </PageRegion>
    </>
  )
}

const RUNDOWN_SLOT_CONFIGS: { [key: string]: SlotConfig } = {
  shortTitle: {
    slotType: 'static',
    contentType: 'short-title',
    decoration: { large: true },
  },
  timestamp: { slotType: 'static', contentType: 'timestamp' },
  pageNumbers: { slotType: 'static', contentType: 'page-number-total' },
}

// The rundown header and footer uses a canned set of slot configs PLUS
// it gets html for the rundown column names and puts that into the header
export const RundownHeaderAndFooter = ({
  gridHeaderHtml,
  prefs,
}: {
  gridHeaderHtml: string
  prefs: RundownPrintPrefs
}) => {
  return (
    <>
      <PageRegion region="top" enabled>
        <ThreeSlotWrapper
          position="header"
          left={RUNDOWN_SLOT_CONFIGS.shortTitle}
        />
        <div
          className={cn('rundown-header', prefs.rowDensity)}
          dangerouslySetInnerHTML={{ __html: gridHeaderHtml }}
        />
      </PageRegion>

      <PageRegion region="bottom" enabled>
        <div className="rundown-footer-space"></div>
        <ThreeSlotWrapper
          position="footer"
          left={RUNDOWN_SLOT_CONFIGS.timestamp}
          right={RUNDOWN_SLOT_CONFIGS.pageNumbers}
        />
      </PageRegion>
    </>
  )
}
