import { RundownRowData } from '@util/ScriptoApiClient/types'
import { PageLayout } from '@util/zodSchemas/printPreferences'

const rowDataToCssVars = (rundownRowData?: RundownRowData): string => {
  const parts: string[] = []
  if (rundownRowData) {
    Object.keys(rundownRowData.blobData).forEach((key) => {
      const value = rundownRowData.blobData[key]
      if (typeof value === 'string') {
        parts.push(`--rundown-cell-${key}: '${CSS.escape(value)}';`)
      }
    })
  }

  return parts.join('\n')
}

/*
  This generates the css variables for the margin sizes and header/footer content.
  Note that the header/footer css vars aren't necessarily used when printing, but the
  variables are generated.
*/
export const buildPageCssVars = ({
  layout,
  monochrome,
  title,
  timestamp,
  duration,
  rundownRowData,
}: {
  layout: PageLayout
  monochrome?: boolean
  title: string
  timestamp: string
  duration?: string
  rundownRowData?: RundownRowData
}) => {
  const { margins, orientation, size } = layout
  const escapedTitle = CSS.escape(title)
  const escapedTimestamp = CSS.escape(timestamp)
  const escapedDuration = duration ? CSS.escape(duration) : ''
  const linkColor = monochrome ? '--link-color: #000' : ''
  const pageVars = `
    :root {
      ${linkColor};
      --doc-title: '${escapedTitle}';
      --section-title: string(act-title, last) string(slug-title, last);
      --timestamp: '${escapedTimestamp}';
      --page-margins: ${margins.top} ${margins.right} ${margins.bottom} ${
        margins.left
      };
      --page-size: ${size.width} ${size.height} ${orientation};
      --duration: '${escapedDuration}';
      --rundown-slot-opacity: ${rundownRowData ? '1' : '0'};
      ${rowDataToCssVars(rundownRowData)}
    }
  `
  return pageVars
}
