import { Group, Stack, Text } from '@mantine/core'
import cn from 'classnames'
import { useRoute } from 'wouter'

import { FaIcon } from '@components/FaIcon'
import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'
import { useMst } from '@state'

// PushStateLink that checks the href to see if it's the current route
// and applies selected styles if so
const NavLink = ({ href, label }: { href: NavAnchorHref; label: string }) => {
  const [isActive] = useRoute(href)

  return (
    <NavAnchor
      href={href}
      className={cn('c-sidenavsection__link is-plain-link', {
        'is-selected': isActive,
      })}
    >
      {label}
    </NavAnchor>
  )
}

export const Sidenav = () => {
  const { user, currentOrg } = useMst()

  return (
    <div className="c-sidenav l-sidenav">
      <Stack>
        <div>
          <Stack gap="xs">
            <Text fw="bold">Your Account</Text>
            <Stack gap={0}>
              <NavLink href="/settings" label="Profile" />
              <NavLink href="/settings/security" label="Change Password" />
            </Stack>
          </Stack>
        </div>

        {currentOrg && (
          <div>
            <Stack gap="xs">
              <Group gap="xs">
                <Text fw="bold">{currentOrg.name}</Text>
                {user.isCurrentOrgAdmin && (
                  <FaIcon color="gray" icon="fa-star" />
                )}
              </Group>
              <Stack gap={0}>
                <NavLink href={'/settings/org'} label="Workspace Settings" />
                <NavLink href="/settings/org/members" label="Members" />
                {user.isCurrentOrgAdmin && (
                  <NavLink
                    href="/settings/org/permissions"
                    label="Permissions"
                  />
                )}
              </Stack>
            </Stack>
          </div>
        )}
      </Stack>
    </div>
  )
}
