import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { nanoid } from 'nanoid'
import path from 'path-browserify'

import { IPrompterSegment } from '@state/types'
import { getTokenHeader } from '@util/authToken'
import { SCRIPTO_REQUEST_ID_HEADER } from '@util/constants'
import { DatadogClient } from '@util/datadog'
import { handle401 } from '@util/handle401'
import {
  CompletePasswordResetProps,
  FetchInvitesPayload,
  InvitePayload,
  MoveFolderPayload,
  OrgPayload,
  PartialFolderSummaryPayload,
  PrompterHistory,
  PrompterSegmentPayload,
  ScriptSnapshotPayload,
  SnapshotFindPayload,
  UserPayload,
} from '@util/ScriptoApiClient/types'

import { ApiConfig } from './configs'

const ddlog = DatadogClient.getInstance()

// Axios throws if status is > 399 which loses all type information,
// so this reverse engineers it from the error and uses 0 if not an
// axios error (which is typically a network error)
const extractErrorInfo = (
  error: unknown,
): {
  status: number
  error: unknown
} => {
  const status =
    error instanceof AxiosError && error.response ? error.response.status : 0

  return { status, error }
}

export abstract class BaseApiClient {
  readonly _axios: AxiosInstance
  private _proxiedBasepath: string

  constructor({ axios }: { axios: AxiosInstance }) {
    this._axios = axios
    this._proxiedBasepath = window.location.origin + '/api/v0'
  }

  private async _executeAxiosRequest<Payload>(args: {
    config: AxiosRequestConfig
    apiVersionPath?: string
    useApiProxy?: boolean
  }): Promise<AxiosResponse> {
    const reqId = nanoid()
    // splice in the auth header
    const { config, apiVersionPath, useApiProxy = false } = args
    const tokenHeader = useApiProxy ? undefined : getTokenHeader()
    const headers: AxiosRequestConfig['headers'] = {
      ...config.headers,
      ...tokenHeader,
      [SCRIPTO_REQUEST_ID_HEADER]: reqId,
    }

    const basePath = typeof apiVersionPath === 'string' ? apiVersionPath : 'v0'

    // most REST requests go to /v0/ but this will change over time
    const url = useApiProxy ? config.url : path.join(basePath, config.url || '')
    const startTime = Date.now()
    try {
      const result = await this._axios({
        ...config,
        url,
        headers,
      })
      ddlog.logApiRequest({
        startTime,
        // shouldn't happen, we always set the path
        path: config.url ?? 'unknown',
        reqId,
        status: result.status,
        // axios default
        method: config.method ?? 'get',
      })
      return result as AxiosResponse<Payload>
    } catch (e) {
      const { status, error } = extractErrorInfo(e)
      ddlog.logApiRequest({
        startTime,
        path: url ?? 'unknown',
        reqId,
        status,
        method: config.method ?? 'get',
        error,
      })

      // Handle 401s specially
      if (status === 401) {
        // if we were using the authToken, we might just need
        // to refresh it
        if (!useApiProxy) {
          const authStatus = await this.getAuthStatus()
          if (authStatus) {
            // retry
            return this._executeAxiosRequest(args)
          }
        }
        // redirect to login
        await handle401()
      }
      throw e
    }
  }

  // TODO: phase this out-- it unwraps the data from the axios response
  // but we're doing that via the ApiConfig transformer now
  private async _makeRequest(
    config: AxiosRequestConfig,
    apiVersionPath?: string,
  ) {
    const { data } = await this._executeAxiosRequest({ config, apiVersionPath })
    return data
  }

  // this wrapper is used by the codegen of ScriptoApiClient to
  // turn configurations into class methods. This gets us all the
  // advantages of compile-time typescript while letting us define
  // each endpoint via a configuration
  protected _configToMethod = <Params, Payload>(
    methodConfig: ApiConfig<Params, Payload>,
  ) => {
    return async (params: Params) => {
      if (typeof methodConfig === 'function') {
        const { data } = await this._executeAxiosRequest({
          config: methodConfig(params),
        })
        return data as Payload
      }
      const { buildRequest, transformResponse, apiVersionPath } = methodConfig
      const result = await this._executeAxiosRequest({
        config: buildRequest(params),
        apiVersionPath,
      })
      if (transformResponse) {
        return transformResponse(result)
      } else {
        return result.data as Payload
      }
    }
  }

  /*
    There are a handful of methods that directly access localPersistence.
    These will remain in BaseApiClient, the rest should move out to configs
  */
  async login({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<{ id: string; socketToken: string; status: 'success' }> {
    const result = await this._executeAxiosRequest({
      config: {
        method: 'POST',
        url: this._proxiedBasepath + '/auth/login',
        data: { email, password },
      },
      useApiProxy: true,
    })
    return result.data
  }

  async getAuthStatus(): Promise<{
    id: string
    socketToken: string
  } | null> {
    const result = await this._executeAxiosRequest({
      config: {
        method: 'GET',
        url: this._proxiedBasepath + '/auth/status',
      },
      useApiProxy: true,
    })
    const data: {
      id: string
      socketToken: string
    } | null = result.data

    return data
  }

  async logout(): Promise<{ success: boolean }> {
    const result = await this._executeAxiosRequest({
      config: {
        method: 'POST',
        url: this._proxiedBasepath + '/auth/logout',
      },
      useApiProxy: true,
    })
    return result.data
  }

  async completeStytchPasswordReset(
    data: CompletePasswordResetProps,
  ): Promise<{ id: string; status: 'success' }> {
    const res = await this._executeAxiosRequest({
      config: {
        method: 'POST',
        url: this._proxiedBasepath + '/reset-password-otp',
        data,
      },
      useApiProxy: true,
    })
    return res.data
  }

  async completeAccountMigration(
    data: CompletePasswordResetProps,
  ): Promise<{ id: string; status: 'success' }> {
    const res = await this._executeAxiosRequest({
      config: {
        method: 'POST',
        url: this._proxiedBasepath + '/complete-account-migration',
        data,
      },
      useApiProxy: true,
    })
    return res.data
  }

  async completePasswordReset({
    email,
    password,
    token,
    isMigrating = false,
  }: {
    password: string
    token: string
    email: string
    isMigrating?: boolean
  }): Promise<void> {
    const opts = { token, password, email }

    if (isMigrating) {
      await this.completeAccountMigration(opts)
    } else {
      await this.completeStytchPasswordReset(opts)
    }
  }

  async getUser(userId: string): Promise<UserPayload> {
    return this._makeRequest({ method: 'GET', url: `/users/${userId}` })
  }

  // TODO: MOVE EVERYTHING BELOW HERE TO CONFIGS
  async initiateSignup(email: string): Promise<{ oneTimePasscodeId: string }> {
    return this._makeRequest({
      method: 'POST',
      url: '/initiate-signup',
      data: { email },
    })
  }

  async validateOneTimePasscode(data: {
    oneTimePasscodeId: string
    code: string
  }): Promise<{ token: string }> {
    return this._makeRequest({
      method: 'POST',
      url: '/validate-otp',
      data,
    })
  }

  async createSnapshot({
    scriptId,
    name,
    autoSave,
  }: {
    scriptId: string
    name?: string
    autoSave?: string
  }): Promise<{ id: string }> {
    const data: { name?: string; autoSave?: string } = {}

    if (name) data.name = name
    if (autoSave) data.autoSave = autoSave

    return this._makeRequest({
      method: 'POST',
      url: `/scripts/${scriptId}/snapshots`,
      data,
    })
  }

  updateUser({
    userId,
    email,
    name,
  }: {
    userId: string
    email?: string
    name?: string
  }): Promise<UserPayload> {
    const data: { email?: string; name?: string; defaultGroup?: string } = {}

    if (email) data.email = email
    if (name) data.name = name

    return this._makeRequest({
      method: 'PUT',
      url: `/users/${userId}`,
      data,
    })
  }

  uploadAvatar({
    userId,
    avatar,
  }: {
    userId: string
    avatar: File
  }): Promise<{ status: 'success'; avatar: string }> {
    const data = new window.FormData()
    data.append('avatar', avatar)
    return this._makeRequest({
      method: 'POST',
      url: `/users/${userId}/avatar`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async grantMemberPermission({
    userId,
    permissionCode,
    orgId,
  }: {
    userId: string
    permissionCode: string
    orgId: string
  }): Promise<OrgPayload> {
    return this._makeRequest({
      method: 'PUT',
      url: `/orgs/${orgId}/permissions/${permissionCode}/grant`,
      data: { memberIds: [userId] },
    })
  }

  async revokeMemberPermission({
    userId,
    permissionCode,
    orgId,
  }: {
    userId: string
    permissionCode: string
    orgId: string
  }): Promise<OrgPayload> {
    return this._makeRequest({
      method: 'PUT',
      url: `/orgs/${orgId}/permissions/${permissionCode}/revoke`,
      data: { memberIds: [userId] },
    })
  }

  createFolder({
    parentId,
    name,
  }: {
    parentId: string
    name: string
  }): Promise<PartialFolderSummaryPayload> {
    return this._makeRequest({
      method: 'POST',
      url: '/folders',
      data: {
        parentId,
        name,
      },
    })
  }

  getSnapshot({
    scriptId,
    snapshotId,
  }: {
    scriptId: string
    snapshotId: string
  }): Promise<ScriptSnapshotPayload> {
    return this._makeRequest({
      method: 'GET',
      url: `/scripts/${scriptId}/snapshots/${snapshotId}`,
    })
  }

  updateSnapshot({
    scriptId,
    snapshotId,
    name,
  }: {
    scriptId: string
    snapshotId: string
    name?: string
  }): Promise<ScriptSnapshotPayload> {
    return this._makeRequest({
      method: 'PUT',
      url: `/scripts/${scriptId}/snapshots/${snapshotId}`,
      data: { name },
    })
  }

  fetchSnapshotHistory({
    scriptId,
    filter = 'all',
    size = 1,
    from = 0,
  }: {
    scriptId: string
    size?: number
    from?: number
    filter?: 'manual' | 'all'
  }): Promise<SnapshotFindPayload> {
    let url = `/scripts/${scriptId}/snapshots/find?from=${from}&size=${size}`
    if (filter !== 'all') url += `&filter=${filter}`

    return this._makeRequest({
      method: 'GET',
      url,
    })
  }

  orgUpgradeInquiry({
    orgId,
  }: {
    orgId: string
  }): Promise<{ status: 'success' }> {
    return this._makeRequest({
      method: 'GET',
      url: `/orgs/${orgId}/upgradeInquiry`,
    })
  }

  demoteAdmin({
    orgId,
    userId,
  }: {
    orgId: string
    userId: string
  }): Promise<OrgPayload> {
    return this._makeRequest({
      method: 'DELETE',
      url: `/orgs/${orgId}/admins/${userId}`,
    })
  }

  promoteAdmin({
    orgId,
    userId,
  }: {
    orgId: string
    userId: string
  }): Promise<OrgPayload> {
    return this._makeRequest({
      method: 'POST',
      url: `/orgs/${orgId}/admins/${userId}`,
    })
  }

  removeUserFromOrg({
    orgId,
    userId,
  }: {
    orgId: string
    userId: string
  }): Promise<OrgPayload> {
    return this._makeRequest({
      method: 'DELETE',
      url: `/orgs/${orgId}/memberships/${userId}`,
    })
  }

  removeSelfFromOrg({ orgId }: { orgId: string }): Promise<OrgPayload> {
    return this._makeRequest({
      method: 'DELETE',
      url: `/orgs/${orgId}/memberships/self`,
    })
  }

  fetchOrgInvites({ orgId }: { orgId: string }): Promise<FetchInvitesPayload> {
    // luckily this isn't *really* a paginated response
    return this._makeRequest({
      method: 'GET',
      url: `/orgs/${orgId}/invites`,
    })
  }

  revokeOrgInvite({
    orgId,
    inviteId,
  }: {
    orgId: string
    inviteId: string
  }): Promise<{ success: true }> {
    return this._makeRequest({
      method: 'DELETE',
      url: `/orgs/${orgId}/invites/${inviteId}`,
    })
  }

  createOrgInvite({
    orgId,
    email,
  }: {
    orgId: string
    email: string
  }): Promise<{ id: string; email: string; status: 'open' | 'accepted' }[]> {
    return this._makeRequest({
      method: 'POST',
      url: `/orgs/${orgId}/invites`,
      data: { emails: [email] },
    })
  }

  acceptWorkspaceInvite({
    inviteId,
  }: {
    inviteId: string
  }): Promise<InvitePayload> {
    return this._makeRequest({
      method: 'PUT',
      url: `/invites/${inviteId}/accepted`,
    })
  }

  updateOrg({
    orgId,
    name,
    ownerId,
  }: {
    orgId: string
    name?: string
    ownerId?: string
  }): Promise<OrgPayload> {
    const url = `/orgs/${orgId}`
    const data: { name?: string; owner?: string } = {}

    if (name) data.name = name
    // old yucky signature
    if (ownerId) data.owner = `user:${ownerId}`

    return this._makeRequest({
      method: 'PUT',
      url,
      data,
    })
  }

  removeOrgMemberByStaff({ orgId, userId }: { orgId: string; userId: string }) {
    const url = `/staff/orgs/${orgId}/memberships/${userId}`
    return this._makeRequest({
      method: 'DELETE',
      url,
    })
  }

  updateUserByStaff({ staff, userId }: { staff: boolean; userId: string }) {
    return this._makeRequest({
      method: 'PUT',
      url: `/staff/users/${userId}`,
      data: { staff },
    })
  }

  trashFolder({
    folderId,
  }: {
    folderId: string
  }): Promise<{ message?: 'success'; error?: string }> {
    return this._makeRequest({
      method: 'DELETE',
      url: `/folders/${folderId}`,
    })
  }

  updateFolder({
    folderId,
    parentId,
    name,
  }: {
    folderId: string
    parentId?: string
    name?: string
  }): Promise<MoveFolderPayload> {
    const data = {} as { name?: string; parentId?: string }
    if (parentId) data.parentId = parentId
    if (name) data.name = name

    return this._makeRequest({
      method: 'PUT',
      url: `/folders/${folderId}`,
      data,
    })
  }

  importFdx({
    folderId,
    file,
    formatId,
  }: {
    folderId: string
    file: File
    formatId: string
  }): Promise<Array<{ id: string }>> {
    const data = new window.FormData()
    data.append('folder', folderId)
    data.append('fdx', file)
    data.append('formatId', formatId)
    return this._makeRequest({
      method: 'POST',
      url: '/scripts/import',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  leaveRundownRoom({
    rundownId,
    socketId,
  }: {
    rundownId: number
    socketId: string
  }) {
    return this._makeRequest({
      method: 'PUT',
      url: `/rundowns/${rundownId}/leave`,
      data: { socketId },
    })
  }

  async prepareScriptPrompterPush({ scriptId }: { scriptId: string }): Promise<{
    history: PrompterHistory
    segments: PrompterSegmentPayload[]
  }> {
    return this._makeRequest({
      url: `/scripts/${scriptId}/prepare-prompter-push`,
      method: 'GET',
    })
  }

  async pushScriptToPrompter({
    scriptId,
    segments,
  }: {
    scriptId: string
    segments: IPrompterSegment[]
  }): Promise<{ id: number }> {
    return this._makeRequest({
      url: `/scripts/${scriptId}/prompter-pushes`,
      method: 'POST',
      data: { segments },
    })
  }

  async prepareRundownPrompterPush({
    rundownId,
  }: {
    rundownId: number
  }): Promise<{
    history: PrompterHistory
    segments: PrompterSegmentPayload[]
  }> {
    return this._makeRequest({
      url: `/rundowns/${rundownId}/prepare-prompter-push`,
      method: 'GET',
    })
  }

  async pushRundownToPrompter({
    rundownId,
    segments,
  }: {
    rundownId: number
    segments: IPrompterSegment[]
  }): Promise<{ id: number }> {
    return this._makeRequest({
      url: `/rundowns/${rundownId}/prompter-pushes`,
      method: 'POST',
      data: { segments },
    })
  }

  async getInvite(inviteId: string): Promise<InvitePayload> {
    return this._makeRequest({
      url: `/invites/${inviteId}`,
      method: 'GET',
    })
  }

  // used for two-column printing
  async passThroughPrint({
    html,
    fileName: filename,
    unwatermark,
  }: {
    html: string
    fileName: string
    unwatermark?: boolean
  }) {
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      url: '/users/print',
      method: 'POST',
      data: { html, filename, unwatermark },
    }
    const response: AxiosResponse<Blob>['data'] =
      await this._makeRequest(config)

    return response
  }
}
