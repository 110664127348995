import { FancyMenuItem } from '@components/FancyMenuItem'
import { useNavigation } from '@hooks'
import {
  CreateScriptFromRundownParams,
  IFolder,
  IOrg,
  IRundown,
  useMst,
} from '@state'

import { useRundownOperations } from '../../RundownGrid/useRundownOperations'

import { showCreateNewScriptModal } from './CreateNewScriptModal'

export const InsertNewScriptMenuItem = ({
  rundown,
  workspace,
  insertAtTop,
  disabled,
}: {
  rundown: IRundown
  workspace: IOrg
  insertAtTop: boolean
  disabled: boolean
}) => {
  const mst = useMst()
  const { navigateToScript } = useNavigation()
  const { insertScriptRow } = useRundownOperations(rundown)

  const { rows } = rundown.selectionSummary
  const sequence = rows[0]?.sequence ?? 1

  const folderListState = mst.view.initializeReadonlyFolderState(
    mst.getRootFolder(false, false) as IFolder,
  )

  if (rundown.parentFolder?.id) {
    folderListState.setSelectedFolderId(rundown.parentFolder.id)
    folderListState.expandFolderPath(rundown.parentFolder.id)
  }

  const createScriptRow = async (params: CreateScriptFromRundownParams) => {
    const { id: scriptId } = await workspace.createScript(params)
    // inject pertinent row into the rundown
    await insertScriptRow({ scriptId, sequence, name: params.name })
    // only launch script if split mode is active
    if (mst.user.prefersSplitLayout) navigateToScript(scriptId)
  }

  const pos = insertAtTop ? 'at top' : 'above'

  return (
    <FancyMenuItem
      title={`Insert new script ${pos}...`}
      iconClass="fa-regular fa-file-plus"
      disabled={disabled}
      onClick={() =>
        showCreateNewScriptModal({
          folderListState,
          onConfirm: createScriptRow,
          workspace,
        })
      }
    />
  )
}
