import { Menu } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { useRundownOperations } from '@components/RundownGrid/useRundownOperations'
import { Toolbar } from '@components/Toolbar'
import { buildShortcutTip, Keys } from '@hooks'
import { ButtonMode, IOrg, IRundown } from '@state/types'

import * as helpers from '../helpers'

import { showInsertMultipleRowsModal } from './InsertMultipleRowsModal'
import { InsertNewScriptMenuItem } from './InsertNewScriptMenuItem'

export const ImportRowsItem = observer(function ImportRowsItem({
  rundown,
}: {
  rundown: IRundown
}) {
  const operations = useRundownOperations(rundown)
  const { rows: selectedOrFocusedRows } = rundown.selectionSummary
  const sequence = selectedOrFocusedRows[0]?.sequence ?? 1
  const uniqueSelectedRow =
    selectedOrFocusedRows.length === 1 ? selectedOrFocusedRows[0] : undefined
  const importScriptId: string | null =
    uniqueSelectedRow?.identityScriptId ?? null
  const canImport = typeof importScriptId === 'string'

  return (
    <FancyMenuItem
      iconClass="fa-solid fa-right-from-line"
      title="Import rows from script"
      disabled={!canImport}
      onClick={() => {
        if (importScriptId) {
          operations.importRows({
            scriptId: importScriptId,
            sequence: sequence + 1,
          })
        }
      }}
    />
  )
})

export const InsertItems = observer(function InsertItems({
  rundown,
  workspace,
}: {
  rundown: IRundown
  workspace: IOrg
}) {
  const operations = useRundownOperations(rundown)
  const { rows: selectedOrFocusedRows, continuous } = rundown.selectionSummary
  const rowCount =
    selectedOrFocusedRows.length > 0 ? selectedOrFocusedRows.length : 1
  const sequence = selectedOrFocusedRows[0]?.sequence ?? 1
  const canInsertRows = rowCount === 1 || continuous
  const insertAtTop = selectedOrFocusedRows.length === 0

  const buildInsertLevelItem = (level: 1 | 2 | 3) => {
    const levelConfig = rundown.schema.schema.levels[level]
    if (!levelConfig?.enableInsert) {
      return null
    }

    return (
      <Menu.Item
        onClick={() => {
          operations.insertBlankRows({
            rowCount: 1,
            sequence,
            rowTypeId: levelConfig.rowTypeId ?? 'element',
            rowLevel: level,
          })
        }}
        disabled={!canInsertRows}
      >
        {helpers.insertRowTitle({
          atTop: insertAtTop,
          rowTypeName: levelConfig.displayName,
        })}
      </Menu.Item>
    )
  }

  // menu items for inserting rows of different types. Depending on the
  // schema we may or may not need all the items and they may have different
  // names
  return (
    <>
      <FancyMenuItem
        title={helpers.insertRowTitle({
          atTop: insertAtTop,
        })}
        onClick={() =>
          operations.insertBlankRows({
            rowCount: 1,
            sequence,
            rowTypeId: 'element',
          })
        }
        disabled={!canInsertRows}
        shortcut={buildShortcutTip([Keys.CMD, Keys.ALT, 'I'])}
      />
      <FancyMenuItem
        title="Insert multiple rows..."
        disabled={!canInsertRows}
        onClick={() =>
          showInsertMultipleRowsModal({
            atTop: insertAtTop,
            onConfirm: (rowCount) =>
              rundown.insertBlankRows({
                rowCount,
                sequence,
                rowTypeId: 'element',
              }),
          })
        }
      />
      {buildInsertLevelItem(3)}
      {buildInsertLevelItem(2)}
      {buildInsertLevelItem(1)}
      <InsertNewScriptMenuItem
        rundown={rundown}
        workspace={workspace}
        disabled={!canInsertRows}
        insertAtTop={insertAtTop}
      />
    </>
  )
})

export const InsertMenu = observer(function InsertMenu({
  rundown,
  workspace,
  buttonMode,
}: {
  rundown: IRundown
  workspace: IOrg
  buttonMode: ButtonMode
}) {
  return (
    <Menu withinPortal>
      <Menu.Target>
        <Toolbar.Button
          label="Insert"
          mode={buttonMode}
          icon="fa-diagram-previous"
          dropdown
        />
      </Menu.Target>
      <Menu.Dropdown>
        <InsertItems rundown={rundown} workspace={workspace} />
        <Menu.Divider />
        <ImportRowsItem rundown={rundown} />
      </Menu.Dropdown>
    </Menu>
  )
})
