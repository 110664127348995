import { Menu as MenuComponent } from '@mantine/core'

import styles from './menu.module.scss'

export const Menu = MenuComponent.extend({
  defaultProps: {
    shadow: 'md',
    position: 'bottom-start',
  },
  classNames: {
    dropdown: styles.dropdown,
    item: styles.item,
    itemSection: styles.itemSection,
    label: styles.label,
  },
})
