import { Group, Menu, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { CompilerStatusIcon, InkStatusIcon } from '@components/FaIcon'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { StatusBar } from '@components/StatusBar'
import { useNavigation } from '@hooks'
import { CompileMessageType, InkCompileMessage } from '@ink'
import { useMst } from '@state'
import { IInkProject } from '@state/types'
import { pathTo, pluralize, ROUTE_PATTERNS } from '@util'
import { scrollToBlock } from '@util/scrolling'

const IconWithCount = ({
  count,
  type,
}: {
  count: number
  type: CompileMessageType
}) => {
  if (count === 0) {
    return null
  }
  return (
    <Group gap={3}>
      <Text size="sm">{count}</Text>
      <CompilerStatusIcon size="sm" type={type} />
    </Group>
  )
}

export const InkMessagesMenu = observer(function InkMessagesMenu({
  inkProject,
  maxHeight,
}: {
  maxHeight?: number
  inkProject: IInkProject
}) {
  const { location, currentRundown } = useMst()
  const { navigate } = useNavigation()

  const currentScriptId = location.getPathParam('scriptId')
  const { compilerMessages } = inkProject

  const errorMessages = compilerMessages.filter((msg) => msg.type === 'error')
  const warnMessages = compilerMessages.filter((msg) => msg.type === 'warning')
  const todoMessages = compilerMessages.filter((msg) => msg.type === 'todo')

  const handleClick = ({ file, blockId }: InkCompileMessage) => {
    if (file === currentScriptId) {
      if (blockId) {
        scrollToBlock(blockId)
      }
    } else if (file && currentRundown) {
      navigate(
        pathTo(
          ROUTE_PATTERNS.splitEditor,
          {
            rundownId: String(currentRundown.id),
            scriptId: file,
          },
          {
            queryParams: blockId ? { block: blockId } : undefined,
          },
        ),
      )
    }
  }

  const fileName = (message: InkCompileMessage) => {
    if (message.file) {
      const source = inkProject.sourceFiles.find(
        (s) => s.scriptId === message.file,
      )
      return source?.name
    }
  }

  const errorCount = errorMessages.length
  const warnCount = warnMessages.length
  const todoCount = todoMessages.length

  if (errorCount + warnCount + todoCount === 0) return null

  const tooltip = `${errorCount} ${pluralize(
    errorCount,
    'error',
  )}, ${warnCount} ${pluralize(warnCount, 'warning')}, ${todoCount} ${pluralize(
    todoCount,
    'to-do',
  )}`

  return (
    <Menu width={350}>
      <Menu.Target>
        <StatusBar.Button tooltip={tooltip}>
          <Group gap={5}>
            {errorCount && <IconWithCount type="error" count={errorCount} />}
            {warnCount && <IconWithCount type="warning" count={warnCount} />}
            {todoCount && <IconWithCount type="todo" count={todoCount} />}
          </Group>
        </StatusBar.Button>
      </Menu.Target>
      <Menu.Dropdown style={{ maxHeight }}>
        {compilerMessages.map((item, index) => (
          <FancyMenuItem
            key={index}
            onClick={() => handleClick(item)}
            customIcon={
              item.type === 'todo' ? (
                <InkStatusIcon.ToDo />
              ) : item.type === 'warning' ? (
                <InkStatusIcon.Warning />
              ) : (
                <InkStatusIcon.Error />
              )
            }
            title={item.message}
            subTitle={fileName(item)}
          />
        ))}
      </Menu.Dropdown>
    </Menu>
  )
})
