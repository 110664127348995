import { type Command, TextSelection } from 'prosemirror-state'

import { isStandardBlock } from '@util'

export const TAB_SIZE = 2
const TAB_CHARS = new Array(TAB_SIZE).fill(' ').join('')

export const ONE_TAB_SPACES = (() => {
  const spaces: string[] = []
  for (let i = 0; i < TAB_SIZE; i++) {
    spaces.push(' ')
  }
  return spaces.join('')
})()

const LEADING_SPACE_REGEX = /(?<spaces> *)/
const leadingSpaceCount = (value: string): number => {
  const reResult = LEADING_SPACE_REGEX.exec(value)
  return reResult?.groups?.spaces?.length ?? 0
}
export const getIndentLevel = (value: string): number => {
  return Math.floor(leadingSpaceCount(value) / TAB_SIZE)
}

const handleTab =
  (shift: boolean): Command =>
  (editorState, dispatch) => {
    const { selection, tr, doc } = editorState
    if (!(selection instanceof TextSelection)) {
      return false
    }

    const anchor = selection.anchor
    const head = selection.head
    doc.nodesBetween(selection.from, selection.to, (node, pos, parent) => {
      const startingPos = tr.mapping.map(pos) + 1
      if (isStandardBlock({ node, parent })) {
        if (shift) {
          const leadingSpaces = /^(\s*)/.exec(node.textContent)?.[1]
          if (leadingSpaces) {
            const blanksToRemove = Math.min(leadingSpaces.length, TAB_SIZE)
            const endingPos = startingPos + blanksToRemove
            tr.insertText('', startingPos, endingPos)
          }
        } else {
          tr.insertText(TAB_CHARS, startingPos)
        }
      }
    })
    if (tr.steps.length > 0) {
      tr.setSelection(
        TextSelection.create(
          tr.doc,
          tr.mapping.map(anchor),
          tr.mapping.map(head),
        ),
      )
      dispatch?.(tr)
    }
    return true
  }

export const indent: Command = handleTab(false)
export const outdent: Command = handleTab(true)
