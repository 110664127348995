import { Toolbar } from '@components/Toolbar'
import { useMst } from '@state'
import { ButtonMode } from '@state/types'

import { UndoRedo } from '../buttons/UndoRedo'

import { FormattingButtons } from './Buttons'
import { FormattingMenu } from './Menu'
import { StyleButtons } from './Style'

export const FormattingOptions = ({
  mode = 'normal',
}: {
  mode: ButtonMode
}) => {
  const { currentScript } = useMst()

  return mode === 'normal' ? (
    <FormattingButtons />
  ) : mode === 'mini' ? (
    <Toolbar.ButtonGroup spacing={0}>
      <Toolbar.Divider />
      <Toolbar.ButtonGroup spacing={0}>
        <UndoRedo />
      </Toolbar.ButtonGroup>
      <StyleButtons />
      {!currentScript?.isInk && <FormattingMenu mode={mode} />}
    </Toolbar.ButtonGroup>
  ) : (
    // micro
    <FormattingMenu mode={mode} />
  )
}
