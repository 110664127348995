import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { ScriptStatus } from '@showrunner/codex'

import { ScriptStatusIcon } from '@components/FaIcon/ScriptStatusIcon'
import { showScriptStatusModal } from '@components/Modals'
import { Toolbar } from '@components/Toolbar'
import { ILoadedScript } from '@state/types'
import { capitalize } from '@util'

import styles from './ScriptStatusButton.module.scss'

export const ScriptStatusButton = observer(function ScriptStatusButton({
  readOnly,
  script,
}: {
  readOnly: boolean
  script: ILoadedScript
}) {
  return (
    <Toolbar.ButtonGroup>
      <Toolbar.Button
        buttonClasses={cn(styles.scriptStatus_button, {
          [styles.scriptStatus_button__open]: script.isOpen,
          [styles.scriptStatus_button__limited]: script.isLimited,
          [styles.scriptStatus_button__private]: script.isPrivate,
        })}
        onClick={() =>
          showScriptStatusModal({
            onConfirm: (status: ScriptStatus) => script.updateStatus(status),
            script,
            readOnly,
          })
        }
        disabled={script.inTrash}
        label={script.accessLevel}
        tooltip={`Script access: ${capitalize(script.accessLevel)}`}
        customIcon={<ScriptStatusIcon status={script.accessLevel} />}
        mode="mini"
      ></Toolbar.Button>
    </Toolbar.ButtonGroup>
  )
})
