import { Menu } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { Toolbar } from '@components/Toolbar'
import { useMst } from '@state'
import { ButtonMode } from '@state/types'
import { downloadZippedFiles, saveTextToFile } from '@util'

import { useFileCreation } from '../../../hooks'

export const InkExportMenu = observer(function InkMenu({
  mode,
}: {
  mode: ButtonMode
}) {
  const { currentScript, currentInkProject } = useMst()
  const { createFile } = useFileCreation()

  if (!currentInkProject) {
    return null
  }

  const fileNameBase = currentScript?.name || 'Untitled'
  const handleExportInk = () => {
    const fileName = `${fileNameBase}.ink`
    saveTextToFile({
      fileName: fileName,
      text: currentInkProject.inkText,
      contentType: 'application/octet-stream',
    })
  }

  const handleExportProject = async () => {
    const zippable = await currentInkProject.getExportableSources()
    downloadZippedFiles(zippable)
  }

  const handleExportJson = () => {
    if (currentInkProject.compiledJson) {
      const fileName = `${fileNameBase}.json`
      saveTextToFile({
        fileName,
        text: currentInkProject.compiledJson,
      })
    }
  }

  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <div>
          <Toolbar.Button
            mode={mode}
            label="Export"
            customIcon={<FaIcon faIconSet="fa" icon="fa-share-from-square" />}
            dropdown
          />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          disabled={!currentInkProject.lastProcessedProseDoc}
          onClick={handleExportInk}
        >
          Current script as .ink
        </Menu.Item>
        <Menu.Item
          disabled={
            !currentInkProject.useRundownScripts() ||
            currentInkProject.isLoadingSources
          }
          onClick={handleExportProject}
        >
          Entire project as .ink
        </Menu.Item>
        <Menu.Item
          disabled={!currentInkProject.compiledJson}
          onClick={handleExportJson}
        >
          Compiled ink JSON
        </Menu.Item>
        <Menu.Item
          disabled={!currentScript}
          onClick={() =>
            createFile({
              fileType: 'line-data',
              script: currentScript,
            })
          }
        >
          Line data JSON
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
})
