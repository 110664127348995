// Add some bells/whistles to the Mantine Menu item so
// we can have support for fontawesome icons with fixed widths,
// 2-line menu items, etc.
import React from 'react'

import { DefaultMantineColor, Menu, Text } from '@mantine/core'
import cn from 'classnames'

import styles from './FancyMenuItem.module.scss'

type ComplexMenuItemProps = {
  title: React.ReactNode
  badge?: React.ReactNode
  color?: DefaultMantineColor
  disabled?: boolean
  hideIcon?: boolean
  hideShortcut?: boolean
  iconClass?: string
  customIcon?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  selected?: boolean
  shortcut?: string
  subTitle?: React.ReactNode
}

const MenuIcon = ({
  iconClass,
  hide,
}: {
  iconClass: string
  hide?: boolean
}) => (
  <i className={cn('fa-fw', { [iconClass]: !hide })} style={{ fontSize: 14 }} />
)

export const FancyMenuItem = ({
  title,
  badge,
  color,
  customIcon,
  disabled,
  hideIcon,
  hideShortcut,
  iconClass,
  onClick,
  selected,
  shortcut,
  subTitle,
}: ComplexMenuItemProps) => {
  const icon = iconClass ? (
    <MenuIcon iconClass={iconClass} hide={hideIcon} />
  ) : customIcon ? (
    customIcon
  ) : undefined

  const rightSection =
    shortcut && !hideShortcut ? (
      <Text
        size="xs"
        c={selected ? 'white' : 'dimmed'}
        style={{ marginLeft: 8 }}
      >
        {shortcut}
      </Text>
    ) : badge ? (
      badge
    ) : undefined

  return (
    <Menu.Item
      leftSection={icon}
      rightSection={rightSection}
      disabled={disabled}
      onClick={onClick}
      color={selected ? 'white' : color}
      className={cn(styles.fancyMenuItem, {
        [styles.fancyMenuItem_selected]: selected,
      })}
    >
      {title}
      {!!subTitle && (
        <Text c={color ?? 'dimmed'} size="sm">
          {subTitle}
        </Text>
      )}
    </Menu.Item>
  )
}
