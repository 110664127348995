/*
  Popup window container to host the ink player. The main app
  mounts this on a route and opens the popup. The two windows
  communicate with each other via comlink using the RemotePlayer
  and RemotePlayerManger types.
*/

import React from 'react'

import * as Comlink from 'comlink'

import { CompileStatus, IInkPlayer, IInkPlayerController } from '@ink/types'

import { InkPlayer } from '../InkPlayer'

// Comlink machinery to expose the original window to receive messages
// from the child window.
const parentEndpoint = Comlink.windowEndpoint(self.opener)
const parentProxy = Comlink.wrap<IInkPlayerController>(parentEndpoint)
const publishInterface = (playerInterface: IInkPlayer) => {
  Comlink.expose(playerInterface, parentEndpoint)
}

export const PopupPlayer = () => {
  const [inkJson, setInkJson] = React.useState<string | undefined>()
  const [title, setTitle] = React.useState('Untitled')
  const [status, setStatus] = React.useState<CompileStatus>('working')

  const playerInterface: IInkPlayer = {
    updateData: (data) => {
      if (data.inkJson) {
        setInkJson(data.inkJson)
      }
      if (data.title) {
        setTitle(data.title)
      }
      if (data.compileStatus) {
        setStatus(data.compileStatus)
      }
    },
  }

  React.useRef(Comlink.expose(playerInterface, parentEndpoint))

  React.useRef(
    publishInterface({
      updateData: (data) => {
        if (data.inkJson) {
          setInkJson(data.inkJson)
        }
        if (data.title) {
          setTitle(data.title)
        }
        if (data.compileStatus) {
          setStatus(data.compileStatus)
        }
      },
    }),
  )

  // once this component mounts, call back to the playermanager
  // that we are ready to receive json
  React.useEffect(() => {
    parentProxy.onPlayerReady()
  }, [])

  React.useEffect(() => {
    document.title = title
  })

  return <InkPlayer status={status} inkJson={inkJson ?? ''} />
}
