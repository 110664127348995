export const safeParseJSON = (value: unknown): JSONValue | undefined => {
  if (typeof value !== 'string') {
    return undefined
  }
  try {
    return JSON.parse(value)
  } catch {
    return undefined
  }
}
