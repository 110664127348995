import React from 'react'

import { observer } from 'mobx-react-lite'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import { useMst } from '@state'
import { IReadonlyFolderListState } from '@state/types'

import {
  getKey,
  getLiveFolderList,
  getReadonlyFolderList,
  ListItem,
} from './folderListHelpers'
import { LiveFolderListing, ReadonlyFolderListing } from './FolderListing'

import styles from './FolderList.module.scss'

// These pure class components avoid re-renders and also
// allow the expand icon to animate (by not rerendering)
class LiveListingRenderer extends React.PureComponent<{
  data: ListItem[]
  index: number
  style: React.CSSProperties
}> {
  render() {
    return (
      <div style={this.props.style}>
        <LiveFolderListing item={this.props.data[this.props.index]} />
      </div>
    )
  }
}

class ReadonlyListingRenderer extends React.PureComponent<{
  data: ListItem[]
  index: number
  style: React.CSSProperties
}> {
  render() {
    return (
      <div style={this.props.style}>
        <ReadonlyFolderListing item={this.props.data[this.props.index]} />
      </div>
    )
  }
}

const FolderList = ({
  setList,
  listItems,
  Renderer,
}: {
  listItems: ListItem[]
  Renderer: typeof ReadonlyListingRenderer | typeof LiveListingRenderer
  setList?: (elt: FixedSizeList | null) => void
}) => {
  return (
    <div className={styles.folderList}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            ref={setList}
            itemKey={(index, data) => getKey(data[index])}
            height={height}
            itemCount={listItems.length}
            itemSize={30}
            width={width}
            itemData={listItems}
          >
            {Renderer}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  )
}

export const ExplorerFolderList = observer(function ExplorerFolderList() {
  const mst = useMst()
  const listItems = getLiveFolderList(mst)
  // for the list, we use state instead of ref because we need to trigger
  // an effect when the ref gets set
  const [list, setList] = React.useState<FixedSizeList | null>(null)
  const [hasAutoscrolled, setHasAutoscrolled] = React.useState(false)

  React.useEffect(() => {
    if (!hasAutoscrolled && list) {
      const targetIndex = listItems.findIndex(
        (d) => d.folderId === mst.view.selectedFolderId,
      )
      if (targetIndex > -1) {
        // for some reason the scrolling fails without a little delay,
        // this is a teensy bit janky but ¯\_(ツ)_/¯
        setTimeout(() => {
          setHasAutoscrolled(true)
          list.scrollToItem(targetIndex, 'smart')
          mst.view.explorerState.clearCrosshairsFolderRequest()
        }, 100)
      }
    }
  }, [
    hasAutoscrolled,
    list,
    listItems,
    mst.view.explorerState,
    mst.view.selectedFolderId,
  ])

  // reset the has scrolled if the user requests a folder
  React.useEffect(() => {
    if (mst.view.explorerState.crosshairsRequestFolderId && hasAutoscrolled) {
      setHasAutoscrolled(false)
    }
  }, [hasAutoscrolled, mst.view.explorerState.crosshairsRequestFolderId])

  return (
    <FolderList
      listItems={listItems}
      Renderer={LiveListingRenderer}
      setList={setList}
    />
  )
})

export const ReadonlyFolderList = observer(function ReadonlyFolderList({
  folderListState,
}: {
  folderListState: IReadonlyFolderListState
}) {
  const mst = useMst()
  const [hasAutoscrolled, setHasAutoscrolled] = React.useState(false)
  const [list, setList] = React.useState<FixedSizeList | null>(null)
  const listItems = getReadonlyFolderList(mst, folderListState)
  const { selectedFolderId } = folderListState

  React.useEffect(() => {
    if (!hasAutoscrolled && list) {
      const targetIndex = listItems.findIndex(
        (d) => d.folderId === selectedFolderId,
      )
      if (targetIndex > -1) {
        setHasAutoscrolled(true)
        list.scrollToItem(targetIndex, 'smart')
      }
    }
  }, [hasAutoscrolled, list, listItems, folderListState, selectedFolderId])

  return (
    <FolderList
      listItems={listItems}
      Renderer={ReadonlyListingRenderer}
      setList={setList}
    />
  )
})
