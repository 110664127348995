import { Decoration } from 'prosemirror-view'

import { IRemoteCollaborator } from '@state'

interface DecorationData extends IRemoteCollaborator {
  startPosition: number
  endPosition: number
  color: string
  clientId: string
}

function isDecorationData(
  collaborator: IRemoteCollaborator,
): collaborator is DecorationData {
  const { startPosition, endPosition } = collaborator
  return typeof startPosition === 'number' && typeof endPosition === 'number'
}

const createCursorDecoration = (collaborator: DecorationData): Decoration => {
  const { clientId, color, startPosition } = collaborator

  return Decoration.widget(
    startPosition,
    () => {
      const elt = document.createElement('span')
      elt.className = 'js-remote-cursor'
      elt.setAttribute('style', `border-right-color: ${color}`)
      return elt
    },
    {
      key: `cursor-${clientId}`,
    },
  )
}

const createSelectionDecoration = (
  collaborator: DecorationData,
): Decoration => {
  const { clientId, color, startPosition, endPosition } = collaborator
  return Decoration.inline(
    startPosition,
    endPosition,
    {
      class: 'remoteSelect',
      style: `background:${color}50`,
    },
    {
      key: `selection-${clientId}`,
    },
  )
}

export const createRemoteCursors = (
  collaborators: IRemoteCollaborator[],
): Decoration[] => {
  const result: Decoration[] = []
  collaborators.forEach((c) => {
    if (isDecorationData(c)) {
      const { startPosition, endPosition } = c
      if (startPosition === endPosition) {
        result.push(createCursorDecoration(c))
      } else {
        result.push(createSelectionDecoration(c))
      }
    }
  })
  return result
}
