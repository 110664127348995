const TRAILING_NUMERIC_REGEX = /\d+$/

// if the user types in "A010" we want to know that "A" is the prefix,
// that 10 is the numeric value and that it's padded out to 3 characters
export const extractInfoFromPattern = (value: string) => {
  const trailingNumericStr = value.match(TRAILING_NUMERIC_REGEX)?.[0]
  const firstValue = trailingNumericStr ? parseInt(trailingNumericStr) : 1
  return {
    firstValue,
    paddedLength: trailingNumericStr?.length ?? 0,
    trailingNumericStr,
  }
}

// This is the type of a generator function used by the `onApplyNumbers` prop
// passed in-- basically this component lets the user configure this function, then
// we pass it back in `onApplyNumbers` to enable the numbers to be generated
// for appropriate rows
type SmartItemNumberGenerator = (fakeIndex: number, seed?: string) => string

// This function uses a sample pattern and an increment value to build and return
// another function that can generate item numbers that are similar
export const buildSmartItemNumberGenerator = (): SmartItemNumberGenerator => {
  // we *could* try to infer the increment from existing values too if we wanted
  const increment = 1

  // for empty slots that precede existing numbers default to 1,2,3
  let initialValue = 0
  let paddedLength = 0
  let prefix = ''

  return (fakeIndex: number, seedValue?: string) => {
    if (seedValue) {
      const info = extractInfoFromPattern(seedValue)
      initialValue = info.firstValue
      paddedLength = info.paddedLength
      prefix = seedValue.substring(0, seedValue.length - paddedLength)
    }
    const value = fakeIndex * increment + initialValue
    const paddedValue = String(value).padStart(paddedLength, '0')
    return `${prefix}${paddedValue}`
  }
}
