import { type Command, TextSelection } from 'prosemirror-state'

import { NodeTypeMap } from '@showrunner/codex'
import { schema } from '@showrunner/prose-schemas'

// TODO: handle wrapping existing dialogue in dual container
export const insertDualDialogue: Command = (viewState, viewDispatch?) => {
  const { $from, $to } = viewState.selection
  if (!$from.parent.isBlock) {
    return false
  }
  if (viewDispatch) {
    // NOTE: not sure why this is needed, this is from some internal PM stuff
    const atEnd = $to.parentOffset === $to.parent.content.size
    if (!atEnd) {
      return false
    }
    const tr = viewState.tr
    const type = viewState.schema.nodes[NodeTypeMap.DUAL_DIALOGUE]
    const node = type.createAndFill()
    if (node) tr.replaceRangeWith($from.pos, $to.pos, node)

    // creating a text selection that is two positions further than where we
    // started, which should be the first character node
    const modifiedSelection = TextSelection.findFrom(
      tr.doc.resolve($from.pos + 2),
      1,
      true,
    )

    if (modifiedSelection) {
      tr.setSelection(modifiedSelection)
    }

    viewDispatch(tr.scrollIntoView())
  }
  return true
}

// insert hard break (inline node) in current block
export const insertHardBreak: Command = (state, dispatch) => {
  dispatch?.(
    state.tr
      .replaceSelectionWith(schema.nodes[NodeTypeMap.HARD_BREAK].create())
      .scrollIntoView(),
  )
  return true
}
