/*
  This lets you print HTML via docraptor using all the css & styles in the <head>
  of the app. In dev environments, the <head> is HUGE because of style-loader. We
  cannot POST a 6MB file to our api server so we go directly to docraptor from
  the browser (in dev environments). In production environments, the styles are
  all bundled into a css file and we simply make the link href absolute so
  docraptor can download it separately
*/
import React from 'react'

import { useMst } from '@state'

import { DatadogClient } from '../datadog'
import { getBlobAndPrint, getBlobAndSave } from '../printing/blobs'

import { buildPrintableRundownHtml } from './buildPrintableRundownHtml'
import { usePassthroughPrinting } from './usePassthroughPrinting'

const ddLog = DatadogClient.getInstance()

export const usePrincePrintableRef = <T extends HTMLElement = HTMLDivElement>(
  title: string,
) => {
  const [loading, setLoading] = React.useState(false)
  const { print } = usePassthroughPrinting()
  const printRef = React.useRef<T>(null)
  const { doDebug, user } = useMst()
  const [errorMessage, setErrorMessage] = React.useState('')

  const getHtml = (omitHeaderFooter?: boolean) =>
    buildPrintableRundownHtml({
      ref: printRef,
      title,
      prefs: user.rundownPrintPrefs,
      omitHeaderFooter,
    })

  const handlePrint = async (
    download: boolean,
  ): Promise<boolean | undefined> => {
    setErrorMessage('')
    if (!printRef.current) {
      return
    }
    setLoading(true)
    const fileName = `${title}.pdf`
    try {
      const downloadFn = () => print({ html: getHtml(), fileName })
      await doDebug()
      if (download) {
        await getBlobAndSave({ downloadFn, fileName })
      } else {
        await getBlobAndPrint(downloadFn)
      }
      return true
    } catch (err) {
      ddLog.error('pass through printing failed', undefined, err)
      setErrorMessage(download ? 'Failed to export' : 'Failed to print')
    } finally {
      setLoading(false)
    }
  }

  const showDebugHtml = () => {
    const win = window.open()
    win?.document.write(getHtml(true))
  }

  return {
    loading,
    printRef,
    handlePrint,
    showDebugHtml,
    errorMessage,
  }
}
