import React from 'react'

import { useInterval } from '@mantine/hooks'

import { ILoadedScript } from '@state'

const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nibh ante, laoreet vel libero a, pellentesque semper ex. Vestibulum lacus velit, vehicula vel libero eu, posuere tempus magna. Cras cursus molestie accumsan. In ultrices nisl id malesuada sagittis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum in purus vitae nulla rhoncus efficitur sed at mauris. Phasellus molestie, libero non porta congue, ligula erat porta ipsum, et hendrerit sem diam vitae arcu. Praesent non lobortis tellus. Nulla facilisi. Quisque lobortis orci nec volutpat tincidunt.'
const SIZE = LOREM_IPSUM.length

export const useMonkeyTyping = (script: ILoadedScript) => {
  const [idx, setIdx] = React.useState(0)
  const [isTyping, setIsTyping] = React.useState(false)

  const interval = useInterval(() => {
    const { editorView } = script.pmEditor
    if (editorView) {
      editorView.focus()
      const letterToType = idx < SIZE ? LOREM_IPSUM[idx] : '\n\n'
      document.execCommand('insertText', false, letterToType)
      setIdx(idx < SIZE ? idx + 1 : 0)
    }
  }, 250)

  React.useEffect(() => {
    return interval.stop
  }, [interval.stop])

  const toggleTyping = () => {
    if (isTyping) {
      interval.stop()
      setIsTyping(false)
    } else {
      interval.start()
      setIsTyping(true)
    }
  }

  return {
    isTyping,
    toggleTyping,
  }
}
