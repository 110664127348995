import React from 'react'

import { ICommentThread, ILoadedScript } from '@state'
import { fireAndForget, noop } from '@util'

import * as domHelpers from './domHelpers'
import { ThreadOperations, ThreadPojo } from './types'

// if the user is editing an existing comment, we need to know
// the state of that to know whether to block de-selection
type EditedComment = {
  id: string
  originalText: string
  newText: string
}

export type ThreadContextState = {
  thread: ThreadPojo
  threadSelected: boolean
  operations: ThreadOperations
  // when a comment is being edited
  // editingCommentId?: string
  // setEditingCommentId: (commentId: string) => void
  // when adding a new comment
  unsavedNewCommentText?: string
  setUnsavedNewCommentText: (text: string) => void
  editedComment: EditedComment | null
  setEditedComment: (value: EditedComment | null) => void
}

export const ThreadContext = React.createContext<ThreadContextState>({
  thread: {
    id: 'dummy',
    snippet: 'dummy',
    updatedAt: new Date(),
    resolvedAt: new Date(),
    comments: [],
  },
  threadSelected: false,
  operations: {},
  setUnsavedNewCommentText: noop,
  editedComment: null,
  setEditedComment: noop,
})

export const OpenThreadProvider = ({
  script,
  thread,
  children,
}: {
  script: ILoadedScript
  thread: ICommentThread
  children: React.ReactNode
}) => {
  const { commentData } = script
  const [editedComment, setEditedComment] =
    React.useState<EditedComment | null>(null)

  const threadSelected = commentData.selectedThreadId === thread.id
  const [unsavedNewCommentText, setUnsavedNewCommentText] = React.useState('')

  React.useEffect(() => {
    if (!threadSelected && editedComment) {
      setEditedComment(null)
    }
  }, [editedComment, threadSelected])

  const operations: ThreadOperations = {
    changeThreadResolution: () =>
      fireAndForget(script.resolveCommentThread(thread.id)),
    addComment: (text: string) =>
      script.addCommentToThread({ threadId: thread.id, text }),
    editComment: (opts: { commentId: string; text: string }) => {
      fireAndForget(script.editComment(opts))
      setEditedComment(null)
    },
    deleteComment: (commentId: string) => {
      const comment = commentData.commentMap.get(commentId)
      if (comment) {
        fireAndForget(script.deleteComment(comment))
      }
    },
    selectThread: () => {
      commentData.selectThread(thread.id)
      // The node gets rerendered by prosemirror, setting a tiny delay
      // makes this scroll work most of the time
      setTimeout(() => {
        domHelpers.scrollToMark(thread.id, threadSelected)
      }, 30)
    },
  }

  return (
    <ThreadContext.Provider
      value={{
        thread,
        threadSelected,
        operations,
        unsavedNewCommentText,
        setUnsavedNewCommentText,
        editedComment,
        setEditedComment,
      }}
    >
      {children}
    </ThreadContext.Provider>
  )
}
