import { Fragment, useState } from 'react'

import { Container, Table } from '@mantine/core'

import { showError } from '@components/Modals'
import { Spinner } from '@components/Spinner'
import { IOrg, PermittedMembers } from '@state/types'

import { UserRow } from './PermissionUserRow'

import styles from './PermissionMemberTable.module.scss'

export const PermissionMemberTable = ({
  permissionCode,
  org,
  members,
}: {
  permissionCode: string
  org: IOrg
  members: PermittedMembers
}) => {
  const [loading, setLoading] = useState(false)

  const revokePermission = async (userId: string) => {
    setLoading(true)
    try {
      await org.revokeMemberPermission({ userId, permissionCode })
    } catch (e) {
      showError({
        message: 'Failed to remove user permission',
      })
    }
    setLoading(false)
  }

  return (
    <Container className={styles.membertablecontainer}>
      {loading && <Spinner delayMs={300} />}
      <Table className={styles.membertable} verticalSpacing="sm">
        <Table.Tbody>
          {members.map((user) => (
            <Fragment key={user.id}>
              <UserRow user={user} onRevoke={revokePermission} />
            </Fragment>
          ))}
        </Table.Tbody>
      </Table>
    </Container>
  )
}
