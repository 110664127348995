/*
  This helper class takes a prose doc and breaks it down block-by-block into
  a structure that can be rendered as our 2-column format. It's the structural
  logic for the TwoColumnScript component.
*/

import { Node as PmNode } from 'prosemirror-model'

import { createProsemirrorDoc } from '@util'
import { isManualBreak } from '@util/pagination/helpers'
import { ScriptJson } from '@util/ScriptoApiClient/types'

import {
  createNewRow,
  getColumnPlacement,
  isTwoColumnRow,
  Page,
  Row,
  TwoColBreakdown,
} from './helpers'

export class TwoColumnBreakdown {
  private readonly _breakdown: TwoColBreakdown = [
    {
      rows: [],
    },
  ]

  constructor({ scriptJson }: { scriptJson: ScriptJson }) {
    createProsemirrorDoc(scriptJson).content.forEach((node) => {
      this._processPageNode(node)
    })
  }

  private get _currentPage(): Page {
    return this._breakdown[this._breakdown.length - 1]
  }

  private get _currentPageIsBlank(): boolean {
    return this._currentPage.rows.length === 0
  }

  private get _currentRow(): Row | undefined {
    const { rows } = this._currentPage
    return rows[rows.length - 1]
  }

  private _appendBlockToPage = (blockNode: PmNode) => {
    const placement = getColumnPlacement(blockNode.type.name)

    // brackets and full-width blocks get their own row
    if (placement === 'left') {
      this._currentPage.rows.push(createNewRow(blockNode, 'left'))
    }
    if (placement === 'center') {
      this._currentPage.rows.push(createNewRow(blockNode, 'center'))
    }

    // right rows append if previous row is 2 column and it's not sealed
    // (which means there was a blank row)
    if (placement === 'right') {
      if (
        this._currentRow &&
        isTwoColumnRow(this._currentRow) &&
        !this._currentRow.sealed
      ) {
        this._currentRow.rightNodes.push(blockNode)
      } else {
        this._currentPage.rows.push(createNewRow(blockNode, 'right'))
      }
    }
  }

  private _processPageNode = (pageNode: PmNode) => {
    // A manual page break is a pageNode without pageNode.attrs.dynamic
    // Whenever we hit one, force a new 2-column page to start unless the
    // current page is blank
    if (!(this._currentPageIsBlank || pageNode.attrs.dynamic)) {
      this._breakdown.push({ rows: [] })
    }
    // now iterate through the blocks in the page, some of these may also
    // trigger new pages if we have breakOnSlugs set
    pageNode.forEach((blockNode) => {
      if (isManualBreak(blockNode) && !this._currentPageIsBlank) {
        this._breakdown.push({ rows: [] })
      }

      // we don't render extra whitespace for blank blocks but we DO ensure
      // that they start a new row.
      const blockIsBlank = blockNode.textContent.trim().length === 0
      if (blockIsBlank) {
        // If there's a current row that could take the next line, seal it,
        // otherwise just skip this row
        if (this._currentRow && isTwoColumnRow(this._currentRow)) {
          this._currentRow.sealed = true
        }
      } else {
        this._appendBlockToPage(blockNode)
      }
    })
  }

  get breakdown() {
    return this._breakdown
  }
}
