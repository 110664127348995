import { Center, Image } from '@mantine/core'

import CAMERA from '@assets/images/templates/camera.png'
import GAME from '@assets/images/templates/game.png'
import TV from '@assets/images/templates/tv.png'
import { extractFaIconInfo, SmartFaIcon } from '@components/FaIcon'

import styles from './TemplateImage.module.scss'

// DO NOT CHANGE THESE NAMES!! They are referenced in the database
// as identifiers for template images
export const TEMPLATE_IMAGE_PNG = {
  CAMERA,
  GAME,
  TV,
}

export type TemplateImageAsset = keyof typeof TEMPLATE_IMAGE_PNG
export function isTemplateImageAsset(
  value: string,
): value is TemplateImageAsset {
  return !!TEMPLATE_IMAGE_PNG[value as TemplateImageAsset]
}

type Props = {
  icon: string
}

export const TemplateImage = ({ icon }: Props) => {
  const isFaIcon = !!extractFaIconInfo(icon).iconName

  if (isFaIcon) {
    return (
      <Center className={styles.templateImage}>
        <SmartFaIcon className={styles.faIcon} icon={icon} />
      </Center>
    )
  }

  // use the TV as a fallback in case of bad data
  const imgSrc = isTemplateImageAsset(icon)
    ? TEMPLATE_IMAGE_PNG[icon]
    : TEMPLATE_IMAGE_PNG.TV

  return <Image className={styles.templateImage} src={imgSrc} />
}
