import { NumberInput as NumberInputComponent } from '@mantine/core'

import styles from './input.module.scss'

export const NumberInput = NumberInputComponent.extend({
  defaultProps: {
    size: 'md',
  },
  classNames: {
    input: styles.numberInput,
  },
})
