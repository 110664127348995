import React from 'react'

import { Popover } from '@mantine/core'

import { util as codexUtil } from '@showrunner/codex'

import { Toolbar } from '@components/Toolbar'
import { Keys, useShortcuts } from '@hooks'
import { ButtonMode } from '@state/types'
import { formatSeconds } from '@util'

import { TimingInfo } from './TimingInfo'

export const TimingPopover = ({
  mode,
  docTiming,
  selectionTiming,
  height,
}: {
  docTiming: codexUtil.TimingSummary
  selectionTiming?: codexUtil.TimingSummary
  mode: ButtonMode
  height: number
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const togglePopover = () => setIsOpen(!isOpen)

  const { getItemProps } = useShortcuts({
    timing: {
      keys: [Keys.CMD, Keys.SHIFT, '0'],
      action: togglePopover,
    },
  })

  const tooltip = `Script timing (${getItemProps('timing').shortcut})`

  const btnProps = {
    icon: 'fa-clock' as `fa-${string}`,
    label: formatSeconds(docTiming.totalSeconds),
    tooltip,
    dropdown: true,
    mode,
    onClick: togglePopover,
    disableTooltip: isOpen,
  }

  return (
    <Popover
      returnFocus
      opened={isOpen}
      position="bottom-start"
      shadow="xl"
      width={250}
      onChange={setIsOpen}
    >
      <Popover.Target>
        <Toolbar.Button {...btnProps} />
      </Popover.Target>
      <Popover.Dropdown pb={0} pt={0} px={0}>
        {/* only render innards when open to avoid unncessary re-renders */}
        {isOpen && (
          <TimingInfo
            containerHeight={height}
            docTiming={docTiming}
            selectionTiming={selectionTiming}
          />
        )}
      </Popover.Dropdown>
    </Popover>
  )
}
