import {
  ApiFetcherArgs,
  AppRoute,
  initClient,
  InitClientReturn,
  tsRestFetchApi,
} from '@ts-rest/core'
import { nanoid } from 'nanoid'

import { legacyApi, scrapi } from '@showrunner/scrapi'

import { IRoot } from '@state'
import { authToken } from '@util/authToken'
import {
  AUTH_HEADER,
  SCRIPTO_REQUEST_ID_HEADER,
  SCRIPTO_WORKSPACE_HEADER,
} from '@util/constants'
import { DatadogClient } from '@util/datadog'
import { handle401 } from '@util/handle401'

const ddlog = DatadogClient.getInstance()

// these are the routes that rely on cookies. We want to send any requests
// for these routes to the front-end URL relyng on the proxy so that the cookie
// goes to the same origin
const routeAffectsCookie = (route: AppRoute): boolean => {
  return (
    route === scrapi.auth.login ||
    route === scrapi.auth.logout ||
    route === scrapi.auth.status
  )
}

const ClientArgs = {
  baseUrl: '',
  credentials: 'include',
  baseHeaders: {
    'content-type': 'application/json',
  },
} as const

export type ScrapiClient = InitClientReturn<typeof scrapi, typeof ClientArgs>

type TsFetchResponse = {
  status: number
  headers: Headers
  body: unknown
}
const errorResponse = (e: unknown): TsFetchResponse => ({
  status: 0,
  headers: new Headers(),
  body: e,
})

export const buildScrapiClient = (mst: IRoot): ScrapiClient => {
  const { NIDO_URL } = mst.environment.config

  return initClient(scrapi, {
    baseUrl: '',
    credentials: 'include',
    baseHeaders: {
      'content-type': 'application/json',
    },
    api: async (args: ApiFetcherArgs) => {
      const reqId = nanoid()
      const { path } = args
      // splice in the abort signal if one is set via debug tools
      args.signal = window.d?.getAbortSignal()
      args.headers[SCRIPTO_REQUEST_ID_HEADER] = reqId

      const callNidoWithoutProxy = !routeAffectsCookie(args.route)
      if (callNidoWithoutProxy) {
        args.path = NIDO_URL + path
        args.headers[AUTH_HEADER] = authToken.get() ?? ''
      }

      if (mst.currentOrg) {
        args.headers[SCRIPTO_WORKSPACE_HEADER] = mst.currentOrg.id
      }

      const startTime = Date.now()
      const result = await tsRestFetchApi(args).catch(errorResponse)

      ddlog.logApiRequest({
        startTime,
        reqId,
        path,
        method: args.method,
        status: result.status,
        error: result.body,
      })

      if (result.status === 401) {
        await handle401()
      }
      return result
    },
  })
}

export const buildLegacyApiClient = (baseUrl: string) =>
  initClient(legacyApi, {
    baseUrl,
    baseHeaders: {
      'content-type': 'application/json',
    },
    credentials: 'include',
    api: async (args) => {
      const result = await tsRestFetchApi(args)
      if (result.status === 401) {
        await handle401()
      }
      return result
    },
  })
export type LegacyApiClient = ReturnType<typeof buildLegacyApiClient>
