import { Node as PmNode } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import { DecorationSet } from 'prosemirror-view'

import { FragmentDiff } from '@util/diffing/FragmentDiff'

import * as asterisks from './asterisks'
import * as helpers from './helpers'

export const inlineChangesPlugin = ({
  snapshotDoc,
}: {
  snapshotDoc: PmNode
}): Plugin<helpers.RevisionPluginState> => {
  return new Plugin({
    key: helpers.PLUGIN_KEY,
    state: {
      init(config, editorState) {
        const diff = new FragmentDiff({
          left: snapshotDoc.content,
          right: editorState.doc.content,
        })

        const { changed, removed } = diff.getChangePositionsForSide('right')
        return {
          changedRanges: changed,
          removedPositions: removed,
          identical: diff.identical,
        }
      },

      // we don't recompute anything
      apply(tr, pluginState) {
        return pluginState
      },
    },
    props: {
      decorations(editorState) {
        const pluginState = helpers.PLUGIN_KEY.getState(editorState)
        if (pluginState) {
          const decorations = [
            ...pluginState.changedRanges.map(helpers.buildChangedDecoration),
            ...pluginState.removedPositions.map(helpers.buildRemovedDecoration),
          ]
          const decorationSet = DecorationSet.create(
            editorState.doc,
            decorations,
          )
          return decorationSet
        }
      },
    },
    view(editorView) {
      const pluginState = helpers.PLUGIN_KEY.getState(editorView.state)
      const asteriskData = asterisks.buildAsteriskData({
        editorView,
        changes: pluginState?.changedRanges ?? [],
        removals: pluginState?.removedPositions ?? [],
      })
      asterisks.insertAsteriskNodes(asteriskData)

      return {
        update() {
          asterisks.removeAsteriskNodes()
          asterisks.insertAsteriskNodes(asteriskData)
        },
        destroy() {
          asterisks.removeAsteriskNodes()
        },
      }
    },
  })
}
