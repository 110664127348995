import { Anchor, Divider, Space, Stack } from '@mantine/core'
import cn from 'classnames'

import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'

import styles from './OnboardingLinks.module.scss'

const handleContactSupport = () => window.Intercom?.('show')

type OnboardingLinkType = 'forgotpassword' | 'support' | 'backtologin'
type OnboardingLinkItem = OnboardingLinkType | 'divider'

const linkHref: Partial<Record<OnboardingLinkType, NavAnchorHref>> = {
  forgotpassword: '/forgot-password',
  backtologin: '/login',
}

const linkText: Record<OnboardingLinkType, string> = {
  forgotpassword: 'Forgot Password?',
  support: 'Contact Support',
  backtologin: 'Back to Sign In',
}

const OnboardingLink = ({ type }: { type: OnboardingLinkType }) => {
  const href = linkHref[type]

  if (href) {
    return (
      <NavAnchor
        href={href}
        className={styles.onboardingLink}
        onClick={type === 'support' ? handleContactSupport : undefined}
        tabIndex={0}
      >
        {type === 'backtologin' && (
          <i className={cn('fas fa-arrow-left-long', styles.backarrow)} />
        )}
        {linkText[type]}
      </NavAnchor>
    )
  }
  return (
    <Anchor
      href={href}
      className={styles.onboardingLink}
      onClick={type === 'support' ? handleContactSupport : undefined}
      tabIndex={0}
    >
      {type === 'backtologin' && (
        <i className={cn('fas fa-arrow-left-long', styles.backarrow)} />
      )}
      {linkText[type]}
    </Anchor>
  )
}

export const OnboardingLinks = ({ types }: { types: OnboardingLinkItem[] }) => (
  <>
    <Space h="xs" />
    <Stack gap="sm" align="center">
      {types.map((type) => {
        return type === 'divider' ? (
          <Divider size="xs" key="divider" className={styles.divider} />
        ) : (
          <OnboardingLink type={type} key={type} />
        )
      })}
    </Stack>
  </>
)
