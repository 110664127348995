import { Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { ExplorerView } from '@components/ExplorerView'
import { FolderExplorerToastHost } from '@components/Toast'
import { useMst } from '@state'
import {
  CollectionId,
  IFolder,
  IListing,
  IRoot,
  isCollectionId,
  isOpenedListing,
} from '@state/types'
import { pluralize } from '@util'
import { SortOrder } from '@util/LocalPersistence'

import { showCreateDocModal } from './CreateDocModal'
import {
  FavoritesList,
  FolderContentsList,
  RecentlyEditedList,
} from './DocumentList'
import { ExplorerFolderList } from './FolderList'
import { collectionItemConfig } from './FolderList/folderListHelpers'
import { MyHistoryList } from './MyHistoryList'
import { SortMenu } from './SortMenu'

import styles from './FolderExplorer.module.scss'

export const FOLDER_SECTION_ID = 'folders'
const DOCUMENTS_SECTION_ID = 'documents'

const FolderHeader = observer(function FolderHeader() {
  const { view, folderMap, currentOrg } = useMst()
  const { selectedFolderId, selectedFolderIsCollection } = view
  const selectedFolder = folderMap.get(selectedFolderId)
  const { isSectionCollapsed, toggleSectionCollapsed } = view.explorerState
  // we're only showing a document list if there's a selected folder or
  // selected collection
  const documentListActive = !!selectedFolder || selectedFolderIsCollection
  const isExpanded = !isSectionCollapsed(FOLDER_SECTION_ID)

  const expandIcon = !documentListActive
    ? undefined
    : isExpanded
      ? 'point-down'
      : 'point-right'

  return (
    <ExplorerView.Header
      enableCollapse={documentListActive}
      title={<span className={styles.folderTreeTitle}>{currentOrg?.name}</span>}
      expandIcon={expandIcon}
      onClick={() => toggleSectionCollapsed(FOLDER_SECTION_ID)}
    />
  )
})

const CollectionHeader = observer(function CollectionHeader({
  docCount,
  collectionId,
}: {
  docCount: number
  collectionId: CollectionId
}) {
  const { user, view } = useMst()
  const { isSectionCollapsed, toggleSectionCollapsed } = view.explorerState
  const isExpanded = !isSectionCollapsed(DOCUMENTS_SECTION_ID)
  const toggleExpanded = () => toggleSectionCollapsed(DOCUMENTS_SECTION_ID)
  const isSortable = collectionId === 'favorites'
  const sortOrder = user.getFolderSortOrder(collectionId)
  const setSortOrder = (order: SortOrder) =>
    user.setCollectionSortOrder(collectionId, order)

  const prefix = isSortable
    ? `${docCount} ${pluralize(docCount, 'doc')}`
    : 'Docs'

  const label = (
    <>
      <Text span>{prefix + ' in '}</Text>
      <Text span fw="bold">
        {collectionItemConfig[collectionId].label.toLowerCase()}
      </Text>
    </>
  )

  return (
    <ExplorerView.Header
      enableCollapse
      expandIcon={isExpanded ? 'point-down' : 'point-right'}
      onClick={toggleExpanded}
      title={
        isSortable ? (
          <SortMenu
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            label={label}
          />
        ) : (
          label
        )
      }
    />
  )
})

const DocumentsHeaderLabel = ({
  folderName,
  docCount,
  showDocCount,
}: {
  folderName: string
  docCount: number
  showDocCount: boolean
}) => (
  <>
    {showDocCount && (
      <Text span>{`${docCount} ${pluralize(docCount, 'doc')} in `}</Text>
    )}
    <Text span fw="bold">
      {folderName}
    </Text>
  </>
)

const DocumentsHeader = observer(function DocumentsHeader({
  parentFolder,
}: {
  parentFolder: IFolder
}) {
  const { currentOrg, view } = useMst()
  const { isSectionCollapsed, toggleSectionCollapsed } = view.explorerState
  const isExpanded = !isSectionCollapsed(DOCUMENTS_SECTION_ID)
  const toggleExpanded = () => toggleSectionCollapsed(DOCUMENTS_SECTION_ID)

  if (!parentFolder) {
    return null
  }

  const showCreateDocButton = currentOrg && !parentFolder.belongsToTrashTree
  const createDocButton = showCreateDocButton && (
    <ExplorerView.HeaderButton
      tooltip={`Create document in ${parentFolder.displayName}`}
      icon="fa-file-plus"
      iconSet="fa-regular"
      onClick={() =>
        showCreateDocModal({
          folder: parentFolder,
          workspace: currentOrg,
          from: 'document pane',
        })
      }
    />
  )

  return (
    <ExplorerView.Header
      enableCollapse
      expandIcon={isExpanded ? 'point-down' : 'point-right'}
      onClick={toggleExpanded}
      title={
        <SortMenu
          label={
            <DocumentsHeaderLabel
              folderName={parentFolder.displayName}
              docCount={parentFolder.documentCount}
              showDocCount={!!parentFolder.contents}
            />
          }
          sortOrder={parentFolder.sortOrder}
          setSortOrder={parentFolder.setSortOrder}
        />
      }
      buttons={createDocButton}
    />
  )
})

const getCurrentListings = (mst: IRoot): IListing[] => {
  const {
    selectedFolderId,
    selectedFolderIsFavorites,
    selectedFolderIsRecent,
    selectedFolderIsMyHistory,
  } = mst.view

  if (selectedFolderIsFavorites) return mst.favoriteListings
  if (selectedFolderIsRecent) return mst.recentListings
  if (selectedFolderIsMyHistory) return mst.myHistoryListings

  const currentFolder = mst.folderMap.get(selectedFolderId)
  return currentFolder?.sortedDocuments ?? []
}

export const FolderExplorer = observer(function FolderExplorer() {
  const mst = useMst()
  const { currentFolder, currentOrg, view } = mst
  const { selectedFolderId } = view
  const listings = getCurrentListings(mst)
  const isCollection = isCollectionId(selectedFolderId)
  const isFavorites = view.selectedFolderIsFavorites
  const isRecent = view.selectedFolderIsRecent
  const isMyHistory = view.selectedFolderIsMyHistory

  return (
    <ExplorerView>
      <FolderHeader />
      <ExplorerView.Content sectionId="folders" enableCollapse>
        <ExplorerFolderList />
      </ExplorerView.Content>
      {currentFolder && <DocumentsHeader parentFolder={currentFolder} />}
      {isCollection && (
        <CollectionHeader
          collectionId={selectedFolderId}
          docCount={listings.length}
        />
      )}
      <ExplorerView.Content sectionId="documents" enableCollapse>
        {isFavorites && <FavoritesList documents={listings} />}
        {isRecent && currentOrg && <RecentlyEditedList org={currentOrg} />}
        {isMyHistory && (
          <MyHistoryList documents={listings.filter(isOpenedListing)} />
        )}
        {!isCollection && currentFolder && (
          <FolderContentsList
            folder={currentFolder}
            key={view.selectedFolderId}
          />
        )}
      </ExplorerView.Content>
      <FolderExplorerToastHost />
    </ExplorerView>
  )
})
