import { Tooltip as TooltipComponent } from '@mantine/core'

export const Tooltip = TooltipComponent.extend({
  defaultProps: {
    events: {
      focus: true, // this is the only one we're customizing 🙃
      hover: true,
      touch: false,
    },
    openDelay: 300,
  },

  styles: {
    // slightly more compact than the default
    tooltip: { padding: '3px 8px' },
  },
})
