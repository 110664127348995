import React from 'react'

import { observer } from 'mobx-react-lite'

import { Explorer } from '@components/Explorer'
import { useMst } from '@state'
import {
  CLOSED_SIDEBAR_WIDTH,
  DEFAULT_SIDEBAR_WIDTH,
  MIN_OPEN_SIDEBAR_WIDTH,
} from '@util/constants'

import { SideBySide, SideBySideProps } from './SideBySide'

import styles from './SidebarAndBody.module.scss'

const limits: SideBySideProps['limits'] = {
  firstChild: {
    minWidth: CLOSED_SIDEBAR_WIDTH,
    minHeight: Infinity,
  },
  secondChild: {
    minWidth: 200,
    minHeight: Infinity,
  },
}

export const SidebarAndBody = observer(function SidebarAndBody({
  children,
}: {
  children: JSX.Element
}) {
  const { view } = useMst()
  const { isExplorerClosed, explorerOpenWidth, enabledViews } =
    view.explorerState

  const openWidth: number = explorerOpenWidth
    ? Math.max(explorerOpenWidth, MIN_OPEN_SIDEBAR_WIDTH)
    : DEFAULT_SIDEBAR_WIDTH

  const handleDragStop = (newWidth: number) => {
    view.explorerState.setDragging(false)
    if (newWidth < MIN_OPEN_SIDEBAR_WIDTH) {
      view.explorerState.setExplorerClosed(true)
    } else {
      view.explorerState.setExplorerWidth(newWidth)
      view.explorerState.setExplorerClosed(false)
    }
  }

  const sidebarWidth = isExplorerClosed ? CLOSED_SIDEBAR_WIDTH : openWidth

  // set a css var to make the toast be slightly less than the width of the opened
  // side bar (a min width is set in its css)
  const toastWidthStyle = {
    '--toastify-toast-width': `${openWidth - 65}px`,
  } as React.CSSProperties

  return (
    <div className={styles.sidebarAndBody} style={toastWidthStyle}>
      <SideBySide
        layout="columns"
        limits={limits}
        onResizeStart={() => view.explorerState.setDragging(true)}
        onResizeEnd={({ width }) => {
          if (typeof width === 'number') {
            handleDragStop(width)
          }
        }}
        preferredWidth={sidebarWidth}
        disabled={enabledViews.length === 0}
      >
        <Explorer />
        {children}
      </SideBySide>
    </div>
  )
})
