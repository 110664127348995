import { Badge, Card, Group, Text } from '@mantine/core'
import { format } from 'date-fns'

import { IPrompterSegment } from '@state/types'

import styles from './PushToPrompterModal.module.scss'

export const SimplePush = ({
  scriptName,
  segment,
}: {
  scriptName: string
  segment: IPrompterSegment
}) => {
  const formattedName = (
    <Text fw="bold" className={styles.promptermodal_text}>
      {segment.slugTitle.length < 1
        ? scriptName
        : segment.slugTitle.toUpperCase()}
    </Text>
  )

  const { hasChanged, hasBeenPushed, lastPushedAt } = segment

  if (!hasBeenPushed || !lastPushedAt) {
    return (
      <Card className={styles.promptermodal_card}>
        <Group justify="space-between" wrap="nowrap">
          {formattedName}
          <Badge color="orange" className={styles.promptermodal_badge}>
            New
          </Badge>
        </Group>
        <Text c="dimmed" className={styles.promptermodal_text}>
          Never pushed to prompter
        </Text>
      </Card>
    )
  }

  return (
    <Card className={styles.promptermodal_card}>
      <Group justify="space-between" wrap="nowrap">
        {formattedName}&nbsp;
        {hasChanged && (
          <Badge color="orange" className={styles.promptermodal_badge}>
            Changed
          </Badge>
        )}
      </Group>
      <Text c="dimmed" className={styles.promptermodal_text}>
        {hasChanged ? 'C' : 'Unc'}
        hanged since last push at&thinsp;
        {format(new Date(lastPushedAt), "M/d/yyyy 'at' h:mmaaa")}
      </Text>
    </Card>
  )
}
