import { Group, Space, Title } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { IOrg, useMst } from '@state'

import { useInviteData } from '../useInviteData'

import { CreateInviteButton } from './CreateInviteButton'
import { MemberTable } from './MemberTable'

import styles from './MemberSettings.module.scss'

export const MemberSettingsInternal = observer(function MemberSettings({
  org,
}: {
  org: IOrg
}) {
  const { user } = useMst()

  const { openInviteCount: inviteCount } = useInviteData(org)
  const memberCount = org.members.length

  let countMeta = ''
  if (user.isCurrentOrgAdmin && inviteCount > 0) {
    countMeta += `${inviteCount} Open Invitation${
      inviteCount === 1 ? '' : 's'
    }, `
  }
  countMeta += `${memberCount} Workspace Member${memberCount === 1 ? '' : 's'}`

  const subheader = user.isCurrentOrgAdmin ? (
    <Group justify="space-between" align="center">
      <Title order={3}>{countMeta}</Title>
      <CreateInviteButton inviteCount={inviteCount} workspace={org} />
    </Group>
  ) : (
    <Title order={3}>{countMeta}</Title>
  )

  return (
    <div className={styles.memberSettings_page}>
      <h1>Members</h1>
      {subheader}
      <Space h="sm" />
      <MemberTable workspace={org} />
    </div>
  )
})

export const MemberSettings = () => {
  const { currentOrg } = useMst()
  if (!currentOrg) {
    return null
  }
  return <MemberSettingsInternal org={currentOrg} />
}
