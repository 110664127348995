import { FormEvent, useRef, useState } from 'react'

import { Button, Container, TextInput } from '@mantine/core'
import { email as sidewayEmail } from '@sideway/address'
import { isAxiosError } from 'axios'

import { showError } from '@components/Modals'
import { NavAnchor } from '@components/NavAnchor'
import { useEffectOnlyOnce } from '@hooks'
import { useMst } from '@state'
import { StaffInvite } from '@util/ScriptoApiClient/types'

import { InviteTable } from './InviteTable'

export const Invites = () => {
  const { apiClient, location } = useMst()
  const createInputRef = useRef<HTMLInputElement>(null)
  const filterInputRef = useRef<HTMLInputElement>(null)
  const [invites, setInvites] = useState<StaffInvite[]>([])
  const emailQueryParam = location.getQueryParam('email')

  async function fetchInvites() {
    const response = await apiClient.getInvitesByStaff(emailQueryParam)
    setInvites(response)
  }

  useEffectOnlyOnce(fetchInvites)

  const filterInvites = (event: FormEvent) => {
    event.preventDefault()
    if (!filterInputRef.current) return
    const email = filterInputRef.current.value.trim()
    const { pathname } = window.location
    window.location.replace(
      sidewayEmail.isValid(email) ? pathname + `?email=${email}` : pathname,
    )
  }

  const createInvite = async (event: FormEvent) => {
    event.preventDefault()
    if (!createInputRef.current) return
    // dummy emails make it convenient to generate a code and instant url.
    const email =
      createInputRef.current.value.trim() ||
      `jane+${Date.now()}@scripto.computer`
    if (sidewayEmail.isValid(email)) {
      try {
        await apiClient.createInviteByStaff({ email })
        emailQueryParam
          ? window.location.replace(window.location.pathname)
          : await fetchInvites()
      } catch (e) {
        const message =
          isAxiosError(e) && /Existing Invite/.test(e.response?.data.error)
            ? 'Email address already has an associated invite'
            : 'Error creating invite'

        showError(message)
      }
    }
  }

  return (
    <div>
      <h1>
        <NavAnchor href="/staff">Staff Zone</NavAnchor>
      </h1>
      <Container size={250}>
        <form onSubmit={createInvite}>
          <TextInput
            ref={createInputRef}
            label="Enter an email address"
            placeholder="jane@scripto.computer"
          ></TextInput>
          <Button type="submit">Request Invite</Button>
        </form>
        <form onSubmit={filterInvites}>
          <TextInput
            ref={filterInputRef}
            label="Find an invite"
            placeholder="name@email.com"
          ></TextInput>
        </form>
      </Container>
      <InviteTable invites={invites} />
    </div>
  )
}
