import { Menu } from '@mantine/core'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { Toolbar } from '@components/Toolbar'
import { Keys, useShortcuts } from '@hooks'
import { ButtonMode } from '@state'

type Props = {
  onPrint?: Callback
  onDownloadPdf?: Callback
  onDownloadFdx?: Callback
  disabled?: boolean
  fdxEnabled?: boolean
  mode: ButtonMode
}

export const ExportMenu = ({
  onPrint,
  onDownloadPdf,
  onDownloadFdx,
  disabled = false,
  fdxEnabled,
  mode,
}: Props) => {
  const { menuProps, getItemProps } = useShortcuts({
    print: {
      keys: [Keys.CMD, 'P'],
      action: () => onPrint?.(),
    },
    export: {
      keys: [Keys.CMD, Keys.SHIFT, 'E'],
      action: () => onDownloadPdf?.(),
    },
  })

  return (
    <Menu {...menuProps} position="bottom-end">
      <Menu.Target>
        <div>
          <Toolbar.Button
            label="Export"
            icon="fa-share-from-square"
            dropdown
            mode={mode}
            disabled={disabled}
          />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <FancyMenuItem title="Print..." {...getItemProps('print')} />
        <FancyMenuItem title="Export PDF" {...getItemProps('export')} />
        {fdxEnabled && (
          <FancyMenuItem
            title="Export to Final Draft"
            onClick={onDownloadFdx}
          />
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
