import { PopoverDropdownProps } from '@mantine/core'

export const COMMENT_BACKGROUND = {
  UNSELECTED: '#fff4dc',
  SELECTED: '#ffca52',
}

// when jumping to a specific comment in a panel thread
// this keyframe animation is triggered to give the comment
// const highlightBackground = `${COMMENT_BACKGROUND.SELECTED}40`
const threadCommentAnimation: Keyframe[] = [
  {
    backgroundColor: '#f1e6ff',
    outline: '5px #f1e6ff solid',
  },
  {
    backgroundColor: '#f1e6ff',
    offset: 0.25,
    outline: '5px #f1e6ff solid',
  },
  {
    backgroundColor: 'transparent',
    outline: '5px transparent solid',
  },
]

// exported to share with storybook
export const POPOVER_PROPS: Partial<PopoverDropdownProps> = {
  w: 350,
  p: 0,
}

// various DOM nodes get data-* props to enable scrolling
const PANEL_THREAD_ATTR = 'data-panel-thread'
const PANEL_COMMENT_ATTR = 'data-panel-comment'
const GUTTER_COUNT_ATTR = 'data-thread-ids'
const PM_MARK_ID_ATTR = 'data-thread-id'
const PM_MARK_STATUS_ATTR = 'data-thread-status'

const RESOLVED = 'resolved'
const OPEN = 'open'

export const COMMENT_DATA_ATTRIBUTE = {
  PANEL_THREAD_ATTR,
  PANEL_COMMENT_ATTR,
  GUTTER_COUNT_ATTR,
  PM_MARK_ID_ATTR,
  PM_MARK_STATUS_ATTR,
} as const

export const COMMENT_STATUS = {
  RESOLVED,
  OPEN,
} as const

type CommentAttribute = ValueOf<typeof COMMENT_DATA_ATTRIBUTE>
type SingleIdCommentAtrribute = Exclude<
  CommentAttribute,
  typeof GUTTER_COUNT_ATTR
>

// create spreadable prop for react components
export const spreadableProps = (
  key: SingleIdCommentAtrribute,
  value: string,
) => ({
  [key]: value,
})

// the data-thread-ids prop is a whitespace separated list of
// ids. We use whitespace to support the ~= selector.
export const gutterCountProp = (threadIds: string[]) => ({
  [GUTTER_COUNT_ATTR]: threadIds.join(' '),
})

// build a queryselector like `[data-thread-id~="abc123"]
// see https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors#attrvalue_2
export const idSelector = (attribute: CommentAttribute, id: string) =>
  `[${attribute}~="${id}"]`

export const openStatusSelector = `[${PM_MARK_STATUS_ATTR}="${OPEN}"]`

// selector to let us style the comment mark in the doc
// for any comment open comment it's [data-thread-id][data-thread-status="open"]
// for the active thread the selector is [data-thread-id="xxx"][data-thread-status="open"]
export const openMarkSelector = (threadId?: string) => {
  const markSelector = threadId
    ? idSelector('data-thread-id', threadId)
    : `[${PM_MARK_ID_ATTR}]`
  const statusSelector = `[${PM_MARK_STATUS_ATTR}="${OPEN}"]`

  return markSelector + statusSelector
}

export const findCommentMark = (threadId: string): HTMLElement | null => {
  const selector = idSelector('data-thread-id', threadId)
  const node = document.querySelector(selector)
  return node instanceof HTMLElement ? node : null
}

const scrollTo = (
  attribute: CommentAttribute,
  id: string,
  block: ScrollLogicalPosition = 'start',
) => {
  const selector = idSelector(attribute, id)
  const elt = document.querySelector(selector)
  if (elt) {
    elt.scrollIntoView({
      behavior: 'smooth',
      block,
    })
  }
}

const highlightPanelComment = (commentId: string) => {
  const elt = document.querySelector(
    idSelector('data-panel-comment', commentId),
  )
  if (elt instanceof HTMLElement) elt.animate(threadCommentAnimation, 2000)
}

export const scrollCommentPanel = (threadId: string, commentId?: string) => {
  if (commentId) {
    scrollTo('data-panel-comment', commentId)
    highlightPanelComment(commentId)
  } else {
    scrollTo('data-panel-thread', threadId)
  }
}

// This smooth scrolls the script to the PM mark. We use it when the user clicks
// the open thread in the already-opend panel. If that thread was
// already selected, then minimizeJitter is passed so that normal interaction
// with the comment panel doesn't cause micro-adjustments to to the
// script scroll position.
export const scrollToMark = (threadId: string, minimizeJitter: boolean) => {
  const block = minimizeJitter ? 'nearest' : 'center'
  scrollTo('data-thread-id', threadId, block)
}
