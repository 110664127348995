import {
  Button,
  Group,
  LoadingOverlay,
  MantineSize,
  Modal,
  Stack,
} from '@mantine/core'

import { Toast } from '@components/Toast'
import { noop } from '@util'

type ModalShellProps = {
  opened: boolean
  onClose: () => void
  title: string
  onConfirm?: () => void
  size?: MantineSize | number
  children?: React.ReactNode
  loading?: boolean
  disabled?: boolean
  dangerous?: boolean
  confirmLabel?: string
  cancelLabel?: string
  errorMessage?: string
  centered?: boolean
}

export const ModalShell = ({
  size,
  title,
  opened,
  onConfirm = noop,
  onClose,
  children,
  disabled,
  loading,
  dangerous,
  confirmLabel,
  cancelLabel,
  errorMessage,
  centered,
}: ModalShellProps) => {
  const confirmButtonVariant = dangerous ? 'danger' : undefined

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!(disabled || loading)) {
      onConfirm()
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={size}
      title={title}
      closeOnEscape={!loading}
      centered={centered}
    >
      <LoadingOverlay visible={!!loading} />
      <form onSubmit={handleSubmit}>
        <Stack>
          {children}

          {errorMessage && (
            <Toast
              icon="fa fa-exclamation-triangle"
              type="error"
              message={errorMessage}
              dismissable={false}
            />
          )}
          {(confirmLabel || cancelLabel) && (
            <Group gap="xs" justify="center">
              {confirmLabel && (
                <Button
                  type="submit"
                  variant={confirmButtonVariant}
                  disabled={disabled}
                  data-autofocus
                >
                  {confirmLabel}
                </Button>
              )}
              {cancelLabel && (
                <Button variant="outline" onClick={onClose}>
                  {cancelLabel}
                </Button>
              )}
            </Group>
          )}
        </Stack>
      </form>
    </Modal>
  )
}
