import { Node as PmNode } from 'prosemirror-model'
import { EditorState } from 'prosemirror-state'

import { createProsemirrorDoc, NavLinkData } from '@util'
import { DocAttrs, ScriptJson } from '@util/ScriptoApiClient/types'

export const createSectionDoc = ({
  existingDoc,
  sleneId,
  slenes,
}: {
  existingDoc: PmNode
  sleneId: string
  slenes: NavLinkData[]
}) => {
  const idx = slenes.findIndex((n) => n.id === sleneId)
  const lastPos = existingDoc.content.size - 1

  const from = slenes[idx].pos ?? 1
  const to = slenes[idx + 1]?.pos ?? lastPos

  const slice = existingDoc.slice(from, to)

  const boilerplate = { type: 'doc', content: [{ type: 'page', content: [] }] }
  const attrs = existingDoc.attrs as DocAttrs
  const doc = createProsemirrorDoc({ ...boilerplate, attrs })

  const throwawayState = EditorState.create({ doc })
  const tr = throwawayState.tr.replaceRange(1, 1, slice)
  return throwawayState.apply(tr).doc.toJSON() as ScriptJson
}
