import { reaction } from 'mobx'
import { types } from 'mobx-state-tree'

import { ClientToServerEvents } from '@showrunner/scrapi'

import { exposeDevTools } from '@config'
import { BaseModel } from '@state/models/BaseModel'
import { SOCKET_STATUS_EVENTS } from '@state/models/SocketManager/types'
import { ILoadedScript, IOrg, IRundown } from '@state/types'
import { authToken } from '@util/authToken'

import {
  connectSocket,
  createSocket,
  isItemUpdateEvent,
  isRundownEvent,
  isScriptEvent,
  isWorkspaceEvent,
  parseSocketEvent,
  RundownEventPayload,
  ScriptEventPayload,
  ScriptoSocketClient,
  WorkspaceEventPayload,
  WorkspaceItemUpdate,
} from './helpers'

const CONNECTION_STATUS_VALUES = ['connected', 'disconnected', 'error'] as const
type ConnectionStatus = (typeof CONNECTION_STATUS_VALUES)[number]

/*
  These events come from the server
*/
export const SocketManager = BaseModel.named('SocketManager')
  .props({
    status: types.optional(
      types.enumeration<ConnectionStatus>([...CONNECTION_STATUS_VALUES]),
      'disconnected',
    ),
    hasConnectedOnce: false,
    hasJoinedScript: false,
    socketId: '',
  })
  .views((self) => ({
    get connected() {
      return self.status === 'connected'
    },
    currentRundownForEvent(payload: RundownEventPayload): IRundown | undefined {
      const { currentRundown } = self.rootStore
      if (currentRundown) {
        if (currentRundown.id === payload.rundownId) {
          return currentRundown
        }
      }
      self.log.errorOnce(
        'wrong rundownId',
        {
          topic: 'sockets',
          currentRundownId: currentRundown?.id ?? 'missing',
          payload,
        },
        'mismatched-rundown-on-socket-message',
      )
    },
    currentScriptForEvent(
      payload: ScriptEventPayload,
    ): ILoadedScript | undefined {
      const { currentScript } = self.rootStore
      if (currentScript) {
        if (currentScript.id === payload.scriptId) {
          return currentScript
        }
      }

      // log but not too spammily to help figure out if
      // we're removing listeners appropriately
      self.log.warnOnce(
        'wrong scriptId',
        {
          topic: 'sockets',
          currentScriptId: currentScript?.id ?? 'missing',
          payload,
        },
        currentScript?.id ?? 'missing',
      )
    },
    currentWorkspaceForEvent(payload: WorkspaceEventPayload): IOrg | undefined {
      const { currentOrg } = self.rootStore
      if (currentOrg) {
        if (currentOrg.id === payload.orgId) {
          return currentOrg
        }
      }
      self.log.errorOnce(
        'wrong workspaceId',
        {
          topic: 'sockets',
          currentOrgId: currentOrg?.id ?? 'missing',
          payload,
        },
        currentOrg?.id ?? 'missing',
      )
    },
    checkCurrentWorkspace(payload: WorkspaceItemUpdate): boolean {
      const { currentOrg } = self.rootStore
      if (currentOrg?.id === payload.workspaceId) {
        return true
      }

      self.log.errorOnce(
        'wrong workspaceId on item payload',
        {
          topic: 'sockets',
          currentOrgId: currentOrg?.id ?? 'missing',
          payload,
        },
        'mismatched-org-on-socket-payload',
      )
      return false
    },
  }))
  .actions((self) => ({
    setStatus(status: ConnectionStatus) {
      self.status = status
      if (status === 'connected' && !self.hasConnectedOnce) {
        self.hasConnectedOnce = true
      }
    },
    setSocketId(id: string) {
      self.socketId = id
    },
  }))
  .extend((self) => {
    let maybeSocket: ScriptoSocketClient | null = null

    const attachListeners = (socket: ScriptoSocketClient) => {
      socket.on(SOCKET_STATUS_EVENTS.CONNECT, () => {
        self.setStatus('connected')
        self.setSocketId(socket.id ?? '')
      })

      socket.on(SOCKET_STATUS_EVENTS.DISCONNECT, () => {
        self.setStatus('disconnected')
        self.setSocketId('')
      })

      socket.on(SOCKET_STATUS_EVENTS.CONNECT_ERROR, (e: unknown) => {
        if (self.status !== 'error') {
          self.setStatus('error')
          if (!self.hasConnectedOnce) {
            self.log.error(
              'Socket connect error',
              {
                topic: 'sockets',
                lastStatus: self.status,
                isOnline: navigator.onLine,
                error: e,
              },
              e,
            )
          }
        }
      })

      socket.onAny((eventType: string, payload: unknown) => {
        const parsed = parseSocketEvent(eventType, payload)
        if (parsed) {
          // new universal workspaceItemUpdate event
          if (isItemUpdateEvent(parsed)) {
            if (self.checkCurrentWorkspace(parsed)) {
              self.rootStore.ingestWorkspaceItems([parsed.data])
            }
          } else if (isRundownEvent(parsed)) {
            self.currentRundownForEvent(parsed)?.handleSocketMessage(parsed)
          } else if (isScriptEvent(parsed)) {
            self.currentScriptForEvent(parsed)?.handleSocketMessage(parsed)
          } else if (isWorkspaceEvent(parsed)) {
            self.currentWorkspaceForEvent(parsed)?.handleSocketMessage(parsed)
          } else {
            switch (parsed.eventType) {
              // TODO: handle this better
              // This only triggers UI if there's a script loaded
              // (existing behavior March 2024)
              case 'NO_ACCESS':
                self.rootStore.currentScript?.handleSocketAccessError(
                  parsed.message,
                )
                break
            }
          }
        }
      })

      return maybeSocket
    }

    const getSocket = (): ScriptoSocketClient => {
      if (!maybeSocket) {
        const url = self.rootStore.view.useNidoSockets
          ? self.environment.config.NIDO_URL
          : self.environment.config.API_URL
        maybeSocket = createSocket(url, () => authToken.get() ?? '')
        attachListeners(maybeSocket)
        if (exposeDevTools()) {
          window.scriptoSocket = maybeSocket
        }
      }
      return maybeSocket
    }

    return {
      actions: {
        connectOnAuthReady() {
          getSocket().connect()
        },
        async connect() {
          return connectSocket(getSocket())
        },
        disconnect() {
          getSocket().disconnect()
        },
        joinRundown(rundownId: number) {
          if (getSocket().connected) {
            getSocket().emit('JOIN_RUNDOWN', rundownId)
          }
        },
        leaveRundown(rundownId: number) {
          if (getSocket().connected) {
            getSocket().emit('LEAVE_RUNDOWN', rundownId)
          }
        },
        joinWorkspace(workspaceId: string) {
          if (getSocket().connected) {
            getSocket().emit('JOIN_WORKSPACE', workspaceId)
          }
        },
        leaveWorkspace(workspaceId: string) {
          if (getSocket().connected) {
            getSocket().emit('LEAVE_WORKSPACE', workspaceId)
          }
        },
        checkLatency() {
          if (getSocket().connected) {
            const start = Date.now()
            // using volatile emit & callback pattern
            getSocket().volatile.emit('PING', () => {
              const latency = Date.now() - start
              // eslint-disable-next-line no-console
              console.log(`ping: ${latency}ms`)
            })
          }
        },

        joinScript(scriptId: string) {
          if (self.connected) {
            getSocket().emit('JOIN_SCRIPT', scriptId, (data) => {
              // make sure we're on the same script
              const { currentScript } = self.rootStore
              if (currentScript && currentScript.id === scriptId) {
                currentScript.handleJoinedRoom(data)
              }
            })
            self.hasJoinedScript = true
          }
        },

        async connectAndJoinScript(scriptId: string) {
          await this.connect()
          this.joinScript(scriptId)
        },

        leaveScript(scriptId: string) {
          if (self.connected) {
            getSocket().emit('LEAVE_SCRIPT', scriptId)
            self.hasJoinedScript = false
          }
        },

        updatePmCursor(
          data: Parameters<ClientToServerEvents['UPDATE_CURSOR']>[0],
        ) {
          if (self.hasJoinedScript) {
            getSocket().volatile.emit('UPDATE_CURSOR', data)
          }
        },
      },
    }
  })
  .actions((self) => ({
    onConnectionLost() {
      self.rootStore.currentScript?.handleLostConnection()
    },

    onConnectionEstablished() {
      const { currentRundown, currentOrg, currentScript } = self.rootStore
      self.environment.datadog.setSocketId(self.socketId)

      // when we reconnect, we need to rejoin any relevant rooms
      if (currentRundown) {
        self.joinRundown(currentRundown.id)
        currentRundown.reloadData()
      }
      if (currentOrg) {
        self.joinWorkspace(currentOrg.id)
        currentOrg.refreshRecentlyUpdated()
      }
      if (currentScript) {
        self.joinScript(currentScript.id)
      }
      self.checkLatency()
    },
    afterAttach() {
      // whenever self.status changes we need to do things like
      // rejoin rooms and notify choo, etc.
      reaction(
        () => self.status,
        (status) => {
          if (status === 'connected') {
            this.onConnectionEstablished()
          } else {
            this.onConnectionLost()
          }
        },
      )
    },
  }))
