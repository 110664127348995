import { Button } from '@mantine/core'

import styles from './ResetButton.module.scss'

export const ResetButton = ({
  onClick,
  disabled,
  label = 'Reset',
}: {
  onClick: (val: string) => void
  disabled: boolean
  label?: string
}) => (
  <Button
    className={styles.resetButton}
    variant="outline"
    onClick={() => onClick('')}
    disabled={disabled}
    size="sm"
  >
    {label}
  </Button>
)
