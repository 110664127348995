/*
  Some meta info about the prosemirror doc that only changes when
  the doc itself changes

  This plugin creates a ScriptBreakdown instance when it runs but only
  updates its part of EditorState when there are actually changes in the
  summary data exposed. This bypasses a lot of extra rendering without
  much complexity

  docInfo state includes:
   * doc timing
   * element numbering
*/

import deepEqual from 'fast-deep-equal'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'

import { util as codexUtil, NodeTypeMap } from '@showrunner/codex'

import { PluginFactory } from '../types'

import { extractNumbering } from './extractNumbering'
import { DocInfoState } from './types'

export const docInfoPluginKey = new PluginKey<DocInfoState>('docInfo')

const hasMultiplePages = (state: EditorState) => {
  return (
    state.doc.childCount > 1 &&
    state.doc.child(1).type.name !== NodeTypeMap.FORMAT_INFO
  )
}

const isEmpty = (state: EditorState): boolean => {
  const { firstChild } = state.doc
  if (hasMultiplePages(state) || !firstChild) {
    return false
  }

  return (
    firstChild.childCount === 1 && firstChild.firstChild?.textContent === ''
  )
}

export const docInfoFactory: PluginFactory = ({
  script,
}): Plugin<DocInfoState> => {
  const getPluginState = (state: EditorState): DocInfoState => {
    const breakdown = new codexUtil.ScriptBreakdown(state.doc, script.readRate)

    return {
      empty: isEmpty(state),
      timing: breakdown.timing,
      elementNumbers: extractNumbering(breakdown, script.type === 'screenplay'),
    }
  }

  return new Plugin<DocInfoState>({
    key: docInfoPluginKey,
    state: {
      init(_, editorState: EditorState) {
        return getPluginState(editorState)
      },
      apply(tr, pluginState, oldState, newState) {
        if (oldState.doc.eq(newState.doc)) {
          return pluginState
        }

        const newPluginState = getPluginState(newState)

        // Only update the state when the value has changed to avoid unnecessary
        // rerendering of the same values
        return deepEqual(newPluginState, pluginState)
          ? pluginState
          : newPluginState
      },
    },
  })
}
