import { ChangeEvent, useState } from 'react'

import { Button } from '@mantine/core'

import { showAlert } from '@components/Modals'
import { useMst } from '@state'
import { extractTsRestSuccess } from '@util/extractTsRest'
import { OrgPayload } from '@util/ScriptoApiClient/types'

export function ReadRateForm({ org }: { org: OrgPayload }) {
  const { scrapi } = useMst()
  const [readRate, setReadRate] = useState(org.readRate)
  const initialReadRate = org.readRate

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setReadRate(e.target.valueAsNumber)
  }

  const updateReadRate = async () => {
    try {
      await extractTsRestSuccess(
        scrapi.staff.updateWorkspace({
          params: { id: org.id },
          body: { readRate },
        }),
        200,
      )
      showAlert({
        title: 'Success',
        children: 'Read rate updated successfully',
      })
    } catch (e) {
      setReadRate(initialReadRate)
      showAlert({
        title: 'Error',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: (e as any).message,
      })
    }
  }

  return (
    <div className="s-form s-form--inline">
      <label htmlFor="ORG_READ_RATE">
        Read rate (characters/second):&nbsp;
      </label>
      <input
        id="ORG_READ_RATE"
        type="number"
        value={readRate}
        onChange={onChange}
      />
      <Button onClick={updateReadRate}>Submit</Button>
    </div>
  )
}
