import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { useMst } from '@state'

export type ApiCredential = ZInfer<typeof schemas.ApiCredentials>[number]

const QUERY_KEY = ['apiCredentials']

export const useFindApiCredentials = () => {
  const { scrapi, doDebug } = useMst()

  const apiResult = useQuery({
    queryFn: async () => {
      await doDebug()
      return scrapi.users.findApiCredentials()
    },
    queryKey: QUERY_KEY,
  })

  const foundCredentials: ApiCredential[] =
    apiResult.isSuccess && apiResult.data.status === 200
      ? apiResult.data.body.credentials
      : []

  return {
    isError: apiResult.isError,
    isPending: apiResult.isPending,
    foundCredentials,
  }
}

export const useDeleteApiCredential = () => {
  const { scrapi, doDebug } = useMst()
  const queryClient = useQueryClient()

  const deleteApiCredential = useMutation({
    mutationFn: async (clientId: string) => {
      await doDebug()
      return scrapi.users.deleteApiCredential({
        params: { id: clientId },
        body: {},
      })
    },
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.refetchQueries({ queryKey: QUERY_KEY })
      }
    },
  })

  return deleteApiCredential
}

export const useCreateApiCredential = () => {
  const { scrapi, doDebug } = useMst()
  const queryClient = useQueryClient()

  const createApiCredential = useMutation({
    mutationFn: async () => {
      await doDebug()
      return scrapi.users.createApiCredentials({ body: {} })
    },
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.refetchQueries({ queryKey: QUERY_KEY })
      }
    },
  })
  return createApiCredential
}
