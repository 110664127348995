import { MantineThemeOverride } from '@mantine/core'

export const { InputWrapper }: MantineThemeOverride['components'] = {
  InputWrapper: {
    // keeping these unused params here for ease of extending later
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    styles: () => ({
      label: {
        fontWeight: 700,
      },
    }),
  },
}
