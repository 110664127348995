import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Divider, Group, Radio, Space, Stack, Text } from '@mantine/core'

import { ModalShell, useModalControls } from '@components/Modals'
import { IRundownListing } from '@state/types'
import { pluralize } from '@util'

type DuplicateProps = {
  listing: IRundownListing
  scriptRowCount: number
}

export const DuplicateRundown = ({
  listing,
  scriptRowCount,
}: DuplicateProps) => {
  const [value, setValue] = React.useState('copy')
  const controls = useModalControls()

  const shouldClone = value === 'clone'

  const handleConfirm = async () => {
    try {
      controls.setLoading(true)
      shouldClone ? await listing.clone() : await listing.duplicate()
      controls.onClose()
    } catch (e) {
      controls.setErrorMessage("Couldn't duplicate rundown")
    } finally {
      controls.setLoading(false)
    }
  }

  const blurb = `This rundown is linked to ${scriptRowCount} ${pluralize(
    scriptRowCount,
    'script',
  )}`

  return (
    <ModalShell
      title="Duplicate rundown"
      onConfirm={handleConfirm}
      confirmLabel="Duplicate"
      cancelLabel="Cancel"
      size="md"
      opened={controls.opened}
      onClose={controls.onClose}
      loading={controls.loading}
      errorMessage={controls.errorMessage}
    >
      <Stack gap="lg">
        <Text>{blurb}</Text>
        <Divider color="gray.3" />
        <Radio.Group px={10} value={value} onChange={setValue}>
          <Stack gap="sm">
            <Group justify="space-between">
              <Radio
                value="copy"
                label={
                  <Text span fw="bold">
                    Duplicate rundown only
                  </Text>
                }
                description={
                  <Text span fz={14}>
                    Create a copy of the rundown that links to the existing
                    script documents
                  </Text>
                }
              />
            </Group>
            <Group justify="space-between">
              <Radio
                value="clone"
                label={
                  <Text span fw="bold">
                    Duplicate rundown and scripts
                  </Text>
                }
                description={
                  <Text span fz={14}>
                    Create a copy of the rundown and a copy of each script
                  </Text>
                }
              />
            </Group>
          </Stack>
        </Radio.Group>
      </Stack>
      <Space h={2} />
    </ModalShell>
  )
}

export const DuplicateRundownModal = NiceModal.create(DuplicateRundown)
export const showDuplicateRundownModal = (props: DuplicateProps) =>
  NiceModal.show(DuplicateRundownModal, props)
