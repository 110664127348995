import { ICellRendererParams } from '@ag-grid-community/core'
import { Image } from '@mantine/core'

import snapstreamLogo from '@assets/images/snapstream-logo.png'
import { IRundown } from '@state/types'
import { isGridBlobKey } from '@util/rundowns'
import { RundownRowData } from '@util/ScriptoApiClient/types'

const URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g

const extractSnapstreamLink = (value: unknown): string | undefined => {
  if (typeof value === 'string') {
    const urlMatchArray = value.match(URL_REGEX)
    return urlMatchArray?.find((item) => item.includes('snapstream.com'))
  }
}

export const SnapstreamLinkCell = ({
  context,
  data,
  colDef,
}: ICellRendererParams<RundownRowData>) => {
  const rundown = context as IRundown
  const columnKey = colDef?.field
  const row = data?.id ? rundown.getRow(data.id) : null
  if (!(row && columnKey && isGridBlobKey(columnKey))) {
    return null
  }

  const snapstreamLink = extractSnapstreamLink(row.getBlobValue(columnKey))

  if (!snapstreamLink) {
    return null
  }

  return (
    <a href={snapstreamLink}>
      <Image src={snapstreamLogo} h={30} fit="contain" />
    </a>
  )
}
