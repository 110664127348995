import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Card, Group, NumberInput, Stack, Text, TextInput } from '@mantine/core'

import { ModalShell, useModalControls } from '@components/Modals'
import { pluralize } from '@util'

import { extractInfoFromPattern } from './generators'

import styles from './ItemNumberingModal.module.scss'

// This is the type of a generator function used by the `onApplyNumbers` prop
// passed in-- basically this component lets the user configure this function, then
// we pass it back in `onApplyNumbers` to enable the numbers to be generated
// for appropriate rows
export type ItemNumberGenerator = (index: number) => string

// This function uses a sample pattern and an increment value to build and return
// another function that can generate item numbers that are similar
const buildItemNumberGenerator = (
  firstItemNumber: string,
  increment: number,
): ItemNumberGenerator => {
  const { firstValue, paddedLength } = extractInfoFromPattern(firstItemNumber)
  const prefix = firstItemNumber.substring(
    0,
    firstItemNumber.length - paddedLength,
  )

  return (index: number) => {
    const value = index * increment + firstValue
    const paddedValue = String(value).padStart(paddedLength, '0')
    return `${prefix}${paddedValue}`
  }
}

type ItemNumberingModalProps = {
  firstItemNumber: string
  rowCount: number
  onApplyNumbers: (generator: ItemNumberGenerator) => Promise<void>
}

const ItemNumberingInternal = ({
  firstItemNumber,
  onApplyNumbers,
  rowCount,
}: ItemNumberingModalProps) => {
  const {
    opened,
    onClose,
    setLoading,
    setErrorMessage,
    loading,
    errorMessage,
  } = useModalControls()
  const [localFirstValue, setLocalFirstValue] = React.useState(firstItemNumber)
  const [increment, setIncrement] = React.useState(1)

  const generator: ItemNumberGenerator = buildItemNumberGenerator(
    localFirstValue,
    increment ?? 1,
  )

  const exampleValues = [generator(0), generator(1), generator(2)]

  const handleConfirm = async () => {
    setLoading(true)
    try {
      await onApplyNumbers(generator)
      onClose()
    } catch (e) {
      setErrorMessage('Failed to update item numbers')
    } finally {
      setLoading(false)
    }
  }

  const isDisabled = localFirstValue.trim().length === 0

  const confirmLabel = `Update ${rowCount} ${pluralize(rowCount, 'row')}`

  return (
    <ModalShell
      size="sm"
      onConfirm={handleConfirm}
      title="Number rows"
      confirmLabel={confirmLabel}
      opened={opened}
      onClose={onClose}
      cancelLabel="Cancel"
      loading={loading}
      errorMessage={errorMessage}
      disabled={isDisabled}
    >
      <Stack align="center">
        <Group>
          <TextInput
            w={140}
            label="Starting value"
            placeholder="e.g. A01"
            value={localFirstValue}
            onChange={(e) => setLocalFirstValue(e.currentTarget.value)}
          />
          <NumberInput
            w={140}
            label="Increment by"
            step={1}
            value={increment}
            onChange={(value) => {
              if (typeof value === 'number') {
                setIncrement(value)
              }
            }}
          />
        </Group>
        <Stack gap={5} align="stretch" w={300}>
          <Text fw="bold">Preview</Text>
          <Card className={styles.numberingModal_card}>
            <Text ta="center" size="lg" c="dimmed">
              {localFirstValue ? exampleValues.join(', ') : 'eg. A01, A02, A03'}
            </Text>
          </Card>
        </Stack>
      </Stack>
    </ModalShell>
  )
}

const ItemNumberingModal = NiceModal.create<ItemNumberingModalProps>(
  ItemNumberingInternal,
)

export const showItemNumberingModal = (props: ItemNumberingModalProps) => {
  NiceModal.show(ItemNumberingModal, props)
}
