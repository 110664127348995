import { RundownPrintPrefs } from '@util/zodSchemas/printPreferences'

export const orientationData: Array<{
  label: string
  value: RundownPrintPrefs['orientation']
}> = [
  {
    label: 'Portrait',
    value: 'portrait',
  },
  {
    label: 'Landscape',
    value: 'landscape',
  },
]

export const rowDensityData: Array<{
  label: string
  value: RundownPrintPrefs['rowDensity']
}> = [
  {
    label: 'Compact',
    value: 'compact',
  },
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Loose',
    value: 'loose',
  },
]
