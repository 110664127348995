import NiceModal from '@ebay/nice-modal-react'
import {
  Button,
  Checkbox,
  Group,
  Modal,
  NumberInput,
  Stack,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { useModalControls } from '@components/Modals'
import { useMst } from '@state'
import {
  defaultPrompterViewPrefs,
  PrompterViewPrefs,
} from '@util/LocalPersistence'

const PrompterViewSettingsModal = NiceModal.create(
  observer(function PrompterViewSettingsModal() {
    const { opened, onClose } = useModalControls()

    const { user, trackEvent } = useMst()

    const prefs = user.prefs.prompterView ?? defaultPrompterViewPrefs

    const updatePreferences = (update: Partial<PrompterViewPrefs>) => {
      trackEvent('PROMPTER_VIEW_CHANGED', update)
      user.updatePreferences({ prompterView: { ...prefs, ...update } })
    }

    return (
      <Modal
        title="Prompter view settings"
        opened={opened}
        onClose={onClose}
        size="xs"
      >
        <Stack>
          <NumberInput
            size="sm"
            style={{ width: 200 }}
            label="Text Size"
            min={16}
            max={400}
            step={4}
            value={prefs.fontSize}
            onChange={(value) => {
              if (typeof value === 'number') {
                updatePreferences({ fontSize: value })
              }
            }}
          />
          <Checkbox
            label="Dark Mode"
            checked={prefs.darkMode}
            onChange={() => {
              updatePreferences({ darkMode: !prefs.darkMode })
            }}
          />
          <Checkbox
            label="Reverse"
            checked={prefs.reversed}
            onChange={() => updatePreferences({ reversed: !prefs.reversed })}
          />
          <Checkbox
            label="Auto update as script changes"
            checked={prefs.autoUpdate}
            onChange={() =>
              updatePreferences({ autoUpdate: !prefs.autoUpdate })
            }
          />
          <Group justify="center">
            <Button onClick={onClose}>Done</Button>
          </Group>
        </Stack>
      </Modal>
    )
  }),
)

export const showPrompterViewSettingsModal = () => {
  NiceModal.show(PrompterViewSettingsModal)
}
