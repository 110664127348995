/*
  The DatadogClient exposes a public singleton instance. It can be imported
  directly but it's also available in the mst environment.

  At app launch time MST sets up observable reactions to update certain
  global context variables like user_id and org_id.
*/

import { datadogLogs } from '@datadog/browser-logs'
import { autorun } from 'mobx'
import { nanoid } from 'nanoid'

import { IRoot } from '@state/types'
import { SCRIPTO_REQUEST_ID_HEADER } from '@util/constants'

import { config } from './config'

/*
  if NODE_ENV is development and level is error
  OR if a special localStorage item is set, log datadog messages
  to console.
*/
const shouldLogToConsole = (level: LogLevel) => {
  if (process.env.NODE_ENV === 'development' && level === 'error') {
    return true
  }
  return localStorage.getItem('datadog') === 'console'
}

type LogLevel = 'error' | 'warn' | 'info' | 'debug'
type LogMetadata = Record<string, unknown>

type AppStatusSummary = {
  backgroundAgeSeconds: number
  activityAgeSeconds: number
}

// this is data we want to include on every log at the top level
const globalContext: { [key: string]: string | number | boolean | null } = {
  tab_id: nanoid(),
}

const sendLog = ({
  data,
  message,
  level,
  error,
}: {
  message: string
  data?: LogMetadata
  level: LogLevel
  error?: unknown
}) => {
  const payload: { [key: string]: unknown } = {
    // context values sent with every request, like tab_id and user_id
    ...globalContext,
    // hoist the scripto_request_id_header if available
    [SCRIPTO_REQUEST_ID_HEADER]: data?.[SCRIPTO_REQUEST_ID_HEADER],
    // event specific data
    data,
  }

  if (shouldLogToConsole(level)) {
    // eslint-disable-next-line no-console
    console.log(message, {
      payload,
      level,
      context: globalContext,
      user: datadogLogs.getUser(),
    })
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  if (level !== 'debug' || !!window.d?.sendDebugLogs) {
    // axios has a special serializer for instances of the Error class,
    // but it needs to be passed in as the last param
    if (error && error instanceof Error) {
      datadogLogs.logger.log(message, payload, level, error)
    } else {
      // if we have a non-Error instance error param, just stick it into
      // the payload.
      payload.error = error
      datadogLogs.logger.log(message, payload, level)
    }
  }
}

// Some logs/warnings can be spammy. This helper gives us a way to
// log the error but only once per app instance (based on a self created ID)
const oneTimeLogs: { [key: string]: boolean } = {}
const sendLogOnce = ({
  message,
  data,
  level,
  id,
}: {
  message: string
  data: LogMetadata
  level: LogLevel
  id: string
}) => {
  if (!oneTimeLogs[id]) {
    oneTimeLogs[id] = true
    sendLog({ data, message, level })
  }
}

const setContextValues = (values: {
  [key: string]: string | number | boolean | null
}) => {
  Object.entries(values).forEach(([key, value]) => {
    globalContext[key] = value
  })
}

export class DatadogClient {
  private initialized = false
  static instance: DatadogClient | null = null

  // Prevent instantiation from outside this file to ensure a singleton
  private constructor() {
    datadogLogs.init(config)
  }

  public static getInstance(): DatadogClient {
    if (!DatadogClient.instance) {
      DatadogClient.instance = new DatadogClient()
    }
    return DatadogClient.instance
  }

  // when certain values change we want to update datadog
  // context variables
  initializeContextTracking(mst: IRoot) {
    if (!this.initialized) {
      this.initialized = true
      // watch some mst values for changes and auto-update
      autorun(() => {
        setContextValues({
          staff: mst.user.staff,
          user_id: mst.user.id,
          org_id: mst.currentOrg?.id ?? null,
          script_id: mst.currentScript?.id ?? null,
        })
      })
    }
  }

  setVisibility(visibility: DocumentVisibilityState) {
    setContextValues({ visibility })
  }

  setAppStatus(values: AppStatusSummary) {
    setContextValues(values)
  }

  setSocketId(socketId: string) {
    setContextValues({ socket_id: socketId })
  }

  setSyncCycleLatency(value: number) {
    setContextValues({ sync_ms: value })
  }

  debug(message: string, data?: LogMetadata, error?: unknown) {
    sendLog({ message, data, level: 'debug', error })
  }

  info(message: string, data?: LogMetadata, error?: unknown) {
    sendLog({ message, data, level: 'info', error })
  }

  warn(message: string, data?: LogMetadata, error?: unknown) {
    sendLog({ message, data, level: 'warn', error })
  }

  warnOnce(message: string, data: LogMetadata, id: string) {
    sendLogOnce({ message, data, id, level: 'warn' })
  }

  error(message: string, data?: LogMetadata, error?: unknown) {
    sendLog({ message, data, level: 'error', error })
  }

  errorOnce(message: string, data: LogMetadata, id: string) {
    sendLogOnce({ message, data, id, level: 'error' })
  }

  logApiRequest({
    startTime,
    reqId,
    path,
    method,
    status,
    error,
  }: {
    startTime: number
    reqId: string
    path: string
    method: string
    status: number
    error?: unknown
  }) {
    const duration = Date.now() - startTime
    const meta = {
      duration,
      path,
      method: method.toUpperCase(),
      status,
      scripto_req_id: reqId,
    }
    if (status === 0) {
      this.warn('network error', meta, error)
    } else if (status > 399) {
      this.error('api error', meta, error)
    } else if (duration > 2000) {
      this.info('api  ', meta, error)
    } else {
      // this only gets sent to datadog if
      // a special value is set
      this.debug('api response', meta)
    }
  }
}
