import { IRoot } from '@state/types'

export function useDocumentTitle({
  currentRundown,
  currentScript,
  appStatus,
  view,
}: IRoot) {
  const suffix = appStatus.suspended && view.debugSleep ? ' (ASLEEP)' : ''
  const currentTitle = currentScript?.name ?? currentRundown?.name ?? 'Scripto'
  document.title = currentTitle + suffix
}
