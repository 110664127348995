// Full screen view with the wallaby header on top
import React from 'react'

import { globalStyleVars } from '@util/constants'

import './AppWithHeader.scss'

// TODO: simplify layout styles and lean more heavily on mantine?
export const AppWithHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="l-app-with-header" style={globalStyleVars}>
    {children}
  </div>
)
