import { CSSProperties } from 'react'

import { schemas } from '@showrunner/scrapi'

import { ScriptFormatBlockConfiguration } from '@util/formats'
import { ScriptPrintPreferences } from '@util/zodSchemas/printPreferences'

import { getColumnPlacement } from './helpers'

const ALL_BLOCK_TYPES = [
  ...schemas.scriptFormats.StandardBlocks.options,
  ...schemas.scriptFormats.DualFormatBlocks.options,
]

export const buildTwoColumnStyles = ({
  blocks,
  prefs,
}: {
  blocks: ScriptFormatBlockConfiguration
  prefs: ScriptPrintPreferences
}): CSSProperties => {
  const result: CSSProperties = {
    '--left-col-width': '3.25in',
    '--right-col-width': '4.25in',
    '--two-column-gap': '0.5in',
    '--right-column-line-height': prefs.twoColDialogueSpacing,
  }

  ALL_BLOCK_TYPES.forEach((blockType) => {
    const { color, bold, underline, italic, uppercase, blockTopMargin } =
      blocks[blockType]

    if (color && !prefs.monochrome) {
      result[`--${blockType}-color`] = color
    }
    if (bold) {
      result[`--${blockType}-font-weight`] = 'bold'
    }
    if (underline) {
      result[`--${blockType}-text-decoration`] = 'underline'
    }
    if (italic) {
      result[`--${blockType}-font-style`] = 'italic'
    }
    if (uppercase) {
      result[`--${blockType}-text-transform`] = 'uppercase'
    }
    // we use the blockTopMargin only for right-column blocks
    if (getColumnPlacement(blockType) === 'right') {
      result[`--${blockType}-margin-top`] = `${blockTopMargin}em`
    }
  })

  if (prefs.monochrome) {
    result['--monochrome-text-color'] = '#000'
    result['--monochrome-background-color'] = 'transparent'
  }

  return result
}
