import React from 'react'

import { Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'

import { showConfirmModal, showError } from '@components/Modals'
import { PasswordInput } from '@components/PasswordInput'
import { useNavigation } from '@hooks'
import { useMst } from '@state'
import { isEmailValid, parseServerError, ROUTE_PATTERNS } from '@util'

import { knownCodes, ServerErrorCode, serverErrorMessages } from './FormErrors'
import { OnboardingPage } from './OnboardingPage'
import { useReturnTo } from './useReturnTo'

const {
  INVALID_CREDENTIALS,
  RESET_PASSWORD,
  TOO_MANY_REQUESTS,
  USER_NOT_IN_STYTCH,
} = knownCodes

type LoginFormValues = {
  email: string
  password: string
}

type LoginPageProps = {
  onSubmit: (values: LoginFormValues) => Promise<void>
  prepopulatedEmail?: string
  serverError?: ServerErrorCode
  loading?: boolean
  attemptCount?: number
}

export const LoginPage = ({
  onSubmit,
  loading,
  serverError,
  prepopulatedEmail,
  attemptCount,
}: LoginPageProps) => {
  const form = useForm<LoginFormValues>({
    validateInputOnBlur: true,
    initialValues: {
      email: prepopulatedEmail ?? '',
      password: '',
    },
    validate: {
      email: (value) => (isEmailValid(value) ? null : 'Not a valid email'),
      password: (value) => (value.length > 0 ? null : 'Value is required'),
    },
  })

  const pumpTheBrakes = () => {
    showConfirmModal({
      title: 'Sign In Errors',
      children: (
        <>
          <Text>
            Too many login attempts could lock you out of your account for up to
            an hour.
          </Text>
          <Text>Do you want to reset your password?</Text>
        </>
      ),
      confirmLabel: 'Yes, reset my password',
      cancelLabel: 'No, try signing in again',
      onConfirm: () => window.location.replace('/forgot-password'),
    })
  }

  React.useEffect(() => {
    if (serverError === 'invalidCredentials') {
      form.setValues({ password: '' })
    }
    if (attemptCount === 3) {
      pumpTheBrakes()
    }
    // not including 'form' is a no-no, but i'm excluding it intentionally...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError, attemptCount])

  return (
    <OnboardingPage title="Sign In" loading={loading}>
      <OnboardingPage.Buttons activeButton="login" />
      <OnboardingPage.Form
        onSubmit={form.onSubmit(onSubmit)}
        errorMessage={
          serverError ? serverErrorMessages[serverError] : undefined
        }
      >
        <TextInput label="Email" {...form.getInputProps('email')} />
        <PasswordInput label="Password" {...form.getInputProps('password')} />
        <OnboardingPage.SubmitButton label="Sign In" />
      </OnboardingPage.Form>
      <OnboardingPage.Links types={['forgotpassword', 'support']} />
    </OnboardingPage>
  )
}

export const LoginRoute = () => {
  const mst = useMst()
  const { returnTo } = useReturnTo({ fallback: '/' })
  const { navigate } = useNavigation()

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<ServerErrorCode | undefined>()
  const [attemptCount, setAttemptCount] = React.useState(0)

  const handleSubmit = async ({ email, password }: LoginFormValues) => {
    setLoading(true)
    try {
      await mst.authManager.loginWithApi({ email, password })
      returnTo()
    } catch (err) {
      const { code } = parseServerError(err)
      switch (code) {
        case INVALID_CREDENTIALS:
          setError('invalidCredentials')
          setAttemptCount(attemptCount + 1)
          break
        case RESET_PASSWORD:
          setError('mustResetPassword')
          break
        case TOO_MANY_REQUESTS:
          showError(serverErrorMessages.tooManyRequests)
          break
        case USER_NOT_IN_STYTCH:
          navigate(ROUTE_PATTERNS.updatePassword)
          break
        default:
          setError('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <LoginPage
      loading={loading}
      onSubmit={handleSubmit}
      serverError={error}
      attemptCount={attemptCount}
    />
  )
}
