export type SyntaxRuleConfig = {
  re: RegExp
  captures: StringMap
}

export type DecorationData = {
  startIndex: number
  length: number
  className: string
}
export const findSyntaxMatches = (
  inputLine: string,
  config: SyntaxRuleConfig,
): DecorationData[] => {
  const decoData: DecorationData[] = []
  const regexResult = config.re.exec(inputLine)
  if (regexResult) {
    // iterate through the regex capture groups in the result
    Object.entries(config.captures).forEach(([captureIndex, className]) => {
      const captureMatch = regexResult.indices?.[parseInt(captureIndex)]
      if (captureMatch) {
        const [startIndex, endIndex] = captureMatch
        decoData.push({
          className,
          startIndex,
          length: endIndex - startIndex,
        })
      }
    })
  }
  return decoData
}
