/*
  Wouter match function that supports multiple patterns.

*/
import { default as makeMatcher, Match, MatcherFn } from 'wouter/matcher'

const builtInMatcher = makeMatcher()

// we SHOULD be able to use this directly when creating the
// router, but I can't figure out how to extend the wouter/matcher types
export const matchRoutePattern = (
  patterns: string | string[],
  path: string,
): Match => {
  for (const pattern of [patterns].flat()) {
    const [match, params] = builtInMatcher(pattern, path)
    if (match) return [match, params]
  }

  return [false, null]
}

// This version can be used by wouter to construct a <Router>
// even though we are passing it array patterns too.
export const multiMatcher: MatcherFn = (pattern, path) =>
  matchRoutePattern(pattern, path)
