import * as AgGrid from '@ag-grid-community/core'

import { EnrichedRowData } from '@util/rundowns'

export type FocusedCellData = { columnId: string; rowId: string }

export const findFocusedRowId = (
  api: AgGrid.GridApi<EnrichedRowData>,
): number | undefined => {
  const focusedCell = api.getFocusedCell()
  if (focusedCell) {
    const node = api.getDisplayedRowAtIndex(focusedCell.rowIndex)
    return node?.data?.id
  }
}

// If a cell is focused, unfocus and return the rowId and columnId.
// We use this before inserting/removing rows so we can preserve the focus
export const clearFocusedCell = (
  api: AgGrid.GridApi,
): FocusedCellData | undefined => {
  const focusedCell = api.getFocusedCell()
  if (focusedCell) {
    const node = api.getDisplayedRowAtIndex(focusedCell.rowIndex)
    api.clearFocusedCell()
    const rowId = node?.id
    if (typeof rowId === 'string') {
      return { rowId, columnId: focusedCell.column.getId() }
    }
  }
  return undefined
}

// If no cell is focused, find a displayed row node matching
// the FocusedCellData and focus. This is used to restore focus
// after row updates are processed.
export const setFocusedCell = (
  { rowId, columnId }: FocusedCellData,
  api: AgGrid.GridApi,
) => {
  if (!api.getFocusedCell()) {
    const node = api.getRowNode(rowId)
    if (typeof node?.rowIndex === 'number') {
      api.setFocusedCell(node.rowIndex, columnId)
    }
  }
}
