import { CompileMessageType, STATUS_ICON_CONFIG } from '@ink'

import { FaIcon, FaIconProps } from './FaIcon'

type StatusIconProps = Omit<FaIconProps, 'color' | 'icon'>

export const CompilerStatusIcon = ({
  type,
  ...props
}: StatusIconProps & { type: CompileMessageType }) => {
  const { iconClass, color } = STATUS_ICON_CONFIG[type]
  return <FaIcon icon={iconClass} color={color} {...props} />
}

const Success = (props: StatusIconProps) => (
  <FaIcon icon="fa-circle-check" color="green.7" {...props} />
)

const Error = (props: StatusIconProps) => (
  <CompilerStatusIcon type="error" {...props} />
)

const Warning = (props: StatusIconProps) => (
  <CompilerStatusIcon type="warning" {...props} />
)

const ToDo = (props: StatusIconProps) => (
  <CompilerStatusIcon type="todo" {...props} />
)

export const InkStatusIcon = {
  Success,
  Warning,
  Error,
  ToDo,
}
