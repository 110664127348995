import { ILoadedScript, IRundown, IRundownRow } from '@state'
import { notEmptyFilter } from '@util'
import { RundownRowData } from '@util/ScriptoApiClient/types'
import { SlotConfig } from '@util/zodSchemas/printPreferences'

export type PrintableScriptData = {
  scriptId: string
  title: string
  rundownRowData?: RundownRowData
  selected?: boolean
}

export type OnPrintHandler = AsyncCallback<
  [
    {
      fileName: string
      printableItems: PrintableScriptData[]
      type: 'print' | 'download'
    },
  ],
  boolean
>

const getPrintData = (row: IRundownRow): PrintableScriptData | undefined => {
  if (row.identityScriptId) {
    const title = row.identityScriptTitle ?? 'Script'

    return {
      scriptId: row.identityScriptId,
      title,
      rundownRowData: row.pojo,
      selected: row.selectedInGrid,
    }
  }
}

export type PrintScriptModalProps =
  | {
      docType: 'rundown'
      rundown: IRundown
    }
  | {
      docType: 'script'
      script: ILoadedScript
    }

export const getPrintableScriptData = (
  props: PrintScriptModalProps,
): PrintableScriptData[] => {
  if (props.docType === 'rundown') {
    return props.rundown.sortedRowInstances
      .map(getPrintData)
      .filter(notEmptyFilter)
  }

  return [
    {
      scriptId: props.script.id,
      title: props.script.name,
    },
  ]
}

export const getFilename = (props: PrintScriptModalProps) => {
  if (props.docType === 'rundown') {
    return `${props.rundown.name}-scripts.pdf`
  }
  return props.script.name + '.pdf'
}

export const CORNER_ITEM_NUMBER: SlotConfig = {
  slotType: 'rundown-cell-value',
  colId: 'itemNumber',
  decoration: {
    circled: true,
  },
}

export const isInactiveConfig = (
  config: SlotConfig | undefined,
  isScreenplay?: boolean,
) => {
  return (
    isScreenplay &&
    config?.slotType === 'static' &&
    config?.contentType === 'duration'
  )
}

// when launched, we set the list of selected IDs. If a subset of the scripts
// are marked as selected, we set the IDs to that set (for the selected rows in
// a rundown). However, if NONE are marked as selected, we set them ALL to selected
// (Because sometimes there's no script selection control-- like when printing from
// a script or when there's only one script in a rundown).
export const initialSelectedIds = (
  allPrintableItems: PrintableScriptData[],
): string[] => {
  const selectedItems = allPrintableItems.filter((item) => item.selected)
  if (selectedItems.length > 0) {
    return selectedItems.map((item) => item.scriptId)
  }
  return allPrintableItems.map((item) => item.scriptId)
}
