import React from 'react'

import { Radio, ScrollArea, Stack } from '@mantine/core'

import { NavLinkData, navLinkText } from '@util'

import styles from './SleneRadios.module.scss'

export const SleneRadios = ({
  slenes,
  selectedSlene,
  setSelectedSlene,
}: {
  slenes: NavLinkData[]
  selectedSlene: string
  setSelectedSlene: React.Dispatch<React.SetStateAction<string>>
}) => (
  <Radio.Group value={selectedSlene} onChange={setSelectedSlene}>
    <ScrollArea className={styles.prefModal_scroll} type="auto" h="30vh">
      <Stack mt={0} mb={0} mx={0} px={10} pt={15} pb={15}>
        {slenes.map((s) => (
          <Radio key={s.id} label={navLinkText(s).toUpperCase()} value={s.id} />
        ))}
      </Stack>
    </ScrollArea>
  </Radio.Group>
)
