import * as AgGrid from '@ag-grid-community/core'

import { suppressKeyboardEvent } from '../eventHandlers'
export * from './getColumnDefs'

export const DEFAULT_COLUMN_DEF: AgGrid.ColDef = {
  resizable: true, // the default
  sortable: false,
  suppressHeaderMenuButton: true,
  suppressKeyboardEvent,
  suppressMovable: true,
}
