import {
  ALT,
  CMD,
  CTRL,
  DEL,
  DOWN,
  ENTER,
  LEFT,
  RIGHT,
  SHIFT,
  TAB,
  UP,
} from './helpers'

export const Keys = {
  CMD,
  CTRL,
  SHIFT,
  ALT,
  ENTER,
  TAB,
  LEFT,
  RIGHT,
  UP,
  DOWN,
  DEL,
}

export * from './useShortcuts'

export { detectedPlatform, buildShortcutTip } from './helpers'

export type { Platform } from './helpers'
