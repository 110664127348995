// Full screen view with the pikachoo header on top
import React from 'react'

import styles from './CenteredScrollingBody.module.scss'

export const CenteredScrollingBody = ({
  children,
}: {
  children: React.ReactNode
}) => <div className={styles.centeredScrollingBody}>{children}</div>
