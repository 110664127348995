import React from 'react'

import { Tooltip } from '@mantine/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { UserAvatar } from '@components/UserAvatar/UserAvatar'
import { ILoadedScript, useMst } from '@state'

import styles from './Collaborators.module.scss'

const PAGED_DIAMETER = 40
const PAGELESS_DIAMETER = 24

const Collaborator = ({
  clientId,
  name,
  avatarSrc,
  color,
  gutterAvatarTop,
  pageless,
}: {
  clientId: string
  name: string
  avatarSrc: string | null
  color: string
  gutterAvatarTop: number
  pageless?: boolean
}) => {
  const topRef = React.useRef(0)
  const [top, setTop] = React.useState(0)
  const [animate, setAnimate] = React.useState(false)
  const diameter = pageless ? PAGELESS_DIAMETER : PAGED_DIAMETER

  React.useEffect(() => {
    const lastTop = topRef.current
    const hasChanged = topRef.current !== gutterAvatarTop
    if (hasChanged) {
      const shouldAnimate =
        lastTop !== 0 && Math.abs(gutterAvatarTop - lastTop) < 1000
      topRef.current = gutterAvatarTop
      setTop(gutterAvatarTop)
      setAnimate(shouldAnimate)
    }
  }, [gutterAvatarTop])

  if (top === 0) {
    return null
  }

  return (
    <Tooltip label={name} position="right">
      <UserAvatar
        id={`remote-user-${clientId}`}
        diameter={diameter}
        ringColor={color}
        className={cn(styles.avatar, {
          [styles.animate]: animate,
          [styles.pageless]: pageless,
          [styles.paged]: !pageless,
        })}
        style={{
          '--gutter-avatar-size': `${diameter}px`,
          top,
        }}
        avatarSrc={avatarSrc}
      />
    </Tooltip>
  )
}

export const Collaborators = observer(function Collaborators({
  script,
}: {
  script: ILoadedScript
}) {
  const { user } = useMst()
  const pageless = script.isInk || user.prefs.pageless
  const invisible = user.prefs.hideCollabAvatars
  const displayedCollaborators = script.collaborators.filter(
    (c) => c.gutterTopPosition > 0,
  )

  return (
    <div className={cn({ [styles.invisible]: invisible })}>
      {displayedCollaborators.map(
        ({ clientId, user, gutterTopPosition, color }) => (
          <Collaborator
            key={clientId}
            clientId={clientId}
            name={user.name}
            color={color}
            avatarSrc={user.avatar}
            gutterAvatarTop={gutterTopPosition}
            pageless={pageless}
          />
        ),
      )}
    </div>
  )
})
