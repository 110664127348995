import { observer } from 'mobx-react-lite'

import { startNewCommentMark } from '@choo-app/lib/editor/plugins/comments'
import { Toolbar } from '@components/Toolbar'
import { Keys, useShortcuts } from '@hooks'
import { useMst } from '@state'

export const NewCommentButton = observer(function NewCommentButton() {
  const mst = useMst()
  const observableEditor = mst.currentScript?.observableEditor
  const disabled = !mst.currentScript?.pmEditor.canAddComment

  const action = () => {
    const editorView = observableEditor?.editorView
    if (editorView) startNewCommentMark(editorView)
  }

  const props = useShortcuts({
    comment: {
      keys: [Keys.CMD, Keys.ALT, 'M'],
      action,
      disabled,
    },
  }).getItemProps('comment')

  const tooltip =
    (disabled ? 'Select text to add a new comment' : 'New comment') +
    ` (${props.shortcut})`

  return <Toolbar.Button icon="fa-comment-plus" tooltip={tooltip} {...props} />
})
