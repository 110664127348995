import React from 'react'

import { Button, Radio, Stack, Text, TextInput, Title } from '@mantine/core'

import { showAlert, showError } from '@components/Modals'
import { NavAnchor } from '@components/NavAnchor'
import { OutsideAnchor } from '@components/OutsideAnchor'
import { Spinner } from '@components/Spinner'
import { Toast } from '@components/Toast'
import { useMst } from '@state'
import { BetaFlag } from '@util/ScriptoApiClient/types'

import { useBetaFlags } from '../helpers'

export const BetaFlags = () => {
  const { apiClient } = useMst()
  const [currBetaFlag, setCurrBetaFlag] = React.useState<BetaFlag | null>(null)
  const [newFlagData, setNewFlagData] = React.useState<{
    name: string
    description: string
  }>({
    name: '',
    description: '',
  })

  const { betaFlags, isError, isFetching, invalidateQuery } =
    useBetaFlags(apiClient)

  React.useEffect(() => {
    if (betaFlags && betaFlags.length > 0 && !currBetaFlag) {
      setCurrBetaFlag(betaFlags[0])
    }
  }, [currBetaFlag, betaFlags])

  const radioChangeHandler = (id: string) => {
    const flag = betaFlags?.find((f) => f.id === id)
    if (flag) setCurrBetaFlag(flag)
  }

  const bulkAddFlag = () => {
    if (currBetaFlag && confirm('are you positive??')) {
      apiClient
        .bulkAddFlag({ flagId: currBetaFlag.id })
        .then(() => {
          showAlert({
            title: 'Success',
            children: 'Successfully added flag to all workspaces',
          })
        })
        .catch((e) => {
          showError({
            title: 'Error',
            message: e.message,
          })
        })
    }
  }

  const bulkRemoveFlag = () => {
    if (currBetaFlag && confirm('are you positive??')) {
      apiClient
        .bulkRemoveFlag({ flagId: currBetaFlag.id })
        .then(() => {
          showAlert({
            title: 'Success',
            children: 'Successfully removed flag from all orgs',
          })
        })
        .catch((e) => {
          showError({
            title: 'Error',
            message: e.message,
          })
        })
    }
  }

  const destroyFlag = () => {
    if (!currBetaFlag || !confirm('are you positive??')) return
    apiClient
      .destroyBetaFlag({ flagId: currBetaFlag.id })
      .then(async () => {
        await invalidateQuery()
        setCurrBetaFlag(null)
      })
      .catch((e) => {
        const message = e.message.includes('422')
          ? 'Are you sure no workspaces have the flag enabled?'
          : 'Failed to destroy beta flag'

        showError({
          title: 'Error',
          message,
        })
      })
  }

  const createFlag = async () => {
    if (!confirm(`Create new feature flag "${newFlagData.name}"?`)) {
      return
    }
    try {
      await apiClient.createBetaFlag({
        name: newFlagData.name.trim(),
        description: newFlagData.description.trim(),
      })
      setNewFlagData({ name: '', description: '' })
      await invalidateQuery()
    } catch (e) {
      showAlert({
        title: 'Error',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: (e as any).message,
      })
    }
  }

  return (
    <Stack>
      <Title order={2}>
        <NavAnchor href="/staff">Staff Zone</NavAnchor>
      </Title>
      <Text size="12">
        Which flags are&nbsp;
        <OutsideAnchor href="https://github.com/showrunner/wallaby/blob/main/src/util/featureFlags.ts">
          currently in use?
        </OutsideAnchor>
      </Text>
      {isFetching && <Spinner />}
      {isError && (
        <Toast
          type="error"
          message="failed to retrieve beta flags"
          dismissable={false}
        />
      )}
      {currBetaFlag && (
        <>
          <Radio.Group value={currBetaFlag.id} onChange={radioChangeHandler}>
            <Stack>
              {betaFlags
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((f) => <Radio key={f.id} value={f.id} label={f.name} />)}
            </Stack>
          </Radio.Group>
          <Button
            color="green"
            onClick={bulkAddFlag}
          >{`Add "${currBetaFlag.name}" to all workspaces`}</Button>
          <Button
            onClick={bulkRemoveFlag}
          >{`Remove "${currBetaFlag.name}" from all workspaces`}</Button>
          <NavAnchor
            href={`/staff/workspaces?flag=${currBetaFlag.name}`}
          >{`View workspaces with "${currBetaFlag.name}" enabled`}</NavAnchor>
          <Button
            variant="danger"
            onClick={destroyFlag}
          >{`☠️ Destroy "${currBetaFlag.name}" flag`}</Button>
        </>
      )}
      <Title order={2}>Need a new flag?</Title>
      <TextInput
        placeholder="new-flag"
        value={newFlagData.name}
        onChange={(e) =>
          setNewFlagData({ ...newFlagData, name: e.target.value })
        }
      />
      <TextInput
        placeholder="description of the new flag"
        value={newFlagData.description}
        onChange={(e) =>
          setNewFlagData({ ...newFlagData, description: e.target.value })
        }
      />
      <Button
        onClick={createFlag}
        disabled={!newFlagData.name.trim() || !newFlagData.description.trim()}
      >
        Create new flag
      </Button>
    </Stack>
  )
}
