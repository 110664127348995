import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Button, Group, Stack } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { ModalShell, useModalControls } from '@components/Modals'
import { usePrintScripts } from '@components/Modals/PrintScriptModal/usePrintScripts'
import { ILoadedScript } from '@state'
import { getMergedFormatDefinition } from '@util'

import { createSectionDoc } from './createSectionDoc'
import { Disclaimer } from './Disclaimer'
import { SleneRadios } from './SleneRadios'

type Props = { script: ILoadedScript }

const PrintSectionInternal = observer(function PrintSection({ script }: Props) {
  const controls = useModalControls()
  const ps = usePrintScripts()

  const slenes = script.pmEditor.navLinks.filter(
    (n) => !n.isBlank && !['new_act', 'end_of_act', 'bracket'].includes(n.type),
  )
  const disabled = slenes.length < 2
  const [selectedSlene, setSelectedSlene] = React.useState(
    disabled ? '' : slenes[0].id,
  )

  const handlePrint = async (type: 'print' | 'download') => {
    const { editorState } = script.pmEditor
    if (!editorState) return

    const { doc: existingDoc } = editorState

    const sectionDoc = createSectionDoc({
      existingDoc,
      sleneId: selectedSlene,
      slenes,
    })

    const formatDefinition = getMergedFormatDefinition(
      existingDoc,
      script.scriptFormat.definition,
    )

    const success = await ps.printSection({
      type,
      fileName: `${script.name}-partial.pdf`,
      sectionDoc,
      formatDefinition,
    })

    script.analytics.track('EXPORTED_SECTION_PDF', { scriptId: script.id })

    if (success) controls.onClose()
  }

  return (
    <ModalShell
      title="Print section"
      opened={controls.opened}
      onClose={controls.onClose}
      loading={ps.loading}
      errorMessage={ps.errorMessage}
    >
      <Stack>
        {disabled ? (
          <Disclaimer isScreenplay={script.isScreenplay} />
        ) : (
          <SleneRadios
            slenes={slenes}
            selectedSlene={selectedSlene}
            setSelectedSlene={setSelectedSlene}
          />
        )}
        <Group gap="xs" justify="center">
          <Button
            disabled={disabled}
            type="submit"
            onClick={() => handlePrint('print')}
          >
            Print
          </Button>
          <Button
            disabled={disabled}
            type="submit"
            onClick={() => handlePrint('download')}
          >
            Export PDF
          </Button>
          <Button variant="outline" onClick={controls.onClose}>
            Close
          </Button>
        </Group>
      </Stack>
    </ModalShell>
  )
})

export const PrintSectionModal = NiceModal.create((props: Props) => (
  <PrintSectionInternal {...props} />
))

export const showPrintSectionModal = (props: Props) => {
  NiceModal.show(PrintSectionModal, props)
}
