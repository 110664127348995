import React from 'react'

import { ActionIcon, Group, Popover, TextInput, Tooltip } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@state'

import { hasActiveFilter } from '../helpers'
import { usePendingSearchContext } from '../useSearchContext'

import { ClearButton } from './ClearButton'
import { FilterDropdown } from './FilterDropdown'

import styles from './Form.module.scss'

export const SearchForm = ({
  placeholder,
  inputRef,
  onSubmit,
  onClear,
  dateFilterCategory,
  setDateFilterCategory,
  folderFilters,
  setFolderFilters,
  opened,
  setOpened,
}: {
  placeholder: string
  inputRef: React.RefObject<HTMLInputElement>
  onSubmit: (event: React.FormEvent) => void
  onClear: () => void
  dateFilterCategory: string
  setDateFilterCategory: React.Dispatch<React.SetStateAction<string>>
  folderFilters: { category: string; folderName: string | undefined }
  setFolderFilters: React.Dispatch<
    React.SetStateAction<{ category: string; folderName: string | undefined }>
  >
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const btnRef = React.useRef<HTMLButtonElement>(null)
  const mst = useMst()
  const pendingContext = usePendingSearchContext()

  const onClick = () => {
    pendingContext.mergePendingCriteria({ phrase: '' })
    onClear()
  }

  const closePopover = () => setOpened(false)
  const togglePopover = () => {
    if (!opened) {
      mst.user.trackEvent('ADVANCED_SEARCH_OPENED')
    }
    setOpened(!opened)
  }

  const popoverBg = hasActiveFilter(pendingContext.pendingCriteria)
    ? 'violet.0'
    : undefined

  return (
    <form onSubmit={onSubmit}>
      <Group wrap="nowrap">
        <Popover
          classNames={{
            dropdown: styles.search_popoverDropdown,
          }}
          opened={opened}
          width={350}
          withArrow
          position="bottom-start"
          trapFocus
        >
          <Group wrap="nowrap" gap={5} className={styles.search_inputGroup}>
            <TextInput
              ref={inputRef}
              classNames={{
                root: styles.search_root,
                input: styles.search_input,
              }}
              size="sm"
              value={pendingContext.pendingCriteria.phrase}
              onChange={(e) =>
                pendingContext.mergePendingCriteria({
                  phrase: e.target.value,
                })
              }
              placeholder={placeholder}
              leftSection={
                <FaIcon c="dimmed" icon="fa-magnifying-glass" size="12" />
              }
              rightSection={
                pendingContext.pendingCriteria.phrase !== '' ? (
                  <ClearButton onClick={onClick} />
                ) : null
              }
            />
            <Popover.Target>
              <Tooltip label="Advanced search" disabled={opened}>
                {/*
                      we set tabIndex below because Safari doesnt give focus to clicked buttons
                      which borks our special logic that is dependent on event.relatedTarget
                      https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#clicking_and_focus
                  */}
                <div tabIndex={0}>
                  <ActionIcon
                    className={styles.search_button}
                    bg={popoverBg}
                    onClick={togglePopover}
                    ref={btnRef}
                  >
                    <FaIcon c="dark" icon="fa-sliders" size="14" />
                  </ActionIcon>
                </div>
              </Tooltip>
            </Popover.Target>
          </Group>
          <FilterDropdown
            onClose={closePopover}
            onSubmit={onSubmit}
            dateFilterCategory={dateFilterCategory}
            setDateFilterCategory={setDateFilterCategory}
            btnRef={btnRef}
            setOpened={setOpened}
            folderFilters={folderFilters}
            setFolderFilters={setFolderFilters}
          />
        </Popover>
      </Group>
    </form>
  )
}
