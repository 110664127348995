import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { schemas } from '@showrunner/scrapi'

import { getSelectionBlock, setBlockFormatAttr } from '@util'
import { FormatBlockName } from '@util/formats'

export const setSpacingAttr = (opts: {
  key: 'lineHeight' | 'blockTopMargin'
  value: number | null
  editorView: EditorView
}) => {
  setBlockFormatAttr(opts)
}

const AllFormatNames: readonly string[] = [
  ...schemas.scriptFormats.AllBlocks.options,
]

function isFormatBlockName(
  value: string | undefined,
): value is FormatBlockName {
  return AllFormatNames.includes(value ?? '')
}

export const getSelFormatBlockName = (
  state: EditorState,
): FormatBlockName | undefined => {
  const name = getSelectionBlock(state)?.type.name
  return isFormatBlockName(name) ? name : undefined
}

export const VALID_LINE_HEIGHTS = [1, 1.5, 2]
