import React from 'react'

import { Text, TextProps } from '@mantine/core'
import cn from 'classnames'

import { extractFaIconInfo, FaIconName, FaIconSet, isFaIcon } from './util'

export type FaIconProps = Omit<TextProps, 'children'> & {
  icon: FaIconName
  faIconSet?: FaIconSet
  fixedWidth?: boolean
}

export const FaIcon = React.forwardRef<HTMLDivElement, FaIconProps>(
  ({ icon, faIconSet = 'fas', fixedWidth, ...textProps }, ref) => {
    const iconClasses = cn(icon, faIconSet, { 'fa-fw': fixedWidth })

    // line-height: 1 ensures that icons are centered horizontally within
    // align-items: center flexboxes
    return (
      <Text {...textProps} lh={1} bg="transparent" ref={ref}>
        <i className={iconClasses} />
      </Text>
    )
  },
)

FaIcon.displayName = 'FaIcon'

type SafeFaIconProps = Omit<FaIconProps, 'icon'> & {
  icon?: string | null
  fallback?: JSX.Element
}

/*
  This lets us use unsanitized values from the database with smart
  fallbacks. If the icon isn't present or is not a valid `fa-` type,
  this will render a fallback or nothing.

  You can also use this to supply icon="fa-kit fa-scripto-custom"
*/
export const SmartFaIcon = (props: SafeFaIconProps) => {
  const { icon, faIconSet, fallback = null, ...passThroughProps } = props
  const { iconName, iconSet } = extractFaIconInfo(icon)

  if (isFaIcon(iconName)) {
    return (
      <FaIcon
        icon={iconName}
        faIconSet={faIconSet ?? iconSet}
        {...passThroughProps}
      />
    )
  }
  return fallback
}
