import React from 'react'

import { observer } from 'mobx-react-lite'

import { useMst } from '@hooks'

import { useSideBySideDiffData } from '../useSideBySideData'

import { BodyPlaceholder } from './BodyPlaceholder'
import { SideBySideDiff } from './SideBySideDiff'

/*
  When one side has been picked, we want to show one side of the side-by-side
  even if the other one isn't available. However, the underlying component needs
  both sides to render (it's really a table where each row has 2 parts).

  So, the hack is this: if we've got one side but not the other, we pass the
  same value in for both sides and the info that one side is blank. Then the blank
  side is rendered as opacity 0 but all the text for the non-blank side is visible.
*/

export const SideBySideView = observer(function SideBySideView({
  scriptId,
}: {
  scriptId: string
}) {
  const diffData = useSideBySideDiffData(scriptId)
  const { sideBySideContextLines, monochrome } = useMst().view.snapshotLand

  if (
    diffData === 'error' ||
    diffData === 'loading' ||
    diffData === 'no-data'
  ) {
    const variant: 'error' | 'loading' | 'pickOne' =
      diffData === 'no-data' ? 'pickOne' : diffData
    return <BodyPlaceholder variant={variant} />
  }

  return (
    <SideBySideDiff
      {...diffData}
      monochrome={monochrome}
      linesOfContext={sideBySideContextLines}
    />
  )
})
