/*
  This makes sure an effect runs once during the lifetime of the component.
  It can happen immediately on mount or can be delayed by passing in a value
  of disabled = true until the time is right.
*/
import React from 'react'

export const useEffectOnlyOnce = (fn: () => void, disabled = false) => {
  const hasRunRef = React.useRef(false)
  React.useEffect(() => {
    if (!(disabled || hasRunRef.current)) {
      hasRunRef.current = true
      fn()
    }
  })
  return hasRunRef.current
}
