/*
  Modal for a yes/no situation where "yes" triggers an async request
  (which means it can fail).

  Required props are title, children, errorMessage and onConfirm. The other
  props use sensible defaults (e.g. confirm button label is "OK") but can
  be overridden
*/
import NiceModal from '@ebay/nice-modal-react'
import { MantineSize } from '@mantine/core'

import { useMst } from '@state'

import { ModalShell } from './ModalShell'
import { useModalControls } from './useModalControls'

type AsyncConfirmModalProps = {
  title: string
  children: React.ReactNode
  errorMessage: string
  onConfirm: AsyncCallback
  onClose?: Callback
  confirmLabel?: string
  cancelLabel?: string
  dangerous?: boolean
  size?: MantineSize
}
const AsyncConfirmInternal = (props: AsyncConfirmModalProps) => {
  const { doDebug } = useMst()
  const controls = useModalControls()
  const {
    onConfirm,
    onClose,
    children,
    errorMessage,
    size = 'md',
    confirmLabel = 'OK',
    cancelLabel = 'Cancel',
    ...passThroughProps
  } = props

  const handleConfirm = async () => {
    controls.setLoading(true)
    try {
      await doDebug()
      await onConfirm()
      controls.onClose()
    } catch {
      controls.setErrorMessage(errorMessage)
    } finally {
      controls.setLoading(false)
    }
  }

  const handleClose = () => {
    onClose?.()
    controls.onClose()
  }

  return (
    <ModalShell
      {...passThroughProps}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
      size={size}
      onConfirm={handleConfirm}
      opened={controls.opened}
      loading={controls.loading}
      errorMessage={controls.errorMessage}
      onClose={handleClose}
    >
      {children}
    </ModalShell>
  )
}

const AsyncConfirmModal =
  NiceModal.create<AsyncConfirmModalProps>(AsyncConfirmInternal)

export const showAsyncConfirmModal = (props: AsyncConfirmModalProps) => {
  NiceModal.show(AsyncConfirmModal, props)
}
