import React from 'react'

import { ActionIcon, Group, Stack, Text } from '@mantine/core'
import { DateInput, TimeInput } from '@mantine/dates'
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz'

import { FaIcon } from '@components/FaIcon'

import env from '../../choo-app/lib/env.js'

import styles from './DateTimeInput.module.scss'

const getTimePartStrings = ({
  input,
  timeZone,
}: {
  timeZone: string
  input: Date | undefined
}): {
  datePart: string
  timePart: string
  tzPart: string
} => {
  if (input && !isNaN(input.valueOf())) {
    const formattedForInputs = formatInTimeZone(
      input,
      timeZone,
      'yyyy-MM-dd HH:mm zzz',
    )
    const [datePart, timePart, tzPart] = formattedForInputs.split(' ')

    return {
      datePart,
      timePart,
      tzPart,
    }
  }
  return {
    datePart: '',
    timePart: '',
    tzPart: '',
  }
}

export const DateTimeInput = ({
  label,
  value,
  onChange,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}: {
  label?: string
  value: Date | undefined
  onChange: (d: Date | undefined) => void
  timeZone?: string
}) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const { datePart, timePart, tzPart } = getTimePartStrings({
    input: value,
    timeZone,
  })

  const [dateVal, setDateVal] = React.useState<Date | null>(
    datePart ? new Date(datePart) : null,
  )
  const [timeVal, setTimeVal] = React.useState(timePart)

  React.useEffect(() => {
    if (dateVal && timeVal) {
      const dateString = dateVal.toISOString().slice(0, 10)
      const newDate = fromZonedTime(`${dateString} ${timeVal}`, timeZone)
      if (!isNaN(newDate.valueOf())) {
        onChange(newDate)
        return
      }
    }
    onChange(undefined)
  }, [dateVal, onChange, timeVal, timeZone])

  const clockIcon = <FaIcon faIconSet="fa-regular" icon="fa-clock" />

  // firefox and safari dont ship a time picker!
  const picker = env.browser.chrome ? (
    <ActionIcon onClick={() => ref.current?.showPicker()} color="dark">
      {clockIcon}
    </ActionIcon>
  ) : (
    clockIcon
  )

  return (
    <Stack gap={0}>
      {label && <Text>{label}</Text>}
      <Group gap={5}>
        <DateInput
          classNames={{ input: styles.timeInput }}
          value={dateVal}
          w={115}
          onChange={setDateVal}
          valueFormat="MM/DD/YYYY"
          placeholder="mm/dd/yyyy"
        />
        <TimeInput
          ref={ref}
          classNames={{ input: styles.timeInput }}
          value={timeVal}
          onChange={(e) => setTimeVal(e.target.value)}
          leftSection={picker}
        />
        <Text>{tzPart}</Text>
      </Group>
    </Stack>
  )
}
