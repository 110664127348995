// this is a non-configurable column that needs to be consistent across all
// schemas/rundowns
export const CONTROLS_COLUMN_ID = '__CONTROLS'

// Row levels are like indent levels in an outline. The top-mos
// level is 1
export const ROW_LEVEL_BLOB_FIELD = '__LEVEL'

// how long to honor rows in the localstorage rundown clipboard
export const CLIPBOARD_LIFESPAN_SECONDS = 120
