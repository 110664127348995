import { Button, Group } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { OrgTierMap } from '@showrunner/codex'

import { showUpgradeInquiryModal } from '@components/Modals'
import { Toast } from '@components/Toast'
import { IOrg } from '@state'

import { showCreateInviteModal } from './CreateInviteModal'

import styles from './CreateInviteButton.module.scss'

export const CreateInviteButton = observer(function CreateInviteButton({
  inviteCount,
  workspace,
}: {
  inviteCount: number
  workspace: IOrg
}) {
  const canInvite = !(
    workspace.tier === OrgTierMap.FREE &&
    workspace.members.length + inviteCount >= 3
  )

  const showModal = () => {
    if (workspace) {
      showCreateInviteModal({ org: workspace })
    }
  }

  return canInvite ? (
    <Group justify="flex-end">
      <Button onClick={showModal}>
        <i className="fa fa-plus is-link-icon"></i>
        Invite New Member
      </Button>
    </Group>
  ) : (
    <Toast
      message={
        <>
          Your workspace is on the&nbsp;<strong>Free Plan</strong>&nbsp;and is
          limited to 3 members.
        </>
      }
      dismissable={false}
    >
      <Button
        className={styles.toast_button___neutral}
        variant="outline"
        onClick={() => showUpgradeInquiryModal(workspace)}
      >
        Request Upgrade
      </Button>
    </Toast>
  )
})
