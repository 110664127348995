import { ScriptDocType } from '@showrunner/codex'

import {
  buildShortcutTip,
  detectedPlatform,
  Keys,
  Platform,
} from '@hooks/useShortcuts'

const { CMD, ENTER, SHIFT, TAB, ALT } = Keys

type ModalShortcuts = {
  navigation: JSONValue
  formatting: JSONValue
  elements: Record<ScriptDocType, JSONValue>
}

export const modalShortcuts = (
  platform: Platform = detectedPlatform,
): ModalShortcuts => {
  return {
    navigation: {
      'Create a new block': buildShortcutTip([ENTER], platform),
      'Cycle through element types': buildShortcutTip([TAB], platform),
      'Cycle through element types backward': buildShortcutTip(
        [SHIFT, TAB],
        platform,
      ),
      'Open element menu': buildShortcutTip([CMD, '\\'], platform),
      'Open timing menu': buildShortcutTip([CMD, SHIFT, '0'], platform),
      'Exclude from timing': buildShortcutTip([CMD, SHIFT, 'X'], platform),
      'Push to prompter': buildShortcutTip([CMD, SHIFT, 'P'], platform),
      'Insert page break': buildShortcutTip([CMD, ENTER], platform),
    },
    formatting: {
      // repeat of the constants above to allow overriding the detected platform
      Bold: buildShortcutTip([CMD, 'B'], platform),
      Italic: buildShortcutTip([CMD, 'I'], platform),
      Underline: buildShortcutTip([CMD, 'U'], platform),
      Strikethrough: buildShortcutTip([CMD, SHIFT, 'U'], platform),
      Caps: buildShortcutTip([CMD, ALT, 'A'], platform),
    },
    elements: {
      variety: {
        General: buildShortcutTip([CMD, '0'], platform),
        Slug: buildShortcutTip([CMD, '1'], platform),
        Bracket: buildShortcutTip([CMD, '2'], platform),
        Character: buildShortcutTip([CMD, '3'], platform),
        Parenthetical: buildShortcutTip([CMD, '4'], platform),
        Dialogue: buildShortcutTip([CMD, '5'], platform),
        'New Act': buildShortcutTip([CMD, '8'], platform),
        'End of Act': buildShortcutTip([CMD, '9'], platform),
        'Toggle menu': buildShortcutTip([CMD, 'K'], platform),
      },
      classic: {
        Slug: buildShortcutTip([CMD, '1'], platform),
        Bracket: buildShortcutTip([CMD, '2'], platform),
        Character: buildShortcutTip([CMD, '3'], platform),
        Parenthetical: buildShortcutTip([CMD, '4'], platform),
        Dialogue: buildShortcutTip([CMD, '5'], platform),
      },
      screenplay: {
        General: buildShortcutTip([CMD, '0'], platform),
        'Scene Heading': buildShortcutTip([CMD, '1'], platform),
        Action: buildShortcutTip([CMD, '2'], platform),
        Character: buildShortcutTip([CMD, '3'], platform),
        Parenthetical: buildShortcutTip([CMD, '4'], platform),
        Dialogue: buildShortcutTip([CMD, '5'], platform),
        Transition: buildShortcutTip([CMD, '6'], platform),
        'New Act': buildShortcutTip([CMD, '8'], platform),
        'End of Act': buildShortcutTip([CMD, '9'], platform),
      },
    },
  }
}
