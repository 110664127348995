import { MantineThemeOverride } from '@mantine/core'

export const { Blockquote }: MantineThemeOverride['components'] = {
  Blockquote: {
    styles: () => ({
      root: {
        lineHeight: 1.2,
      },
    }),
  },
}
