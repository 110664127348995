/*
  Experimental data model: Eventually more of this will move to the
  server, right now we're hard-coding one of three data rundown schemas.

  Every rundown gets to specify some per-rundown behaviors and what columns it uses...
  Right now we've only got a couple of variants and we're hard coding them in the react app... down the road
  the values AND the schema definitions should live in the database, but we're not there yet, so
  right now this file is making some assumptions about field names/interpretations and is shimming
  in data (from the RundownSchemas file) that eventually will move to the server.
*/

import { addSeconds } from 'date-fns'
import { getSnapshot, types } from 'mobx-state-tree'

import { BlobData } from '@util/ScriptoApiClient/types'

import { IsoDate } from '../IsoDate'

export const RundownBlobData = types
  .model('RundownBlobData', {
    startTime: types.maybe(IsoDate),
    episodeLengthSeconds: 0,
  })
  .views((self) => ({
    get endTime(): Date | undefined {
      if (self.startTime && self.episodeLengthSeconds > 0) {
        return addSeconds(self.startTime, self.episodeLengthSeconds)
      }
    },
    forServer(): BlobData {
      return getSnapshot(self) as unknown as BlobData
    },
    // used to know when we need to recompute grid values (e.g. front-timing
    // cells need to recompute when start time changes
    get hash() {
      return JSON.stringify(this.forServer())
    },
  }))
  .actions((self) => ({
    setStartTime(startTime: Date | undefined) {
      self.startTime = startTime
    },
    setEpisodeLength(totalSeconds: number) {
      self.episodeLengthSeconds = totalSeconds
    },
  }))
