import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { StatusBar } from '@components/StatusBar'
import { useMst } from '@state'

export const SyntaxHighlighting = observer(function SyntaxHighlighting() {
  const mst = useMst()
  const active = !!mst.user.inkPreferences?.syntaxHighlighting

  const onClick = () => {
    mst.user.updateInkPreferences({ syntaxHighlighting: !active })
    mst.currentScript?.pmEditor.rerender()
  }

  return (
    <StatusBar.ActionIcon
      tooltip="Syntax highlighting"
      btnVariant="subtle"
      active={active}
      onClick={onClick}
      icon={<FaIcon icon="fa-regular fa-highlighter-line"></FaIcon>}
    />
  )
})
