/*
  Helper type so we can take the ISO serialized date strings from
  our api and use them directly as types.Date in mst and reserialize
  back properly for the server

  https://stackoverflow.com/questions/71790983/how-to-convert-datetime-string-to-date-object-via-mobx-state-tree-properly


  addendum- July 2023: For nido with ts-rest the scrapi api layer is already transforming
  to date so this model can receive a Date instance OR an IsoString and convert it to a
  date in mst. If we start consuming all dates via scrapi, we no longer need this class.
*/

import { types } from 'mobx-state-tree'

function validateDate(input: string | Date) {
  if (input instanceof Date) {
    return input
  }
  const date = Date.parse(input)
  if (isNaN(date)) throw new Error('Invalid date')

  return new Date(date)
}

export const IsoDate = types.custom({
  name: 'IsoDate',
  fromSnapshot(value: string | Date) {
    if (value instanceof Date) {
      return value
    }
    return validateDate(value)
  },
  toSnapshot(value) {
    return value.toISOString()
  },
  isTargetType(maybeDate) {
    return maybeDate instanceof Date
  },
  getValidationMessage(snapshot) {
    try {
      validateDate(snapshot)
      return ''
    } catch (error) {
      return `Invalid date: ${snapshot}`
    }
  },
})
