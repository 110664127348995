import React from 'react'

import { useIntersection } from '@mantine/hooks'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useDrag, useDrop } from 'react-dnd'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@state'
import { NavLinkData, navLinkText } from '@util'

import styles from './NavLink.module.scss'

function navLinkClasses(link: NavLinkData) {
  return cn(styles.navLink, {
    [styles.navLink___blank]: link.isBlank,
    [styles.navLink___top]: link.type === 'new_act',
    [styles.navLink___bracket]: link.type === 'bracket',
  })
}

const DropTarget = ({
  id,
  isFinal,
}: {
  // This is true for the drop target below the last nav link
  isFinal?: boolean
  id: string
}) => {
  const { currentScript } = useMst()

  const handleDrop = (droppedId: string) => {
    if (isFinal) {
      currentScript?.moveNavLinkToEnd(droppedId)
    } else {
      currentScript?.moveNavLinkAboveOtherLink({
        movedLinkId: droppedId,
        targetLinkId: id,
      })
    }
  }

  const [{ isOver }, dropRef] = useDrop<
    { id: string },
    void,
    { isOver: boolean }
  >(() => ({
    accept: 'nav-link',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop(item) {
      handleDrop(item.id)
    },
  }))

  return (
    <div
      className={cn(styles.navLink_dropTarget, {
        [styles.navLink_dropTarget___over]: isOver,
        [styles.navLink_dropTarget___below]: isFinal,
      })}
      ref={dropRef}
    >
      <div className={styles.navLink_dropTargetLine} />
    </div>
  )
}

type NavLinkProps = {
  link: NavLinkData
  // true if this item can be dragged in the current context
  draggable?: boolean
  // true if this item can have another item dropped above it
  canDropAbove?: boolean
  // true if this item can have another item dropped below it
  // (this is only the case if this is the final act/slug/scene and
  // other criteria are met)
  showFinalDropTarget?: boolean
  // if the cursor in the doc is on this link or between this and the
  // next link
  cursorPlacement?: 'above' | 'on' | 'below'
  onClick: () => void
}

export const NavLink = observer(function NavLink({
  link,
  draggable,
  canDropAbove,
  showFinalDropTarget,
  onClick,
  cursorPlacement,
}: NavLinkProps) {
  const [, dragHandleRef, dragPreviewRef] = useDrag(() => ({
    canDrag: draggable,
    type: 'nav-link',
    item: { id: link.id },
    options: {
      dropEffect: 'move',
    },
    collect: (monitor) => ({
      allowDrag: monitor.canDrag() && draggable,
      isDragging: monitor.isDragging(),
    }),
  }))
  const intersection = useIntersection({
    threshold: 1,
  })
  const classes = navLinkClasses(link)
  const label = navLinkText(link)

  React.useEffect(() => {
    if (!intersection.entry?.isIntersecting && cursorPlacement) {
      intersection.entry?.target?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    }
    // we intentionally don't include isIntersecting in the dep array because
    // it triggers Safari overzealously trying to keep the current cursor pos
    // in view when users are actively trying to scroll away
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursorPlacement, intersection.entry?.target])

  return (
    <div className={classes} onClick={onClick} ref={dragHandleRef}>
      {draggable && (
        <span className={styles.navLink_icon}>
          <i className="icon-drag" />
        </span>
      )}
      <div className={styles.navLink_element} ref={dragPreviewRef}>
        {label}
      </div>

      <div
        ref={intersection.ref}
        className={cn(styles.navLink_cursor, {
          [styles.on]: cursorPlacement === 'on',
          [styles.above]: cursorPlacement === 'above',
          [styles.below]: cursorPlacement === 'below',
        })}
      >
        <FaIcon icon="fa-arrow-right-long" c="dark" />
      </div>

      {canDropAbove && <DropTarget id={link.id} />}
      {showFinalDropTarget && <DropTarget id={link.id} isFinal />}
    </div>
  )
})
