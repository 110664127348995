import { observer } from 'mobx-react-lite'

import { Toolbar } from '@components/Toolbar'
import { ButtonMode, useMst } from '@state'

import { isPopulatedData, useSideBySideDiffData } from '../useSideBySideData'
import { useScriptData } from '../useSnapshotLandData'

import { showSelectSlenes } from './SelectSlenesModal'

export const SlenePickerButton = observer(function SlenePickerButton({
  mode,
  scriptId,
}: {
  mode: ButtonMode
  scriptId: string
}) {
  const {
    selectSlene,
    deselectSlene,
    trackSnapshotEvent: trackEvent,
  } = useMst().view.snapshotLand
  const sideBySideData = useSideBySideDiffData(scriptId)
  const enabled = isPopulatedData(sideBySideData) && !sideBySideData.blankSide
  // if we have a slene selected for either snapshot, the button is
  // highlighted
  const sleneIsSelected =
    enabled &&
    !!(
      sideBySideData.leftActiveSlene?.id || sideBySideData.rightActiveSlene?.id
    )
  const scriptQuery = useScriptData(scriptId)
  // we need to look at the script itself to know if we should
  // describe slenes as "slugs" or "scenes". If it hasn't loaded yet,
  // we're being lazy and just writing screenplay (but the script is very likely
  // already in the cache when we hit this view)
  const docType = scriptQuery.isSuccess
    ? scriptQuery.data.script.type
    : 'screenplay'

  const label = `${sleneIsSelected ? 'Comparing' : 'Compare'} ${
    docType === 'screenplay' ? 'scenes' : 'slugs'
  }`

  const handleClick = () => {
    trackEvent('SHOW_SLENE_PICKER')
    if (enabled) {
      const { leftSlenes, rightSlenes, leftActiveSlene, rightActiveSlene } =
        sideBySideData
      showSelectSlenes({
        leftItems: leftSlenes,
        rightItems: rightSlenes,
        docType,
        selectedLeftId: leftActiveSlene?.id,
        selectedRightId: rightActiveSlene?.id,
        onSelect: ({ left, right }) => {
          if (left) {
            selectSlene(left.id, 'left')
          } else {
            deselectSlene('left')
          }

          if (right) {
            selectSlene(right.id, 'right')
          } else {
            deselectSlene('right')
          }
        },
      })
    }
  }

  return (
    <Toolbar.Button
      mode={mode}
      disabled={!enabled}
      icon="fa-arrow-down-left-and-arrow-up-right-to-center"
      label={label}
      active={sleneIsSelected}
      onClick={handleClick}
    />
  )
})
