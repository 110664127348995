import { Button } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'
import { StatusBar } from '@components/StatusBar'

export const PagelessToggle = ({
  isPageless = false,
  onEnablePageless,
  onDisablePageless,
}: {
  isPageless?: boolean
  onDisablePageless?: () => void
  onEnablePageless?: () => void
}) => (
  <Button.Group>
    <StatusBar.ActionIcon
      tooltip="Pages"
      active={!isPageless}
      onClick={onDisablePageless}
      icon={<FaIcon icon="fa-regular fa-memo"></FaIcon>}
    />
    <StatusBar.ActionIcon
      tooltip="Pageless"
      active={isPageless}
      onClick={onEnablePageless}
      icon={<FaIcon size="9" icon="fa-pageless" faIconSet="fa-kit" />}
    />
  </Button.Group>
)
