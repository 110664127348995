import { Radio as RadioComponent } from '@mantine/core'

import styles from './radio.module.scss'

export const Radio = RadioComponent.extend({
  classNames: {
    description: styles.description,
    label: styles.label,
  },
})
