import React from 'react'

import { Menu } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { useRundownOperations } from '@components/RundownGrid/useRundownOperations'
import { Toolbar } from '@components/Toolbar'
import { Keys, useFloatingMenu, useShortcuts } from '@hooks'
import { useMst } from '@state'
import { ButtonMode, IRundown } from '@state/types'
import { RundownRowData } from '@util/ScriptoApiClient/types'

import { ContextMenu } from '../ContextMenu'
import * as helpers from '../helpers'
import { ImportRowsItem, InsertItems } from '../InsertMenu'

import { showClipboardPasteModal } from './ClipboardPasteModal'

export const EditMenu = observer(function EditMenu({
  rundown,
  buttonMode,
  readonly,
}: {
  readonly: boolean
  rundown: IRundown
  buttonMode: ButtonMode
}) {
  const { user, currentOrg } = useMst()
  const floatProps = useFloatingMenu()
  const [pasteableRows, setPasteableRows] = React.useState<RundownRowData[]>([])
  const operations = useRundownOperations(rundown, user)
  const fakeWindows = rundown.rootStore.view.isDebugEnabled('windows')

  const { rows: selectedOrFocusedRows, continuous } = rundown.selectionSummary
  const rowCount =
    selectedOrFocusedRows.length > 0 ? selectedOrFocusedRows.length : 1
  const sequence = selectedOrFocusedRows[0]?.sequence ?? 1
  const canInsertRows =
    currentOrg && !readonly && (rowCount === 1 || continuous)
  const pasteableRowCount = pasteableRows.length
  const canPaste = !readonly && selectedOrFocusedRows.length === 0
  const canPasteRows = !readonly && pasteableRowCount > 0 && continuous
  const canDeleteRows = !readonly && selectedOrFocusedRows.length > 0
  const uniqueSelectedRow =
    selectedOrFocusedRows.length === 1 ? selectedOrFocusedRows[0] : undefined
  const importScriptId: string | null =
    uniqueSelectedRow?.identityScriptId ?? null
  const canImport = !readonly && typeof importScriptId === 'string'
  const rowIds = selectedOrFocusedRows.map((r) => r.id)

  const deleteRowsAction = () => {
    user.prefs.suppressConfirmations
      ? rundown.removeRows(rowIds)
      : operations.deleteRows(rowIds)
  }

  const { menuProps, getItemProps } = useShortcuts(
    {
      // this is copy/pasta from <InsertItems /> but i couldnt think of a clever way to
      // share logic with two disparate menu items without triggering a duplicate listener
      insertRows: {
        keys: [Keys.CMD, Keys.ALT, 'I'],
        action: () =>
          operations.insertBlankRows({
            rowCount,
            sequence,
            rowTypeId: 'element',
          }),
        disabled: !canInsertRows,
      },
      deleteRows: {
        keys: [Keys.CMD, Keys.DEL],
        action: deleteRowsAction,
        disabled: !canDeleteRows,
      },
    },
    { platform: fakeWindows ? 'win' : undefined },
  )

  React.useEffect(() => {
    if (menuProps.opened || floatProps.opened) {
      setPasteableRows(rundown.pasteableRows)
    }
  }, [floatProps.opened, menuProps.opened, rundown])

  const copyItem = (
    <FancyMenuItem
      title={helpers.copyTitle(rundown.sortedSelectedRows.length)}
      onClick={() => rundown.gridRef.current?.api.copyToClipboard()}
      shortcut={helpers.COPY_TIP}
      disabled={selectedOrFocusedRows.length === 0}
    />
  )

  const pasteItem = (
    <FancyMenuItem
      title="Paste into cell"
      shortcut={helpers.PASTE_TIP}
      onClick={showClipboardPasteModal}
      disabled={!canPaste}
    />
  )

  const pasteRowsItem = (
    <FancyMenuItem
      title={helpers.pasteRowsTitle(
        pasteableRowCount,
        selectedOrFocusedRows.length === 0,
      )}
      disabled={!canPasteRows}
      onClick={() => operations.insertFromClipboard()}
    />
  )

  const deleteRowsItem = (
    <FancyMenuItem
      iconClass="fas fa-trash"
      color="red"
      title={rowCount > 1 ? `Delete ${rowCount} rows` : 'Delete row'}
      {...getItemProps('deleteRows')}
    />
  )

  const handleSelectRowAndChildren = () => {
    if (uniqueSelectedRow) {
      rundown.replaceSelectedRows([
        uniqueSelectedRow,
        ...rundown.getChildRows(uniqueSelectedRow.id),
      ])
    }
  }

  // we only put enabled items into the context menu.
  // This array groups them into the sets that have dividers
  // between them
  const contextMenuItems: React.ReactNode[] = [
    // select child rows
    uniqueSelectedRow?.rowLevel ? (
      <FancyMenuItem
        onClick={handleSelectRowAndChildren}
        title={helpers.selectChildRowsTitle(uniqueSelectedRow)}
        shortcut={helpers.SELECT_CHILD_ROWS_TIP}
        iconClass="fas fa-square-check"
      />
    ) : null,
    // all insert row types
    canInsertRows ? (
      <InsertItems rundown={rundown} workspace={currentOrg} />
    ) : null,
    // clipboard items
    selectedOrFocusedRows.length > 0 ? (
      <>
        {copyItem}
        {canPasteRows && pasteRowsItem}
        {canPaste && pasteItem}
      </>
    ) : null,
    // paste rows
    canImport ? <ImportRowsItem rundown={rundown} /> : null,
    canDeleteRows ? deleteRowsItem : null,
  ].filter((item) => item !== null)

  return (
    <>
      {/* This is the Edit menu which has a normal target button */}
      <Menu {...menuProps} withinPortal>
        <Menu.Target>
          <div>
            <Toolbar.Button
              label="Edit"
              mode={buttonMode}
              icon="fa-pen-to-square"
              dropdown
            />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {copyItem}
          {pasteItem}
          {pasteRowsItem}
          <Menu.Divider />
          {deleteRowsItem}
        </Menu.Dropdown>
      </Menu>
      {/*
          This is the Context menu which has a subset of edit menu items and whose
          target is fixed position, usually off screen, and shows up on right-click
        */}
      <ContextMenu floatProps={floatProps} items={contextMenuItems} />
    </>
  )
})
