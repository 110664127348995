import { Plugin, PluginKey } from 'prosemirror-state'

export const collabUpdateKey = new PluginKey('COLLAB_UPDATE')

export function collabUpdatePlugin() {
  return new Plugin({
    key: collabUpdateKey,
    state: {
      init() {
        return false
      },
      apply(tr) {
        // if there's any collab meta on the tr at all, it's a collab update
        const collab = tr.getMeta('collab$')
        if (collab) {
          return true
        }
        return false
      },
    },
  })
}
