import { Plugin } from 'prosemirror-state'

import { PluginFactory } from '@choo-app/lib/editor/plugins/types'

import {
  ElementNumberInventory,
  elementNumbersPluginKey,
  getDecorations,
  getElementNumberInventory,
} from './helpers'

export const elementNumbersPlugin: PluginFactory = ({ script }) => {
  return new Plugin<ElementNumberInventory>({
    state: {
      init(_, state) {
        return getElementNumberInventory(state)
      },
      apply(_, pluginState, oldState, newState) {
        return oldState.doc.eq(newState.doc)
          ? pluginState
          : getElementNumberInventory(newState)
      },
    },
    key: elementNumbersPluginKey,
    props: {
      decorations(state) {
        return getDecorations({ state, script })
      },
    },
  })
}
