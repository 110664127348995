import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { TextInput } from '@mantine/core'
import { email } from '@sideway/address'

import { ModalShell, useModalControls } from '@components/Modals'
import { useInviteData } from '@components/Settings/useInviteData'
import { IOrg, useMst } from '@state'

const CreateInvite = ({ org }: { org: IOrg }) => {
  const { doDebug } = useMst()
  const controls = useModalControls()
  const { invalidateInviteQuery } = useInviteData(org)
  const [trimmedEmail, setTrimmedEmail] = React.useState('')

  const handleSubmit = async () => {
    controls.setLoading(true)
    try {
      await doDebug()
      await org.createInvite(trimmedEmail)
      invalidateInviteQuery()
      controls.onClose()
    } catch (e) {
      controls.setErrorMessage('Failed to send invitation')
    } finally {
      controls.setLoading(false)
    }
  }

  return (
    <ModalShell
      size="sm"
      title="Invite new member"
      opened={controls.opened}
      onClose={controls.onClose}
      onConfirm={handleSubmit}
      confirmLabel="Create Invitation"
      cancelLabel="Cancel"
      disabled={!email.isValid(trimmedEmail)}
      loading={controls.loading}
      errorMessage={controls.errorMessage}
    >
      <TextInput
        placeholder="name@email.com"
        value={trimmedEmail}
        onChange={(e) => setTrimmedEmail(e.target.value.trim())}
      />
    </ModalShell>
  )
}
const CreateInviteModal = NiceModal.create(CreateInvite)

export const showCreateInviteModal = (props: { org: IOrg }) =>
  NiceModal.show(CreateInviteModal, props)
