import { RowClassRules } from '@ag-grid-community/core'

import { EnrichedRowData, ROW_LEVEL_BLOB_FIELD } from '@util/rundowns'

import styles from './rowClassRules.module.scss'

export const PAGE_BREAK_BLOB_KEY = '__PAGE_BREAK'

export const rowClassRules: RowClassRules<EnrichedRowData> = {
  [styles.scriptRow]: (params) => {
    return params.data?.rowTypeId === 'script'
  },

  [styles.level1]: (params) => {
    return params.data?.blobData[ROW_LEVEL_BLOB_FIELD] === 1
  },

  [styles.level2]: (params) => {
    return params.data?.blobData[ROW_LEVEL_BLOB_FIELD] === 2
  },

  [styles.level3]: (params) => {
    return params.data?.blobData[ROW_LEVEL_BLOB_FIELD] === 3
  },

  [styles.pageBreakRow]: (params) => {
    return !!params.data?.blobData[PAGE_BREAK_BLOB_KEY]
  },
}
