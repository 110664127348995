import * as p2r from 'path-to-regexp'
import { ExtractRouteParams } from 'wouter'

import { NavAnchorHref } from '@components/NavAnchor'

import { ROUTE_PATTERNS } from './pathConfigs'

// don't recreate the compile function every time, just lazily build
// a cache and reuse
const buildCache: { [key: string]: p2r.PathFunction } = {}

const makeBuilder = (pattern: string) => {
  if (buildCache[pattern]) {
    return buildCache[pattern]
  }

  const fn = p2r.compile(pattern, {
    encode: encodeURIComponent,
  })
  buildCache[pattern] = fn
  return fn
}

export const pathTo = <T extends ValueOf<typeof ROUTE_PATTERNS>>(
  pattern: T,
  params?: ExtractRouteParams<T>,
  options?: {
    queryParams?: { [key: string]: string | number | boolean }
  },
): NavAnchorHref => {
  const parts: string[] = []
  parts.push(makeBuilder(pattern)(params))
  if (options?.queryParams) {
    parts.push('?')
    const queryParts: string[] = []
    Object.entries(options.queryParams).forEach(([key, val]) => {
      queryParts.push(`${key}=${val}`)
    })
    parts.push(queryParts.join('&'))
  }
  // too hard to figure out how to get typescript to know that
  // our internal paths all start with /
  return parts.join('') as NavAnchorHref
}

export const absolutePathTo = <T extends ValueOf<typeof ROUTE_PATTERNS>>(
  pattern: T,
  params?: ExtractRouteParams<T>,
  options?: {
    queryParams?: { [key: string]: string | number | boolean }
  },
) => {
  return window.location.origin + pathTo(pattern, params, options)
}
