import axios from 'axios'

export type ParsedServerError = {
  code?: string
  message: string
  status?: number
  error?: Error
}
export const parseServerError = (e: unknown): ParsedServerError => {
  if (axios.isAxiosError(e) && e.response) {
    const { data, status, statusText } = e.response
    const code = typeof data?.code === 'string' ? data.code : undefined
    const message =
      typeof data?.message === 'string' ? data.message : statusText

    return {
      code,
      message,
      status,
      error: e,
    }
  }

  if (e instanceof Error) {
    const { message } = e
    return {
      message,
    }
  }

  return {
    message: 'An unknown server error occurred.',
  }
}
