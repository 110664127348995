// TRANSIENT_FLAGS
// for experimental nido endpoints
export const NIDO_ALPHA = 'nido-alpha'
// for nido endpoints nearing readiness for general availability
export const NIDO_BETA = 'nido-beta'
export const NIDO_SOCKETS = 'nido-sockets'
export const FORMATTING = 'formatting'
export const LEGACY_SNAPSHOTS = 'legacy-snapshots'

// PERMANENT FLAGS
