import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Group, NumberInput, Stack, Text } from '@mantine/core'

import { ModalShell, useModalControls } from '@components/Modals'
import { IRundown } from '@state/types'
import { breakDownSeconds, formatSeconds } from '@util'

const DurationInput = ({
  label,
  value,
  setValue,
  valid,
}: {
  label: string
  value: number
  setValue: (newValue: number) => void
  valid: boolean
}) => {
  const handleChange = (newValue: number | '') => {
    const numericValue = typeof newValue === 'number' ? newValue : 0
    setValue(numericValue)
  }

  return (
    <NumberInput
      styles={{
        root: {
          width: 60,
        },
        input: {
          textAlign: 'right',
        },
      }}
      label={label}
      size="sm"
      value={value}
      onChange={(val) => handleChange(Number(val))}
      hideControls
      error={!valid}
    />
  )
}

const EpisodeLengthModal = NiceModal.create(
  ({ rundown }: { rundown: IRundown }) => {
    const controls = useModalControls()

    const savedValues = breakDownSeconds(rundown.blobData.episodeLengthSeconds)

    const [hours, setHours] = React.useState(savedValues.hours)
    const [minutes, setMinutes] = React.useState(savedValues.minutes)
    const [seconds, setSeconds] = React.useState(savedValues.seconds)

    const hoursValid = hours >= 0
    const minutesValid = minutes >= 0 && minutes < 60
    const secondsValid = seconds >= 0 && seconds < 60

    const isValid = hoursValid && minutesValid && secondsValid
    const totalInSeconds = hours * 60 * 60 + minutes * 60 + seconds
    const displayValue = isValid
      ? formatSeconds(totalInSeconds)
      : 'Invalid episode length'

    React.useEffect(() => {
      controls.setDisabled(!isValid)
    }, [controls, isValid])

    const handleSave = async () => {
      controls.setLoading(true)
      try {
        await rundown.rootStore.doDebug()
        await rundown.updateEpisodeLength(totalInSeconds)
        controls.onClose()
      } catch {
        controls.setErrorMessage('Failed to update Episode Length')
      } finally {
        controls.setLoading(false)
      }
    }

    return (
      <ModalShell
        title="Episode Length"
        onConfirm={handleSave}
        confirmLabel="Save"
        cancelLabel="Cancel"
        opened={controls.opened}
        onClose={controls.onClose}
        errorMessage={controls.errorMessage}
        loading={controls.loading}
        disabled={controls.disabled}
      >
        <Stack align="center">
          <Group>
            <DurationInput
              label="Hours"
              value={hours}
              setValue={setHours}
              valid={hoursValid}
            />
            <DurationInput
              label="Mins"
              value={minutes}
              setValue={setMinutes}
              valid={minutesValid}
            />
            <DurationInput
              label="Secs"
              value={seconds}
              setValue={setSeconds}
              valid={secondsValid}
            />
          </Group>
          <Text color={isValid ? undefined : 'red'}>{displayValue}</Text>
        </Stack>
      </ModalShell>
    )
  },
)

export const showEpisodeLengthModal = (rundown: IRundown) => {
  NiceModal.show(EpisodeLengthModal, { rundown })
}
