import React from 'react'

/*
  Two fancy HTML hacks going on here:

  1.  to allow the user to select text on just one side of the diff, we set
      up some css classes that toggle user-select:none on and off. Then we look
      at mousedown events and see if they're within a left/right side and toggle
      the selection side accordingly

  2.  WebKit doesn't respect user-select:none when deciding what to put into the
      clipboard (long-standing, super annoying, deep issue).  So, if the user has selected
      stuff ONLY on the left or right side of a side-by-side diff, we intercept the
      copy event and strip out the content from the other side.
*/
const LEFT_ATTRIBUTE = 'data-diff-left'
const RIGHT_ATTRIBUTE = 'data-diff-right'
const ROW_ATTRIBUTE = 'data-diff-row'

// these are used in the component to apply the
// correct data-diff-* props... like <div {...DIFF_DATA_PROPS.left}>
export const DATA_DIFF_PROPS = {
  left: { [LEFT_ATTRIBUTE]: true },
  right: { [RIGHT_ATTRIBUTE]: true },
  row: { [ROW_ATTRIBUTE]: true },
}

const LEFT_SELECTOR = `[${LEFT_ATTRIBUTE}]`
const RIGHT_SELECTOR = `[${RIGHT_ATTRIBUTE}]`

const isWebkit = navigator.userAgent.indexOf('AppleWebKit') > -1

// helper to remove all children with an attribute (like data-diff-left)
const stripChildren = (elt: Element, attributeToExclude: string) => {
  elt.childNodes.forEach((childNode) => {
    if (childNode.nodeType === Node.ELEMENT_NODE) {
      const childElt = childNode as Element
      if (childElt.hasAttribute(attributeToExclude)) {
        elt.removeChild(childElt)
      } else {
        stripChildren(childElt, attributeToExclude)
      }
    }
  })
}

// get the textContent of a node. If it's a row node, append a newline.
const getClipboardText = (node: ChildNode): string => {
  if (node.nodeType === Node.ELEMENT_NODE) {
    const elt = node as Element
    if (elt.textContent !== null) {
      const isRow = elt.hasAttribute(ROW_ATTRIBUTE)
      return isRow ? elt.textContent + '\n' : elt.textContent
    }
  } else if (node.textContent !== null) {
    return node.textContent
  }

  return ''
}

type SelectionSide = LeftRight | null

// for any element, see if it has an ancestor (including itself) that identifies
// it as left or right, if not return none
const getDiffSideForElement = (elt: Element): SelectionSide => {
  if (elt.closest(LEFT_SELECTOR)) {
    return 'left'
  }
  if (elt.closest(RIGHT_SELECTOR)) {
    return 'right'
  }
  return null
}

export const useDiffSelection = () => {
  // the side that's currently being selected. We apply the user-select none
  // to the other side when this is either 'left' or 'right'
  const [selectionSide, setSelectionSide] = React.useState<SelectionSide>(null)

  // Replaces the clipboard contents with something we construct to remove
  // the user-select:none elements that shouldn't have been copied
  const handleCopyEvent = async (e: ClipboardEvent) => {
    if (selectionSide && e.clipboardData) {
      const copied = window.getSelection()?.getRangeAt(0)?.cloneContents()
      if (copied) {
        const attributeToExclude =
          selectionSide === 'left' ? RIGHT_ATTRIBUTE : LEFT_ATTRIBUTE

        copied.childNodes.forEach((child) => {
          if (child.nodeType === Node.ELEMENT_NODE) {
            const elt = child as Element
            if (elt.hasAttribute(attributeToExclude)) {
              copied.removeChild(elt)
            } else {
              stripChildren(elt, attributeToExclude)
            }
          }
        })

        // Now copied is a node list stripped of nodes that belonged to the
        // incorrect side. We want to turn this into text content to put in the clipboard
        const textParts: string[] = []
        copied.childNodes.forEach((cn) => textParts.push(getClipboardText(cn)))
        e.clipboardData.setData('text/plain', textParts.join(''))
        // this prevents the regular copy behavior
        e.preventDefault()
      }
    }
  }

  // handler that sets the appropriate diff side on mousedown
  const handleMouseDown = (e: MouseEvent) => {
    if (e.target instanceof Element) {
      const newSide = getDiffSideForElement(e.target)
      if (newSide !== selectionSide) {
        window.getSelection()?.empty()
        setSelectionSide(getDiffSideForElement(e.target))
      }
    }
  }

  React.useEffect(() => {
    if (isWebkit) {
      document.addEventListener('copy', handleCopyEvent)
      return () => document.removeEventListener('copy', handleCopyEvent)
    }
  })

  React.useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => document.removeEventListener('mousedown', handleMouseDown)
  })

  return {
    selectionSide,
  }
}
