import NiceModal from '@ebay/nice-modal-react'
import { MantineSize } from '@mantine/core'

import { ModalShell } from './ModalShell'
import { useModalControls } from './useModalControls'

type AlertProps = {
  opened: boolean
  onClose: () => void
  title: string
  children: React.ReactNode
  buttonLabel?: string
  size?: MantineSize
}

const AlertModalBase = ({
  opened,
  onClose,
  title,
  children,
  buttonLabel = 'OK',

  size = 'xs',
}: AlertProps) => {
  return (
    <ModalShell
      size={size}
      title={title}
      opened={opened}
      onClose={onClose}
      onConfirm={onClose}
      confirmLabel={buttonLabel}
    >
      {children}
    </ModalShell>
  )
}

type NiceAlertProps = Omit<AlertProps, 'opened' | 'onClose'>
const NiceAlertModal = NiceModal.create((props: NiceAlertProps) => {
  const { opened, onClose, resolve } = useModalControls()
  return (
    <AlertModalBase
      {...props}
      opened={opened}
      onClose={() => {
        resolve()
        onClose()
      }}
    />
  )
})

export const showAlert = (props: NiceAlertProps) => {
  return NiceModal.show(NiceAlertModal, props)
}
