import { useState } from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Checkbox, Group, Text } from '@mantine/core'

import avatars from '@assets/images/avatars.png'
import cursors from '@assets/images/cursors.png'
import blockmenu from '@assets/images/el-menu.png'
import ruler from '@assets/images/ruler.png'
import { ModalShell, useModalControls } from '@components/Modals'
import { useMst } from '@state'

import styles from './ViewPreferencesModal.module.scss'

export const ViewPreferences = () => {
  const controls = useModalControls()
  const mst = useMst()
  const {
    hideCollabAvatars,
    showCollabCursors,
    hideBlockMenu,
    showMarginRuler,
  } = mst.user.prefs

  const [hideAvatars, setHideAvatars] = useState(hideCollabAvatars ?? false)
  const [showCursors, setShowCursors] = useState(showCollabCursors ?? false)
  const [hideMenu, setHideMenu] = useState(hideBlockMenu ?? false)
  const [showRuler, setShowRuler] = useState(showMarginRuler)

  const togglePrefs = () => {
    mst.user.updatePreferences({
      hideCollabAvatars: hideAvatars,
      showCollabCursors: showCursors,
      hideBlockMenu: hideMenu,
      showMarginRuler: showRuler,
    })
    mst.currentScript?.pmEditor.rerender()
    controls.onClose()
  }

  return (
    <ModalShell
      title="View preferences"
      onConfirm={togglePrefs}
      onClose={controls.onClose}
      confirmLabel="Save"
      cancelLabel="Cancel"
      size="md"
      opened={controls.opened}
    >
      <Group justify="space-between" wrap="nowrap">
        <Checkbox
          classNames={{
            body: styles.collabPreferenceModal_body,
            description: styles.collabPreferenceModal_description,
          }}
          label={<Text fw="bold">Show collaborators in margin</Text>}
          description="You'll see and be able to jump to other people's avatars on the left of the script"
          checked={!hideAvatars}
          onChange={() => setHideAvatars(!hideAvatars)}
        />
        <img src={avatars} alt="collaborator avatars" />
      </Group>
      <Group justify="space-between" wrap="nowrap">
        <Checkbox
          classNames={{
            body: styles.collabPreferenceModal_body,
            description: styles.collabPreferenceModal_description,
          }}
          label={<Text fw="bold">Show collaborator cursors</Text>}
          description="You'll see other people's cursors and text selections"
          checked={showCursors}
          onChange={() => setShowCursors(!showCursors)}
        />
        <img src={cursors} alt="collaborator cursors" />
      </Group>
      <Group justify="space-between" wrap="nowrap">
        <Checkbox
          classNames={{
            body: styles.collabPreferenceModal_body,
            description: styles.collabPreferenceModal_description,
          }}
          label={<Text fw="bold">Show inline block type menu</Text>}
          description="You'll see the current block type represented by an icon in the left margin"
          checked={!hideMenu}
          onChange={() => setHideMenu(!hideMenu)}
          // ink scripts suppress the inline element menu altogether
          disabled={mst.currentScript?.isInk}
        />
        <img src={blockmenu} alt="block menu" />
      </Group>
      {mst.view.hasBetaFormatting && (
        <Group justify="space-between" wrap="nowrap">
          <Checkbox
            classNames={{
              body: styles.collabPreferenceModal_body,
              description: styles.collabPreferenceModal_description,
            }}
            label={<Text fw="bold">Show ruler</Text>}
            description="You'll see a ruler that you can use to adjust individual block margins"
            checked={showRuler}
            onChange={() => setShowRuler(!showRuler)}
            // the ruler is omitted in pageless mode
            disabled={mst.user.prefs.pageless}
          />
          <img src={ruler} alt="ruler" />
        </Group>
      )}
    </ModalShell>
  )
}

export const ViewPreferenceModal = NiceModal.create(ViewPreferences)

export const showViewPreferencesModal = () =>
  NiceModal.show(ViewPreferenceModal)
