import React from 'react'

import { Group } from '@mantine/core'

import styles from './ToolbarButtonGroup.module.scss'

export const ToolbarButtonGroup = ({
  children,
  spacing = 0,
}: {
  children: React.ReactNode
  spacing?: number
}) => {
  return (
    <Group gap={spacing} className={styles.toolbarButton_group}>
      {children}
    </Group>
  )
}
