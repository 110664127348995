import { Popover, Portal } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { useMst } from '@state'

import {
  HyperlinkForm,
  HyperlinkFormValues,
  HyperlinkInfo,
} from './HyperlinkEditor'
import { useInlineHyperlink } from './useInlineHyperlink'

import styles from './HyperlinkPopover.module.scss'

export const HyperlinkPopover = observer(function HyperlinkPopover() {
  const mst = useMst()

  const {
    userSelecting,
    currentHyperlink,
    headPosition,
    isEditing,
    addOrEditLink,
    removeCurrentLink,
    setEditing,
  } = useInlineHyperlink()

  const rawHref = currentHyperlink?.attrs.href
  const href = typeof rawHref === 'string' ? rawHref : undefined
  const hasCurrentHref = !!href
  const showHyperlinkPopover = (!userSelecting && hasCurrentHref) || isEditing

  const handleSubmit = ({ href }: HyperlinkFormValues) => {
    if (href !== '' && href !== currentHyperlink?.attrs.href) {
      addOrEditLink(href, currentHyperlink)
    }
    setEditing(false)
  }

  return (
    <Portal target="#pm-host-wrapper">
      <Popover
        opened={showHyperlinkPopover}
        position="top"
        classNames={{
          dropdown: styles.hyperlinkPopover_dropdown,
        }}
      >
        <Popover.Target>
          <div
            className={styles.hyperlinkPopover_target}
            style={{
              top: headPosition?.top,
              left: headPosition?.left,
            }}
          ></div>
        </Popover.Target>
        <Popover.Dropdown>
          {isEditing && (
            <HyperlinkForm
              href={href}
              onSubmit={handleSubmit}
              onCancel={() => {
                setEditing(false)
                mst.currentScript?.observableEditor?.focusEditor()
              }}
            />
          )}
          {/* check href present so we don't have a flash on click-outside */}
          {!isEditing && href && (
            <HyperlinkInfo
              href={href}
              onEdit={() => setEditing(true)}
              onRemove={removeCurrentLink}
            />
          )}
        </Popover.Dropdown>
      </Popover>
    </Portal>
  )
})
