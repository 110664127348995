import { BetaFlag, BetaFlagPayload } from '../types'

import { ApiConfig } from './types'

type BetaFlagId = { flagId: string }

export const getBetaFlags: ApiConfig<void, BetaFlagPayload> = () => ({
  method: 'GET',
  url: `/staff/features`,
})

export const destroyBetaFlag: ApiConfig<BetaFlagId, { success: true }> = (
  params,
) => ({
  method: 'DELETE',
  url: `/staff/features/${params.flagId}`,
})

export const bulkRemoveFlag: ApiConfig<BetaFlagId, BetaFlag> = (params) => ({
  method: 'POST',
  url: `/staff/features/${params.flagId}/disableAllOrgs`,
})

export const bulkAddFlag: ApiConfig<BetaFlagId, BetaFlag> = ({ flagId }) => ({
  method: 'POST',
  url: `/staff/features/${flagId}/enableAllOrgs`,
})

export const createBetaFlag: ApiConfig<
  { name: string; description: string },
  BetaFlag
> = (data) => ({
  method: 'POST',
  url: '/staff/features',
  data,
})
