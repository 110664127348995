/**
 * These hooks are wired into the global setInterval that runs 1x per second
 * (when the app is not suspended).
 *
 * These hooks work fine from non-observer components.
 */
import React from 'react'

import { useMst } from '@state'

export const useThrottledCallback = ({
  intervalSeconds,
  cb,
}: {
  intervalSeconds?: number
  cb: () => void
}) => {
  const { appStatus } = useMst()

  // the callback passed in might change but we pass an immutable copy
  // to mst so we don't have to recreate the cached listener every time
  const stableCbRef = React.useRef(() => {
    cb()
  })

  React.useEffect(() => {
    const id = appStatus.registerTimeListener({
      cb: stableCbRef.current,
      intervalSeconds,
    })

    return () => appStatus.removeTimeListener(id)
  }, [appStatus, intervalSeconds])

  return {
    now: appStatus.now,
  }
}

export const useThrottledNow = (intervalSeconds?: number) => {
  const { appStatus } = useMst()
  const [now, setNow] = React.useState(appStatus.now)

  React.useEffect(() => {
    const id = appStatus.registerTimeListener({
      cb: () => {
        setNow(appStatus.now)
      },
      intervalSeconds,
    })

    return () => appStatus.removeTimeListener(id)
  }, [appStatus, intervalSeconds])

  return now
}
