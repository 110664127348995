import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import {
  Divider,
  FileInput,
  Group,
  Radio,
  Space,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'

import { FaIcon } from '@components/FaIcon'
import { ModalShell } from '@components/Modals/ModalShell'
import { useModalControls } from '@components/Modals/useModalControls'
import { useResourceCreation } from '@hooks'
import { IFolder, IOrg, useMst } from '@state'

type CreateDocFormValues = {
  name: string
  formatId: string
}

type ModalProps = {
  folder: IFolder
  workspace: IOrg
  from: 'explorer' | 'document pane'
}

const ModalBase = ({ from, folder, workspace }: ModalProps) => {
  const mst = useMst()
  const creation = useResourceCreation(from)
  const controls = useModalControls()
  const [file, setFile] = React.useState<File | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const { hasRundownsEnabled, studioFormat, screenplayFormat } = workspace

  const form = useForm<CreateDocFormValues>({
    initialValues: {
      name: 'Untitled',
      formatId: studioFormat.id,
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  const handleConfirm = async () => {
    setLoading(true)
    try {
      mst.view.setSelectedFolderId(folder.id)
      const { name, formatId } = form.values
      if (file) {
        await creation.importScript({ file, folder })
      } else if (formatId === 'rundown') {
        await creation.createRundown(folder, name)
      } else {
        await creation.createScript({ folder, name, formatId })
      }
      controls.onClose()
    } catch {
      setErrorMessage('Failed to create document')
    } finally {
      setLoading(false)
    }
  }

  const handleFileChange = (file: File | null) => {
    setErrorMessage('')
    setFile(file)
    if (!file) {
      form.values.name = 'Untitled'
      return
    }
    const extension = file.name
      .substring(file.name.lastIndexOf('.'))
      .toLowerCase()

    if (extension !== '.fdx') {
      setErrorMessage('Only Final Draft files can be imported')
      setFile(null)
      return
    }

    form.values.name = file.name.replace('.fdx', '')
    form.values.formatId = screenplayFormat.id
  }

  const includeRundown = hasRundownsEnabled && !folder.isPrivate

  return (
    <ModalShell
      title="Create document"
      confirmLabel={file ? 'Import script' : 'Create document'}
      cancelLabel="Cancel"
      size="sm"
      onConfirm={handleConfirm}
      opened={controls.opened}
      onClose={controls.onClose}
      loading={loading}
      errorMessage={errorMessage}
    >
      <Stack gap={25}>
        <Stack gap={5}>
          <Text fw="bold">Location</Text>
          <Group gap={8}>
            <FaIcon c="dark.5" icon="fa-folder" size="16" />
            <Text>{folder.displayName}</Text>
          </Group>
        </Stack>
        <Stack gap={5}>
          <Radio.Group
            {...form.getInputProps('formatId')}
            label={<Text fw="bold">Document type</Text>}
            px={0}
          >
            <Space h={8} />
            <Stack gap="sm">
              {[studioFormat, screenplayFormat].map((f) => (
                <Radio
                  disabled={!!file && f.id === studioFormat.id}
                  key={f.id}
                  value={f.id}
                  label={
                    <Group gap={8}>
                      <FaIcon icon={f.icon as `fa-${string}`} size="16" />
                      <Text>{f.name}</Text>
                    </Group>
                  }
                />
              ))}
              {includeRundown && (
                <Radio
                  value="rundown"
                  label={
                    <Group gap={8}>
                      <FaIcon faIconSet="fas" icon="fa-table" size="16" />
                      <Text>Rundown</Text>
                    </Group>
                  }
                  disabled={!!file || !mst.user.canEditRundowns}
                />
              )}
            </Stack>
          </Radio.Group>
        </Stack>
        <TextInput
          data-autofocus
          {...form.getInputProps('name')}
          label={
            <>
              <Text span fw="bold">
                Title
              </Text>
              <Text span> (optional)</Text>
            </>
          }
          onFocus={(e) => e.target.select()}
          disabled={!!file}
        />
        <Divider labelPosition="center" label="OR" />
        <FileInput
          onChange={handleFileChange}
          label={
            <>
              <Text span fw="bold">
                Choose an .FDX file to import
              </Text>
              <Text span> (optional)</Text>
            </>
          }
          placeholder="Brick & Steel.fdx"
          leftSection={<FaIcon c="violet" icon="fa-arrow-up-from-bracket" />}
          leftSectionPointerEvents="none"
          accept=".fdx"
          clearable
          mb={20}
        />
      </Stack>
    </ModalShell>
  )
}

const CreateDocModal = NiceModal.create<ModalProps>(ModalBase)

export const showCreateDocModal = (props: ModalProps) => {
  NiceModal.show(CreateDocModal, props)
}
