import { Group, Menu, UnstyledButton } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'
import { useMst } from '@state'
import { SOME_DIFF_CONTEXT_LINES } from '@util/printing'
import { SnapshotSummary } from '@util/ScriptoApiClient/types'

import styles from './SnapshotItem.module.scss'

const getHref = (
  target: 'compare' | 'diff',
  scriptId: string,
  snapshotId: string,
): NavAnchorHref => {
  const qs = target === 'diff' ? `?context=${SOME_DIFF_CONTEXT_LINES}` : ''
  return `/scripts/${scriptId}/${target}/${snapshotId}${qs}`
}

const LinkItem = ({
  label,
  href,
  icon,
}: {
  label: string
  href: `/${string}`
  icon: `fa-${string}`
}) => {
  const { view } = useMst()
  const { setLastScriptPath } = view.explorerState

  return (
    <Menu.Item>
      <div onClick={() => setLastScriptPath(location.pathname)}>
        <NavAnchor className="is-plain-link" href={href}>
          <Group gap={8}>
            <FaIcon icon={icon} fixedWidth />
            {label}
          </Group>
        </NavAnchor>
      </div>
    </Menu.Item>
  )
}

export const SnapshotMenu = ({
  snapshot,
  renameHandler,
}: {
  snapshot: SnapshotSummary
  renameHandler: (id: string) => void
}) => (
  <Menu withinPortal position="right">
    <Menu.Target>
      <UnstyledButton className={styles.snapshotmenu_button}>
        <FaIcon icon="fa-ellipsis-v" />
      </UnstyledButton>
    </Menu.Target>
    <Menu.Dropdown>
      <FancyMenuItem
        title="Rename snapshot"
        iconClass="fa fa-edit"
        onClick={() => renameHandler(snapshot.id)}
      ></FancyMenuItem>
      <Menu.Divider />
      <LinkItem
        label="Revision asterisk comparison"
        icon="fa-asterisk"
        href={getHref('compare', snapshot.scriptId, snapshot.id)}
      />
      <LinkItem
        label="Side-by-side comparison"
        icon="fa-columns"
        href={getHref('diff', snapshot.scriptId, snapshot.id)}
      />
    </Menu.Dropdown>
  </Menu>
)
