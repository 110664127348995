import React from 'react'

import {
  differenceInSeconds,
  format,
  formatDistance,
  isBefore,
  isThisYear,
  isToday,
} from 'date-fns'

import { useThrottledNow } from './timeListeners'

// Aug 3
export const SHORT_DAY__YEARLESS_MASK = 'LLL d'
// Aug 3, 2020
export const SHORT_DAY_MASK = 'LLL d, yyyy'
// 1:32 pm
export const TIME_MASK = 'h:mm aaa'
// 1:32 pm on Wed, Aug 3
export const TIME_DAY_MASK = `${TIME_MASK} 'on' EEE, MMM d`
// 1:32 pm on Wed, Aug 3, 2020
export const TIME_DAY_YEAR_MASK = `${TIME_DAY_MASK}, yyyy`

// if two items are within this number of seconds of each other
// we treat them as being edited at the same time so that sort
// orders don't go crazy when sorting by "recently edited"
export const SAME_TIME_DIFF_IN_SECONDS = 10

const PRE_PREFIX = 'Last edited '
const PREFIX = PRE_PREFIX + 'at '

const getDelaySeconds = (now: Date, lastUpdated: Date): number => {
  const seconds = differenceInSeconds(now, lastUpdated)
  // update every 10 seconds for the first 90 seconds
  if (seconds < 90) {
    return 10
  }
  // update every 30 seconds for the first 45 minutes
  if (seconds < 45 * 60) {
    return 30
  }
  // update once every half hour after that (who cares at this point?)
  return 60 * 30
}

export const getDateFormatting = ({
  now,
  lastUpdated,
  user,
}: {
  now: Date
  lastUpdated: Date
  user?: string | null
}): string => {
  const byline = user ? ` by ${user} ` : ''
  const seconds = differenceInSeconds(now, lastUpdated)

  // to keep flutter to a minimum we tack the username on last
  if (seconds < SAME_TIME_DIFF_IN_SECONDS) {
    return PRE_PREFIX + 'just now' + byline
  }
  // up to 30 seconds, calculate time ago
  if (seconds < 30) {
    const distance = formatDistance(lastUpdated, now, { addSuffix: true })
    return PRE_PREFIX + distance + byline
  }
  // it's slightly precious, but we dont like what date-fns returns between 30-60 sec
  if (seconds < 60) return PRE_PREFIX + 'less than a minute ago' + byline

  // omit todays date, omit current year
  return isToday(lastUpdated)
    ? PREFIX + format(lastUpdated, TIME_MASK) + byline
    : isThisYear(lastUpdated)
      ? PREFIX + format(lastUpdated, TIME_DAY_MASK) + byline
      : PREFIX + format(lastUpdated, TIME_DAY_YEAR_MASK) + byline
}

const formatter = ({
  now,
  then,
  user,
}: {
  now: Date
  then: Date
  user?: string | null
}) => {
  // nowToUse is like Math.min for dates:
  //
  // sometimes "then" is ahead of "now"-- example: we receive
  // a socket event with lastUpdated time but we haven't updated "now"
  // and we'd get "2 minutes from now" as a last updated if we didn't
  // deal with that
  const nowToUse = isBefore(now, then) ? then : now
  return getDateFormatting({
    now: nowToUse,
    lastUpdated: then,
    user,
  })
}

export const useFormattedTimestamp = ({
  date,
  user,
}: {
  date: Date
  user?: string | null
}) => {
  // the delay gets longer the further back in time the doc
  // was last touched
  const [delay, setDelay] = React.useState(1)
  const now = useThrottledNow(delay)
  const [formattedValue, setFormattedValue] = React.useState(
    formatter({ now, then: date, user }),
  )

  React.useEffect(() => {
    setDelay(getDelaySeconds(now, date))
  }, [date, now])

  React.useEffect(() => {
    setFormattedValue(formatter({ now, then: date, user }))
  }, [date, now, user])

  return formattedValue
}
