import React from 'react'

import { ButtonProps, ThemeIcon } from '@mantine/core'
import cn from 'classnames'

import { StatusBarButton } from './StatusBarButton'

import styles from './StatusBarActionIcon.module.scss'

type StatusBarActionIconProps = {
  icon: React.ReactNode
  tooltip?: string
  active?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  btnVariant?: ButtonProps['variant']
  dangerous?: boolean
}

export const StatusBarActionIcon = React.forwardRef<
  HTMLButtonElement,
  StatusBarActionIconProps
>(
  (
    {
      icon,
      tooltip,
      onClick,
      active = false,
      btnVariant = 'default',
      dangerous = false,
    },
    ref,
  ) => (
    <StatusBarButton
      ref={ref}
      active={active}
      tooltip={tooltip}
      onClick={onClick}
      variant={btnVariant}
    >
      <ThemeIcon
        className={cn(styles.statusBar_icon, {
          [styles.statusBar_icon___active]: active,
          [styles.statusBar_icon___danger]: dangerous,
        })}
      >
        {icon}
      </ThemeIcon>
    </StatusBarButton>
  ),
)

StatusBarActionIcon.displayName = 'StatusBarActionIcon'
