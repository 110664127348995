import { Fragment, useState } from 'react'

import { format, isToday } from 'date-fns'

import { SnapshotSummary } from '@util/ScriptoApiClient/types'

import { SnapshotHeader } from './SnapshotHeader'
import { SharedSnapshotHistoryProps } from './SnapshotHistory'
import { SnapshotItem } from './SnapshotItem'

type SnapshotFeedProps = SharedSnapshotHistoryProps & {
  snapshots: SnapshotSummary[]
}

export function SnapshotFeed({ snapshots, onUpdate }: SnapshotFeedProps) {
  let prevDate: string
  const [editableSnapshot, setEditableSnapshot] = useState('')

  return (
    <>
      {snapshots.map((snapshot) => {
        const snapCreated = new Date(snapshot.createdAt)
        const month = snapCreated.toLocaleString('default', {
          month: 'long',
        })

        // today is a special day
        const snapDate = isToday(snapCreated)
          ? 'Today'
          : `${month} ${format(snapCreated, 'd, yyyy')}`

        const displayHeader = prevDate !== snapDate
        prevDate = snapDate

        return (
          <Fragment key={snapshot.id}>
            {displayHeader && <SnapshotHeader>{snapDate}</SnapshotHeader>}
            <SnapshotItem
              onUpdate={onUpdate}
              snapshot={snapshot}
              isRenaming={editableSnapshot === snapshot.id}
              renameHandler={setEditableSnapshot}
            />
          </Fragment>
        )
      })}
    </>
  )
}
