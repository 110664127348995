import { EditorState, TextSelection, Transaction } from 'prosemirror-state'

import { NodeTypeMap } from '@showrunner/codex'

const SNOWFLAKES: readonly string[] = [
  NodeTypeMap.BRACKET,
  NodeTypeMap.PARENTHETICAL,
]

const CLOSING_CHAR = /^(\)|])(\s+)?$/

export const maybeSidestepLastChar = (state: EditorState): Transaction => {
  const { tr } = state
  if (!state.selection.empty) return tr

  const { parent } = state.selection.$anchor
  const { name: blockType } = parent.type
  if (!SNOWFLAKES.includes(blockType)) return tr

  const { parentOffset, pos } = state.selection.$anchor
  const remainder = parent.content.size - parentOffset

  if (!CLOSING_CHAR.test(tr.doc.textBetween(pos, pos + remainder))) return tr
  return tr.setSelection(TextSelection.create(state.tr.doc, pos + 1))
}
