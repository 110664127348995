import { Group, Text } from '@mantine/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import {
  ILoadedScript,
  IRundownListing,
  IScriptListing,
  isScriptListing,
  useMst,
} from '@state'

import { Destroy, Restore } from './TrashedActions'

import styles from './ReadOnlyBanner.module.scss'

const CTAS = {
  NO_PERMISSION_SCRIPT:
    ". You don't have permission to edit scripts when the access level is limited.",
  NO_PERMISSION_RUNDOWN:
    ". You don't have permission to edit rundowns in this workspace.",
  DISCONNECTED:
    ". Your browser cannot connect to our server. We'll try to reconnect.",
  UNSAVED_STEPS:
    ' because we are having trouble saving your recent edits. Please copy your work before reloading the page.',
  UNFETCHED_STEPS:
    ' because we are unable to get the latest changes from the server. Please try reloading the page.',
  ARCHIVED: " because it's from the Classic Scripto archive.",
  STRUCTURAL: '. Copy/paste into a new document to continue writing.',
}

export const TrashedDocumentBanner = ({
  listing,
  canDestroy,
  canRestore,
}: {
  listing: IRundownListing | IScriptListing
  canDestroy?: boolean
  canRestore: boolean
}) => {
  const isScript = isScriptListing(listing)

  const destroy = async () => {
    if (isScript) await listing?.destroy()
    // send them to the folder dashboard without reloading
    window.history.replaceState(null, '', `/folders/${listing.folderId}`)
  }

  return (
    <Group className={cn(styles.banner, styles.trashed)} justify="center">
      <FaIcon icon="fa-trash" />
      <Text>This document is in the trash</Text>
      {canRestore && <Restore listing={listing} />}
      {isScript && canDestroy && (
        <Destroy name={listing.name} onConfirm={destroy} />
      )}
    </Group>
  )
}

const BannerBase = ({
  level,
  docType,
  statusText,
  callToAction,
}: {
  level: 'info' | 'warn'
  docType: 'script' | 'rundown'
  statusText: string
  callToAction: string
}) => {
  const emoji = level === 'info' ? '👀' : '🚨'

  return (
    <Group
      className={cn(styles.banner, { [styles.warn]: level === 'warn' })}
      justify="center"
    >
      <Text>
        {emoji} This {docType} is
        <Text span fw="bold">
          &nbsp;{statusText}
        </Text>
        <Text span>{callToAction}</Text>
      </Text>
    </Group>
  )
}

export const ReadonlyScriptStructuralBanner = () => (
  <BannerBase
    docType="script"
    level="info"
    statusText="read only"
    callToAction={CTAS.STRUCTURAL}
  />
)

export const ReadonlyScriptPermissionsBanner = () => (
  <BannerBase
    docType="script"
    level="info"
    statusText="read only"
    callToAction={CTAS.NO_PERMISSION_SCRIPT}
  />
)

export const ReadonlyRundownBanner = () => (
  <BannerBase
    docType="rundown"
    level="info"
    statusText="read only"
    callToAction={CTAS.NO_PERMISSION_RUNDOWN}
  />
)

export const ArchivedScriptBanner = () => (
  <BannerBase
    docType="script"
    level="info"
    callToAction={CTAS.ARCHIVED}
    statusText="read only"
  />
)

export const DisconnectedBanner = () => (
  <BannerBase
    docType="script"
    level="warn"
    callToAction={CTAS.DISCONNECTED}
    statusText="offline"
  />
)

export const PushStepsBanner = () => (
  <BannerBase
    docType="script"
    level="warn"
    callToAction={CTAS.UNSAVED_STEPS}
    statusText="read only"
  />
)

export const PullStepsBanner = () => (
  <BannerBase
    docType="script"
    level="warn"
    callToAction={CTAS.UNFETCHED_STEPS}
    statusText="out of sync"
  />
)

export const ReadonlyScriptBanner = observer(function ReadonlyScriptBanner({
  script,
}: {
  script: ILoadedScript
}) {
  const { scriptMap, appStatus } = useMst()
  const { inTrash, isEditable, syncStatus } = script
  const { isTooStaleForSafety, sendStepsRequired } = syncStatus

  // show disconnected banner if sockets are disconnected and we've
  // been in the foreground long enough to resume
  if (appStatus.isForegroundDisconnected) {
    return <DisconnectedBanner />
  }

  if (isTooStaleForSafety) {
    return sendStepsRequired ? <PushStepsBanner /> : <PullStepsBanner />
  }

  const listing = scriptMap.get(script.id)
  if (inTrash && listing) {
    return (
      <TrashedDocumentBanner
        listing={listing}
        canDestroy={script.isDestroyable}
        canRestore={script.isRestorable}
      />
    )
  }

  if (!isEditable) {
    return script.isStructurallyPaginated ? (
      <ReadonlyScriptStructuralBanner />
    ) : (
      <ReadonlyScriptPermissionsBanner />
    )
  }

  return null
})
