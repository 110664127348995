import { AUTH_HEADER } from '@util/constants'
// lazy singleton for the auth token. It gets read and written
// by the various api clients and socket manager
let token: string | null = null

const get = () => token
const set = (value: string) => {
  token = value
}
const clear = () => {
  token = null
}

export const authToken = {
  get,
  set,
  clear,
}

export const getTokenHeader = (): StringMap | undefined => {
  const token = authToken.get()
  if (token) {
    return {
      [AUTH_HEADER]: token,
    }
  }
}
