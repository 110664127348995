import { Portal } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { ILoadedScript } from '@state'

import { CreateThreadButton } from './CreateThreadButton'

export const GutterComments = observer(function GutterComments({
  script,
}: {
  script: ILoadedScript
}) {
  const { canAddComment, hasUnsavedComment } = script.pmEditor

  return (
    <Portal target="#editor-gutter-right">
      {canAddComment && !hasUnsavedComment && (
        <CreateThreadButton script={script} />
      )}
    </Portal>
  )
})
