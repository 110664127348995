import React from 'react'

import { Group, Space, Text } from '@mantine/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useRoute } from 'wouter'

import { ExpandIcon } from '@components/ExpandIcon'
import { FaIcon } from '@components/FaIcon'
import { useNavigation } from '@hooks'
import { useMst } from '@state'
import { CollectionId, IFolder } from '@state/types'
import { ROUTE_PATTERNS } from '@util/pathConfigs'

import { CreateMenu } from '../CreateMenu'
import { NameListingInput } from '../NameListingInput'

import {
  collectionItemConfig,
  FolderItemType,
  isCollectionItem,
  ListItem,
} from './folderListHelpers'
import { FolderListingMenu } from './FolderListingMenu'

import styles from './FolderListing.module.scss'

// when renaming or creating children, we need to put in spacers that
// are the same width as the expand icon
const EXPAND_ICON_WIDTH = 16

export type FolderListingProps = {
  folderId: string
  itemType: FolderItemType
}

// dumb component to render folder icon and name in either
// a move modal or in the folder tree
const FolderNameAndIcon = ({
  disabled,
  expanded,
  icon = 'fa-folder',
  id,
  menu,
  name,
  noChevron,
  onSelect,
  onToggleExpanded,
  selected,
}: {
  disabled?: boolean
  expanded: boolean
  icon?: `fa-${string}`
  id?: string
  menu?: JSX.Element
  name: string
  noChevron?: boolean
  onSelect?: () => void
  onToggleExpanded: () => void
  selected?: boolean
}) => {
  const mst = useMst()

  return (
    <>
      {disabled || noChevron ? (
        <Space w={EXPAND_ICON_WIDTH} />
      ) : (
        <div onClick={onToggleExpanded}>
          <ExpandIcon expanded={expanded} width={EXPAND_ICON_WIDTH} />
        </div>
      )}
      <div
        className={cn(styles.folderNameAndIcon, {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
          [styles.selectable]: !!onSelect,
        })}
        onClick={disabled ? undefined : onSelect}
      >
        <FaIcon icon={icon} fixedWidth size="13" />
        <Space w={5} />
        <Text className={styles.folderName}>{name}</Text>
        <Group
          justify="flex-end"
          gap={0}
          className={styles.menuWrapper}
          onClick={(e) => e.stopPropagation()}
        >
          {id && mst.currentOrg && (
            <CreateMenu folderId={id} workspace={mst.currentOrg} />
          )}
          {menu}
        </Group>
      </div>
    </>
  )
}

const FolderNameInput = observer(function FolderNameInput({
  folder,
  mode,
}: {
  folder: IFolder
  mode: 'rename' | 'create'
}) {
  const { view } = useMst()
  const handleSubmit = async ({ name }: { name: string }) => {
    // note that the NameListingInput handle its own errors
    if (mode === 'create') {
      await folder.createSubfolder({ name })
    } else {
      await folder.rename(name)
      view.stopRenamingFolder()
    }
  }

  const handleCancel = () => {
    if (mode === 'create') {
      view.setCreatingChild(false)
    } else {
      view.stopRenamingFolder()
    }
  }

  const initialValue = mode === 'create' ? '' : folder.displayName

  return (
    <>
      <Space w={EXPAND_ICON_WIDTH} />
      <NameListingInput
        mode={mode}
        itemType="folder"
        initialValue={initialValue}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        icon="fas fa-folder"
      />
    </>
  )
})

const FolderListingWrap = ({
  indentLevel,
  children,
}: {
  indentLevel: number
  children: React.ReactNode
}) => (
  <div
    style={{
      paddingLeft: indentLevel * 8 + 5,
      paddingRight: 8,
      height: '100%',
    }}
  >
    {children}
  </div>
)

export const ReadonlyFolderListing = observer(function ReadonlyFolderListing({
  item,
}: {
  item: ListItem
}) {
  const { itemType, folderId } = item
  const { folderMap, view } = useMst()
  const folderListState = view.readonlyFolderListState
  const folder = folderMap.get(folderId)
  if (!(folder && folderListState)) {
    return null
  }

  const indentLevel = folder.treeDepth

  const expanded = folderListState.expandedFolders.includes(folder.id)

  const handleSelect = () => {
    if (itemType === 'folder') {
      folderListState.setSelectedFolderId(folder.id)
    }
  }

  const handleChevron = () => {
    folderListState.toggleFolderExpanded(folder)
  }

  return (
    <FolderListingWrap indentLevel={indentLevel}>
      <div className={styles.folderListing_contents}>
        {itemType === 'folder' && (
          <FolderNameAndIcon
            onSelect={handleSelect}
            onToggleExpanded={handleChevron}
            expanded={expanded}
            name={folder.displayName}
            disabled={folder.id === folderListState.disabledFolderId}
            selected={folderListState.selectedFolderId === folder.id}
            noChevron={folder.childFolders.length === 0}
          />
        )}
      </div>
    </FolderListingWrap>
  )
})

export const CollectionListing = observer(function CollectionListing({
  collectionId,
}: {
  collectionId: CollectionId
}) {
  const { icon, label, indentLevel, selectable } =
    collectionItemConfig[collectionId]
  const expandable = indentLevel === 0
  const [isFolderRoute] = useRoute(ROUTE_PATTERNS.folder)
  const { navigate } = useNavigation()

  const handleSelect = () => {
    if (selectable) {
      view.setSelectedFolderId(collectionId)
      if (isFolderRoute) {
        navigate(ROUTE_PATTERNS.root)
      }
    }
  }

  const handleChevron = () => {
    if (expandable) {
      view.explorerState.toggleCollections()
    }
  }

  const { view } = useMst()
  return (
    <FolderListingWrap indentLevel={indentLevel}>
      <div
        className={styles.folderListing_contents}
        data-folder-listing={collectionId}
      >
        <FolderNameAndIcon
          onSelect={selectable ? handleSelect : undefined}
          onToggleExpanded={handleChevron}
          icon={icon}
          noChevron={!expandable}
          name={label}
          selected={view.selectedFolderId === collectionId}
          expanded={view.explorerState.collectionsExpanded}
        />
      </div>
    </FolderListingWrap>
  )
})

export const LiveFolderListing = observer(function LiveFolderListing({
  item,
}: {
  item: ListItem
}) {
  const { folderMap, view } = useMst()
  const [isDashboardRoute] = useRoute(ROUTE_PATTERNS.root)
  const [isFolderRoute] = useRoute(ROUTE_PATTERNS.folder)
  const { navigate } = useNavigation()

  if (isCollectionItem(item)) {
    return <CollectionListing collectionId={item.folderId} />
  }

  const { folderId, itemType } = item
  const folder = folderMap.get(folderId)
  if (!folder) {
    return null
  }

  const indentLevel =
    itemType === 'new-child' ? folder.treeDepth + 1 : folder.treeDepth

  const toggleExpanded = () => {
    view.toggleFolderExpanded(folder)
  }

  const expanded = view.expandedFolders.includes(folder)
  const handleSelect = () => {
    if (itemType === 'folder') {
      view.setSelectedFolderId(folder.id)
      if (isFolderRoute || isDashboardRoute) {
        navigate(folder.path)
      }
    }
  }

  return (
    <FolderListingWrap indentLevel={indentLevel}>
      <div
        className={styles.folderListing_contents}
        data-folder-listing={itemType === 'folder' ? folderId : false}
      >
        {itemType === 'new-child' && (
          <FolderNameInput mode="create" folder={folder} />
        )}
        {itemType === 'folder' && !folder.isBeingRenamed && (
          <FolderNameAndIcon
            id={folder.id}
            onToggleExpanded={toggleExpanded}
            icon={
              folder.isRootTrash || folder.isPrivateRootTrash
                ? 'fa-trash'
                : 'fa-folder'
            }
            onSelect={handleSelect}
            expanded={expanded}
            name={folder.displayName}
            selected={view.selectedFolderId === folder.id}
            noChevron={folder.childFolders.length === 0}
            menu={
              folder.isRootFolder ? undefined : (
                <FolderListingMenu folder={folder} />
              )
            }
          />
        )}
        {itemType === 'folder' && folder.isBeingRenamed && (
          <FolderNameInput folder={folder} mode="rename" />
        )}
      </div>
    </FolderListingWrap>
  )
})
