import { Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { CursorCoords, IPmDomInfo, IRoot } from '@state/types'

const getOffsetCoords = (
  editorCoords: { top: number; left: number },
  absoluteCoords: Rect,
): CursorCoords => {
  const y = absoluteCoords.top - editorCoords.top
  const x = absoluteCoords.left - editorCoords.left
  const height = absoluteCoords.bottom - absoluteCoords.top

  return { x, y, height }
}

const recordDomPositionsPluginKey = new PluginKey('recordDomPositions')

export const recordDomPositions = ({ mst }: { mst: IRoot }): Plugin => {
  const recordSelection = (view: EditorView, pmDomInfo: IPmDomInfo) => {
    const viewCoords = view.dom.getBoundingClientRect()
    try {
      const { $anchor, $head } = view.state.selection
      const anchor = getOffsetCoords(viewCoords, view.coordsAtPos($anchor.pos))
      const head = getOffsetCoords(viewCoords, view.coordsAtPos($head.pos))
      pmDomInfo.setSelectionCoords({ anchor, head })
    } catch (err) {
      mst.log.errorOnce(
        'Could not compute selection coords',
        { err },
        'record-selection',
      )
    }
  }

  return new Plugin({
    key: recordDomPositionsPluginKey,

    view(view) {
      const pmDomInfo = mst.currentScript?.pmDomInfo
      if (pmDomInfo && !view.isDestroyed) {
        recordSelection(view, pmDomInfo)
        mst.currentScript?.updateEditorViewObservables()
      }

      return {
        update(ev) {
          const pmDomInfo = mst.currentScript?.pmDomInfo
          if (!pmDomInfo || view.isDestroyed) {
            return
          }

          recordSelection(ev, pmDomInfo)
          mst.currentScript?.updateEditorViewObservables()
        },
      }
    },
  })
}
