import React from 'react'

import { CopyButton, Space, Tooltip, UnstyledButton } from '@mantine/core'
import cn from 'classnames'

import { FaIcon, GreenCheckIcon } from '@components/FaIcon'
import { useMst } from '@state'
import { ButtonMode } from '@state/types'

import styles from './ToolbarButton.module.scss'

export interface ToolbarButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  active?: boolean
  buttonClasses?: string
  customIcon?: React.ReactNode
  disabled?: boolean
  dropdown?: boolean
  icon?: `fa-${string}`
  label?: string
  // mini buttons are label-less
  // labeled buttons only get a tooltip when it is distinct
  // mini buttons prioritize label as tooltip (when present)
  mode?: ButtonMode
  onClick?: Callback
  onToggle?: Callback<[boolean]>
  tooltip?: string
  fixedWidth?: number
  disableTooltip?: boolean
  primary?: boolean
  focusEditor?: boolean
}

export const ToolbarButton = React.forwardRef<
  HTMLButtonElement,
  ToolbarButtonProps
>(
  (
    {
      active,
      buttonClasses,
      customIcon,
      disabled,
      dropdown,
      icon,
      label,
      mode = 'normal',
      onClick,
      onToggle,
      tooltip = label,
      fixedWidth,
      disableTooltip,
      primary,
      focusEditor,
    },
    ref,
  ) => {
    const { currentScript } = useMst()
    const isNormalMode = mode === 'normal'
    const showLabel = label && isNormalMode
    const hasIcon = icon || customIcon
    const showTooltip =
      !disableTooltip && tooltip && (!isNormalMode || tooltip !== label)
    const displayedTooltip = isNormalMode ? tooltip : label || tooltip

    const renderIcon = () => {
      if (icon) {
        return (
          <FaIcon icon={icon} className={styles.toolbar_button___mainIcon} />
        )
      }
      if (customIcon) {
        return (
          <div className={styles.toolbar_button___mainIcon}>{customIcon}</div>
        )
      }
      return null
    }

    return (
      <Tooltip
        disabled={!showTooltip}
        label={displayedTooltip}
        position="bottom"
      >
        <div style={{ width: fixedWidth }}>
          <UnstyledButton
            ref={ref}
            disabled={disabled}
            // this keeps the button from getting focus
            // and, if specified, forces focus back onto the editor
            onMouseDown={(e) => {
              e.preventDefault()
              if (focusEditor) {
                currentScript?.pmEditor.focusEditor()
              }
            }}
            onClick={() => {
              onClick?.()
              onToggle?.(!active)
            }}
            className={cn(
              styles.toolbar_button,
              {
                [styles.toolbar_button___active]: active,
                [styles.toolbar_button___disabled]: disabled,
                [styles.toolbar_button___primary]: primary,
              },
              buttonClasses, // fine if undefined
            )}
          >
            {renderIcon()}
            {hasIcon && showLabel && <Space w={3} />}
            {showLabel && (
              <div className={styles.toolbar_button___label}>{label}</div>
            )}
            {dropdown && (
              <FaIcon
                size="12"
                icon="fa-caret-down"
                className={styles.toolbar_button___dropdownIcon}
              />
            )}
          </UnstyledButton>
        </div>
      </Tooltip>
    )
  },
)

ToolbarButton.displayName = 'ToolbarButton'

export const CopyUrlButton = (props: ToolbarButtonProps) => {
  const { label } = props
  return (
    <CopyButton value={location.href} timeout={1000}>
      {({ copied, copy }) => (
        <ToolbarButton
          {...props}
          label={copied ? 'Copied!' : label}
          customIcon={copied ? <GreenCheckIcon /> : <FaIcon icon="fa-link" />}
          onClick={copy}
        />
      )}
    </CopyButton>
  )
}
