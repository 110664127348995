import { Card, Text } from '@mantine/core'

import styles from './PushToPrompterModal.module.scss'

export const NoContent = ({ scriptName }: { scriptName: string }) => (
  <Card className={styles.promptermodal_card}>
    <Text fw="bold" className={styles.promptermodal_text}>
      {scriptName}
    </Text>
    <Text c="dimmed" className={styles.promptermodal_text}>
      This script doesn&apos;t contain anything that can be teleprompted.
    </Text>
  </Card>
)
