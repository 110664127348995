import { useQuery } from '@tanstack/react-query'

import { useMst } from '@state'

/*
  notes about staleTime/gcTime

  staleTime means: "How long is this query considered valid"
  gcTime means: "When no longer mounted, when should it be garbage collected"

  While in snapshot land for a script, we need to balance between reusing API
  responses and using too much memory.

  For the "current version" of the script, we should consider the value to
  be invalid pretty quickly, but it's still in discussion how this should work

  Snapshots on the other hand are immutable, so staleTime is Infinity, but we don't
  want to over-cache them.

*/
const minutesToMillis = (minutes: number) => minutes * 60 * 1000

export const useSnapshotData = ({
  snapshotId,
  scriptId,
}: {
  snapshotId: string
  scriptId: string
}) => {
  const { apiClient, doDebug, view } = useMst()

  return useQuery({
    queryFn: async () => {
      await doDebug()
      return apiClient.getSnapshot({ scriptId, snapshotId })
    },
    queryKey: view.snapshotLand.snapshotQueryKey({ snapshotId, scriptId }),
    // if we have a snapshot in the cache, it is not stale
    staleTime: Infinity,
    // keep for 5 minutes if a snapshot is unmounted
    gcTime: minutesToMillis(5),
  })
}

export const useMaybeSnapshot = ({
  snapshotId,
  scriptId,
}: {
  snapshotId?: string
  scriptId: string
}) => {
  const { apiClient, doDebug, view } = useMst()
  return useQuery({
    queryFn: async () => {
      if (snapshotId) {
        await doDebug()
        return apiClient.getSnapshot({ scriptId, snapshotId })
      }
      return null
    },
    queryKey: view.snapshotLand.snapshotQueryKey({
      snapshotId: snapshotId ?? 'none',
      scriptId,
    }),
    // if we have a snapshot in the cache, it is not stale
    staleTime: Infinity,
    // keep for 5 minutes if a snapshot is unmounted
    gcTime: minutesToMillis(5),
  })
}

export const useSelectedSnapshots = (scriptId: string) => {
  const { snap1, snap2 } = useMst().view.snapshotLand
  const topQuery = useMaybeSnapshot({ scriptId, snapshotId: snap1 })
  const bottomQuery = useMaybeSnapshot({ scriptId, snapshotId: snap2 })

  return {
    topQuery,
    bottomQuery,
  }
}

export const useScriptData = (scriptId: string) => {
  const { apiClient, doDebug } = useMst()
  return useQuery({
    queryFn: async () => {
      await doDebug()
      const script = await apiClient.getScript(scriptId)
      const fetchedAt = new Date()
      return {
        script,
        fetchedAt,
      }
    },
    queryKey: ['snapshotland-script', scriptId],

    // TODO: revisit these
    staleTime: Infinity,
    gcTime: 0,
  })
}
