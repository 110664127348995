import React from 'react'

import {
  ActionIcon,
  Button,
  createSafeContext,
  Group,
  Indicator,
  Popover,
  Space,
  Stack,
  Title,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@hooks'

import styles from './AnnouncementPopover.module.scss'

const [AnnouncementPopoverContextProvider, useAnnouncementContext] =
  createSafeContext<{
    opened: boolean
    dismissAnnouncement(): void
    trapFocus(): void
  }>('No parent <AnnouncementPopover> component found in tree')

const AnnouncementPopoverInternal = (props: { children?: React.ReactNode }) => {
  const { view, user } = useMst()
  const [shouldTrapFocus, setTrapFocus] = React.useState(false)

  const context = {
    opened: view.showAnnouncementPopover,
    dismissAnnouncement: user.dismissAnnouncement,
    trapFocus: () => setTrapFocus(true),
  }

  return (
    <AnnouncementPopoverContextProvider value={context}>
      <Popover
        trapFocus={shouldTrapFocus}
        opened={context.opened}
        arrowSize={15}
        width={350}
        shadow="xl"
      >
        {props.children}
      </Popover>
    </AnnouncementPopoverContextProvider>
  )
}

const AnnouncementPopoverTarget = (props: { children: React.ReactElement }) => {
  const { opened, trapFocus } = useAnnouncementContext()

  if (!opened) {
    return props.children
  }

  return (
    <Indicator processing offset={2} size={7}>
      <Popover.Target>
        {React.cloneElement(props.children, {
          variant: 'light',
          color: 'violet.9',
          onClick: trapFocus,
        })}
      </Popover.Target>
    </Indicator>
  )
}

AnnouncementPopoverTarget.displayName = 'AnnouncementPopover.Target'
AnnouncementPopoverInternal.Target = AnnouncementPopoverTarget

const AnnouncementPopoverDropdown = (props: {
  title: React.ReactNode
  children: React.ReactNode
}) => {
  const { dismissAnnouncement } = useAnnouncementContext()

  return (
    <Popover.Dropdown px={0} pt={0}>
      <Stack className={styles.announcementPopover_header}>
        <Group justify="flex-end" pt={5} px={5}>
          <ActionIcon
            data-autofocus
            className={styles.announcementPopover_button}
            onClick={dismissAnnouncement}
          >
            <FaIcon c="dark.9" icon="fa-xmark" size="18" />
          </ActionIcon>
        </Group>
        <Title mt={-20} px={20} pb={20} order={3}>
          {props.title}
        </Title>
      </Stack>
      <Stack px={20} pt={5} pb={10}>
        <Space />
        {props.children}
        <Group justify="flex-end">
          <Button
            size="xs"
            className={styles.announcementPopover_button}
            onClick={dismissAnnouncement}
          >
            Got it
          </Button>
        </Group>
      </Stack>
    </Popover.Dropdown>
  )
}

AnnouncementPopoverDropdown.displayName = 'AnnouncementPopover.Dropdown'
AnnouncementPopoverInternal.Dropdown = AnnouncementPopoverDropdown

export const AnnouncementPopover = observer(AnnouncementPopoverInternal)
