/*
  Observable state about the path and query string. This gives us a way to know about
  path parameters and query string values without using choo state.

  This does a few things now and will change how it does stuff in the future once we can
  extract all need for routing from choo. Here's what it does

  1. Sets up event listeners for window.location and makes the path and query string into
     mobx observables
  2. Adds helpers to query params so we don't need to use choo.query any more ever
  3. Lets you figure out route params using choo routes (if choo manages routing to there)
     or using wouter routes.  Eventually we'll have no choo routes and we can remove this bridge

*/
import qs from 'query-string'

import { NavAnchorHref } from '@components/NavAnchor'
import {
  buildLoginRedirect,
  extractParameters,
  findConfigForPathname,
  RoutePathConfig,
} from '@util/pathConfigs'

import { BaseModel } from '../BaseModel'

const LOCATION_CHANGE_EVENTS = [
  'popstate',
  'pushState',
  'replaceState',
] as const
type LocationChangeType = (typeof LOCATION_CHANGE_EVENTS)[number]

// Wouter v2 doesn't handle setting query string/search params
// so we do this on window directly. Somewhat of a kludge that we
// hang this off the model, but it's convenient
type SearchParams = Record<string, string | null>
const updateSearchParams = (newParams: SearchParams): void => {
  const currentUrl = new URL(window.location.href)
  Object.entries(newParams).forEach(([key, value]) => {
    if (value === null) {
      currentUrl.searchParams.delete(key) // Remove param if value is null
    } else {
      currentUrl.searchParams.set(key, value.toString())
    }
  })
  window.history.pushState({}, '', currentUrl.toString())
}

const replaceSearchParams = (newParams: SearchParams): void => {
  const currentUrl = new URL(window.location.href)
  currentUrl.search = ''
  Object.entries(newParams).forEach(([key, value]) => {
    if (value !== null) {
      currentUrl.searchParams.set(key, value.toString())
    }
  })
  window.history.pushState({}, '', currentUrl.toString())
}

const parsedQueryValueToString = (
  value: null | string | Array<null | string>,
): string => {
  if (value === null) {
    return ''
  }
  if (typeof value === 'string') {
    return value
  }
  const stringParts: string[] = []
  value.forEach((item) => {
    if (typeof item === 'string') {
      stringParts.push(item)
    }
  })
  return stringParts.join(',')
}

export const Location = BaseModel.named('Location')
  .props({
    hasChanged: false,
    pathname: location.pathname,
    search: location.search,
  })
  .views((self) => ({
    get queryParams(): StringMap {
      const rawValues = qs.parse(self.search)
      const result: StringMap = {}
      Object.entries(rawValues).forEach(([key, value]) => {
        result[key] = parsedQueryValueToString(value)
      })
      return result
    },
    getQueryParam(key: string): string | undefined {
      return this.queryParams[key]
    },
    getPathParam(key: string): string | undefined {
      const params = extractParameters(self.pathname)
      return params?.[key]
    },
    pathWithCurrentSearch(path: NavAnchorHref): NavAnchorHref {
      return `${path}${self.search}`
    },

    get loginRedirect() {
      const { pathname, search } = self
      return buildLoginRedirect({ pathname, search })
    },
    get currentPathConfig(): RoutePathConfig | undefined {
      return findConfigForPathname(self.pathname)
    },
    get currentPathPattern(): string | undefined {
      return this.currentPathConfig?.pattern
    },
  }))
  .actions((self) => ({
    setPathname(value: string) {
      self.pathname = value
    },
    setQueryParams(updates: SearchParams) {
      replaceSearchParams(updates)
    },
    updateQueryParams(updates: SearchParams) {
      updateSearchParams(updates)
    },
    updateFromWindow(type: LocationChangeType) {
      //  when we get a popstate, reset the requested document and has changed.
      // because this is a browser back/forward button and we treat it much like
      // a fresh load of the browser
      const isBrowserNavigation = type === 'popstate'
      const { pathname, search } = location
      const urlHasChanged = self.pathname !== pathname || self.search !== search

      if (isBrowserNavigation) {
        self.hasChanged = false
        self.rootStore.view.setSelectedFolderId('')
      } else if (urlHasChanged) {
        self.hasChanged = true
      }

      self.pathname = pathname
      self.search = search
    },
  }))
  .actions((self) => ({
    afterAttach() {
      LOCATION_CHANGE_EVENTS.forEach((changetype) => {
        addEventListener(changetype, () => self.updateFromWindow(changetype))
      })
    },
    // setQueryParm,
  }))
