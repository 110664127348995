import { Redirect, Route, Switch } from 'wouter'

import * as OnboardPages from '@components/Onboarding'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util/pathConfigs'

import { LogoutRoute, SignupRoute } from './components'

export const OnboardingRouter = () => {
  const { location, loggedIn } = useMst()

  if (!loggedIn) {
    return (
      <Switch>
        <Route
          path={ROUTE_PATTERNS.createAccount}
          component={OnboardPages.CreateStytchAccountRoute}
        />

        <Route
          path={ROUTE_PATTERNS.forgotPassword}
          component={OnboardPages.ForgotPasswordRoute}
        />

        <Route
          path={ROUTE_PATTERNS.updatePassword}
          component={OnboardPages.UpdatePasswordRoute}
        />

        <Route
          path={ROUTE_PATTERNS.login}
          component={OnboardPages.LoginRoute}
        />

        <Route path={ROUTE_PATTERNS.signup} component={SignupRoute} />

        {/* We shouldn't hit this, it was already handled in main router, but just in case...  */}
        <Redirect to={location.loginRedirect} />
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route
          path={ROUTE_PATTERNS.createWorkspace}
          component={OnboardPages.NewWorkspaceRoute}
        />
        <Route path={ROUTE_PATTERNS.signup} component={SignupRoute} />
        <Route path={ROUTE_PATTERNS.logout} component={LogoutRoute} />

        {/* route is accessible to logged in users to support lazy stytch migration */}
        <Route
          path={ROUTE_PATTERNS.updatePassword}
          component={OnboardPages.UpdatePasswordRoute}
        />

        {/* We shouldn't hit this, it was already handled in main router, but just in case...  */}
        <Redirect to={ROUTE_PATTERNS.root} />
      </Switch>
    )
  }
}
