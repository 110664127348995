import { util } from '@showrunner/codex'
import { schemas, ZInfer } from '@showrunner/scrapi'

const rdf = schemas.rundownFormats

export type RundownFormat = ZInfer<typeof schemas.RundownSchema>

export type ContextBlock = ZInfer<typeof schemas.rundownFormats.ContextBlock>
export type BracketImportRule = ZInfer<typeof rdf.BracketImportRule>
export type SimpleImportRule = ZInfer<typeof rdf.SimpleImportRule>
export type SlugImportRule = ZInfer<typeof rdf.SlugImportRule>
export type RowImportRule = ZInfer<typeof rdf.RowImportRule>
export type BlockContext = Partial<Record<ContextBlock, util.BlockSummary>>
export type RowDataInput = ZInfer<typeof schemas.RowDataInput>
export type GridBlobColumnKey = ZInfer<typeof rdf.GridBlobKey>
export type RundownColumnType = ZInfer<typeof rdf.RundownColumnType>
export type RundownColDef = ZInfer<typeof rdf.RundownColDef>
export type RowLevel = ZInfer<typeof rdf.RowLevel>

export function isBracketRule(rule: RowImportRule): rule is BracketImportRule {
  return rule.ruleType === 'bracket-parse'
}

export function isSlugRule(rule: RowImportRule): rule is SlugImportRule {
  return rule.ruleType === 'slug-with-timing'
}
