type DurationBreakdown = {
  seconds: number
  minutes: number
  hours: number
}

export const breakDownSeconds = (totalSeconds: number): DurationBreakdown => {
  const seconds = totalSeconds % 60
  const totalMinutes = Math.floor(totalSeconds / 60)
  const minutes = totalMinutes % 60
  const hours = Math.floor(totalMinutes / 60)

  return {
    seconds,
    minutes,
    hours,
  }
}

const pad = (input: number): string => {
  return input < 10 ? `0${input}` : String(input)
}

export const formatSeconds = (totalSeconds: number): string => {
  if (!Number.isInteger(totalSeconds) || totalSeconds < 0) {
    return ''
  }
  const { seconds, minutes, hours } = breakDownSeconds(totalSeconds)

  const hourStr = hours > 0 ? `${hours}:` : ''
  const minuteStr = hours > 0 ? pad(minutes) : minutes

  return `${hourStr}${minuteStr}:${pad(seconds)}`
}
