/*
  Generate ID-specific css styles for showing
  in-margin compilation issues
*/

import { CSSObject } from '@emotion/react'

import { InkCompileMessage, STATUS_ICON_CONFIG } from '@ink'
import { colors } from '@theme'

export const createInlineInkStyles = (
  compilerMessages: InkCompileMessage[],
) => {
  // Create line numbers using CSS counters
  const inkStyles: Record<string, CSSObject> = {
    '.ProseMirror': {
      counterReset: 'line-number 0',
    },
    '.o-block-element': {
      counterIncrement: 'line-number',
    },
    '.o-block-element::before': {
      color: colors.gray[6],
      content: 'counter(line-number)',
      fontSize: '0.9em',
      letterSpacing: -1,
      marginRight: 10,
      right: '100%',
      position: 'absolute',
      textAlign: 'right',
    },
  }

  // Compiler messages must go after line numbers to override them
  // (they use the same pseudoelements)
  compilerMessages.forEach(({ type, message, blockId }) => {
    if (!(blockId && type)) {
      return
    }
    const selector = `[id="${blockId}"]`

    const { iconUnicode: content, color } = STATUS_ICON_CONFIG[type]

    const styles: CSSObject = {
      display: 'block',
      pointerEvents: 'none',

      '&::before': {
        color,
        content,
        font: 'var(--fa-font-solid)',
        pointerEvents: 'all',
        position: 'absolute',
        textIndent: 0,
        left: -44,
      },
    }

    if (type !== 'todo') {
      styles['&::after'] = {
        backgroundColor: '#2b222a',
        borderRadius: 3,
        color: '#fff',
        content: `'${CSS.escape(message)}'`,
        display: 'inline-block',
        opacity: 0,
        left: -44,
        lineHeight: 1.2,
        padding: 8,
        position: 'absolute',
        textIndent: 0,
        top: 24,
        transition: 'opacity 0.2s ease',
        whiteSpace: 'normal',
        width: 400,
        zIndex: 12,
      }

      styles['&:hover::after'] = {
        opacity: 1,
      }
    }

    inkStyles[selector] = styles
  })
  return inkStyles
}
