import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Card, Group, Radio, Stack, Text } from '@mantine/core'

import { ScriptStatus } from '@showrunner/codex'

import { ScriptStatusIcon } from '@components/FaIcon'
import { ModalShell, useModalControls } from '@components/Modals'
import { Toast } from '@components/Toast'
import { ILoadedScript, SharedScriptStatus } from '@state/types'

import styles from './ScriptStatus.module.scss'

type ScriptStatusProps = {
  onConfirm: AsyncCallback<[ScriptStatus]>
  script: ILoadedScript
  readOnly?: boolean
}

const PrivateScriptStatus = ({ onConfirm }: { onConfirm: () => void }) => {
  const controls = useModalControls()
  const handleConfirm = () => {
    controls.onClose()
    onConfirm()
  }

  return (
    <ModalShell
      title="Script Access"
      onConfirm={handleConfirm}
      confirmLabel="Share with entire workspace"
      size="sm"
      opened={controls.opened}
      onClose={controls.onClose}
    >
      <Text>Currently only you can see and edit this script</Text>
    </ModalShell>
  )
}

const StatusCard = ({
  status,
  description,
  disabled,
}: {
  status: SharedScriptStatus
  description: string
  disabled?: boolean
}) => (
  <Card className={styles.scriptStatus_card}>
    <Radio
      classNames={{
        root: styles.scriptStatus_root,
        body: styles.scriptStatus_body,
        inner: styles.scriptStatus_inner,
        label: styles.scriptStatus_label,
        description: styles.scriptStatus_description,
      }}
      value={status}
      label={
        <Group gap={4}>
          <ScriptStatusIcon status={status} />
          <Text span size="14" fw="bold">
            {status}
          </Text>
        </Group>
      }
      description={description}
      disabled={disabled}
      size="sm"
    />
  </Card>
)

const ScriptStatusInternal = ({
  onConfirm,
  readOnly = false,
  script,
}: ScriptStatusProps) => {
  const controls = useModalControls()
  const [value, setValue] = React.useState<ScriptStatus>(script.accessLevel)

  if (script.isPrivate) return <PrivateScriptStatus onConfirm={script.share} />

  const handleConfirm = () => {
    controls.onClose()
    onConfirm(value)
  }

  return (
    <ModalShell
      title="Script Access"
      onConfirm={handleConfirm}
      confirmLabel="Save"
      cancelLabel="Cancel"
      disabled={readOnly || value === script.accessLevel}
      size="sm"
      opened={controls.opened}
      onClose={controls.onClose}
    >
      {readOnly && (
        <Toast
          message="You don't have permission to change the script's access level."
          dismissable={false}
        />
      )}
      <Radio.Group
        name="scriptAccess"
        onChange={(v) => setValue(v as ScriptStatus)}
        size="lg"
        value={value}
      >
        <Stack gap="sm">
          <StatusCard
            status="LIMITED"
            description="Only certain members can edit"
            disabled={readOnly}
          />
          <StatusCard
            status="OPEN"
            description="Everyone in the workspace can edit"
            disabled={readOnly}
          />
        </Stack>
      </Radio.Group>
    </ModalShell>
  )
}

export const ScriptStatusModal = NiceModal.create(ScriptStatusInternal)

export const showScriptStatusModal = (props: ScriptStatusProps) =>
  NiceModal.show(ScriptStatusModal, props)
