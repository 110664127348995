import { Stack, Text, Title } from '@mantine/core'

import { showAlert } from '@components/Modals'

import { PASTE_TIP } from '../helpers'

export const showClipboardPasteModal = () =>
  showAlert({
    title: 'Use this keyboard shortcut',
    children: (
      <Stack>
        <Title ta="center">{PASTE_TIP}</Title>
        <Text ta="center" size="md">
          For security reasons, browsers don&apos;t allow pasting from the
          clipboard via Scripto menus
        </Text>
      </Stack>
    ),
  })
