import { ScrollingBody } from '@layouts'

import { Sidenav } from './Sidenav'

import styles from './SettingsPage.module.scss'

export const SettingsPage = ({ children }: { children: React.ReactNode }) => {
  return (
    <ScrollingBody>
      <div className={styles.settingsPage}>
        <Sidenav />
        {children}
      </div>
    </ScrollingBody>
  )
}
