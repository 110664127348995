import { Table, Text, Title } from '@mantine/core'

import styles from './Shortcuts.module.scss'

export const ShortcutTable = ({
  rows,
  title,
}: {
  rows: [string, string][]
  title: string
}) => (
  <>
    <Title className={styles.shortcuts_title} order={3}>
      {title}
    </Title>
    <Table>
      <Table.Tbody>
        {rows.map(([name, shortcut]) => (
          <Table.Tr key={name}>
            <Table.Td>
              <Text size="16">{name}</Text>
            </Table.Td>
            <Table.Td className={styles.shortcuts_shortcutCol}>
              <Text span className={styles.shortcuts_keys}>
                {shortcut}
              </Text>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  </>
)
