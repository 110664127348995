import React from 'react'

import { ActionIcon, Tooltip } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@state'
import { highlightFavoritesCollection } from '@util/animation'

export const FavoriteToggle = observer(function FavoriteToggle({
  uuid,
}: {
  uuid: string
}) {
  const { currentOrg } = useMst()
  const isFav = currentOrg?.isFavorite(uuid)

  const toggleFavorite = () => {
    isFav ? currentOrg?.removeFavorite(uuid) : currentOrg?.addFavorite(uuid)
    // dont animate on unfavorite
    if (!isFav) highlightFavoritesCollection()
  }

  const action = isFav ? 'Remove from' : 'Add to'
  const color = isFav ? 'yellow' : 'gray.5'
  const iconSet = isFav ? 'fas' : 'fa-regular'

  return (
    <Tooltip label={`${action} My Favorites`}>
      <ActionIcon color={color} onClick={toggleFavorite}>
        <FaIcon faIconSet={iconSet} icon="fa-star" />
      </ActionIcon>
    </Tooltip>
  )
})
