import { createContext, useContext } from 'react'

import { QueryClient } from '@tanstack/react-query'
import { AxiosInstance } from 'axios'
import { Instance, SnapshotIn } from 'mobx-state-tree'

import { NamedServerConfig } from '@config'
import { buildLegacyApiClient, ScriptoApiClient } from '@util'
import { DatadogClient } from '@util/datadog'
import { LocalPersistence } from '@util/LocalPersistence'
import { MixpanelClient } from '@util/mixpanel'

import { Root } from './models/Root'
import type { Environment } from './types'

export const createRootStore = (opts: {
  config: NamedServerConfig
  axios: AxiosInstance
  localPersistence: LocalPersistence
  snapshot: SnapshotIn<typeof Root>
  queryClient: QueryClient
}) => {
  const { config, axios, localPersistence, snapshot, queryClient } = opts
  const analytics = new MixpanelClient(config.MIXPANEL_TOKEN || '')
  const env: Environment = {
    config,
    apiClient: new ScriptoApiClient({ axios }),
    localPersistence,
    analytics,
    datadog: DatadogClient.getInstance(),
    legacyApiClient: buildLegacyApiClient('/api'),
    queryClient,
  }

  const store = Root.create(snapshot, env)
  store.environment.datadog.initializeContextTracking(store)
  return store
}

// the MstContext is the vehicle to inject state into nested component trees.
// we create one here-- which is really just a global container for null or the
// singleton root store
const MstContext = createContext<Instance<typeof Root> | null>(null)

// this is the thingy that is used at the top level to wrap the whole app
// with context. We do this in App.tsx, but also in the storybook setup
export const MstProvider = MstContext.Provider

// Child components use this hook to access the app singleton context. It should
// NEVER be null-- that means we broke the code, so we throw if it is.
export function useMst() {
  const store = useContext(MstContext)
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  }
  return store
}

export * from './types'
