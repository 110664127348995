import { EditorState } from 'prosemirror-state'

import { NodeTypeKey } from '@showrunner/codex'
import { schema } from '@showrunner/prose-schemas'

import {
  cursorInDualDialogueBlock,
  getSelectionBlockType,
  nextNodeOnEnter,
} from '@util/prosemirrorHelpers'

const TRAILING_SPACE_RE = /^\s+$/

export const nextBlockTypeAndAttrsForSplit = (state: EditorState) => {
  const isDual = cursorInDualDialogueBlock(state)
  const currType = getSelectionBlockType(state) as NodeTypeKey
  const nextDefaultType = nextNodeOnEnter(
    currType,
    state.doc.attrs.docType,
    isDual,
  )

  const { $from, $to } = state.selection
  const { parent, parentOffset } = $to

  const trimSize = parent.content.size - parentOffset
  const hasTrailingSpace = TRAILING_SPACE_RE.test(
    state.doc.textBetween($to.pos, $to.pos + trimSize),
  )

  // See if the end of the selection is effectively at the end of
  // a block. If so, we'll look up the appropriate following block
  // and preserve only the untimed attribute. We're basically doing a
  // delete here
  const isAtEnd = trimSize === 0 || hasTrailingSpace
  if (isAtEnd) {
    return {
      type: schema.nodes[nextDefaultType],
      attrs: { untimed: $from.parent.attrs?.untimed },
    }
  }

  // The node type will carry over from the $to
  const type = $to.parent.type

  // Some of these attributes depend on whether or not we're splitting
  // a single block in two or just deleting intervening content
  const { id, untimed, pageBreak, elementNumber } = $to.parent.attrs

  const isSplittingSingleBlock = $from.parent.eq($to.parent)

  if (isSplittingSingleBlock) {
    return {
      type,
      attrs: { untimed },
    }
  }

  return {
    type,
    attrs: {
      id,
      untimed,
      pageBreak,
      elementNumber,
    },
  }
}
