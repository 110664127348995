import { Checkbox } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { IRundown } from '@state'

export const CheckboxHeader = observer(function CheckboxHeader({
  context: rundown,
}: {
  context: IRundown
}) {
  const selectedRowCount = rundown.sortedRowInstances.filter(
    (r) => r.selectedInGrid,
  ).length
  const totalRowCount = rundown.sortedRowInstances.length

  const hasSelectedRows = selectedRowCount > 0
  const allRowsSelected = totalRowCount === selectedRowCount

  const handleClick = () => {
    if (allRowsSelected) {
      rundown.deselectAllRows()
    } else {
      rundown.selectAllRows()
    }
  }

  return (
    <Checkbox
      size="18"
      checked={hasSelectedRows}
      indeterminate={hasSelectedRows && !allRowsSelected}
      onChange={handleClick}
    />
  )
})
