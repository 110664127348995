/*
  Link marks are non-inclusive which means if abc is wrapped with
  a mark, the resolved positions of a and c will not show that they
  are in the mark. This leads to very torturous logic
*/
import { toggleMark } from 'prosemirror-commands'
import { Mark, ResolvedPos } from 'prosemirror-model'
import { EditorState, TextSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { schema } from '@showrunner/prose-schemas'

import { LINK_MARK } from '@util'

const linkAt = (rp: ResolvedPos): Mark | undefined => {
  return rp.marks().find((m) => m.type === LINK_MARK)
}

// If we have <link>abc</link> and the cursor is before a, this
// will return the link
const linkAcross = (rp: ResolvedPos): Mark | undefined => {
  return rp.marksAcross(rp)?.find((m) => m.type === LINK_MARK)
}

const linkInEffectAtStart = ({ $from, empty }: TextSelection) => {
  const interiorLink = linkAt($from)
  if (interiorLink) {
    return interiorLink
  }
  if (empty) {
    return undefined
  }
  return linkAcross($from)
}

const linkInEffectAtEnd = ({ selection, doc }: EditorState) => {
  if (selection instanceof TextSelection) {
    const { $to, empty } = selection
    const interiorLink = linkAt($to)
    if (interiorLink) {
      return interiorLink
    }
    if (empty) {
      return undefined
    }

    const rpInEffect = doc.resolve($to.pos - 1)
    return linkAcross(rpInEffect)
  }
}

export const getLinkForSelection = (editorView: EditorView) => {
  const selection = editorView.state.selection
  if (selection instanceof TextSelection) {
    const fromLink = linkInEffectAtStart(selection)
    if (fromLink) {
      const toLink = linkInEffectAtEnd(editorView.state)
      if (fromLink === toLink) {
        return fromLink
      }
    }
  }
}

const urlify = (text: string) =>
  /^https?:\/\//i.test(text) ? text : 'http://' + text

// cribbed from https://discuss.prosemirror.net/t/find-extents-of-a-mark-given-a-selection/344/8
const getMarkExtent = (pos: ResolvedPos, mark: Mark) => {
  let startIndex = pos.index()
  let endIndex = pos.indexAfter()
  while (
    startIndex > 0 &&
    mark.isInSet(pos.parent.child(startIndex - 1).marks)
  ) {
    startIndex--
  }
  while (
    endIndex < pos.parent.childCount &&
    mark.isInSet(pos.parent.child(endIndex).marks)
  ) {
    endIndex++
  }
  let startPos = pos.start()
  let endPos = startPos
  for (let i = 0; i < endIndex; i++) {
    const size = pos.parent.child(i).nodeSize
    if (i < startIndex) {
      startPos += size
    }
    endPos += size
  }
  return { from: startPos, to: endPos }
}

export const addOrEditLinkMark = ({
  text,
  mark,
  editorView,
}: {
  text: string
  mark?: Mark
  editorView?: EditorView
}) => {
  if (!editorView) return
  const href = urlify(text)
  if (!mark) return addLinkMark({ href, editorView })
  return editLinkMark({ href, mark, editorView })
}

const addLinkMark = ({
  href,
  editorView,
}: {
  href: string
  editorView: EditorView
}) => {
  toggleMark(LINK_MARK, { href })(editorView.state, editorView.dispatch)
  editorView.focus()
}

const editLinkMark = ({
  href,
  mark,
  editorView,
}: {
  href: string
  mark: Mark
  editorView: EditorView
}) => {
  const markJson = mark.toJSON()
  const clonedMark = Mark.fromJSON(schema, {
    ...markJson,
    attrs: { ...markJson.attrs, href },
  })

  const { state } = editorView
  const { selection, tr } = state
  const { from, to } = getMarkExtent(selection.$anchor, mark)
  // its wacky but removing and readding a mark with the same boundaries and
  // different attributes is the recommended way to 'update'
  // https://discuss.prosemirror.net/t/updating-mark-attributes/776/2
  tr.addMark(from, to, clonedMark)
  // whether the user had a plain cursor or partial selection
  // signal that we acted on the entire link
  tr.setSelection(TextSelection.create(tr.doc, from, to))
  editorView.dispatch(tr)
  editorView.focus()
}

export const removeLinkMark = ({
  mark,
  editorView,
}: {
  mark: Mark
  editorView: EditorView
}) => {
  const { state, dispatch } = editorView
  const { tr } = state
  const { from, to } = getMarkExtent(state.selection.$anchor, mark)
  tr.removeMark(from, to, mark)
  // regardless of the current selection,
  // signal that we acted on the entire link
  tr.setSelection(TextSelection.create(tr.doc, from, to))
  dispatch(tr)
  editorView.focus()
}
