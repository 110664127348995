import { downloadZip } from 'client-zip'
import sanitize from 'sanitize-filename'

export const saveBlobToFile = ({
  blob,
  fileName,
}: {
  fileName: string
  blob: Blob
}) => {
  const href = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', sanitize(fileName))
  document.body.appendChild(link)
  link.click()

  // clean up "a" element & remoke ObjectURL
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

export const saveTextToFile = ({
  text,
  fileName,
  contentType = 'text/plain',
}: {
  text: string
  fileName: string
  contentType?: string
}) => {
  const blob = new Blob([text], { type: contentType })
  return saveBlobToFile({ blob, fileName })
}

export const downloadZippedFiles = async ({
  files,
  zipFileName,
}: {
  files: Array<{ fileName: string; contents: string }>
  zipFileName: string
}) => {
  const blob = await downloadZip(
    files.map(({ fileName, contents }) => ({
      name: fileName,
      input: contents,
    })),
  ).blob()
  return saveBlobToFile({ blob, fileName: zipFileName })
}
