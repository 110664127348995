import { Space, Text, Title } from '@mantine/core'
import { useQueries } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'

import { Loader } from '@components/Loader'
import { NavAnchor } from '@components/NavAnchor'
import { useStaffFormats } from '@hooks'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util'
import { BetaFlag, OrgPayload } from '@util/ScriptoApiClient/types'

import { rundownSchemaQuery, staleTime } from '../helpers'

import { AccountStatusForm } from './AccountStatusForm'
import { BetaFlagsForm } from './BetaFlagsForm'
import { DefaultFormatsForm } from './DefaultFormatsForm'
import { MembersForm } from './MembersForm'
import { PlanOptionsForm } from './PlanOptionsForm'
import { ReadRateForm } from './ReadRateForm'
import { ReindexDocsForm } from './ReindexDocsForm'
import { RundownSchemaForm } from './RundownSchemaForm'
import { TierForm } from './TierForm'
import { StaffFormatSummary } from './util'

import './OrgDetail.scss'

const OrgDetailInternal = ({
  org,
  formats,
  betaFlags,
  schemaNames,
}: {
  org: OrgPayload
  formats: StaffFormatSummary[]
  betaFlags: BetaFlag[]
  schemaNames: string[]
}) => (
  <div className="org-detail-staff-page">
    <h1>
      <NavAnchor href={ROUTE_PATTERNS.staffLanding}>Staff Zone</NavAnchor>
      &nbsp;&gt;&nbsp;
      <NavAnchor href={ROUTE_PATTERNS.staffWorkspaces}>Workspaces</NavAnchor>
      &nbsp;&gt;&nbsp;
      {org.name}
    </h1>
    <h3>Account Status</h3>
    <Text>only used for internal KPIs/analytics</Text>
    <Space h={20} />
    <AccountStatusForm org={org} />
    <hr className="s-hr" />
    <h3>Tier</h3>
    <Text>
      controls what workspace features are available to the client and how many
      users they can invite
    </Text>
    <Space h={10} />
    <TierForm org={org} />
    <hr className="s-hr" />
    <h3>Default Script Formats</h3>
    <DefaultFormatsForm org={org} formats={formats} />
    <hr className="s-hr" />
    <h3>Plan Options</h3>
    <h4>
      ☠️ Turning off plan options is very destructive. Don&apos;t uncheck any
      options unless you know what you&apos;re doing. ☠️
    </h4>
    <PlanOptionsForm org={org} />
    <hr className="s-hr" />
    <h3>Change Read Rate</h3>
    <ReadRateForm org={org} />
    <hr className="s-hr" />
    <h3>Reindex Documents</h3>
    <ReindexDocsForm orgId={org.id} />
    <hr className="s-hr" />
    <h3>Members</h3>
    <MembersForm org={org} />
    <hr className="s-hr" />
    <h3>Feature Flags</h3>
    <h4>
      ☠️ Don&apos;t uncheck any flags unless you know what you&apos;re doing. ☠️
    </h4>
    <BetaFlagsForm org={org} betaFlags={betaFlags} />
    <hr className="s-hr" />
    <h3>Rundown Schema</h3>
    <RundownSchemaForm org={org} schemaNames={schemaNames} />
  </div>
)

export const OrgDetail = observer(function OrgDetail({
  params,
}: {
  params: { uuid: string }
}) {
  const { apiClient } = useMst()
  const orgId = params.uuid

  const orgQuery = async () => await apiClient.getOrgForStaff(orgId)

  const { formatsQuery } = useStaffFormats()

  const betaFlagQuery = async () => {
    const res = await apiClient.getBetaFlags()
    return res.features
  }

  const results = useQueries({
    queries: [
      {
        queryKey: ['staff_org_info', { orgId }],
        queryFn: orgQuery,
        // we fetch workspace details each time to avoid showing stale info
        gcTime: 0,
        staleTime: Infinity,
      },
      {
        queryKey: ['staff_flag_query'],
        queryFn: betaFlagQuery,
        staleTime,
      },
      rundownSchemaQuery(apiClient),
    ],
  })

  const isLoading = results.some((r) => r.isLoading) || formatsQuery.isLoading
  const isError = results.some((r) => r.isError) || formatsQuery.isError

  if (isLoading) return <Loader fullScreen={true} />

  const org = results[0].data
  const formats = formatsQuery.data
  const betaFlags = results[1].data
  const schemaNames = results[2].data
    ?.map((s) => s.name)
    .sort((a, b) => a.localeCompare(b))

  if (isError || !org || !formats || !betaFlags) {
    return <Title>Unexpected error</Title>
  }

  return (
    <OrgDetailInternal
      org={org}
      formats={formats}
      betaFlags={betaFlags}
      schemaNames={schemaNames ?? []}
    />
  )
})
