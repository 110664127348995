import { types } from 'mobx-state-tree'

import { BaseModel } from './BaseModel'

type MemberRole = 'Owner' | 'Admin' | 'Contributor'

export const OrgMember = BaseModel.named('OrgMember')
  .props({
    id: types.string,
    name: types.string,
    email: types.string,
    avatar: types.maybeNull(types.string),
    permissions: types.array(types.string),
    isAdmin: false,
    isRemoving: false,
    isChangingRole: false,
  })
  .views((self) => ({
    get role(): MemberRole {
      if (self.rootStore.currentOrg?.owner.id === self.id) return 'Owner'
      return self.isAdmin ? 'Admin' : 'Contributor'
    },
  }))
  .actions((self) => ({
    setIsRemoving(value: boolean) {
      self.isRemoving = value
    },
    setIsChangingRole(value: boolean) {
      self.isChangingRole = value
    },
  }))
  // async actions
  .actions((self) => ({
    async remove() {
      const { currentOrg, doDebug } = self.rootStore
      if (!currentOrg) return
      self.setIsRemoving(true)

      await doDebug()
      const { members } = await self.apiClient.removeUserFromOrg({
        orgId: currentOrg.id,
        userId: self.id,
      })
      self.trackEvent('SHOW_REMOVED')
      // resets isRemoving
      currentOrg.setMembers(members)
    },

    async removeSelf() {
      const { currentOrg, doDebug } = self.rootStore
      if (!currentOrg) return
      self.setIsRemoving(true)
      try {
        await doDebug()
        await self.apiClient.removeSelfFromOrg({
          orgId: currentOrg.id,
        })
        self.trackEvent('SHOW_REMOVED_SELF')
      } finally {
        self.setIsRemoving(false)
      }
    },

    async promote() {
      const { currentOrg, doDebug } = self.rootStore
      if (!currentOrg) return
      self.setIsChangingRole(true)

      await doDebug()
      const { members } = await self.apiClient.promoteAdmin({
        orgId: currentOrg.id,
        userId: self.id,
      })
      self.trackEvent('ADMIN_PROMOTED')
      // this resets isChangingRole too
      currentOrg.setMembers(members)
    },

    async demote() {
      const { currentOrg, user, doDebug } = self.rootStore
      if (!currentOrg) return
      self.setIsChangingRole(true)

      await doDebug()
      const userId = self.id
      const { members } = await self.apiClient.demoteAdmin({
        orgId: currentOrg.id,
        userId,
      })
      self.trackEvent('ADMIN_DEMOTED')
      // this resets isChangingRole too
      currentOrg.setMembers(members)
      if (user.id === userId) {
        user.selectedMembership?.setIsAdmin(false)
        currentOrg.setInvites([])
      }
    },
  }))
