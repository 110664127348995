export const chunkArray = <T>(arr: T[], chunkSize: number): T[][] => {
  if (parseInt(String(chunkSize)) !== chunkSize) {
    throw new Error('Chunk size must be an integer')
  }

  if (chunkSize < 1) {
    throw new Error('Chunk size must be greater than 0')
  }

  const result: T[][] = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize))
  }
  return result
}

// if you have an array of promises that return the same type
// you can use this instead of Promise.all to execute them in
// batches vs all at once or linearly
export const chunkPromiseAll = async <T>(
  fns: Array<() => Promise<T>>,
  chunkSize: number,
): Promise<Array<T>> => {
  const result: Array<T> = []
  const chunks = chunkArray(fns, chunkSize)
  for (let i = 0; i < chunks.length; i++) {
    const promises = chunks[i].map((fn) => fn())
    const chunkResult = await Promise.all(promises)
    result.push(...chunkResult)
  }
  return result
}
