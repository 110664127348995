import React from 'react'

import { Text, TextProps } from '@mantine/core'

import styles from './StatusBarText.module.scss'

export const StatusBarText = ({
  children,
  txtColor = 'dimmed',
}: {
  children: React.ReactNode
  txtColor?: TextProps['c']
}) => (
  <Text c={txtColor} size="sm" className={styles.statusBar_text} lineClamp={1}>
    {children}
  </Text>
)
