import {
  Button,
  Checkbox,
  Group,
  NumberInput,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'

import { TemplateImage } from '@components/Onboarding/NewWorkspace/TemplateImage'

import { showDecommissionModal } from './DecommissionModal'
import { TemplateWorkspaceData, useStaffTemplates } from './useTemplateApis'
import { TemplateFormData } from './useTemplateForm'

export const TemplateWorkspaceForm = ({
  form,
}: {
  form: UseFormReturnType<TemplateFormData>
}) => {
  const { createTemplateMutation, updateTemplateMutation } = useStaffTemplates()
  const { mode, selectedCode } = form.values

  const handleSubmit = async (values: TemplateWorkspaceData) => {
    if (mode === 'create') {
      await createTemplateMutation.mutateAsync(values)
    } else {
      updateTemplateMutation.mutate(values)
    }
  }

  const handleDecomission = () => {
    if (selectedCode) {
      showDecommissionModal(selectedCode)
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack align="stretch">
        <TextInput size="sm" label="Name" {...form.getInputProps('name')} />
        <TextInput
          size="sm"
          label="Code"
          {...form.getInputProps('code')}
          readOnly={mode === 'update'}
          variant={mode === 'update' ? 'filled' : 'default'}
        />
        <TextInput
          size="sm"
          label="Workspace ID"
          {...form.getInputProps('workspaceId')}
          readOnly={mode === 'update'}
          variant={mode === 'update' ? 'filled' : 'default'}
        />
        <Textarea
          rows={5}
          size="sm"
          label="Description (user facing)"
          {...form.getInputProps('description')}
        />
        <Textarea
          rows={5}
          size="sm"
          label="Notes (not user facing)"
          {...form.getInputProps('notes')}
        />
        <Group>
          <TextInput
            size="sm"
            label="Icon"
            w={300}
            {...form.getInputProps('icon')}
            description="TV, CAMERA, GAME, or fa-icon"
          />
          {form.isValid('icon') && <TemplateImage icon={form.values.icon} />}
        </Group>
        <NumberInput
          size="sm"
          w={100}
          label="Sequence"
          min={0}
          step={1}
          {...form.getInputProps('sequence')}
          onChange={(value) => {
            const numeric = typeof value === 'number' ? value : Number(value)
            form.setFieldValue('sequence', numeric)
          }}
        />
        <Checkbox
          size="sm"
          label="Active"
          {...form.getInputProps('active', { type: 'checkbox' })}
        />
        <Group justify="center">
          <Button type="submit">
            {mode === 'create' ? 'Create' : 'Update'}
          </Button>
          {mode === 'update' && (
            <Button variant="danger" onClick={handleDecomission}>
              Decommission
            </Button>
          )}
        </Group>
      </Stack>
    </form>
  )
}
