import React from 'react'

import { ActionIcon, Tooltip } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { useCurrentDocLocator } from '@hooks'
import { useMst } from '@state'

import styles from './DocLocator.module.scss'

export const DocLocator = observer(function DocLocator({
  folderId,
  docType,
}: {
  folderId: string
  docType: 'rundown' | 'script'
}) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const docLocator = useCurrentDocLocator(docType)
  const { getFolderPath } = useMst()

  const pathParts: string[] = getFolderPath(folderId).map((f) => f.displayName)

  if (!docLocator.enabled) {
    return null
  }

  return (
    <Tooltip
      position="bottom-start"
      opened={tooltipOpen}
      label={pathParts.join(' > ')}
    >
      <ActionIcon
        className={styles.docLocator_button}
        onClick={() => {
          setTooltipOpen(false)
          docLocator.locate()
        }}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        <FaIcon icon="fa-folder-tree" />
      </ActionIcon>
    </Tooltip>
  )
})
