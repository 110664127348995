import React from 'react'

import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from 'wouter'

import { SideBySide, SideBySideProps } from '@layouts/SideBySide'
import { useMst } from '@state'

import { SplitLayoutPlaceholder } from '../SplitLayoutPlaceholder'

import {
  useCheckUnsavedBeforeLeavingApp,
  useCheckUnsavedBeforeLeavingScript,
  useUnloadEditors,
} from './hooks'
import { RundownLoader } from './RundownLoader'
import { ScriptLoader } from './ScriptLoader'

const limit = {
  // mini-header height
  minHeight: 44,
  // enough to accommodate the mini-header control bars
  minWidth: 120,
}

const limits: SideBySideProps['limits'] = {
  firstChild: limit,
  secondChild: limit,
}

export const EditorsRoute = observer(function RouteComponentProps({
  params,
}: RouteComponentProps<{ rundownId?: string; scriptId?: string }>) {
  const root = useMst()
  const { layout, rundownHeight, rundownWidth } = root.view.rundownScriptLayout
  const { prefersSplitLayout, exitSplitLayout } = root.user
  const { rundownId, scriptId } = params

  useUnloadEditors()
  useCheckUnsavedBeforeLeavingApp()
  useCheckUnsavedBeforeLeavingScript()

  // When we enter the view, update split layout pref if needed. In this way if
  // you launch the app on a split view, we set your prefs
  React.useEffect(() => {
    if (rundownId && scriptId && !root.user.prefersSplitLayout) {
      root.user.enterSplitLayout()
    }
  }, [root.user, rundownId, scriptId])

  React.useEffect(() => {
    if (rundownId) {
      root.view.setRequestedDocument({
        id: rundownId,
        type: 'rundown',
      })
    } else if (scriptId) {
      root.view.setRequestedDocument({
        id: scriptId,
        type: 'script',
      })
    }
  }, [root.view, rundownId, scriptId])

  const handleSplitResize = ({ height, width }: Partial<Dimensions>) => {
    root.view.updateRundownScriptLayout({
      rundownHeight: height,
      rundownWidth: width,
    })
  }

  const rundownLoader = rundownId ? (
    <RundownLoader rundownId={rundownId} />
  ) : null

  const scriptLoader = scriptId ? <ScriptLoader scriptId={scriptId} /> : null

  const firstChild = rundownLoader ?? scriptLoader

  const renderSecondChild =
    (rundownId && scriptId) || (rundownId && prefersSplitLayout)

  const secondChild = renderSecondChild
    ? scriptLoader ?? <SplitLayoutPlaceholder onClose={exitSplitLayout} />
    : null

  return (
    <SideBySide
      limits={limits}
      layout={layout}
      preferredHeight={rundownHeight}
      preferredWidth={rundownWidth}
      onResizeEnd={handleSplitResize}
    >
      {firstChild}
      {secondChild}
    </SideBySide>
  )
})
