import * as AgGrid from '@ag-grid-community/core'

import { IRundown } from '@state/types'
import { absolutePathTo, ROUTE_PATTERNS } from '@util'
import { CONTROLS_COLUMN_ID, EnrichedRowData } from '@util/rundowns'

import { DRAG_HANDLE_ATTRIBUTE } from './cells/ControlsCell/Controls'
import { CELL_VALUE_EXPORTER } from './columns/columnTypes'

export const getRowId = ({ data }: AgGrid.GetRowIdParams<EnrichedRowData>) =>
  String(data.id)

// General handler for deselecting rows when clicking on individual cells-- but
// with exceptions to allow the context menu and multi-row dragging
export const handleCellMouseDown = ({
  event,
  context,
  node,
}: AgGrid.CellMouseDownEvent) => {
  const rundown = context as IRundown
  if (event instanceof MouseEvent) {
    const isRightClick = event.button === 2 || event.ctrlKey
    const selected = node.isSelected()
    const isDragHandle =
      event.target instanceof Element &&
      event.target.closest(`[${DRAG_HANDLE_ATTRIBUTE}]`)

    // normally, when a cell mouse down propagates to here, we want to clear any
    // selected rows. However, in two cases we let the event propagate to ag-grid
    // but don't want to deselect rows-- one is to allow the popup to display
    // when right-clicking a selected row, the other is when a drag is initialated on
    // a selected row
    const showPopupOnSelected = selected && isRightClick
    const startDragOnSelected = selected && isDragHandle

    if (!(showPopupOnSelected || startDragOnSelected)) {
      rundown.deselectAllRows()
    }
  }
}

const {
  UP,
  DOWN,
  LEFT,
  RIGHT,
  PAGE_UP,
  PAGE_DOWN,
  PAGE_END,
  PAGE_HOME,
  TAB,
  SPACE,
} = AgGrid.KeyCode

const NAVIGATION_CODES: readonly string[] = [
  UP,
  DOWN,
  LEFT,
  RIGHT,
  PAGE_UP,
  PAGE_DOWN,
  PAGE_END,
  PAGE_HOME,
  TAB,
]

// We intervene with the ag-grid keyboard behaviors for two cases:
//  * We handle the SPACE key to toggle row selection in mst instead of in the grid
//  * Keyboard navigation deselects any selected rows
export const suppressKeyboardEvent = ({
  event,
  node,
  context,
  editing,
}: AgGrid.SuppressKeyboardEventParams<EnrichedRowData>) => {
  const rundown = context as IRundown
  const row = node.data ? rundown.getRow(node.data.id) : undefined

  if (editing) {
    // If you're tabbing forward or back, AgGrid usually keeps you editing but
    // we want to stop editing to give us a chance to save the changes to the cell
    // (and also for a better UX)
    if (event.key === TAB) {
      // stop editing
      rundown.gridRef.current?.api.stopEditing()
    }

    // We never suppress the keyboard event when editing, we only meddle with the tab
    return false
  }

  // AgGrid usually handles space (by selecting the row). The browser usually
  // handles it by scrolling when AgGrid does NOT handle.
  // We want to override BOTH and use space to set the mst row to selected
  if (event.key === SPACE) {
    if (row) {
      rundown.toggleRowSelected(row.id)
    }
    event.preventDefault() // this prevents the browser from handling
    return true // this prevents ag-grid from handling
  }

  // If it's a navigation keypress, we will let ag grid handle it
  // but we want to mess with the side effects a bit
  if (NAVIGATION_CODES.includes(event.key)) {
    // just like clicking on a cell, we want to deslect rows
    rundown.deselectAllRows()
  }
  return false
}

// If you copy multiple cells (e.g. from google sheets) this
// turns that into a single cell value when you paste
export const processDataFromClipboard = (
  params: AgGrid.ProcessDataFromClipboardParams,
) => {
  const valueToPaste = params.data[0][0] ?? null
  // this cancels the paste altogether if we didn't have a value clipboard
  if (valueToPaste === null) {
    return null
  }

  return [[valueToPaste]]
}

export const processCellForExport = (
  params: AgGrid.ProcessCellForExportParams<EnrichedRowData>,
) => {
  const { context, column, value, node } = params
  const rundown = context as IRundown
  const columnId = column.getColDef().colId

  // if it's a controls cell, return a script link if it exists
  if (columnId === CONTROLS_COLUMN_ID && node?.data) {
    const row = rundown.getRow(node.data.id)
    if (row && row.scriptId) {
      const queryParams = row.blockId ? { block: row.blockId } : undefined
      return absolutePathTo(
        ROUTE_PATTERNS.scriptEditor,
        { scriptId: row.scriptId },
        { queryParams },
      )
    }
  }

  const columnSchema = rundown.schema.schema.columns.find(
    (c) => c.colId === columnId,
  )

  const transformFn = columnSchema
    ? CELL_VALUE_EXPORTER[columnSchema.rundownColumnType]
    : undefined
  if (transformFn) {
    return transformFn(value)
  }
  return value
}

// Save extra information about the contents of rows that were copied
// to the clipboard so we can paste row data into other rundowns
export const sendToClipboard = ({
  data,
  context,
}: AgGrid.SendToClipboardParams<EnrichedRowData>) => {
  const rundown = context as IRundown
  rundown.copyRows()
  navigator.clipboard.writeText(data)
}
