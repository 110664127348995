import React from 'react'

import { ThreadContext } from './ThreadContext'

export const useThreadContext = () => React.useContext(ThreadContext)

export const useKeyDown = ({
  onSubmit,
  onCancel,
}: {
  onSubmit: Callback
  onCancel: Callback
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      onSubmit()
    } else if (e.key === 'Escape') {
      e.preventDefault()
      onCancel()
    } else if (e.key === 'Tab') {
      e.preventDefault()
    }
  }

  return { handleKeyDown }
}
