import { Node as PmNode } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import { NodeTypeKey, NodeTypeMap } from '@showrunner/codex'

import { docInfoPluginKey } from '@choo-app/lib/editor/plugins/doc-info'

export const toggleElementNumbers = ({
  nodeType,
  editorView,
}: {
  nodeType: NodeTypeKey
  editorView: EditorView
}) => {
  const { doc, tr } = editorView.state
  const docInfo = docInfoPluginKey.getState(editorView.state)
  const nodeExists = docInfo?.elementNumbers[nodeType]?.nodeExists
  const shouldTurnOff = docInfo?.elementNumbers[nodeType]?.isNumbered

  const blocksToProcess: Array<{
    blockNode: PmNode
    pos: number
  }> = []

  // Collect node & position of all direct children of page nodes that
  // match the nodeType. Note that dual_dialogue gets treated like dialogue
  // for the purpose of numbering
  if (nodeExists) {
    doc.forEach((pageNode, pageOffset) => {
      pageNode.forEach((blockNode, blockOffset) => {
        // special case for dual dialogue: it matches dialogue
        const isTargetBlock =
          blockNode.type.name === nodeType ||
          (blockNode.type.name === NodeTypeMap.DUAL_DIALOGUE &&
            nodeType === NodeTypeMap.DIALOGUE)

        if (isTargetBlock) {
          blocksToProcess.push({
            blockNode,
            pos: pageOffset + blockOffset + 1,
          })
        }
      })
    })

    // now create a transaction to set/remove all values
    blocksToProcess.forEach(({ blockNode, pos }, index) => {
      const valueToSet = shouldTurnOff ? null : String(index + 1)
      tr.setNodeMarkup(pos, undefined, {
        ...blockNode.attrs,
        elementNumber: valueToSet,
      })
    })
    // Is this actually necessary
    tr.setMeta('choo', true)
    editorView.dispatch(tr)
  }
}
