import html from 'nanohtml'
import { keydownHandler } from 'prosemirror-keymap'
import { Plugin, PluginKey } from 'prosemirror-state'

import { NodeTypeMap } from '@showrunner/codex'

import { getSelectionBlock, getSelectionBlockType } from '@util'

import { getCurrentDOMNode } from '../prose-utils.js'
const { CHARACTER, DUAL_DIALOGUE } = NodeTypeMap
const characterAutocompleteKey = new PluginKey('CHARACTER_AUTOCOMPLETE_PLUGIN')
class CharacterAutocomplete {
  constructor({ mst }) {
    // for the set of all characters in the script
    this.characters = new Set()
    // for the selected character which will show up selected in the list
    // and be used to generate the suggested text for autocomplete
    this.selectedCharacter = null
    // for the characters that have a prefix or match to the text user typed
    this.matching = []
    // element references
    this.overlay = document.querySelector('.c-editor__overlay')
    this.suggestionContainer = null
    // bound methods
    this.update = this.update.bind(this)
    this.destroy = this.destroy.bind(this)
    this.triggerUpdate = this.triggerUpdate.bind(this)
    this.changeSelected = this.changeSelected.bind(this)
    this.mst = mst
  }
  /**
   * Sometimes we need to trigger update to redraw the list and suggested text.
   */
  triggerUpdate() {
    if (this.editorView) {
      this.update(this.editorView)
    }
  }
  /**
   * Looks at the text the user has typed and the currently selected character
   * from the list to get the remaining text for the autocomplete.
   *
   * @return {string} text to display as suggested ending
   */
  getSuffix() {
    const block = getSelectionBlock(this.editorView.state)
    const textContent = block.textContent.toUpperCase()
    return this.selectedCharacter.substring(textContent.length)
  }
  /**
   * Helper function to determine if there are suggetions to show
   *
   * @return {boolean} suggestions are available
   */
  hasSuggestions() {
    return this.matching && this.matching.length
  }
  /**
   * Helper function to determine if suggestions are currently showing
   *
   * @return {boolean} autocomplete is visible
   */
  isActive() {
    return this.suggestionContainer !== null
  }
  /**
   * Create the html for the suggested text that appears inline with the
   * character being typed.
   *
   * @return {object} html for suggested text
   */
  suggestionBlock(left) {
    const suffix = this.getSuffix()
    return html`
      <span
        id="char-suggestion-text"
        style="position:absolute; white-space:pre-wrap; left:${left}px;"
        class="is-light is-script-text"
        >${suffix}</span
      >
    `
  }
  /**
   * Create the html for the list of matching characters, including a click
   * handler.
   *
   * @return {object} html for character list
   */
  characterModal() {
    const selectCharacter = (e) => {
      this.selectedCharacter = e.target.innerText
      this.acceptSelected()
    }
    const selected = this.selectedCharacter
    // use the current node height to get the top position of the list so that
    // it shows up underneath the text being typed
    const node = getCurrentDOMNode(this.editorView)
    const nodeHeight = node.offsetHeight
    return html`
      <div class="o-tinymodal" style="position:absolute; top:${nodeHeight}px">
        ${this.matching.map(function (character) {
          return html`<div
            class="o-tinymodal__text ${character === selected
              ? 'o-tinymodal__text--selected'
              : ''}"
            onclick="${selectCharacter}"
          >
            ${character}
          </div>`
        })}
      </div>
    `
  }
  update(view) {
    if (view !== null) {
      this.editorView = view
    }
    if (this.editorView === null) {
      return
    }
    // clean up first
    this.destroy()
    const { state } = this.editorView
    // if we are not in a character node then get out of here
    const blockType = getSelectionBlockType(state)
    if (blockType !== CHARACTER) {
      return
    }
    if (this.hasSuggestions()) {
      // if a character hasn't been selected yet, pick the first one
      if (!this.selectedCharacter) {
        this.selectedCharacter = this.matching[0]
      }
      const { empty, to, $anchor } = view.state.selection
      const depth = $anchor.depth
      const endPos = $anchor.end(depth)
      // end of the node the selection is in
      const end = this.editorView.coordsAtPos(endPos)
      // if the cursor is not at the end of the node, get out of here and clear
      if (!empty || endPos !== to) {
        return this.clearSuggestions()
      }
      const startPos = $anchor.start(depth)
      // start of the node the selection is in
      const start = this.editorView.coordsAtPos(startPos)
      // do some calculations to place the suggestion container in the overlay
      const overlayBox = this.overlay.getBoundingClientRect()
      const charTop = Math.ceil(end.top - overlayBox.top + 1)
      const charLeft = start.left - overlayBox.left - 9
      const width = overlayBox.width - charLeft
      const containerStyle = `
        position: absolute;
        left: ${charLeft}px;
        width: ${width}px;
        top: ${charTop}px
      `

      if (!this.mst.view.isZoomed) {
        this.suggestionContainer = html`
          <div id="char-suggestions" style=${containerStyle}></div>
        `
        // set the left side of the suggested text so it follows the user text
        const left = end.left - start.left + 9
        this.suggestionContainer.appendChild(this.suggestionBlock(left))
        this.suggestionContainer.appendChild(this.characterModal())
        this.overlay.appendChild(this.suggestionContainer)
      }
    }
  }
  /**
   * Used by arrow keys to change the selected character and trigger an update
   * to change the suggested text for autocomplete
   *
   * @param {string} direction - direction to move in the list (prev or next)
   */
  changeSelected(direction) {
    // failsafe, shouldn't hit this if suggestions are not showing
    if (!this.hasSuggestions) {
      this.selectedCharacter = null
      return
    }
    // get the current index of the selected character as starting point
    let selectedIndex = this.matching.indexOf(this.selectedCharacter)
    // go to the next index and wrap to the beginning if at the end
    if (direction === 'next') {
      selectedIndex++
      if (selectedIndex === this.matching.length) {
        selectedIndex = 0
      }
      // go to the prev index and wrap to the end if at the beginning
    } else {
      selectedIndex--
      if (selectedIndex < 0) {
        selectedIndex = this.matching.length - 1
      }
    }
    // update selected character to what is at the index
    this.selectedCharacter = this.matching[selectedIndex]
    // trigger an update to get the new suggested text ending
    this.triggerUpdate()
  }
  /**
   * Used by TAB and ENTER to accept what is presented in the suggested text
   */
  acceptSelected() {
    const { tr } = this.editorView.state
    // get the current suggested autocomplete
    const suffix = this.getSuffix()
    // insert it into the transaction
    tr.insertText(suffix)
    // dispatch the transaction
    this.editorView.dispatch(tr)
    // clear suggestions because we are done
    this.removeSuggestions()
  }
  destroy() {
    // nothing to do if the suggestion container doesn't exist
    if (this.suggestionContainer === null) {
      return
    }
    this.overlay.removeChild(this.suggestionContainer)
    this.suggestionContainer = null
  }
  /**
   * Go through the script and collect the set of characters in it.
   *
   * @param {object} doc - the Prosemirror doc to parse
   * @param {object} currentBlock - the block the recent transaction is in
   * @param {string} currentContent - the text content in the current block
   */
  parseCharacters(doc, currentContent) {
    if (!doc) {
      return
    }
    // clear the set before reparsing
    this.characters.clear()
    // we are going to keep track of how many times we see content that matches
    // the current content so we can decide if it should be added to the
    // character set.  The node `eq` method can be true if two nodes have the
    // same content but are not the same exact node.
    let currentContentCount = 0
    let character
    doc.forEach((pageNode) => {
      pageNode.forEach((child) => {
        character = this.characterFromNode(child)
        // if the character is the same as the current content increment the
        // counter
        if (character) {
          if (character === currentContent) {
            currentContentCount++
          } else {
            this.characters.add(character)
          }
        }
        if (child.type.name === DUAL_DIALOGUE) {
          child.forEach((columnNode) => {
            columnNode.forEach((dualBlockNode) => {
              character = this.characterFromNode(dualBlockNode)
              if (character) {
                // if the character is the same as the current content increment
                // the counter
                if (character === currentContent) {
                  currentContentCount++
                } else {
                  this.characters.add(character)
                }
              }
            })
          })
        }
      })
    })
    // If the count is over 1 then we saw the content in nodes other than the
    // current one
    if (currentContentCount > 1) {
      this.characters.add(currentContent)
    }
  }
  /**
   * Helper function to pull the character text out of a character node
   *
   * @param {object} node - node to check for character text
   * @return {string} character text trimmed and upper cased if it exists
   */
  characterFromNode(node) {
    if (
      node.type.name === CHARACTER &&
      node.textContent &&
      node.textContent.length
    ) {
      return node.textContent.toUpperCase().trim()
    }
  }
  /**
   * Set the matching characters that get used for autocomplete
   *
   * @param {object} editorState - the state of the editor
   * @param {object} currentBlock - the block the selection is in
   */
  characterMatches(editorState, currentBlock) {
    const currentContent = currentBlock.textContent.toUpperCase()
    this.parseCharacters(editorState.doc, currentContent)
    this.matching = []
    if (currentContent === null || currentContent.length < 1) {
      return
    }
    this.characters.forEach((character) => {
      if (character.startsWith(currentContent)) {
        this.matching.push(character)
      }
    })
    // if we have matches sort them by length and select the first one
    if (this.matching.length) {
      this.matching.sort((a, b) => a.length - b.length)
      this.selectedCharacter = this.matching[0]
    }
  }
  clearSuggestions() {
    this.matching = []
    this.selectedCharacter = null
  }
  removeSuggestions() {
    this.clearSuggestions()
    this.triggerUpdate()
  }
}
/**
 * @returns {Plugin} new plugin instance
 */
function characterAutocompletePlugin({ mst }) {
  return new Plugin({
    key: characterAutocompleteKey,
    state: {
      init() {
        return new CharacterAutocomplete({ mst })
      },
      apply(tr, characterAutocomplete, oldState, newState) {
        // If this is a collab transaction do not make any changes to the
        // autocomplete list or suggestion
        const collabChange = tr.getMeta('collab$')
        if (collabChange) {
          return characterAutocomplete
        }
        const blockType = getSelectionBlockType(newState)
        // if this is not a character node we want to clear the suggestions
        if (blockType !== CHARACTER) {
          characterAutocomplete?.removeSuggestions()
          return characterAutocomplete
        }
        const newBlock = getSelectionBlock(newState)
        if (tr.docChanged) {
          characterAutocomplete?.characterMatches(newState, newBlock)
        } else {
          const oldBlock = getSelectionBlock(oldState)
          // for the scenario where it isn't a doc change but you click into
          // a different character node, we want to clear the suggestions
          if (!oldBlock?.eq(newBlock)) {
            characterAutocomplete?.removeSuggestions()
          }
        }
        return characterAutocomplete
      },
    },
    props: {
      handleKeyDown: (view, evt) => {
        const characterAutocomplete = characterAutocompleteKey.getState(
          view.state,
        )
        keydownHandler({
          ArrowDown: function () {
            if (characterAutocomplete.isActive()) {
              characterAutocomplete.changeSelected('next')
              return true
            }
          },
          ArrowUp: function () {
            if (characterAutocomplete.isActive()) {
              characterAutocomplete.changeSelected('prev')
              return true
            }
          },
          Tab: function () {
            if (characterAutocomplete.isActive()) {
              characterAutocomplete.acceptSelected()
              return true
            }
          },
          Enter: function () {
            if (characterAutocomplete.isActive()) {
              characterAutocomplete.acceptSelected()
              return false
            }
          },
          Escape: function () {
            if (characterAutocomplete.isActive()) {
              characterAutocomplete.removeSuggestions()
              return false
            }
          },
        })(view, evt)
      },
    },
    view(view) {
      const characterAutocomplete = characterAutocompleteKey.getState(
        view.state,
      )
      return {
        update: characterAutocomplete?.update,
        destroy: characterAutocomplete?.destroy,
      }
    },
  })
}
export { characterAutocompletePlugin }
