import { Menu } from '@mantine/core'

import { ExplorerView } from '@components/ExplorerView'
import { FancyMenuItem } from '@components/FancyMenuItem'

export const RefreshButton = ({ onClick }: { onClick: () => void }) => (
  <ExplorerView.HeaderButton
    tooltip="Refresh latest snapshots"
    icon="fa-arrow-rotate-right"
    onClick={onClick}
  />
)

const SNAPSHOT_FILTER_VALUES = ['all', 'manual'] as const
export type SnapshotFilter = (typeof SNAPSHOT_FILTER_VALUES)[number]
const filterLabel: Record<SnapshotFilter, string> = {
  all: 'All',
  manual: 'Manual',
}

export const FilterMenu = ({
  filter,
  onUpdateFilter,
}: {
  filter: SnapshotFilter
  onUpdateFilter: (value: SnapshotFilter) => void
}) => (
  <Menu position="bottom-start">
    <Menu.Target>
      <div>
        <ExplorerView.HeaderButton
          tooltip="Filter snapshots"
          label={filterLabel[filter]}
          dropdownCaret
        />
      </div>
    </Menu.Target>
    <Menu.Dropdown>
      {SNAPSHOT_FILTER_VALUES.map((f) => (
        <FancyMenuItem
          key={f}
          title={filterLabel[f]}
          selected={filter === f}
          onClick={() => onUpdateFilter(f)}
        />
      ))}
    </Menu.Dropdown>
  </Menu>
)
