import { Global } from '@emotion/react'

import { inkSyntaxStyles } from '@choo-app/lib/editor/plugins/syntax-highlighting/inkCssConfig'
import { InkCompileMessage } from '@ink'

import { createInlineInkStyles } from './createInlineInkStyles'

export const InlineInkStyles = ({
  messages,
}: {
  messages: InkCompileMessage[]
}) => {
  return (
    <>
      <Global styles={inkSyntaxStyles} />
      <Global styles={createInlineInkStyles(messages)} />
    </>
  )
}
