import { PageLayout } from '@util/zodSchemas/printPreferences'

import { IPrincePrintStrategy } from './types'

// universal page directive for prince printing, across any doc
// type. If we ever want to configure this per doc type, we can add it
// IPrincePrintStrategy
const buildPageDirective = (layout: PageLayout): string => {
  const { orientation, size, margins } = layout
  const { top, left, right, bottom } = margins
  const { height, width } = size
  return `
    @page {
      margin: ${top} ${right} ${bottom} ${left};
      size: ${width} ${height} ${orientation};
    }`
}

export const buildHtmlForPrince = (strategy: IPrincePrintStrategy) => `
<html>
  <head>
    ${strategy.generateHeadElements()}
    <style>
      ${buildPageDirective(strategy.generatePageLayout())}
      ${strategy.generateStyles()}
    </style>
    </head>
    <body>
      ${strategy.generateHeaderAndFooter()}
      ${strategy.generateBody()}
    </body>
</html>`
