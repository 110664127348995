import { MantineThemeColors } from '@mantine/core'
/*
  These were copied from the list of default Mantine colors then individual values have been tweaked
  to splice on our scripto colors:

  https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/default-colors.ts

  We can replace any of the individual colors in one of these defined families or we can invent a new
  family name (but we need to wire up some typescript to recognize the new key)


  NOTE: All these colors are auto-built into a scss partials file
  found at src/scss/_theme-colors.scss using a naming scheme like this

  $theme-dark-0: #c1c2c5;
  $theme-dark-1: #a6a7ab;

*/

export const BLACKISH_TEXT = '#0d0d13'

export const colors: MantineThemeColors = {
  dark: [
    '#c1c2c5',
    '#a6a7ab',
    '#909296',
    '#5c5f66',
    '#373a40',
    '#2c2e33',
    '#21222f', //  mantine-overrides background color for dark
    '#1a1b1e',
    '#141517',
    BLACKISH_TEXT, // $d2-dark-gray (default color for text in the editor)
  ],

  // took several values from _variables.scss and modified the rest of the defaults from
  // mantine to keep with equal rgb values across the boards
  gray: [
    '#f8f8f8',
    '#f2f2f2', // $l3-light-gray
    '#eeeeee', // $l2-light-gray  mantine-overrides background color for light theme
    '#d6d6d6', // $d1-light-gray
    '#cecece', // just made this and the rest up to stick with neutral grays
    '#adadad',
    '#868686',
    '#494949',
    '#343434',
    '#212529', // mantine-overrides color for light theme
  ],

  red: [
    '#fff5f5',
    '#ffe3e3',
    '#ffc9c9',
    '#ffa8a8',
    '#ff8787',
    '#f92929',
    '#ec0606',
    '#d90000',
    '#bb0000',
    '#9f0000',
  ],

  pink: [
    '#fff0f6',
    '#ffdeeb',
    '#fcc2d7',
    '#faa2c1',
    '#f783ac',
    '#f06595',
    '#e64980',
    '#d6336c',
    '#c2255c',
    '#a61e4d',
  ],

  grape: [
    '#f8f0fc',
    '#f3d9fa',
    '#eebefa',
    '#e599f7',
    '#da77f2',
    '#cc5de8',
    '#be4bdb',
    '#ae3ec9',
    '#9c36b5',
    '#862e9c',
  ],

  violet: [
    '#f1e6ff', // $l4-purple
    '#d3beee', // $l3-purple
    '#b89ade', // $l2-purple
    '#a27fd3', // $l1-purple
    '#8e66c8', // $base-purple
    '#8252c6', // $d1-purple
    '#6f3db8', // $d2-purple
    '#61369f', // $d3-purple
    '#441589', // $d4-purple
    '#5f3dc4', // original mantine value
  ],

  indigo: [
    '#edf2ff',
    '#dbe4ff',
    '#bac8ff',
    '#91a7ff',
    '#748ffc',
    '#5c7cfa',
    '#4c6ef5',
    '#4263eb',
    '#3b5bdb',
    '#364fc7',
  ],

  blue: [
    '#e7f5ff',
    '#d0ebff',
    '#a5d8ff',
    '#74c0fc',
    '#4dabf7',
    '#339af0',
    '#228be6',
    '#1c7ed6',
    '#1971c2',
    '#1864ab',
  ],

  cyan: [
    '#e3fafc',
    '#c5f6fa',
    '#99e9f2',
    '#66d9e8',
    '#3bc9db',
    '#22b8cf',
    '#15aabf',
    '#1098ad',
    '#0c8599',
    '#0b7285',
  ],

  teal: [
    '#e6fcf5',
    '#c3fae8',
    '#96f2d7',
    '#63e6be',
    '#38d9a9',
    '#20c997',
    '#12b886',
    '#0ca678',
    '#099268',
    '#087f5b',
  ],

  green: [
    '#ebfbee',
    '#d3f9d8',
    '#b2f2bb',
    '#8ce99a',
    '#69db7c',
    '#51cf66',
    '#40c057',
    '#37b24d',
    '#2f9e44',
    '#118000', // $success-color
  ],

  lime: [
    '#f4fce3',
    '#e9fac8',
    '#d8f5a2',
    '#c0eb75',
    '#a9e34b',
    '#94d82d',
    '#82c91e',
    '#74b816',
    '#66a80f',
    '#5c940d',
  ],

  yellow: [
    '#fff9db',
    '#fff3bf',
    '#ffec99',
    '#ffe066',
    '#ffd43b',
    '#fcc419',
    '#fab005',
    '#f59f00',
    '#f08c00',
    '#e67700',
  ],

  orange: [
    '#fff4e6',
    '#ffe8cc',
    '#ffd8a8',
    '#ffc078',
    '#ffa94d',
    '#ff922b',
    '#fd7e14',
    '#f76707',
    '#e8590c',
    '#833c01',
  ],
}
