export type SideLimit = {
  minHeight: number
  minWidth: number
}

export type LimitConfiguration = {
  firstChild: SideLimit
  secondChild: SideLimit
}

export const clampWidthForLimits = ({
  limits,
  dimensions,
  desiredWidth,
}: {
  limits: LimitConfiguration
  dimensions: Dimensions
  desiredWidth: number
}): number => {
  const minimumSize = limits.firstChild.minWidth
  if (desiredWidth < minimumSize) {
    return minimumSize
  }
  const maximumSize = dimensions.width - limits.secondChild.minWidth
  if (desiredWidth > maximumSize) {
    return maximumSize
  }

  return desiredWidth
}

export const clampHeightForLimits = ({
  limits,
  dimensions,
  desiredHeight,
}: {
  limits: LimitConfiguration
  dimensions: Dimensions
  desiredHeight: number
}): number => {
  const minimumSize = limits.firstChild.minHeight
  if (desiredHeight < minimumSize) {
    return minimumSize
  }
  const maximumSize = dimensions.height - limits.secondChild.minHeight
  if (desiredHeight > maximumSize) {
    return maximumSize
  }

  return desiredHeight
}

export const resizableSizeToStyle = ({
  height,
  width,
}: Dimensions): React.CSSProperties => {
  const result: React.CSSProperties = {}
  if (isFinite(height) && height > 0) {
    result.height = height
  }

  if (isFinite(width) && width > 0) {
    result.width = width
  }

  return result
}
