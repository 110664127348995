import React from 'react'

import { Dashboard } from '@components/Dashboard'
import { useMst } from '@state'

export const DashboardRoute = ({
  params,
}: {
  params: { folderId?: string }
}) => {
  const { view, rootFolders } = useMst()

  const folderId = params.folderId ?? rootFolders.sharedDashboard?.id

  React.useEffect(() => {
    if (folderId && !view.selectedFolderId) {
      view.setSelectedFolderId(folderId)
      view.expandFolderPath(folderId)
    }
    return () => {
      view.setRequestedDocument(undefined)
    }
  }, [folderId, view])

  return <Dashboard />
}
