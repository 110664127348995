import React from 'react'

import { Menu } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { StatusBar } from '@components/StatusBar'
import { useMst } from '@state'

import { showCustomZoomModal } from './CustomZoomModal'

const zooms: number[] = [0.5, 0.75, 0.9, 1, 1.25, 1.5, 2, 2.5, 3]

const makeLabel = (zoomLevel: number) => {
  const displayValue = (zoomLevel * 100).toFixed(0)
  return displayValue + '%'
}

export const ZoomMenu = observer(function ZoomMenu() {
  const { view, user } = useMst()
  const [open, setOpen] = React.useState(false)
  const maxHeight = view.dimensions.scriptScroller.height - 20

  const handleSelectZoom = (zoomLevel: number) => {
    const currentZoom = user.prefs.editorZoom ?? 1
    if (currentZoom === zoomLevel) return
    view.setEditorZoom(zoomLevel)
  }

  return (
    <Menu onChange={setOpen} opened={open} returnFocus>
      <Menu.Target>
        <StatusBar.Button
          tooltip={open ? undefined : 'Zoom'}
          onClick={() => setOpen(true)}
        >
          {makeLabel(view.editorZoom)}
        </StatusBar.Button>
      </Menu.Target>
      <Menu.Dropdown style={{ maxHeight }}>
        {zooms.map((z) => (
          <FancyMenuItem
            title={makeLabel(z)}
            key={z}
            selected={z === view.editorZoom}
            onClick={() => handleSelectZoom(z)}
          />
        ))}
        <Menu.Divider />
        <FancyMenuItem
          title="Custom..."
          selected={!zooms.includes(view.editorZoom)}
          onClick={() => {
            showCustomZoomModal({
              onConfirm: handleSelectZoom,
              currentValue: view.editorZoom * 100,
            })
          }}
        />
      </Menu.Dropdown>
    </Menu>
  )
})
