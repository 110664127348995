import { ActionIcon, Tooltip } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'

export const ClearButton = ({ onClick }: { onClick: () => void }) => (
  <Tooltip label="Clear search">
    <ActionIcon c="dark.9" onClick={onClick}>
      <FaIcon icon="fa-xmark" size="14" />
    </ActionIcon>
  </Tooltip>
)
