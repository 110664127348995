import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Stack, Text } from '@mantine/core'
import { differenceInSeconds, isValid } from 'date-fns'

import { DateTimeInput } from '@components/DateTimeInput'
import { ModalShell, useModalControls } from '@components/Modals'
import { IRundown } from '@state/types'
import { formatSeconds } from '@util'

const computeEpisodeLength = (
  startTime: Date | undefined,
  endTime: Date | undefined,
): {
  isValid: boolean
  value: number
  displayValue: string
} => {
  if (startTime && endTime && isValid(startTime) && isValid(endTime)) {
    const value = differenceInSeconds(endTime, startTime)
    const isValid = value > 0
    const displayValue = isValid
      ? `Episode length: ${formatSeconds(value)}`
      : 'Start time must be before end time'
    return {
      displayValue,
      value,
      isValid,
    }
  }

  return {
    displayValue: 'Configure start and end times',
    value: 0,
    isValid: false,
  }
}

const StartTimeEndTime = NiceModal.create(
  ({ rundown }: { rundown: IRundown }) => {
    const controls = useModalControls()

    const [startTime, setStartTime] = React.useState<Date | undefined>(
      rundown.blobData.startTime,
    )
    const [endTime, setEndTime] = React.useState<Date | undefined>(
      rundown.blobData.endTime,
    )

    const episodeLength = computeEpisodeLength(startTime, endTime)
    React.useEffect(() => {
      controls.setDisabled(!episodeLength.isValid)
    }, [episodeLength.isValid, controls])

    const handleSave = async () => {
      if (startTime && episodeLength.isValid) {
        controls.setLoading(true)
        try {
          await rundown.rootStore.doDebug()
          await rundown.updateStartTimeAndLength(startTime, episodeLength.value)
          controls.onClose()
        } catch {
          controls.setErrorMessage('Failed to save updates')
        } finally {
          controls.setLoading(false)
        }
      }
    }

    return (
      <ModalShell
        size="md"
        title="Start Time / End Time"
        confirmLabel="Save"
        cancelLabel="Cancel"
        opened={controls.opened}
        onClose={controls.onClose}
        loading={controls.loading}
        errorMessage={controls.errorMessage}
        disabled={controls.disabled}
        onConfirm={handleSave}
      >
        <Stack align="center">
          <Stack align="flex-start">
            <DateTimeInput
              value={startTime}
              onChange={setStartTime}
              label="Start time"
            />
            <DateTimeInput
              value={endTime}
              onChange={setEndTime}
              label="End time"
            />
          </Stack>
          <Text color={episodeLength.isValid ? undefined : 'red'}>
            {episodeLength.displayValue}
          </Text>
        </Stack>
      </ModalShell>
    )
  },
)

export const showStartTimeEndTimeModal = (rundown: IRundown) => {
  NiceModal.show(StartTimeEndTime, { rundown })
}
