/*
  Janky/transitional strategy to allow  doing the unauthed routes in React but the
  join/create-show routes in choo. As soon as we get the create/join workspace routes into
  react, we can retire this.

  What it's doing:

  When the app launches and no cached user credentials are found, react handles login and/or
  create account and puts the expected values into cookies. Then, this hook is called to set
  the correct url and reload the page to get the choo app to load from scratch.
*/
import { useMst } from '@state'

export const useReturnTo = ({ fallback }: { fallback: string }) => {
  const { location, setLoading } = useMst()

  const returnTo = () => {
    // we'll wind up flashing a different route here if we don't set loading to true
    setLoading(true)
    const nextPath = location.getQueryParam('returnTo') ?? fallback
    window.history.replaceState({}, '', nextPath)
    window.location.reload()
  }

  return { returnTo }
}
