import { highlightBlockInEditor } from './animation'

const EDITOR_SCROLLER_SELECTOR = "[data-editor-scroller='true']"
// This controls the gap between the toolbar and the top of the
// selected block
const SCROLL_OFFSET = 50

const findOffset = (scroller: Element, target: Element) => {
  const scrollerTop = scroller.getBoundingClientRect().top
  const scrollPos = scroller.scrollTop
  const targetTop = target.getBoundingClientRect().top
  return targetTop + scrollPos - scrollerTop - SCROLL_OFFSET
}

export const scrollToBlock = (blockId: string): { blockFound: boolean } => {
  const scroller = document.querySelector(EDITOR_SCROLLER_SELECTOR)
  const elt = document.getElementById(blockId)
  if (elt && scroller) {
    const top = findOffset(scroller, elt)
    scroller.scrollTo({
      top,
      behavior: 'smooth',
    })

    highlightBlockInEditor(elt)
    return { blockFound: true }
  }
  return { blockFound: false }
}

export const measureScrollerHeight = (): number => {
  const scroller = document.querySelector(EDITOR_SCROLLER_SELECTOR)
  const height = scroller?.getBoundingClientRect().height
  if (typeof height === 'number' && isFinite(height)) {
    return height
  }
  return 0
}
