import React from 'react'

import { Table } from '@mantine/core'

import { SmartFaIcon } from '@components/FaIcon'
import { useStaffFormats } from '@hooks'

export const FormatsList = () => {
  const { formatsQuery } = useStaffFormats()
  const { isPending, isError, data } = formatsQuery

  if (isPending) {
    return <div>loading formats</div>
  }
  if (isError) {
    return <div>error fetching formats</div>
  }

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Name</Table.Th>
          <Table.Th>id</Table.Th>
          <Table.Th>icon</Table.Th>
          <Table.Th>replaced by</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data.map(({ name, id, replacedById, icon }) => (
          <Table.Tr key={id}>
            <Table.Td>{name}</Table.Td>
            <Table.Td>{id}</Table.Td>
            <Table.Td>
              <SmartFaIcon icon={icon} fallback={<div>icon</div>} />
            </Table.Td>
            <Table.Td>{replacedById}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}
