import { isBefore } from 'date-fns'
import { types } from 'mobx-state-tree'
import { z } from 'zod'

import { BaseModel } from '@state/models/BaseModel'

export type SyncStatusLevel = 'ok' | 'warn' | 'error' | 'fatal'

const OK_THRESHOLD_SECONDS = 5
const WARN_THRESHOLD_SECONDS = 20
const MODAL_ERROR_THRESHOLD_SECONDS = 90

const toNumber = z
  .union([z.string(), z.number()])
  .transform((val) => (typeof val === 'string' ? Number(val) : val))
const positiveNumber = toNumber.pipe(z.number().int().positive())
const nonNegativeNumber = toNumber.pipe(z.number().int().min(0))

export const settingsSchema = z.object({
  okThreshold: positiveNumber,
  warnThreshold: positiveNumber,
  modalErrorThreshold: positiveNumber,
  pullDelay: nonNegativeNumber,
  pushDelay: nonNegativeNumber,
})

export type SyncSettings = z.infer<typeof settingsSchema>

export const SyncStatus = BaseModel.named('SyncStatus')
  .props({
    // This value is used to compute staleness, whenever a script is
    // out of sync, this gets updated to the clock time 1x/second
    getStepsRequired: false,
    getStepsRequiredSince: types.maybe(types.Date),
    sendStepsRequired: false,
    sendStepsRequiredSince: types.maybe(types.Date),
    settings: types.frozen<SyncSettings>({
      okThreshold: OK_THRESHOLD_SECONDS,
      warnThreshold: WARN_THRESHOLD_SECONDS,
      modalErrorThreshold: MODAL_ERROR_THRESHOLD_SECONDS,
      pullDelay: 0,
      pushDelay: 0,
    }),
    intervalId: types.maybe(types.number),
  })
  .views((self) => ({
    get inSync(): boolean {
      return !(self.sendStepsRequired || self.getStepsRequired)
    },
    // if getStepsRequired is true, return the timestamp, otherwise undefined
    get pullStaleness(): Date | undefined {
      return self.getStepsRequired ? self.getStepsRequiredSince : undefined
    },
    // ditto
    get pushStaleness(): Date | undefined {
      return self.sendStepsRequired ? self.sendStepsRequiredSince : undefined
    },
    // figure out the stalest sync value
    get stalest(): Date | undefined {
      const { pullStaleness, pushStaleness } = this
      if (!pullStaleness) {
        return pushStaleness
      }
      if (!pushStaleness) {
        return pullStaleness
      }
      return isBefore(pullStaleness, pushStaleness)
        ? pullStaleness
        : pushStaleness
    },
    get secondsOfStaleness(): number {
      const { now } = self.rootStore.appStatus
      const { stalest, inSync } = this
      if (!stalest || inSync) {
        return 0
      }
      return (now.valueOf() - stalest.valueOf()) / 1000
    },
    get status(): SyncStatusLevel {
      const { secondsOfStaleness } = this
      if (secondsOfStaleness < self.settings.okThreshold) {
        return 'ok'
      } else if (secondsOfStaleness < self.settings.warnThreshold) {
        return 'warn'
      } else if (secondsOfStaleness < self.settings.modalErrorThreshold) {
        return 'error'
      }
      return 'fatal'
    },

    get isTooStaleForSafety(): boolean {
      return this.secondsOfStaleness > self.settings.modalErrorThreshold
    },
  }))
  .actions((self) => ({
    updateSettings(settings: SyncSettings) {
      self.settings = settings
    },
    reportGetSuccess() {
      self.getStepsRequiredSince = new Date()
    },
    reportPushSuccess() {
      self.sendStepsRequiredSince = new Date()
    },
    update({
      getStepsRequired,
      sendStepsRequired,
    }: {
      getStepsRequired: boolean
      sendStepsRequired: boolean
    }) {
      const now = new Date()
      // if we just learned we need to get or send steps, mark the time
      // so we can track staleness
      if (getStepsRequired && !self.getStepsRequired) {
        self.getStepsRequiredSince = now
      }
      if (sendStepsRequired && !self.sendStepsRequired) {
        self.sendStepsRequiredSince = now
      }
      self.getStepsRequired = getStepsRequired
      self.sendStepsRequired = sendStepsRequired
    },
  }))
