import type { ServerConfig } from '@config'

export const getAvatarUrl = (
  avatar: string | null | undefined,
  config: ServerConfig,
) => {
  if (!avatar) {
    return
  }
  const { NODE_ENV, API_URL } = config

  // in production environments, we have a proxy set up
  if (NODE_ENV === 'production') {
    return `/assets/avatars/${avatar}`
  }
  // if running locally, we don't have a proxy so instead we can
  // infer/guess what s3 bucket to use based on whether the API server is
  // using SSL or not. (Note: This only affects local dev environments)
  const bucketPart = API_URL.startsWith('https') ? 'prod' : 'dev'
  return `https://s3.amazonaws.com/sr-avatars/${bucketPart}/${avatar}`
}
