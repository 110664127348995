import { Group, Text, UnstyledButton } from '@mantine/core'
import cn from 'classnames'

import { TemplateImage } from './TemplateImage'
import { TemplateWorkspaceListing } from './util'

import styles from './TemplateCard.module.scss'

export const TemplateCard = ({
  template,
  selected,
  onSelect,
}: {
  template: TemplateWorkspaceListing
  selected: boolean
  onSelect: () => void
}) => {
  return (
    <UnstyledButton
      onClick={onSelect}
      className={cn(styles.templateCard, { [styles.selected]: selected })}
    >
      <Group wrap="nowrap">
        <TemplateImage icon={template.icon} />
        <div>
          <Text fw="bold">{template.name}</Text>
          <Text c="dimmed">{template.description}</Text>
        </div>
      </Group>
    </UnstyledButton>
  )
}
