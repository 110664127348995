import React from 'react'

import { Anchor, Group, Stack, Text } from '@mantine/core'
import { useTimeout } from '@mantine/hooks'

import { FaIcon } from '@components/FaIcon'

export const BailoutLink = ({
  message,
  linkText,
  onClick,
}: {
  message: string
  linkText: string
  onClick?: () => void
}) => (
  <Stack gap={4} align="center">
    <Text size="14" c="dimmed">
      {message}
    </Text>
    <Anchor size="14" onClick={onClick}>
      {linkText}
    </Anchor>
  </Stack>
)

export const ThrottledBailoutLink = ({
  message,
  linkText,
  onClick,
  successText,
  delayMs = 3000,
}: {
  message: string
  linkText: string
  successText: string
  delayMs?: number
  onClick?: () => void
}) => {
  const [status, setStatus] = React.useState<'ready' | 'success'>('ready')

  const { start: startTimer } = useTimeout(() => {
    setStatus('ready')
  }, delayMs)

  const handleClick = () => {
    onClick?.()
    // we're just going to assume this didn't error or things get too
    // complicated. The point is to throttle the interaction a bit so users
    // don't clobber their OTPs
    setStatus('success')
    startTimer()
  }

  return (
    <Stack gap={4} align="center">
      <Text size="14" c="dimmed">
        {message}
      </Text>
      {status === 'ready' && (
        <Anchor size="14" onClick={handleClick}>
          {linkText}
        </Anchor>
      )}
      {status === 'success' && (
        <Group gap={8}>
          <Text size="14">{successText}</Text>
          <FaIcon size="14" icon="fa-check" color="green.8" />
        </Group>
      )}
    </Stack>
  )
}

export const OtpBailouts = ({
  onResendCode,
  onStartOver,
}: {
  onResendCode: () => void
  onStartOver: () => void
}) => (
  <>
    <ThrottledBailoutLink
      message="Didn't receive a verification code?"
      linkText="Resend"
      successText="Sent"
      onClick={onResendCode}
    />
    <BailoutLink
      message="Wrong email address?"
      linkText="Start over"
      onClick={onStartOver}
    />
  </>
)
