import { Group, NumberInput, Select, Stack, Text } from '@mantine/core'

import { AlignmentType } from '@showrunner/codex'

import { ILoadedScript } from '@state'
import { capitalize } from '@util'
import {
  BlockOverrides,
  DefinedSingleBlockOverride,
  FormatBlockName,
  FormatOption,
  FormatOptionValue,
} from '@util/formats'

import {
  BulkChangeHandler,
  calcLeftMarginCh,
  calcLeftMarginIn,
  calcRightMarginIn,
  calcWidthCh,
  isBlockTooNarrow,
} from './helpers'

const alignmentMap: Record<string, AlignmentType> = {
  Left: 'left',
  Right: 'right',
  Center: 'center',
}

export const LayoutInputs = ({
  script,
  unsavedOverrides,
  selectedBlock,
  onChange,
}: {
  script: ILoadedScript
  unsavedOverrides: BlockOverrides
  selectedBlock: FormatBlockName
  onChange: BulkChangeHandler
}) => {
  const getUnsavedVal = <T extends FormatOption>(
    key: T,
  ): FormatOptionValue<T> | undefined => {
    if (unsavedOverrides && unsavedOverrides[selectedBlock]) {
      const blockOverrides: DefinedSingleBlockOverride =
        unsavedOverrides[selectedBlock]
      const unsavedValue = blockOverrides[key]
      if (unsavedValue !== undefined) {
        return unsavedValue as FormatOptionValue<T>
      }
    }
  }

  const getCurrVal = <T extends FormatOption>(key: T): FormatOptionValue<T> => {
    const unsavedVal = getUnsavedVal(key)
    const currVal: FormatOptionValue<T> = script.currentFormatValue(
      selectedBlock,
      key,
    )
    return unsavedVal ?? currVal
  }

  const leftMargin = calcLeftMarginIn(getCurrVal('marginLeft'))
  const rightMargin = calcRightMarginIn(leftMargin, getCurrVal('width'))
  const invalidMargins = isBlockTooNarrow(getCurrVal('width'))

  return (
    <Stack align="start" gap={20}>
      <Stack gap={2}>
        <Text fw="bold">Text alignment</Text>
        <Select
          size="md"
          data={['Left', 'Center', 'Right']}
          value={capitalize(getCurrVal('alignment'))}
          onChange={(a) =>
            onChange(selectedBlock, { alignment: alignmentMap[a ?? 'Left'] })
          }
          inputSize="6"
        />
      </Stack>
      <Group gap={20}>
        <Stack gap={2}>
          <Text fw="bold">Line spacing</Text>
          <Select
            size="md"
            inputSize="6"
            data={['1', '1.5', '2']}
            value={String(getCurrVal('lineHeight'))}
            onChange={(a) => {
              const lineHeight = Number(a) ?? getCurrVal('lineHeight')
              onChange(selectedBlock, { lineHeight })
            }}
          />
        </Stack>
        <NumberInput
          inputSize="8"
          label="Space above"
          allowDecimal={false}
          max={30}
          min={0}
          value={getCurrVal('blockTopMargin')}
          onChange={(val) =>
            onChange(selectedBlock, { blockTopMargin: Number(val) })
          }
        />
      </Group>
      <Stack gap={5}>
        <Group gap={20}>
          <NumberInput
            inputSize="8"
            label="Left margin"
            decimalScale={1}
            step={0.5}
            error={invalidMargins}
            min={1.5}
            max={6.5}
            allowNegative={false}
            clampBehavior="strict"
            suffix='"'
            value={leftMargin}
            onChange={(rawVal) => {
              const val = Number(rawVal)
              const marginLeft = calcLeftMarginCh(val)
              const width = calcWidthCh(val, rightMargin)
              onChange(selectedBlock, { marginLeft, width })
            }}
          />
          <NumberInput
            inputSize="8"
            label="Right margin"
            decimalScale={1}
            step={0.5}
            error={invalidMargins}
            min={1}
            max={6}
            allowNegative={false}
            clampBehavior="strict"
            suffix='"'
            value={rightMargin}
            onChange={(value) => {
              const width = calcWidthCh(leftMargin, Number(value))
              onChange(selectedBlock, { width })
            }}
          />
        </Group>
        {invalidMargins && (
          <Text span c="red" size="sm">
            Blocks must be at least 1&rdquo; wide
          </Text>
        )}
      </Stack>
    </Stack>
  )
}
