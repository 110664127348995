import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import {
  Button,
  Divider,
  Group,
  NumberInput,
  Space,
  Stack,
  Text,
} from '@mantine/core'

import { ModalShell, useModalControls } from '@components/Modals'

type ModalProps = {
  onConfirm: (val: number) => void
  defaultValue: number
  currentValue: number
}

const SpaceAboveModal = NiceModal.create(
  ({ defaultValue, currentValue, onConfirm }: ModalProps) => {
    const controls = useModalControls()
    const [spaceAbove, setSpaceAbove] = React.useState(currentValue)

    const saveChanges = () => {
      onConfirm(spaceAbove)
      controls.onClose()
    }

    const isDefault = spaceAbove === defaultValue
    return (
      <ModalShell
        size={280}
        title="Space above"
        opened={controls.opened}
        onClose={controls.onClose}
        errorMessage={controls.errorMessage}
        loading={controls.loading}
      >
        <Stack align="center" justify="center">
          <NumberInput
            label="Number of lines"
            allowDecimal={false}
            allowNegative={false}
            max={30}
            min={0}
            value={spaceAbove}
            onChange={(v) => setSpaceAbove(Number(v))}
          />
        </Stack>
        {isDefault ? (
          <>
            <Text size="sm" c="gray" ml={20} lh={0}>
              Default
            </Text>
            <Space h={20} />
          </>
        ) : (
          <Button variant="subtle" onClick={() => setSpaceAbove(defaultValue)}>
            Use default space above
          </Button>
        )}
        <Divider />
        <Group gap="xs" justify="center">
          <Button type="submit" onClick={saveChanges} disabled={false}>
            Apply
          </Button>
          <Button variant="outline" onClick={controls.onClose}>
            Cancel
          </Button>
        </Group>
      </ModalShell>
    )
  },
)

export const showSpaceAboveModal = ({
  currentValue,
  defaultValue,
  onConfirm,
}: ModalProps) => {
  NiceModal.show(SpaceAboveModal, { currentValue, defaultValue, onConfirm })
}
