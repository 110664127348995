// we only call this hook from the snapshot toolbar because it scrapes
// HTML from the associated route
import React from 'react'

import { showError } from '@components/Modals'
import { useMst } from '@hooks'
import { getMergedFormatDefinition, getPrintTimestamp } from '@util'
import { MixpanelEventName } from '@util/mixpanel/eventNames'
import {
  buildPrintableDiffHtml_V2,
  buildPrintableRevisionHtml,
  buildPrintableScriptHtml,
} from '@util/princePrinting'
import { getBlobAndPrint, getBlobAndSave } from '@util/printing'
import { ScriptSnapshotPayload } from '@util/ScriptoApiClient/types'

import { useMaybeSnapshot, useScriptData } from '../useSnapshotLandData'

const EXPORT_EVENTS: { [key: string]: { [key: string]: MixpanelEventName } } = {
  download: {
    static: 'SCRIPT_VERSION_EXPORTED_PDF',
    asterisk: 'SCRIPT_REVISION_EXPORTED',
    diff: 'SIDE_BY_SIDE_EXPORTED',
  },
  print: {
    static: 'SCRIPT_VERSION_PRINTED',
    asterisk: 'SCRIPT_REVISION_PRINTED',
    diff: 'SIDE_BY_SIDE_PRINTED',
  },
}

export const useSnapshotExportMenu = ({
  scriptId,
  snap1,
  snap2,
}: {
  scriptId: string
  snap1?: string
  snap2?: string
}) => {
  const [isPrinting, setIsPrinting] = React.useState(false)
  const mst = useMst()
  const scriptQuery = useScriptData(scriptId)
  const snap1Query = useMaybeSnapshot({ scriptId, snapshotId: snap1 })
  const snap2Query = useMaybeSnapshot({ scriptId, snapshotId: snap2 })

  const { currentView, trackSnapshotEvent: trackEvent } = mst.view.snapshotLand
  const prefs = mst.user.scriptPrintPrefs
  const { data } = snap1Query
  const scriptName = scriptQuery?.data?.script.name ?? 'Script'

  const canExport =
    currentView === 'static' ? !!data : !!(data && snap2Query.data)

  const handleDownloadFdx = async () => {
    if (!snap1) return

    setIsPrinting(true)
    try {
      await mst.doDebug()
      await mst.currentOrg?.exportSnapshotToFdx({ scriptId, snapshotId: snap1 })
    } catch (e) {
      showError('Failed to create FDX')
    } finally {
      setIsPrinting(false)
    }
  }

  const handleCreatePdf = async (type: 'download' | 'print') => {
    if (!data) return

    const { fileName, html } =
      currentView === 'static'
        ? getStaticParams(data)
        : currentView === 'asterisk'
          ? getAsteriskParams(data)
          : getSideBySideParams()

    setIsPrinting(true)
    const unwatermark = mst.view.shouldUnwatermark

    const downloadFn = () =>
      mst.apiClient.passThroughPrint({ html, fileName, unwatermark })

    try {
      await mst.doDebug()
      type === 'print'
        ? await getBlobAndPrint(downloadFn)
        : await getBlobAndSave({ downloadFn, fileName })
      trackEvent(EXPORT_EVENTS[type][currentView])
    } catch (e) {
      const message = 'Failed to ' + (type === 'print' ? 'print' : 'create PDF')
      showError(message)
    } finally {
      setIsPrinting(false)
    }
  }

  function getStaticParams({ doc, scriptFormat }: ScriptSnapshotPayload) {
    const snapshotName = data?.name ?? data?.autoSave ?? 'Snapshot'
    const title = `${scriptName} - ` + snapshotName
    const fileName = title + '.pdf'

    const { definition } = scriptFormat
    const formatDefinition = getMergedFormatDefinition(doc, definition)

    const readRate = mst.currentOrg?.readRate
    const params = { doc, formatDefinition, prefs, readRate, title }
    const html = buildPrintableScriptHtml(params)
    return { html, fileName }
  }

  function getAsteriskParams({ doc, scriptFormat }: ScriptSnapshotPayload) {
    const scrapedHtml =
      document.querySelector('#prosemirror-editor')?.innerHTML ?? ''
    const title = scriptName + ' - asterisk'
    const fileName = title + '.pdf'

    const { definition } = scriptFormat
    const formatDefinition = getMergedFormatDefinition(doc, definition)

    const params = { doc, formatDefinition, prefs, title, html: scrapedHtml }
    const html = buildPrintableRevisionHtml(params)
    return { html, fileName }
  }

  function getSideBySideParams() {
    const printableElement = document.querySelector('#side-by-side-wrapper')
    const scrapedHtml = printableElement?.outerHTML ?? ''
    const title = scriptName + ' - side by side'
    const headerHtml =
      printableElement?.querySelector('.sbs-diff_titlerow')?.outerHTML ?? ''
    const fileName = title + '.pdf'

    const html = buildPrintableDiffHtml_V2({
      title,
      html: scrapedHtml,
      headerHtml,
      prefs: {
        ...prefs,
        headers: { enabled: true, showOnFirstPage: true, slots: {} },
      },
      timestamp: getPrintTimestamp(),
    })
    return { html, fileName }
  }

  return { canExport, handleDownloadFdx, handleCreatePdf, isPrinting }
}
