import { observer } from 'mobx-react-lite'

import { ReadonlyScriptBanner } from '@components/ReadOnlyBanner'
import { Toolbar } from '@components/Toolbar'
import { useDebouncedElementSize } from '@hooks'
import { InkExportMenu } from '@ink/components'
import { useMst } from '@state'
import { ButtonMode, ILoadedScript, IOrg } from '@state/types'
import { isInteger } from '@util'

import { CommentHistoryButton } from '../Comments/CommentHistoryButton'

import { CreateSnapshotButton } from './buttons/CreateSnapshot'
import { ExcludeFromTimingButton } from './buttons/ExcludeFromTiming'
import { Collaborators } from './Collaborators'
import { ElementMenu } from './ElementMenu'
import { ExtrasMenu } from './ExtrasMenu/ExtrasMenu'
import { FormattingOptions } from './Formatting'
import { NewCommentButton } from './Formatting/NewComment'
import { getItemsToShrink, ToolbarItem } from './responsiveHelpers'
import { TimingPopover } from './TimingPopover'

import styles from './EditorToolbar.module.scss'

export const EditorToolbar = observer(function EditorToolbar({
  script,
  org,
}: {
  script: ILoadedScript
  org: IOrg
}) {
  const { currentInkProject, view } = useMst()
  const { ref, width } = useDebouncedElementSize()

  const {
    collaborators,
    hasRemoteUsers,
    isEditable,
    isInk,
    isScreenplay,
    observableEditor,
    pmEditor,
  } = script

  const { docTiming } = pmEditor
  const selectionTiming = pmEditor.getSelectionTiming(script.readRate)
  const itemsToRender: ToolbarItem[] = ['COMMENT_MENU', 'SNAPSHOT_BUTTON']

  if (!isInk && !isScreenplay) {
    itemsToRender.push('TIMING_POPOVER_BUTTON')
    if (isEditable) {
      itemsToRender.push('EXCLUDE_FROM_TIMING_BUTTON')
    }
  }
  if (isEditable) {
    itemsToRender.push('FORMATTING_BUTTONS')
    if (!isInk) itemsToRender.push('ELEMENT_MENU')
  } else {
    itemsToRender.push('NEW_COMMENT_BUTTON') // bundled w/ formatting buttons in editable scripts
  }
  if (isInk) {
    itemsToRender.push('INK_EXPORT_MENU')
    itemsToRender.push('INK_PLAYER')
  } else {
    itemsToRender.push('EXTRAS_MENU')
  }
  if (hasRemoteUsers) {
    itemsToRender.push('COLLABORATORS')
  }

  const { mini, micro } = getItemsToShrink(itemsToRender, width)
  const getModeFor = (item: ToolbarItem): ButtonMode =>
    micro.includes(item) ? 'micro' : mini.includes(item) ? 'mini' : 'normal'

  if (script.inTrash) {
    return <ReadonlyScriptBanner script={script} />
  }

  return (
    <div className={styles.toolbar} ref={ref}>
      <Toolbar>
        <Toolbar.Section position="left">
          <Toolbar.ButtonGroup spacing={4}>
            {itemsToRender.includes('INK_PLAYER') && (
              <Toolbar.Button
                tooltip="Show Player Window"
                label="Play"
                icon="fa-play"
                onClick={currentInkProject?.launchPopup}
                primary
              />
            )}
            {itemsToRender.includes('ELEMENT_MENU') && observableEditor && (
              <ElementMenu
                script={script}
                observableEditor={observableEditor}
                mode={getModeFor('ELEMENT_MENU')}
              />
            )}
            {itemsToRender.includes('TIMING_POPOVER_BUTTON') && docTiming && (
              <>
                <TimingPopover
                  mode={getModeFor('TIMING_POPOVER_BUTTON')}
                  docTiming={docTiming}
                  selectionTiming={selectionTiming}
                  height={view.dimensions.scriptScroller.height}
                />
                {itemsToRender.includes('EXCLUDE_FROM_TIMING_BUTTON') &&
                  observableEditor && (
                    <ExcludeFromTimingButton
                      observableEditor={observableEditor}
                    />
                  )}
              </>
            )}
          </Toolbar.ButtonGroup>
          {itemsToRender.includes('NEW_COMMENT_BUTTON') && <NewCommentButton />}
          {itemsToRender.includes('FORMATTING_BUTTONS') && (
            <FormattingOptions mode={getModeFor('FORMATTING_BUTTONS')} />
          )}
        </Toolbar.Section>
        <Toolbar.Section position="right">
          {itemsToRender.includes('COLLABORATORS') && (
            <Collaborators
              collaborators={collaborators}
              mode={getModeFor('COLLABORATORS')}
            />
          )}
          {isInteger(script.totalCommentCount) && (
            <CommentHistoryButton
              script={script}
              mode={getModeFor('COMMENT_MENU')}
              count={script.totalCommentCount ?? 0}
              disableSlack={org.isUnpaid}
            />
          )}
          <CreateSnapshotButton
            script={script}
            mode={getModeFor('SNAPSHOT_BUTTON')}
          />
          {itemsToRender.includes('EXTRAS_MENU') && <ExtrasMenu />}
          {itemsToRender.includes('INK_EXPORT_MENU') && (
            <InkExportMenu mode={getModeFor('INK_EXPORT_MENU')} />
          )}
        </Toolbar.Section>
      </Toolbar>
      <ReadonlyScriptBanner script={script} />
    </div>
  )
})
