import React from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { Resizable, ResizeCallbackData } from 'react-resizable'

import { useDebouncedElementSize } from '@hooks'
import { SplitEditorPref } from '@state/types'

import { DragHandle } from './DragHandle'
import {
  clampHeightForLimits,
  clampWidthForLimits,
  LimitConfiguration,
  resizableSizeToStyle,
} from './helpers'

import styles from './SideBySide.module.scss'

export type SideBySideProps = {
  layout: SplitEditorPref
  children: [React.ReactNode, React.ReactNode]
  // restrictions on how small each side can get
  limits: LimitConfiguration
  // what's stored as the desired dimensions for the resizable childe
  preferredHeight?: number
  preferredWidth?: number
  onResizeStart?: () => void
  onResizeEnd?: (size: Partial<Dimensions>) => void
  disabled?: boolean
}

export const SideBySide = observer(function SideBySide({
  children,
  onResizeStart,
  onResizeEnd,
  layout,
  limits,
  preferredHeight,
  preferredWidth,
  disabled,
}: SideBySideProps) {
  const firstChild = children[0]
  const secondChild = children[1]
  const [dragging, setDragging] = React.useState(false)
  const { ref, ...dimensions } = useDebouncedElementSize()
  const orientationStyle = layout === 'rows' ? styles.rows : styles.columns

  const [resizableHeight, setResizableHeight] = React.useState(0)
  const [resizableWidth, setResizableWidth] = React.useState(0)
  const desiredHeight = preferredHeight ?? dimensions.height / 2
  const desiredWidth = preferredWidth ?? dimensions.width / 2

  const width = layout === 'columns' ? resizableWidth : Infinity
  const height = layout === 'rows' ? resizableHeight : Infinity

  // auto-adapt to preferred sizes and available size restrictions
  React.useEffect(() => {
    if (dragging) {
      return
    }
    if (layout === 'rows' && dimensions.height > 0) {
      setResizableHeight(
        clampHeightForLimits({ limits, dimensions, desiredHeight }),
      )
    } else if (layout === 'columns' && dimensions.width > 0) {
      setResizableWidth(
        clampWidthForLimits({
          limits,
          dimensions,
          desiredWidth,
        }),
      )
    }
  }, [desiredHeight, desiredWidth, dimensions, dragging, layout, limits])

  const handleResizeStop = React.useCallback(
    (requestedSize: Dimensions) => {
      setDragging(false)
      if (layout === 'columns') {
        const desiredWidth = requestedSize.width
        const allowedWidth = clampWidthForLimits({
          limits,
          dimensions,
          desiredWidth,
        })
        if (desiredWidth !== allowedWidth) {
          setResizableWidth(allowedWidth)
        }
        onResizeEnd?.({ width: allowedWidth })
      } else if (layout === 'rows') {
        const desiredHeight = requestedSize.height
        const allowedHeight = clampHeightForLimits({
          limits,
          dimensions,
          desiredHeight,
        })
        if (desiredHeight !== allowedHeight) {
          setResizableHeight(allowedHeight)
        }
        onResizeEnd?.({ height: allowedHeight })
      }
    },
    [dimensions, layout, limits, onResizeEnd],
  )

  const handleResize = React.useCallback(
    (e: React.SyntheticEvent, data: ResizeCallbackData) => {
      if (layout === 'columns') {
        setResizableWidth(data.size.width)
      } else {
        setResizableHeight(data.size.height)
      }
    },
    [layout],
  )

  // if there's no second child, skip the resizable wrapper
  // and treat it like "rows" to get full width
  if (!secondChild) {
    return (
      <div className={cn(styles.sideBySide, styles.rows, styles.childWrapper)}>
        {firstChild}
      </div>
    )
  }

  return (
    <div ref={ref} className={cn(styles.sideBySide, orientationStyle)}>
      <div className={styles.reactResizableWrapper}>
        <Resizable
          className={cn(styles.reactResizable, orientationStyle)}
          width={width}
          height={height}
          handle={
            <DragHandle
              layout={layout}
              dragging={dragging}
              disabled={disabled}
            />
          }
          onResizeStart={() => {
            onResizeStart?.()
            setDragging(true)
          }}
          onResizeStop={(e, { size }) => handleResizeStop(size)}
          onResize={handleResize}
          draggableOpts={{ disabled }}
        >
          <div
            className={cn(styles.childWrapper, orientationStyle)}
            style={resizableSizeToStyle({ height, width })}
          >
            {firstChild}
          </div>
        </Resizable>
      </div>
      <div
        className={cn(styles.childWrapper, {
          [styles.blockPointerEvents]: dragging,
        })}
      >
        {secondChild}
      </div>
    </div>
  )
})
