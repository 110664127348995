import { EditorState, Plugin, PluginKey } from 'prosemirror-state'
import { DecorationSet } from 'prosemirror-view'

import {
  createPaginationBreakdown,
  PaginationBreakdown,
} from '@util/pagination'

import { getConfigData } from '../configData'

import { buildDesiredDecorations } from './decorations'

export type PageBreakPluginState = {
  data: PaginationBreakdown
  decorations: DecorationSet
} | null

export const inlinePagebreaksKey = new PluginKey<PageBreakPluginState>(
  'inlinePageBreaks',
)

const getEnabled = (editorState: EditorState): boolean => {
  const { paginationType } = getConfigData(editorState)
  return paginationType !== 'structural'
}

export const inlinePageBreaksPlugin = () => {
  return new Plugin<PageBreakPluginState>({
    key: inlinePagebreaksKey,
    state: {
      init(config, editorState) {
        const isEnabled = getEnabled(editorState)
        if (!isEnabled) {
          return null
        }

        const data = createPaginationBreakdown(
          editorState.doc,
          getConfigData(editorState).currentBlockFormats,
        )
        const decorations = DecorationSet.create(
          editorState.doc,
          buildDesiredDecorations(data),
        )
        return { data, decorations }
      },

      apply(tr, pluginState, oldState, newState) {
        const isEnabled = getEnabled(newState)
        if (!isEnabled) {
          return null
        }

        const docEqual = oldState.doc.eq(newState.doc)
        const newlyEnabled = pluginState === null

        if (docEqual && !newlyEnabled) {
          return pluginState
        }

        const data = createPaginationBreakdown(
          newState.doc,
          getConfigData(newState).currentBlockFormats,
        )
        const decorations = DecorationSet.create(
          newState.doc,
          buildDesiredDecorations(data),
        )
        return {
          data,
          decorations,
        }
      },
    },
    props: {
      decorations(editorState) {
        const pluginState = inlinePagebreaksKey.getState(editorState)
        if (pluginState) {
          return pluginState.decorations
        }
        return null
      },
    },
  })
}
