import { Stack, Text } from '@mantine/core'

import { NavAnchor } from '@components/NavAnchor'

import { OnboardingPage } from './OnboardingPage'
import styles from './OnboardingPage/OnboardingLinks.module.scss'

const UnauthedAcceptedInviteMessage = () => (
  <Stack>
    <Text ta="left">
      The invitation link you used to launch this page was already accepted.
    </Text>
    <Text ta="left">
      If you&apos;ve already created a Scripto account,
      <br />
      <NavAnchor className={styles.onboardingLink} href="/login">
        try signing in
      </NavAnchor>
      .
    </Text>
  </Stack>
)

const AuthedAcceptedInviteMessage = ({ email }: { email: string }) => (
  <Stack>
    <Text ta="left">Hi {email}</Text>
    <Text ta="left">
      The invitation link you used to launch this page was already accepted. Ask
      the workspace admin to send you a new invitation.
    </Text>
    <Text ta="left">
      Or try&nbsp;
      <NavAnchor className={styles.onboardingLink} href="/logout">
        signing in
      </NavAnchor>
      &nbsp;to Scripto with a different account.
    </Text>
  </Stack>
)

const BadInviteMessage = () => (
  <Stack>
    <Text ta="left">We can&apos;t find that invitation.</Text>
    <Text ta="left">
      Try clicking the link from your email again or ask the Scripto workspace
      admin to send you a new one.
    </Text>
  </Stack>
)

export const InviteErrorPage = ({
  inviteStatus,
  loggedInUserEmail,
}: {
  loggedInUserEmail?: string
  inviteStatus: 'invalid' | 'accepted'
}) => {
  const message =
    inviteStatus === 'invalid' ? (
      <BadInviteMessage />
    ) : loggedInUserEmail ? (
      <AuthedAcceptedInviteMessage email={loggedInUserEmail} />
    ) : (
      <UnauthedAcceptedInviteMessage />
    )

  const title =
    inviteStatus === 'invalid' ? 'Invalid Link' : 'Invitation Accepted'

  return (
    <OnboardingPage title={title}>
      <OnboardingPage.Buttons
        includedButtons={loggedInUserEmail ? ['logout'] : ['login', 'signup']}
      />
      <OnboardingPage.Info>{message}</OnboardingPage.Info>
      <OnboardingPage.Links types={['support']} />
    </OnboardingPage>
  )
}
