import { Command } from 'prosemirror-state'

import { getConfigData } from '@choo-app/lib/editor/plugins/configData'

import { pageBreak } from './pageBreak'

export { handleEnter } from './enter'

export const insertPageBreak: Command = (state, dispatch) => {
  const { paginationType } = getConfigData(state)
  if (paginationType === 'inline') {
    return pageBreak.create(state, dispatch)
  }
  return false
}

export const removePageBreak: Command = (state, dispatch) => {
  const { paginationType } = getConfigData(state)
  if (paginationType === 'inline') {
    return pageBreak.remove(state, dispatch)
  }
  return false
}
