import { chainCommands, joinDown, joinUp, lift } from 'prosemirror-commands'
import { undoInputRule } from 'prosemirror-inputrules'
import { keymap } from 'prosemirror-keymap'
import { EditorState } from 'prosemirror-state'

import { handleEnter, removePageBreak, retrieveCommentIds } from '@util'

import { PluginFactory } from '../types'

import { insertHardBreak } from './commands'
import { selectAlmostAll } from './select-almost-all'
import { getTabHandler } from './tab'

/**
 * Create a plugin to handle editor-specific key bindings.
 * Must be declared before other keymaps to intercept user actions first.
 * @param {object} config - plugin config
 * @param {function} config.emit - event emitter
 * @param {object} config.script - script object from backend
 * @param {string} config.script.type - script doc type
 */

export const editorKeymapPlugin: PluginFactory = ({ mst, script }) => {
  const { type } = script
  const handleTab = getTabHandler(type)

  const cutShortcut = (viewState: EditorState) => {
    mst.currentScript?.setCutComments(retrieveCommentIds(viewState))
    // carry on either way
    return false
  }
  const keys = {
    // fun fact: on a mac the delete key is actually backspace (unless you hold down fn)
    // note that if neither of these commands fire, the base-keymap will handle (for deleting
    // the selection, etc.
    Backspace: chainCommands(removePageBreak, undoInputRule),
    'Alt-ArrowUp': joinUp,
    'Alt-ArrowDown': joinDown,
    'Mod-BracketLeft': lift,
    // Enter & Friends
    Enter: handleEnter,
    'Shift-Enter': insertHardBreak,
    // NOTE: all keydown handlers below are overriding browser defaults.
    // this may prove to be a bad idea! so says nate.
    // TAB!!!
    // NOTE: tab and shift+tab are used for accessibility
    Tab: handleTab(false),
    'Shift-Tab': handleTab(true),
    'Mod-x': cutShortcut,
    'Mod-a': selectAlmostAll,
  }
  return keymap(keys)
}
