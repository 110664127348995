import greenLogo from '@assets/images/favicons/favicon-green.png'
import orangeLogo from '@assets/images/favicons/favicon-orange.png'
import { getServerConfig, KnownServer } from '@config'

const env = getServerConfig()
// set by React
const { NODE_ENV } = process.env

const isDevBuild = NODE_ENV === 'development'

export const getHttpTimeoutMs = (): number => env.HTTP_TIMEOUT_MS || 30_000

export const exposeDevTools = () =>
  isDevBuild || !!localStorage.getItem('devtopia')

export const getAvatarRoot = () => {
  if (isDevBuild) {
    // use prod avatars if using a prod server
    return env.API_URL.includes('https://')
      ? 'https://s3.amazonaws.com/sr-avatars/prod/'
      : 'https://s3.amazonaws.com/sr-avatars/dev/'
  }
  return '/assets/avatars/'
}

export const getFaKitRoot = () => {
  // use prod avatars if using a prod server
  return isDevBuild ? 'https://fontawesome-kits.s3.amazonaws.com/' : '/fa-kit/'
}

// for NON-PRODUCTION servers we return a custom logomark
// return undefined for prod to stick with the default
export const getEnvLogoOverride = (name: KnownServer): string | undefined => {
  if (name === 'staging') {
    return orangeLogo
  }

  if (name !== 'production') {
    return greenLogo
  }
}
