import { Radio, Stack } from '@mantine/core'

import { usePendingSearchContext } from '../useSearchContext'

import styles from './IncludeRadio.module.scss'

const TITLE_ONLY = 'Title only'
const ALL_MATCHING = 'Entire document'

export const IncludeRadio = () => {
  const context = usePendingSearchContext()
  return (
    <Stack gap={6}>
      <Radio.Group
        classNames={{ label: styles.includeRadio_label }}
        label="Search within"
        value={
          context.pendingCriteria.includeTitleOnly ? TITLE_ONLY : ALL_MATCHING
        }
        onChange={() => {
          context.mergePendingCriteria({
            includeTitleOnly: !context.pendingCriteria.includeTitleOnly,
          })
        }}
      >
        <Stack gap={6}>
          <Radio value={ALL_MATCHING} label={ALL_MATCHING} />
          <Radio value={TITLE_ONLY} label={TITLE_ONLY} />
        </Stack>
      </Radio.Group>
    </Stack>
  )
}
