import {
  ActionIcon,
  Group,
  Indicator,
  SegmentedControl,
  Tooltip,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@hooks'

export const SnapshotFilter = observer(function HistoryFilter({
  scriptId,
}: {
  scriptId: string
}) {
  const { hasNewer, fetchSnapshots, clearCachedData, filter, setFilter } =
    useMst().view.snapshotLand

  // when new snapshots are available and user clicks
  // the refresh button, we clear the old cache and start at the top
  const refreshHistory = () => {
    clearCachedData()
    fetchSnapshots(scriptId)
  }

  return (
    <Group gap={4} wrap="nowrap">
      {hasNewer && (
        <Tooltip label="New snapshots available" position="bottom">
          <ActionIcon variant="subtle" onClick={refreshHistory} color="dark">
            <Indicator color="red" size={6} offset={-1}>
              <FaIcon icon="fa-arrow-rotate-left" />
            </Indicator>
          </ActionIcon>
        </Tooltip>
      )}
      <SegmentedControl
        style={{ flexShrink: 0 }}
        value={filter}
        onChange={(value) => {
          setFilter(value === 'manual' ? 'manual' : 'all')
        }}
        size="xs"
        data={[
          {
            value: 'all',
            label: 'All',
          },
          {
            value: 'manual',
            label: 'Manual',
          },
        ]}
      />
    </Group>
  )
})
