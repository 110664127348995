import isUUID from 'is-uuid'

import { util } from '@showrunner/codex'
import { schemas } from '@showrunner/scrapi'

import { FormatBlockName } from '@util/formats'
import {
  BlockContext,
  BracketImportRule,
  isBracketRule,
  isSlugRule,
  RowDataInput,
  RowImportRule,
  SimpleImportRule,
  SlugImportRule,
} from '@util/rundowns'

import { buildScriptBreakdown } from './prosemirrorHelpers'
import { ROW_LEVEL_BLOB_FIELD, RundownSchema } from './rundowns'
import { BlobData, ScriptPayload } from './ScriptoApiClient/types'
import { trimAndRemoveBrackets } from './scripts'

const IMPORTABLE_BLOCK_TYPES = schemas.rundownFormats.ImportableBlock.options

const extractBlockId = (summary: util.BlockSummaryBase): string | null => {
  const blockId = summary.id
  if (typeof blockId === 'string' && isUUID.v4(blockId)) {
    return blockId
  }
  return null
}

const splitBracketPrefix = (
  text: string,
  timeString?: string,
): {
  prefix?: string
  remainder: string
} => {
  const firstColonPos = text.indexOf(':')
  if (firstColonPos > 0 && firstColonPos < 20) {
    const candidate = {
      prefix: text.substring(0, firstColonPos).trim(),
      remainder: text.substring(firstColonPos + 1).trim(),
    }

    // we need to make sure the candidiate prefix is non-empty and
    // that we didn't break into a timing string by accident
    const timeStringOk =
      !timeString || candidate.remainder.indexOf(timeString) > -1
    if (timeStringOk && candidate.prefix.length > 0) {
      return candidate
    }
  }
  return {
    remainder: text,
  }
}

const extractBracketBlob = (
  rule: BracketImportRule,
  summary: util.BracketSummary,
): BlobData => {
  const embeddedTimeString = summary.timing.timeString?.text

  const { prefix, remainder } = splitBracketPrefix(
    trimAndRemoveBrackets(summary.text),
    embeddedTimeString,
  )

  const result: BlobData = {}
  result[rule.contentColumnId] = remainder

  if (prefix) {
    result[rule.bracketTypeColumnId] = prefix
  }

  if (embeddedTimeString) {
    result[rule.timeColumnId] = summary.timing.seconds
  }

  return result
}

const extractSimpleBlob = (
  rule: SimpleImportRule,
  summary: util.BlockSummary,
): BlobData => ({
  [rule.columnId]: summary.text,
})

const isZerothSlugSummary = (summary: util.BlockSummary): boolean => {
  return util.isSlugSummary(summary) && isUUID.nil(String(summary.id))
}

const extractSlugBlob = (
  rule: SlugImportRule,
  summary: util.SlugSummary,
): BlobData => {
  return {
    [rule.columnId]: summary.text,
    [rule.durationColumnId]: summary.durationSeconds,
  }
}

const extractRuleBlob = (
  rule: RowImportRule,
  blockSummary: util.BlockSummary,
): BlobData => {
  if (isSlugRule(rule)) {
    if (
      util.isSlugSummary(blockSummary) &&
      !isZerothSlugSummary(blockSummary)
    ) {
      return extractSlugBlob(rule, blockSummary)
    }
  } else if (isBracketRule(rule)) {
    if (util.isBracketSummary(blockSummary)) {
      return extractBracketBlob(rule, blockSummary)
    }
  } else {
    return extractSimpleBlob(rule, blockSummary)
  }
  return {}
}

const extractElementNumberBlob = (
  { elementNumberColumnId }: RowImportRule,
  { elementNumber }: util.BlockSummary,
): StringMap | undefined => {
  if (elementNumberColumnId && elementNumber) {
    return { [elementNumberColumnId]: elementNumber }
  }
}

const extractContextBlob = (
  rule: RowImportRule,
  context: BlockContext,
): BlobData => {
  const result: BlobData = {}
  const { character, slug, sceneHeading, new_act } = rule.contextColumns ?? {}
  if (character && context.character) {
    result[character] = context.character.text
  }
  if (slug && context.slug) {
    result[slug] = context.slug.text
  }
  if (sceneHeading && context.sceneHeading) {
    result[sceneHeading] = context.sceneHeading.text
  }
  if (new_act && context.new_act) {
    result[new_act] = context.new_act.text
  }
  return result
}

const updateContext = (
  context: BlockContext,
  summary: util.BlockSummary,
): BlockContext => {
  switch (summary.elementType) {
    case 'slug':
      context.slug = summary
      break
    case 'sceneHeading':
      context.sceneHeading = summary
      break
    case 'character':
      context.character = summary
      break
    case 'new_act':
      context.new_act = summary
      context.slug = undefined
      context.sceneHeading = undefined
      break
    default:
      break
  }
  return context
}

export const rowDataFromScript = ({
  payload,
  schema,
}: {
  payload: ScriptPayload
  schema: RundownSchema
}): RowDataInput[] => {
  const result: RowDataInput[] = []
  const context: BlockContext = {}
  const { importRules } = schema
  const breakdown = buildScriptBreakdown(payload)

  const shouldUppercase = (nodeType: string) =>
    payload.scriptFormat.definition.blocks[nodeType as FormatBlockName]
      ?.uppercase

  const summaries = breakdown
    .getBlockSummaries(IMPORTABLE_BLOCK_TYPES)
    .filter(({ text }) => text.length > 0)
    .map((n) =>
      shouldUppercase(n.elementType) ? { ...n, text: n.text.toUpperCase() } : n,
    )

  // iterate through all blocks- we update the context object for each block
  // but then create a row only for the ones configured in the schema
  // (example: a schema might only import dialogue blocks but might want the
  // character context information in those rows)
  summaries.forEach((blockSummary) => {
    updateContext(context, blockSummary)
    const rule = importRules.rows.find(
      (r) => r.nodeType === blockSummary.elementType,
    )
    if (!rule || isZerothSlugSummary(blockSummary)) {
      return
    }

    const blockId = extractBlockId(blockSummary)
    const linkedScriptId = blockId !== null ? payload.id : null

    const blobData: BlobData = {
      ...extractContextBlob(rule, context),
      ...extractRuleBlob(rule, blockSummary),
      ...extractElementNumberBlob(rule, blockSummary),
    }

    if (rule.rowLevel) {
      blobData[ROW_LEVEL_BLOB_FIELD] = rule.rowLevel
    }

    const rowData: RowDataInput = {
      blockId,
      linkedScriptId,
      rowTypeId: rule.rowTypeId ?? 'element',
      blobData,
    }
    result.push(rowData)
  })

  return result
}
