import { Button } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { showError, showTransferOwnershipModal } from '@components/Modals'
import { useMst } from '@state'
import { IOrgMember } from '@state/types'

import { openRemoveUserModal } from '../util'

import styles from './MemberAction.module.scss'

export const MemberAction = observer(function MemberAction({
  member,
}: {
  member: IOrgMember
}) {
  const { currentOrg, user: currentUser } = useMst()
  const isOrgPrivateEnabled = currentOrg?.hasPrivateScriptsEnabled

  const removeMember = async (userId: string) => {
    try {
      if (userId === currentUser.id) {
        await member.removeSelf()
        // nuclear option to assign another default show or hint to create
        window.location.replace(window.location.origin)
      } else {
        await member.remove()
      }
    } catch (e) {
      showError({ message: 'Failed to update user' })
    }
  }

  const memberCount: number = currentOrg?.members.length ?? 0
  if (
    member.role === 'Owner' &&
    currentUser.isCurrentOrgOwner &&
    memberCount > 1
  ) {
    return (
      <Button
        className={styles.memberAction_button}
        variant="subtle"
        onClick={() => {
          if (currentOrg) showTransferOwnershipModal(currentOrg)
        }}
      >
        Transfer Ownership
      </Button>
    )
  } else if (currentUser.id === member.id && !currentUser.isCurrentOrgOwner) {
    return (
      <Button
        className={styles.memberAction_button}
        variant="subtle"
        color="red"
        onClick={() =>
          openRemoveUserModal({
            member,
            isUserSelf: true,
            isOrgPrivateEnabled,
            onConfirm: removeMember,
          })
        }
        loading={member.isRemoving}
      >
        Leave Workspace
      </Button>
    )
  } else if (currentUser.isCurrentOrgAdmin && member.role !== 'Owner') {
    return (
      <Button
        className={styles.memberAction_button}
        variant="subtle"
        color="red"
        onClick={() =>
          openRemoveUserModal({
            member,
            isUserSelf: false,
            isOrgPrivateEnabled,
            onConfirm: removeMember,
          })
        }
        loading={member.isRemoving}
      >
        Remove Member
      </Button>
    )
  }
  return null
})
