import React from 'react'

import cn from 'classnames'

import grayface from '../../assets/images/grayface.svg'

import styles from './Avatar.module.scss'

type AvatarProps = {
  avatarUrl?: string
  statusIndicator?: 'online' | 'offline'
  mode?: 'user_menu' | 'profile'
}

export function Avatar({
  avatarUrl = grayface,
  statusIndicator,
  mode = 'user_menu',
}: AvatarProps) {
  const dotClasses = cn(styles.dot, {
    [styles.online_dot]: statusIndicator === 'online',
  })
  const wrapperClasses = cn(styles.avatar_wrapper, {
    [styles.avatar_wrapper___profile]: mode === 'profile',
  })

  const imageClasses = cn(styles.avatar_image, {
    [styles.avatar_image___profile]: mode === 'profile',
  })

  // use the grayface URL if the user doesn't have an avatar or if there's an
  // error trying to retrieve the user's avatar
  const [validAvatar, setValidAvatar] = React.useState<string>(avatarUrl)
  const handleAvatarError = () => {
    setValidAvatar(grayface)
  }
  // If the avatarUrl changes, update the validAvatar value
  React.useEffect(() => setValidAvatar(avatarUrl), [avatarUrl])

  return (
    <div className={wrapperClasses}>
      <img
        src={validAvatar}
        className={imageClasses}
        onError={handleAvatarError}
      />
      {statusIndicator && <div className={dotClasses} />}
    </div>
  )
}
