import React from 'react'

import type * as AgGrid from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { useMst } from '@state'
import { IRundown } from '@state/types'
import { EnrichedRowData } from '@util/rundowns'

import { DEFAULT_COLUMN_DEF, getPrintColumnDefs } from '../RundownGrid/columns'
import { getRowId } from '../RundownGrid/eventHandlers'
import {
  PAGE_BREAK_BLOB_KEY,
  rowClassRules,
} from '../RundownGrid/helpers/rowClassRules'

import './PrintableRundownGrid.scss'

/*
  Q: Why is there a <table> in this component?

  A: In order to get the grid header to print on every page, we're using the technique
  described here. This seems to do the trick everywhere except Safari (which doesn't support
  the @page directive).

  https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a

*/

export type PrintableRundownGridProps = {
  rundown: IRundown
  onPrintReady?: () => void
  onWidthChange?: () => void
}

export type ColumnConfigs = Array<{
  colId: string
  width: number
}>

const PrintableRundownGridWithRef = (
  { rundown, onWidthChange, onPrintReady }: PrintableRundownGridProps,
  printRef: React.LegacyRef<HTMLDivElement>,
) => {
  const [gridReady, setGridReady] = React.useState(false)
  const { user } = useMst()
  // The column definition for ag-grid needs to be a stable value by reference
  // Because the available columns and their order don't change once the print
  // modal is opened, we can compute this once when the component is first
  // instantiated. This avoids ag-grid resetting its internal state and causing
  // flushSync warnings, while still allowing the printColumnPrefs state to be
  // updated when columns are resized and omitted
  const colDefs = React.useMemo(
    () => getPrintColumnDefs({ rundown }),
    [rundown],
  )
  const editable = rundown.isEditable

  const handleColumnEvent = (e: AgGrid.ColumnEvent) => {
    rundown.saveColumnState(e.api.getColumnState(), 'print')
    onWidthChange?.()
  }

  const handleDoubleClick = ({
    node,
  }: AgGrid.CellDoubleClickedEvent<EnrichedRowData>) => {
    const rowId = node.data?.id
    if (typeof rowId === 'number') {
      rundown.updateRowBlobData({
        rowId,
        columnKey: `blobData.${PAGE_BREAK_BLOB_KEY}`,
        value: !rundown
          .getRow(rowId)
          ?.getBlobValue(`blobData.${PAGE_BREAK_BLOB_KEY}`),
      })
    }
  }

  React.useEffect(() => {
    if (gridReady) {
      rundown.printableGridRef.current?.api.redrawRows()
    }
  }, [gridReady, rundown, user.rundownPrintPrefs.rowDensity])

  return (
    <div ref={printRef} className="printable-rundown-grid">
      <div
        className={cn('ag-theme-alpine', user.rundownPrintPrefs.rowDensity)}
        key={rundown.id}
      >
        <AgGridReact
          ref={rundown.printableGridRef}
          onGridReady={() => setGridReady(true)}
          onGridSizeChanged={onWidthChange}
          onFirstDataRendered={() => {
            // "FirstDataRendered" should be the correct event — it's fired when
            // the first render of the grid is complete — but in practice it
            // happens before wrapped, autoHeight rows have been calculated.
            if (onPrintReady) {
              // We can delay a bit to give the browser a chance to render
              // first.
              //
              // TODO: remove timeout when this issue has a resolution:
              // https://github.com/ag-grid/ag-grid/issues/9353
              setTimeout(onPrintReady, 500)
            }
          }}
          rowClassRules={rowClassRules}
          getRowId={getRowId}
          context={rundown}
          columnDefs={colDefs}
          rowData={rundown.rowDataForGrid}
          domLayout="print"
          suppressContextMenu
          onCellDoubleClicked={editable ? handleDoubleClick : undefined}
          defaultColDef={DEFAULT_COLUMN_DEF}
          onColumnVisible={handleColumnEvent}
          onColumnResized={(e) => {
            if (e.finished && handleColumnEvent) {
              handleColumnEvent(e)
            }
          }}
        />
      </div>
    </div>
  )
}

export const PrintableRundownGrid = observer(
  React.forwardRef(PrintableRundownGridWithRef),
)
