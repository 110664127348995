import { ChangeEvent, useState } from 'react'

import {
  Avatar,
  Button,
  Card,
  Container,
  Group,
  Space,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { showAlert, showError } from '@components/Modals'
import { useMst } from '@state'
import { getAvatarUrl } from '@util'

import { PlanInfo } from './PlanInfo'
import { StatusInfo } from './StatusInfo'

import styles from './OrgSettings.module.scss'

export const OrgSettings = observer(function OrgSettings() {
  const { user, currentOrg, environment } = useMst()

  const [orgData, setOrgData] = useState<{
    validName: boolean
    dirty: boolean
    name: string
  }>({
    dirty: false,
    validName: false,
    name: currentOrg?.name ?? '',
  })

  if (!currentOrg) return <></>
  const reset = () => {
    setOrgData({
      dirty: false,
      validName: false,
      name: currentOrg.name,
    })
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (orgData.validName && orgData.dirty) {
      try {
        await currentOrg.update({ name: orgData.name })
        showAlert({
          title: 'Success',
          children: 'Your changes have been saved',
        })
      } catch (e) {
        showError({ message: 'Failed to rename workspace' })
      }
      reset()
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrgData({
      dirty: currentOrg.name !== e.target.value,
      validName: e.target.value.trim().length > 0,
      name: e.target.value,
    })
  }

  const showName = user.isCurrentOrgOwner ? (
    <form onSubmit={submit}>
      <Group>
        <TextInput
          type="text"
          className={styles.settings_input}
          value={orgData.name}
          onChange={handleChange}
        />
        <Button
          type="submit"
          loading={currentOrg.isUpdating}
          disabled={!orgData.validName}
        >
          Save changes
        </Button>
        <Button onClick={reset} variant="subtle" disabled={!orgData.validName}>
          Cancel
        </Button>
      </Group>
    </form>
  ) : (
    <Text>{currentOrg.name}</Text>
  )

  return (
    <Container fluid className={styles.settings_container}>
      <div>
        <h1>Workspace Settings</h1>
        <Stack gap="sm">
          <Text fw="bold">Workspace name</Text>
          <Card>{showName}</Card>
        </Stack>
        <Space h="sm" />
        <Stack gap="sm">
          <Text fw="bold">Workspace owner</Text>
          <Card>
            <Group>
              <Avatar
                size={50}
                src={getAvatarUrl(currentOrg.owner.avatar, environment.config)}
                radius={50}
              />
              <Stack gap={0}>
                <Text>{currentOrg.owner.name}</Text>
                <Text c="dimmed">{currentOrg.owner.email}</Text>
              </Stack>
            </Group>
          </Card>
        </Stack>
        <Space h="sm" />
        <PlanInfo org={currentOrg}></PlanInfo>
        <Space h="sm" />
        <StatusInfo org={currentOrg} />
      </div>
    </Container>
  )
})
