import { SyntaxRuleConfig } from './helpers'

/*
  NOTE:  It's important that the regexes END with /d. We need these to get the indices
  of the capturing groups.

  These rules adapted from https://github.com/sequitur/ink-vscode/blob/master/syntaxes/ink.YAML-tmLanguage
*/

// NOTE: tricky beginning of the DIVERT_RE:   ^((?!\/\/).)*
// this matches anything up to "//" - it lets us have content before a comment starts
const DIVERT_RE =
  /^((?!\/\/).)*(->|<-)\s*((?:(DONE)|(END)|(\w+))(?:\s*\.\s*(?:\w+))*\s*(?:\([^)]+\))?)?/d
export const DIVERT: SyntaxRuleConfig = {
  re: DIVERT_RE,
  captures: {
    '2': 'ink-divert-arrow',
    '4': 'ink-done',
    '5': 'ink-end',
    '6': 'ink-divert-target',
  },
}

const COMMENT_RE = /(\/\/.*)$/d
export const COMMENT: SyntaxRuleConfig = {
  re: COMMENT_RE,
  captures: {
    '1': 'ink-comment',
  },
}

/*
# knot declaration
- match: ^\s*(={2,})\s*(function)?\s*(\w+)\s*(\([^)]*\))?\s*(={1,})?
  captures:
    '1': {name: markup.punctuation}
    '2': {name: keyword.function}
    '3': {name: entity.name.knot}
    '4': {name: variable.parameter}
    '5': {name: markup.punctuation}
  name: meta.knot.declaration


  modifications: changed the ending punctuation to require 2+ = signs
  to match inky.

*/

const KNOT_RE = /^\s*(={2,})\s*(function)?\s*(\w+)\s*(\([^)]*\))?\s*(={2,})?/d
export const KNOT: SyntaxRuleConfig = {
  re: KNOT_RE,
  captures: {
    1: 'ink-knot-punctuation',
    2: 'ink-function',
    3: 'ink-knot-name',
    4: 'ink-function-param',
    5: 'ink-knot-punctuation',
  },
}

/*
# globalVAR declaration

  modifications: capture the entire line to match inky.

*/
const GLOBAL_VAR_RE = /^\s*((VAR|CONST)\s.*)$/d
export const GLOBAL_VAR: SyntaxRuleConfig = {
  re: GLOBAL_VAR_RE,
  captures: {
    1: 'ink-global-declaration',
  },
}

/*
# stitch declaration
*/
const STITCH_RE = /^\s*(=)\s*(\w+)\s*(\([^)\n]*\))?\s*$/d
export const STITCH: SyntaxRuleConfig = {
  re: STITCH_RE,
  captures: {
    1: 'ink-stitch-punctuation',
    2: 'ink-stitch-name',
    3: 'ink-stitch-param',
  },
}

/*
# logicLine
*/
const LOGIC_LINE_RE = /^\s*(~\s?.*)$/d
export const LOGIC_LINE: SyntaxRuleConfig = {
  re: LOGIC_LINE_RE,
  captures: {
    1: 'ink-logic-line',
  },
}

/*
# tag/hashtag

modified to account for a lack of whitespace
*/
const HASHTAG_RE = /(#\w.*)$/d
export const HASHTAG: SyntaxRuleConfig = {
  re: HASHTAG_RE,
  captures: {
    '1': 'ink-hashtag',
  },
}

/*
# choice
*/
const CHOICE_RE = /^\s*((?:[*+]\s?)+)\s*(\(\s*(\w+)\s*\))?/d
export const CHOICE: SyntaxRuleConfig = {
  re: CHOICE_RE,
  captures: {
    '1': 'ink-choice',
    '2': 'ink-choice-label',
  },
}

/*
# gather
*/
const GATHER_RE = /^\s*((?:-\s*)+)(?!>)(\(\s*(\w+)\s*\))?/d
export const GATHER: SyntaxRuleConfig = {
  re: GATHER_RE,
  captures: {
    '1': 'ink-gather',
    '2': 'ink-gather-label',
  },
}

const INLINE_LOGIC_RE = /(\{.*\})/d
export const INLINE_LOGIC: SyntaxRuleConfig = {
  re: INLINE_LOGIC_RE,
  captures: {
    '1': 'ink-inline-logic',
  },
}

export const inkSyntaxRules = [
  DIVERT,
  COMMENT,
  KNOT,
  GLOBAL_VAR,
  STITCH,
  LOGIC_LINE,
  HASHTAG,
  CHOICE,
  GATHER,
  INLINE_LOGIC,
]
