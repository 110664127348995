import { Pagination, Space, Stack } from '@mantine/core'

import { ApiSearchResult, PAGE_SIZE } from './helpers'

import styles from './Pagination.module.scss'

export const SearchPagination = ({
  onChange,
  apiResult,
}: {
  apiResult: ApiSearchResult
  onChange: (num: number) => void
}) => {
  if (!apiResult.isSuccess) {
    return null
  }
  const { total, from } = apiResult.data.body

  if (total <= PAGE_SIZE) return null

  return (
    <Stack gap={5} align="center">
      <Space h={10} />
      <Pagination
        withControls={false}
        total={Math.ceil(total / PAGE_SIZE)}
        value={Math.floor(from / PAGE_SIZE) + 1}
        onChange={onChange}
        size="xs"
        gap={5}
        classNames={{
          control: styles.search_pagination_control,
        }}
      />
      <Space h={10} />
    </Stack>
  )
}
