import {
  NodeTypeKey,
  NodeTypeMap,
  ScriptDocType,
  ScriptFormatMap,
} from '@showrunner/codex'

const {
  // block types
  ACTION,
  CHARACTER,
  DIALOGUE,
  PARENTHETICAL,
  END_OF_ACT,
  GENERAL,
  NEW_ACT,
  SCENE_HEADING,
  TRANSITION,
  BRACKET,
  SLUG,
  // special types
  DUAL_DIALOGUE,
} = NodeTypeMap

const dualTypes = [DIALOGUE, PARENTHETICAL]

// the scripto classic emulator and studio scripts are both light on context dependent tab ordering
const CLASSIC_TAB_ORDER = [DIALOGUE, BRACKET, CHARACTER, PARENTHETICAL, SLUG]
const STUDIO_TAB_ORDER = [
  DIALOGUE,
  BRACKET,
  CHARACTER,
  PARENTHETICAL,
  SLUG,
  GENERAL,
]

// map of nodes to create on enter for given current node and doc type
const enter: Record<string, Record<ScriptDocType, NodeTypeKey>> = {
  // same in both
  [CHARACTER]: same(DIALOGUE),
  [END_OF_ACT]: same(NEW_ACT),
  [GENERAL]: same(GENERAL),
  [PARENTHETICAL]: same(DIALOGUE),

  // screenplay only
  [ACTION]: {
    screenplay: ACTION,
    variety: DIALOGUE,
    classic: DIALOGUE,
  },
  [SCENE_HEADING]: {
    screenplay: ACTION,
    variety: DIALOGUE,
    classic: DIALOGUE,
  },
  [TRANSITION]: {
    screenplay: SCENE_HEADING,
    variety: SLUG,
    classic: SLUG,
  },

  // variety only
  [BRACKET]: same(DIALOGUE),
  [SLUG]: same(DIALOGUE),

  // different
  [DIALOGUE]: {
    screenplay: ACTION,
    variety: DIALOGUE,
    classic: DIALOGUE,
  },
  [NEW_ACT]: {
    screenplay: SCENE_HEADING,
    variety: SLUG,
    classic: DIALOGUE,
  },
}

// map of valid nodes to tab to for given last node and doc type
const tab: Record<string, Record<ScriptDocType, NodeTypeKey[]>> = {
  blank: {
    screenplay: [SCENE_HEADING, ACTION, CHARACTER],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  // same in both
  [END_OF_ACT]: {
    screenplay: [NEW_ACT],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  [ACTION]: {
    screenplay: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  // screenplay only
  [SCENE_HEADING]: {
    screenplay: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  [TRANSITION]: {
    screenplay: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  // variety only
  [BRACKET]: {
    screenplay: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  [SLUG]: {
    screenplay: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  // different in both
  [GENERAL]: {
    screenplay: [GENERAL, ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  [CHARACTER]: {
    screenplay: [DIALOGUE, PARENTHETICAL],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  [DIALOGUE]: {
    screenplay: [ACTION, CHARACTER, PARENTHETICAL, TRANSITION, SCENE_HEADING],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  [NEW_ACT]: {
    screenplay: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  [PARENTHETICAL]: {
    screenplay: [DIALOGUE, PARENTHETICAL],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
  // special case, but same as dialogue
  [DUAL_DIALOGUE]: {
    screenplay: [ACTION, CHARACTER, TRANSITION, SCENE_HEADING],
    variety: STUDIO_TAB_ORDER,
    classic: CLASSIC_TAB_ORDER,
  },
}

/**
 * convenience wrapper for nodes with same behavior in both doc types
 */
function same(nodeType: NodeTypeKey): Record<ScriptDocType, NodeTypeKey> {
  return {
    screenplay: nodeType,
    variety: nodeType,
    classic: nodeType,
  }
}

/**
 * Given the current node and doc type, tells you what the next node should be
 */
export function nextNodeOnEnter(
  blockType: NodeTypeKey,
  docType: ScriptDocType,
  isDual: boolean,
): NodeTypeKey {
  // next for dual is always dialogue
  if (isDual) return DIALOGUE

  // no mapping for next node in current doc type, use fallback
  return enter[blockType][docType] ?? DIALOGUE
}

/**
 * Given the current node and doc type, tells you what the next node should be
 */
export function nextNodeOnTab(
  blockType: NodeTypeKey,
  lastBlockType: NodeTypeKey,
  docType: ScriptDocType,
  options: { reverse: boolean; dual: boolean },
) {
  const { reverse, dual } = options || {}
  const nodeTypes = dual
    ? dualTypes
    : lastBlockType
      ? tab[lastBlockType][docType]
      : tab.blank[docType]
  // no mapping for next node in current doc type, use fallback
  if (typeof nodeTypes === 'undefined') {
    if (docType === ScriptFormatMap.SCREENPLAY) {
      return ACTION
    }
    return DIALOGUE
  }
  let index = nodeTypes.indexOf(blockType)
  // if current node is not in list, use first
  if (index === -1) {
    return nodeTypes[0]
  }
  const len = nodeTypes.length
  if (reverse) {
    index--
    // return previous if index is valid
    if (index >= 0) {
      return nodeTypes[index]
    }
    return nodeTypes[len - 1] // return last
  }
  index++
  // if next index is less than length, it's valid
  if (index < len) {
    return nodeTypes[index]
  }
  // if next index is equal to or higher than length, start over
  return nodeTypes[0] // return first
}
