import React from 'react'

import { getHotkeyHandler } from '@mantine/hooks'
import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { handleClick } from '@choo-app/lib/editor/handleClick'
import { getConfigData } from '@choo-app/lib/editor/plugins/configData'
import { InlineInkStyles } from '@ink/components'
import { ScriptWrapper } from '@layouts/Script'
import { useMst } from '@state'
import {
  createComparisonEditorState,
  createEditorView,
  createSnapshotEditorState,
  getPageCount,
} from '@util'
import { LEGACY_PAGE_LAYOUT } from '@util/PageLayout'
import {
  ScriptPayload,
  ScriptSnapshotPayload,
} from '@util/ScriptoApiClient/types'

/**
 * helper to intercept CMD+A and select the contents of
 * contenteditable=false in read only editor routes
 *
 * cribbed from https://stackoverflow.com/a/6150060/3019940
 * and myself back in 2021 https://github.com/showrunner/wallaby/pull/1034
 */
function selectAll(event: React.KeyboardEvent, el?: Element) {
  if (!el) return
  // dont select everything on the page
  event.preventDefault()
  // select only whats in contenteditable
  const range = document.createRange()
  range.selectNodeContents(el)
  const sel = window.getSelection()
  sel?.removeAllRanges()
  sel?.addRange(range)
}

export function SnapshotViewerBase({
  editorState,
  pageless,
  noWrap,
  editorZoom = 1,
  children,
}: {
  editorZoom?: number
  pageless?: boolean
  noWrap?: boolean
  editorState: EditorState
  children?: React.ReactNode
}) {
  // store a ref to the PM editor view. We want to destroy it if the snapshot
  // or config changes
  const editorViewRef = React.useRef<EditorView | null>(null)
  const { scriptType, paginationType, currentBlockFormats } =
    getConfigData(editorState)

  const pageCount = getPageCount(editorState)

  const ref = React.useCallback(
    (node: HTMLDivElement | null) => {
      // always destroy the editorView. This happens on unmount or
      // if we get a different snapshot or docType.
      if (editorViewRef.current) {
        editorViewRef.current.destroy()
      }
      // if node is not null, we either mounted or changed props, go
      // ahead and create a new editorView and attach.
      if (node) {
        editorViewRef.current = createEditorView({
          element: node,
          editorProps: {
            state: editorState,
            attributes: {
              class: 'is-static',
            },
            editable: () => false,
            handleClick,
          },
        })
      }
    },
    [editorState],
  )

  // the mantine hook typings were easier to wrap my mind around because
  // useShortcuts accounts for both click and keyboard triggered events
  const selectAllHandler = getHotkeyHandler([
    ['mod+A', (e) => selectAll(e, editorViewRef.current?.dom)],
  ])

  React.useEffect(() => {
    document.body.addEventListener('keydown', selectAllHandler)
    return () => document.body.removeEventListener('keydown', selectAllHandler)
  }, [selectAllHandler])

  return (
    <ScriptWrapper
      pageCount={pageCount}
      zoomLevel={editorZoom}
      pageless={pageless}
      noWrap={noWrap}
      blockFormats={currentBlockFormats}
      paginationType={paginationType}
      pageLayout={LEGACY_PAGE_LAYOUT}
      showMarginRuler={false}
    >
      {children}
      {scriptType === 'ink' && <InlineInkStyles messages={[]} />}
      <div ref={ref} id="prosemirror-editor" />
    </ScriptWrapper>
  )
}

export const StaticSnapshotViewer = ({
  snapshot,
}: {
  snapshot: Pick<ScriptSnapshotPayload, 'doc' | 'scriptFormat'>
}) => {
  const editorState = createSnapshotEditorState(snapshot, useMst())
  return <SnapshotViewerBase editorState={editorState} />
}

export const CompareSnapshotViewer = ({
  script,
  snapshot,
}: {
  script: Pick<ScriptPayload, 'doc' | 'scriptFormat'>
  snapshot: Pick<ScriptSnapshotPayload, 'doc'>
}) => {
  const editorState = createComparisonEditorState({
    script,
    snapshot,
  })
  return <SnapshotViewerBase editorState={editorState} />
}
