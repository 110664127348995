export type ServerConfig = {
  API_URL: string
  NIDO_URL: string
  LOG_LEVEL: 'error' | 'warn' | 'info' | 'debug' | 'silent'
  MIXPANEL_TOKEN: string
  NODE_ENV: 'development' | 'test' | 'production'
  SLACK_CLIENT_ID: string
  HTTP_TIMEOUT_MS?: number
}

export const KNOWN_SERVERS = [
  'local',
  'production',
  'staging',
  'skatepark',
  'flanders',
  'juan-dev',
  'boon-test',
  'alex-dev',
] as const

export type KnownServer = (typeof KNOWN_SERVERS)[number]
export type NamedServerConfig = ServerConfig & { name: KnownServer }
