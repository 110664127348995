import React from 'react'

import { Group, Popover } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { Toolbar } from '@components/Toolbar'
import { useMst } from '@state'
import { ButtonMode } from '@state/types'

import { FormattingButtons } from './Buttons'

export const FormattingMenu = observer(function FormattingMenu({
  mode,
}: {
  mode: ButtonMode
}) {
  const { currentScript } = useMst()
  const [opened, setOpened] = React.useState(false)

  if (!currentScript?.observableEditor) return null

  const tooltip = 'Text formatting options'

  return (
    <Popover
      position="bottom"
      shadow="md"
      opened={opened}
      closeOnClickOutside={false}
      closeOnEscape={false}
      keepMounted
    >
      <Popover.Target>
        <Toolbar.Button
          onClick={() => setOpened((o) => !o)}
          icon="fa-text-size"
          dropdown
          tooltip={tooltip}
          disableTooltip={opened}
          focusEditor
        />
      </Popover.Target>
      <Popover.Dropdown>
        <Group gap={0}>
          <FormattingButtons overflow={true} mode={mode} />
        </Group>
      </Popover.Dropdown>
    </Popover>
  )
})
